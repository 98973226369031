import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import { connect } from "react-redux";
import { userSelectorById } from "../../poll/selectors";
import { profilesPOST } from "../../api/apiEndpoints";
import history from "../../history";
import { routes } from "../../router/router";
import { poll } from "../../poll/actions";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import FormManager from "../../components/form/FormManager";
import FormRow from "../../components/form/FormRow";
import InputText from "../../components/input/InputText";
import InputPassword from "../../components/input/InputPassword";

class CreateUserModal extends React.Component {
	render() {
		return (
			<div>
				<FormManager
					initialFormValues={{
						Email: "",
						Password: "",
						ConfirmPassword: "",
						StageName: ""
					}}
					handleValidation={v => {
						v.email({ fieldName: "Email" });
						v.mustNotExistInDB({
							fieldName: "Email",
							storeKey: "Users",
							objectKey: "Email"
						});
						v.mustNotExistInDB({
							fieldName: "StageName",
							storeKey: "Profiles",
							objectKey: "StageName"
						});
						v.url({ fieldName: "Facebook" });
						v.password({ fieldName: "Password" });
						v.confirmPassword({
							fieldName: "ConfirmPassword",
							passwordFieldName: "Password"
						});
						v.required({
							fieldNames: ["Email", "StageName", "Password", "ConfirmPassword"]
						});
					}}
					handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
						profilesPOST({
							StageName: formValues.StageName,
							Email: formValues.Email,
							Password: formValues.Password
						})
							.then(response => {
								handleSubmitSuccess();
								this.props.dispatch(poll({}));
								history.push(routes.Profile + "/" + response.data.UserId);
							})
							.catch(() => {
								handleSubmitFail();
							});
					}}
				>
					{/*FormManager children*/}
					{({
						formValues,
						handleOnChange,
						handleSubmit,
						formValidation,
						submitEnabled,
						submitting
					}) => {
						return (
							<div>
								<FormRow
									label={"Stage Name"}
									value={
										<InputText
											name={"StageName"}
											value={formValues.StageName}
											onChange={handleOnChange}
											validationMessages={formValidation.StageName}
										/>
									}
								/>
								<FormRow
									label={"Email"}
									value={
										<InputText
											name={"Email"}
											value={formValues.Email}
											onChange={handleOnChange}
											validationMessages={formValidation.Email}
										/>
									}
								/>
								<FormRow
									label={"Password"}
									value={
										<InputPassword
											name={"Password"}
											value={formValues.Password}
											onChange={handleOnChange}
											validationMessages={formValidation.Password}
										/>
									}
								/>
								<FormRow
									label={"Confirm Password"}
									value={
										<InputPassword
											name={"ConfirmPassword"}
											value={formValues.ConfirmPassword}
											onChange={handleOnChange}
											validationMessages={formValidation.ConfirmPassword}
										/>
									}
								/>

								<AdminContainerMigrate
									align={"right"}
									buttons={[
										<LoadingButton
											onClick={handleSubmit}
											loading={submitting}
											disabled={!submitEnabled}
										>
											Create
										</LoadingButton>
									]}
								/>
							</div>
						);
					}}
				</FormManager>
			</div>
		);
	}
}

CreateUserModal.propTypes = {
	modalOpen: PropTypes.bool,
	onCloseFn: PropTypes.func
};

const mapStateToProps = state => {
	return {
		users: userSelectorById(state)
	};
};

export default connect(mapStateToProps)(CreateUserModal);
