import * as React from "react";
import PropTypes from "prop-types";

export default class SearchBarItem extends React.Component {
	render() {
		return (
			<div className="">
				{/* Large */}
				<div className="col-lg-3 hidden-xs hidden-sm hidden-md">
					<div className="row">
						<div className="col-lg-12 input-text-top-bottom-padding">
							{this.props.label}
						</div>
						<div className="col-lg-12">{this.props.value}</div>
					</div>
				</div>
				{/* Medium */}
				<div className="col-md-4 hidden-xs hidden-sm hidden-lg">
					<div className="row">
						<div className="col-md-12 input-text-top-bottom-padding">
							{this.props.label}
						</div>
						<div className="col-md-12">{this.props.value}</div>
					</div>
				</div>
				{/* Small */}
				<div className="col-sm-12 hidden-lg hidden-md">
					<div className="row">
						<div className="col-sm-12 input-text-top-bottom-padding">
							{this.props.label}
						</div>
						<div className="col-sm-12">{this.props.value}</div>
					</div>
				</div>
			</div>
		);
	}
}

SearchBarItem.propTypes = {
	label: PropTypes.any,
	value: PropTypes.any
};
