import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MiniContainer from "../components/container/MiniContainer";
import ResponsiveTableNew from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import LoadingButton from "../components/button/LoadingButton";
import FormManager from "../components/form/FormManager";
import { levelSelectorAsArray, levelSelectorById } from "../poll/selectors";
import InputNumber from "../components/input/InputNumber";
import { updateLevelsPATCH } from "../api/apiEndpoints";

class AdminManageLevels extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let sortedLevels = [...this.props.levelsAsArray];

		sortedLevels.sort((a, b) => {
			return a["Id"] - b["Id"];
		});

		const colSpacing = [3, 3, 3];
		return (
			<div>
				<h4 className="heading">Manage Levels</h4>
				<MiniContainer>
					<ResponsiveTableNew
						values={sortedLevels}
						headings={[
							{
								label: "Level",
								size: colSpacing[0],
								disableSort: true
							},
							{
								label: "Weeks",
								size: colSpacing[1],
								disableSort: true
							},
							{
								label: "",
								size: colSpacing[2],
								disableSort: true
							}
						]}
					>
						{({ sortedValues }) => {
							return sortedValues.map((row, rowIdx) => {
								return (
									<div key={rowIdx} className="row col-sm-12 div-table-responsive-row">
										<ResponsiveTableColumn size={colSpacing[0]} heading="Level">
											{row.Level}
										</ResponsiveTableColumn>

										<FormManager
											initialFormValues={row}
											handleSubmit={({
												formValues,
												handleSubmitFail,
												handleSubmitSuccess
											}) => {
												updateLevelsPATCH({
													Id: formValues.Id,
													BookAheadWeeks: formValues.BookAheadWeeks
												})
													.then(() => {
														handleSubmitSuccess();
													})
													.catch(() => {
														handleSubmitFail();
													});
											}}
										>
											{/*FormManager children*/}
											{({
												formValues,
												handleSubmit,
												submitEnabled,
												submitting,
												handleOnChange
											}) => {
												return (
													<div>
														<ResponsiveTableColumn size={colSpacing[1]} heading="Weeks">
															<InputNumber
																name={"BookAheadWeeks"}
																value={formValues.BookAheadWeeks}
																onChange={handleOnChange}
															/>
														</ResponsiveTableColumn>
														<ResponsiveTableColumn size={colSpacing[2]} heading="Edit">
															<LoadingButton
																onClick={handleSubmit}
																loading={submitting}
																disabled={!submitEnabled}
															>
																Update
															</LoadingButton>
														</ResponsiveTableColumn>
													</div>
												);
											}}
										</FormManager>
									</div>
								);
							});
						}}
					</ResponsiveTableNew>
				</MiniContainer>
			</div>
		);
	}
}

AdminManageLevels.propTypes = {};

const mapStateToProps = state => {
	return {
		levelsAsArray: levelSelectorAsArray(state),
		levelsById: levelSelectorById(state)
	};
};

export default connect(mapStateToProps)(AdminManageLevels);
