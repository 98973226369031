import * as React from "react";
import { connect } from "react-redux";
import BaseLayout from "./BaseLayout";
import { userGroups } from "../lib/userInfo/userInfo";
import { venueBibleTermsConditionsHeading } from "../Bible/VenueBible";
import { skimpyBibleTermsConditionsHeading } from "../Bible/SkimpyBible";
import { routes } from "../router/router";
import Notifications from "../Notifications/Notifications";
import "./BaseLayout.css";
import { NavLink } from "react-router-dom";
import Logout from "./components/Logout";
import {
	pollLoading,
	skimpyBibleSelectorById,
	venueBibleSelectorById
} from "../poll/selectors";
import { getAuthUserId } from "../auth/selectors";
import CollapsibleMenu from "./components/CollapsibleMenu";
import MenuItem from "./components/MenuItem";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import HrmChecklistNotificationBadge from "../HrmChecklist/HrmChecklistNotificationBadge";
import UserCredit from "../UserCredit/UserCredit";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import ChangePassword from "../Password/ChangePassword";
import AppVersion from "../components/appVersion/AppVersion";

class VenueLayout extends React.Component {
	render() {
		const { skimpyBible, venueBible } = this.props;
		// Conv from obj => array
		let skimpyBibleOrdered = Object.keys(skimpyBible).map(id => {
			return skimpyBible[id];
		});
		let venueBibleOrdered = Object.keys(venueBible).map(id => {
			return venueBible[id];
		});

		// sort the entries by Order key
		skimpyBibleOrdered.sort((a, b) => {
			return a.Heading.localeCompare(b.Heading);
		});

		skimpyBibleOrdered = skimpyBibleOrdered.filter(entry => {
			if (entry.Id === 1) {
				return false;
			}
			return true;
		});
		venueBibleOrdered.sort((a, b) => {
			return a.Heading.localeCompare(b.Heading);
		});

		venueBibleOrdered = venueBibleOrdered.filter(entry => {
			if (entry.Id === 1) {
				return false;
			}
			return true;
		});

		return (
			<BaseLayout
				renderLeftMenu={
					<React.Fragment>
						<CollapsibleMenu
							initCollapsed={false}
							heading="Menu"
							icon={"fas fa-home"}
							collapsable={false}
						>
							<MenuItem route={routes.ClientLineup}>Lineup</MenuItem>
							<MenuItem route={routes.ClientFeedback}>Feedback</MenuItem>
							<MenuItem route={routes.ClientVenue}>Venue</MenuItem>
							<li className="left_menu_item_sub">
								<StandardModalWrapper
									heading={"Change Password"}
									modalOpener={({ handleOpenModal }) => {
										return (
											<button onClick={handleOpenModal} className="btn-clear color-white">
												<i className="fas fa-key" /> Change Password
											</button>
										);
									}}
									render={({ handleCloseModal }) => {
										return (
											<ChangePassword
												handleSaveSuccess={handleCloseModal}
												userId={this.props.authId}
											/>
										);
									}}
								/>
							</li>
						</CollapsibleMenu>
						<CollapsibleMenu
							heading="INFO & IDEAS"
							icon="fas fa-book"
							initCollapsed={false}
							collapseDisabled={true}
						>
							<MenuItem
								route={
									routes.ClientInfoAndIdeas + "/" + venueBibleTermsConditionsHeading
								}
							>
								{"Terms & Conditions"}
							</MenuItem>
							{venueBibleOrdered.map((bibleEntry, idx) => {
								return (
									<MenuItem
										key={idx}
										route={routes.ClientInfoAndIdeas + "/" + bibleEntry.Heading}
									>
										{bibleEntry.Heading}
									</MenuItem>
								);
							})}
						</CollapsibleMenu>
						<Logout />
						<AppVersion />
					</React.Fragment>
				}
				renderTopMenu={[]}
				{...this.props}
			/>
		);
	}
}

VenueLayout.propTypes = {};

const mapStateToProps = (state, props) => {
	return {
		skimpyBible: skimpyBibleSelectorById(state),
		venueBible: venueBibleSelectorById(state),
		authId: getAuthUserId(state),
		pollLoading: pollLoading(state)
	};
};

VenueLayout = connect(mapStateToProps)(VenueLayout);
export default VenueLayout;
