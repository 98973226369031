import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormRow from "../components/form/FormRow";
import SkimpyList from "../components/select/SkimpyList";
import FormManager from "../components/form/FormManager";
import { allPaymentsPOST, createPaymentPOST } from "../api/apiEndpoints";
import Money from "../components/text/Money";
import InputNumber from "../components/input/InputNumber";
import PaymentMethodList from "../components/select/PaymentMethodList";
import InputTextArea from "../components/input/InputTextArea";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";

class CreatePayment extends React.Component {
	render() {
		return (
			<div>
				<FormManager
					initialFormValues={{
						UserId: undefined,
						Amount: 0,
						// PaymentCode: undefined,
						// PaymentCategorySubCodesId: undefined,
						Notes: "",
						Credit: undefined,
						PaymentMethod: undefined
					}}
					// handleSubmit={}
					handleValidation={v => {
						v.required({
							fieldNames: [
								"UserId",
								"Amount",
								// "PaymentCode",
								"Notes"
							]
						});
					}}
					handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
						createPaymentPOST({
							UserId: formValues.UserId,
							Amount: formValues.Amount,
							PaymentCode: 2,
							// PaymentCode: formValues.PaymentCode,
							// PaymentCategorySubCodesId: formValues.PaymentCategorySubCodesId,
							Notes: formValues.Notes,
							// Hardcoded values
							PaymentType: 1, // 2 = Charge, 1 = Payment
							PaymentMethod: formValues.PaymentMethod // 2 = Adjustment
						})
							.then(() => {
								handleSubmitSuccess();
								this.props.handleSaveReturnUserId(formValues.UserId);
							})
							.catch(error => {
								handleSubmitFail();
								throw error;
							});
					}}
				>
					{/*FormManager children*/}
					{({
						formValues,
						handleOnChange,
						handleSubmit,
						formValidation,
						handleSetFormValues,
						submitEnabled,
						submitting
					}) => {
						const handleUserIdChange = e => {
							handleSetFormValues({
								...formValues,
								UserId: e.target.value,
								Credit: undefined
							});

							if (e.target.value !== undefined) {
								allPaymentsPOST({
									UserId: e.target.value,
									StartDate: "01/01/2000",
									EndTime: "01/01/3000"
								}).then(response => {
									handleSetFormValues({
										...formValues,
										UserId: e.target.value,
										Credit: response.data.Credit
									});
								});
							}
						};

						return (
							<div>
								<FormRow
									label={"Account"}
									value={
										<SkimpyList
											name={"UserId"}
											value={formValues.UserId}
											onChange={handleUserIdChange}
										/>
									}
								/>

								{formValues.Credit !== undefined && (
									<FormRow
										label={"Credit"}
										value={
											<Money negativeValuesAreRed={true} value={formValues.Credit} />
										}
									/>
								)}

								<FormRow
									label={"Payment Amount"}
									value={
										<InputNumber
											name={"Amount"}
											value={formValues.Amount}
											onChange={handleOnChange}
											validationMessages={formValidation.Amount}
											allowEmptyValue={true}
										/>
									}
								/>

								<FormRow
									label={"Payment Method"}
									value={
										<PaymentMethodList
											name={"PaymentMethod"}
											onChange={handleOnChange}
											value={formValues.PaymentMethod}
										/>
									}
								/>

								<FormRow
									label={"Description"}
									value={
										<InputTextArea
											name={"Notes"}
											value={formValues.Notes}
											onChange={handleOnChange}
											validationMessages={formValidation.Notes}
										/>
									}
								/>

								<AdminContainerMigrate
									align={"right"}
									buttons={[
										<LoadingButtonWithConfirm
											onClick={handleSubmit}
											disabled={!submitEnabled}
											loading={submitting}
											className="btn btn-success"
										>
											Save
										</LoadingButtonWithConfirm>
									]}
								/>
							</div>
						);
					}}
				</FormManager>
			</div>
		);
	}
}

CreatePayment.propTypes = {
	handleSaveReturnUserId: PropTypes.func
};

export default connect()(CreatePayment);
