import { REDUX_ROOT_AUTH } from "../store";

export function rootSelector(state) {
	return state[REDUX_ROOT_AUTH];
}

export function selectTokenDecoded(state) {
	return rootSelector(state)["tokenDecoded"];
}

export function getAuthUserId(state) {
	return rootSelector(state)["tokenDecoded"]["userId"];
}

export function getAuthUserGroupId(state) {
	return rootSelector(state)["tokenDecoded"]["UserGroupId"];
}
