import * as React from "react";
import PropTypes from "prop-types";

class ToggleSlider extends React.Component {
	render() {
		let className = "";

		if (this.props.value === true) {
			className = "fas fa-toggle-on";
		} else if (this.props.value === false) {
			className = "fas fa-toggle-off";
		} else {
			className = "fas fa-toggle-on";
		}

		return <i className={className} />;
	}
}

ToggleSlider.propTypes = {
	value: PropTypes.bool
};

export default ToggleSlider;
