import * as React from "react";
import { connect } from "react-redux";
import {
	clientSelectorAsArray,
	hrmsByClientId,
	userSelectorById
} from "../poll/selectors";
import VenueEmailAddressesModal from "./components/VenueEmailAddressesModal";
import ClientName from "../components/links/ClientName";
import ClientTelephone from "../components/links/ClientTelephone";
import ClientAddress from "../components/text/ClientAddress";
import ProfileStageName from "../components/links/ProfileStageName";
import AllHrmsList from "../components/select/AllHrmsList";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import ResponsiveTableNew, {
	sortDirectionOptions
} from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import InputCheckbox from "../components/input/InputCheckbox";
import { poll } from "../poll/actions";
import CreateVenueModal from "./components/CreateVenueModal";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";

class VenueSearch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			venuesUnfiltered: [],
			venuesFiltered: [],
			filteredResultCount: 0,
			filterSearchTerm: "",
			filterHrmUserId: undefined,
			filterShowInactiveVenues: false,
			showAdvancedFilters: false
		};
	}

	handleFilterChange = e => {
		switch (e.target.name) {
			case "searchFilter": {
				this.setState(
					{ filterSearchTerm: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
			case "hrmFilter": {
				this.setState(
					{ filterHrmUserId: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
			case "filterShowInactiveVenues": {
				this.setState(
					{ filterShowInactiveVenues: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
		}
	};

	handleToggleShowAdvancedFilters = () => {
		if (this.state.showAdvancedFilters) {
			this.setState({ showAdvancedFilters: false });
		} else {
			this.setState({ showAdvancedFilters: true });
		}
	};

	onLoad = nextProps => {
		const venuesUnfiltered = nextProps.venuesAsArray.map(venue => {
			const venueHRMs = nextProps.hrmsByClientId[venue.Id] || [];

			return {
				_ClientName:
					venue.ClientName !== null ? venue.ClientName.toLowerCase() : "",
				_PhoneNumber: venue.PhoneNumber !== null ? venue.PhoneNumber : "",
				_AddressSuburb:
					venue.AddressSuburb !== null ? venue.AddressSuburb.toLowerCase() : "",
				_AddressRoad:
					venue.AddressRoad !== null ? venue.AddressRoad.toLowerCase() : "",
				HRMUserIds: venueHRMs.map(hrm => {
					return hrm.UserId;
				}),
				...venue
			};
		});

		this.setState(
			{
				venuesUnfiltered: venuesUnfiltered,
				venuesFiltered: venuesUnfiltered
			},
			this.filterAndCreateDisplayComponents
		);
	};

	filterAndCreateDisplayComponents = () => {
		const filterSearchTerm = this.state.filterSearchTerm.toLowerCase();
		let venuesFiltered = this.state.venuesUnfiltered;

		// Filter by search term if set
		if (filterSearchTerm !== "") {
			venuesFiltered = venuesFiltered.filter(venue => {
				return (
					venue._ClientName.indexOf(filterSearchTerm) !== -1 ||
					venue._PhoneNumber.indexOf(filterSearchTerm) !== -1 ||
					venue._AddressSuburb.indexOf(filterSearchTerm) !== -1 ||
					venue._AddressRoad.indexOf(filterSearchTerm) !== -1
				);
			});
		}

		// Filter by hrm
		if (this.state.filterHrmUserId !== undefined) {
			venuesFiltered = venuesFiltered.filter(venue => {
				return venue["HRMUserIds"].find(userId => {
					return userId === this.state.filterHrmUserId;
				});
			});
		}

		// Filter active pubs only
		if (!this.state.filterShowInactiveVenues) {
			venuesFiltered = venuesFiltered.filter(venue => {
				return venue.DeletedAt === null;
			});
		}

		// Only render the paginated results
		this.setState({
			filteredResultCount: venuesFiltered.length,
			venuesFiltered: venuesFiltered
		});
	};

	render() {
		return (
			<div>
				<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
					{this.renderHeader()}
				</ProtectedComponent>

				<div className="row" style={{ marginBottom: 15 }}>
					<div className="col-lg-12">
						<div className="mini-container">{this.renderSearch()}</div>
					</div>
				</div>

				<div className="row ">
					<div className="col-lg-12">
						<div className="mini-container">{this.renderContent()}</div>
					</div>
				</div>
			</div>
		);
	}

	renderHeader() {
		return (
			<div className="admin-box">
				<StandardModalWrapper
					heading={"Create Venue"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<button
								className="admin-box-button btn btn-primary"
								onClick={handleOpenModal}
							>
								<i className="fas fa-address-card" /> Create Venue
							</button>
						);
					}}
					render={({ handleCloseModal }) => {
						const handleCreateVenue = clientName => {
							this.setState({
								filterSearchTerm: clientName
							});
							handleCloseModal();
						};

						return <CreateVenueModal onSaveFn={handleCreateVenue} />;
					}}
				/>

				<StandardModalWrapper
					heading={"Venue Email Addresses"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<button
								className="admin-box-button btn btn-primary"
								onClick={handleOpenModal}
							>
								<i className="fas fa-envelope" /> Venue Email Addresses
							</button>
						);
					}}
					render={({ handleCloseModal }) => {
						return <VenueEmailAddressesModal />;
					}}
				/>
			</div>
		);
	}

	renderSearch() {
		if (this.state.showAdvancedFilters) {
			return (
				<div>
					{/*Advanced Search*/}

					<span
						className="heading-edit"
						onClick={this.handleToggleShowAdvancedFilters}
					>
						Simple
					</span>
					<h4 className="heading">Advanced Search</h4>
					<div className="row">
						<div className="col-lg-12">
							{/*Search Box*/}
							<input
								type="text"
								name="searchFilter"
								className="form-control"
								onChange={this.handleFilterChange}
								placeholder="Search..."
							/>

							<SearchBarItem
								label="Search Hrms"
								value={
									<AllHrmsList
										name="hrmFilter"
										onChange={this.handleFilterChange}
										value={this.state.filterHrmUserId}
									/>
								}
							/>
							<ProtectedComponent allowedGroups={[userGroups.hrm, userGroups.admin]}>
								<SearchBarItem
									label="Show Inactive Venues"
									value={
										<InputCheckbox
											name="filterShowInactiveVenues"
											onChange={this.handleFilterChange}
											value={this.state.filterShowInactiveVenues}
										/>
									}
								/>
							</ProtectedComponent>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					{/*Regular Search*/}

					<span
						className="heading-edit"
						onClick={this.handleToggleShowAdvancedFilters}
					>
						Advanced
					</span>
					<h4 className="heading">Search</h4>
					<div className="row">
						<div className="col-lg-12">
							{/*Search Box*/}
							<input
								name="searchFilter"
								type="text"
								onChange={this.handleFilterChange}
								className="form-control"
								placeholder="Search..."
							/>
						</div>
					</div>
				</div>
			);
		}
	}

	renderContent() {
		const colSpacing = [4, 2, 3, 3];

		return (
			<ResponsiveTableNew
				initialSortColumn={"_ClientName"}
				initialSortDirection={sortDirectionOptions.decending}
				values={this.state.venuesFiltered}
				headings={[
					{
						label: "Venue",
						name: "_ClientName",
						size: colSpacing[0]
					},
					{
						label: "Phone",
						name: "ContactNumber",
						size: colSpacing[1],
						disableSort: true
					},
					{
						label: "Address",
						name: "Address",
						size: colSpacing[2],
						disableSort: true
					},
					{
						label: "Venue HRM",
						name: "HRMs",
						size: colSpacing[3],
						disableSort: true
					}
				]}
			>
				{({ sortedValues }) => {
					return (
						<InfiniteScroll
							rowCount={sortedValues.length}
							rowComparison={() => {
								return false;
							}}
							rows={sortedValues}
							overscanRowCount={5}
							rowRender={({ index, row, style, redrawRow }) => {
								return (
									<div key={row} style={style} className="div-table-responsive-row">
										<ResponsiveTableColumn size={colSpacing[0]} heading="Venue">
											<ClientName clientId={row.Id} />
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[1]} heading="Phone">
											<ClientTelephone clientId={row.Id} />
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[2]} heading="Address">
											<ClientAddress clientId={row.Id} />
										</ResponsiveTableColumn>
										<ResponsiveTableColumn
											size={colSpacing[3]}
											heading="Hotel Relationship Manager"
										>
											{row.HRMUserIds.map((hrmUserId, hrmUserIdx) => {
												return (
													<div key={`${hrmUserId}.${hrmUserIdx}`}>
														<ProfileStageName userId={hrmUserId} />
													</div>
												);
											})}
										</ResponsiveTableColumn>
									</div>
								);
							}}
						/>
					);
				}}
			</ResponsiveTableNew>
		);
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
		this.onLoad(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}
}

VenueSearch.defaultProps = {};

const mapStateToProps = state => {
	return {
		venuesAsArray: clientSelectorAsArray(state),
		users: userSelectorById(state),
		hrmsByClientId: hrmsByClientId(state)
	};
};

export default connect(mapStateToProps)(VenueSearch);
