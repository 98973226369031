import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import FormManager from "../components/form/FormManager";
import API from "../api/client";
import FormRow from "../components/form/FormRow";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import LoadingButton from "../components/button/LoadingButton";
import InputText from "../components/input/InputText";
import { usersPATCH } from "../api/apiEndpoints";
import InputPassword from "../components/input/InputPassword";
import jwtDecode from "jwt-decode";
import history from "../history";
import { NavLink } from "react-router-dom";
import { routes } from "../router/router";

class ResetPassword extends React.Component {
	state = {
		resetSuccess: false
	};

	static propTypes = {};

	render() {
		return (
			<div>
				<div
					className="container-fluid"
					style={{
						background: "#EEE",
						height: "100%",
						width: "100%",
						position: "absolute"
					}}
				>
					<div className="row">
						<div
							className="col-sm-12 col-md-4 col-md-offset-4 mini-container-shadow"
							style={{ background: "#FFF", marginTop: 50, padding: 20 }}
						>
							<div>
								<NavLink to="/">
									<img
										alt="skimpy heaven"
										style={{
											marginLeft: "auto",
											marginRight: "auto",
											display: "block",
											height: "200px"
										}}
										src="../assets/img/logo-black-no-bg.png"
									/>
								</NavLink>
							</div>
							{this.renderPage()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderPage() {
		if (this.state.resetSuccess) {
			return (
				<div>
					Your password has been changed, please{" "}
					<NavLink to={routes.Login}>click here</NavLink> to login
				</div>
			);
		}

		const decodedToken = this.props.match.params.id;	

		return (
			<FormManager
				initialFormValues={{
					Password: "",
					ConfirmPassword: ""
				}}
				handleValidation={v => {
					v.password({ fieldName: "Password" });
					v.characterCount({ fieldName: "Password", characterLimit: 60 });
					v.confirmPassword({
						fieldName: "ConfirmPassword",
						passwordFieldName: "Password"
					});
					v.required({
						fieldNames: ["Password", "ConfirmPassword"]
					});

				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					// parse the token (to get the user ID)

					axios
						.patch(
							"/api/confirm_password",
							{
								Password: formValues.Password,
								Recover: decodedToken
							}
						)
						.then(() => {
							this.setState({
								resetSuccess: true
							});
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"New Password"}
								value={
									<InputPassword
										name={"Password"}
										value={formValues.Password}
										onChange={handleOnChange}
										validationMessages={formValidation.Password}
									/>
								}
							/>
							<FormRow
								label={"Confirm Password"}
								value={
									<InputPassword
										name={"ConfirmPassword"}
										value={formValues.ConfirmPassword}
										onChange={handleOnChange}
										validationMessages={formValidation.ConfirmPassword}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										<i className="fas fa-key" /> Change Password
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {};
};

ResetPassword = connect(mapStateToProps)(ResetPassword);
export default ResetPassword;
