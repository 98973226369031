import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentMethodById } from "../../config/selectors";

class PaymentMethodText extends React.Component {
	render() {
		if (!this.props.paymentMethodById || !this.props.paymentMethodId) {
			return "";
		}

		return this.props.paymentMethodById[this.props.paymentMethodId] ? this.props.paymentMethodById[this.props.paymentMethodId].Description : '';
	}
}

PaymentMethodText.propTypes = {
	paymentMethodId: PropTypes.number
};

const mapStateToProps = state => {
	return {
		paymentMethodById: paymentMethodById(state)
	};
};

export default connect(mapStateToProps)(PaymentMethodText);
