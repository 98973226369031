export const colors = {
	black: "rgb(0, 0, 0)",
	grey: "rgb(211,211,211)",
	greyAlt: "rgb(195,195,195)",
	white: "rgb(255, 255, 255)",
	gold: "rgb(255,200,75)",
	red: "rgb(255, 100, 100)",
	redAlt: "rgb(201,48,44)",
	yellow: "rgb(240,173,78)",
	yellowAlt: "rgb(236,151,31)",
	green: "rgb(96,187,113)",
	greenAlt: "rgb(68,157,68)",
	lightBlue: "rgb(70,184,218)",
	lightBlueAlt: "rgb(60,158,188)",
	blue: "rgb(51,122,183)",
	blueAlt: "rgb(40,96,144)",
};
