import { Route, Router, Switch } from "react-router-dom";
import * as React from "react";
import LoginRoute from "./../loginScreen/login";
import history from "../history";
import VenueLayout from "../layouts/VenueLayout";
import KFW from "../KFW/KFW";
import Shifts from "../Shifts/Shifts";
import Profile from "../Profile/Profile";
import SkimpyBible from "../Bible/SkimpyBible";
import PropTypes from "prop-types";
import SkimpySearch from "../SkimpySearch/SkimpySearch";
import VenueSearch from "../VenueSearch/VenueSearch";
import Venue from "../Venue/Venue";
import SkimpyAlerts from "../SkimpyAlerts/SkimpyAlerts";
import HrmChecklistPending from "../HrmChecklist/HrmChecklistPending";
import ReportCodes from "../ReportCodes/ReportCodes";
import ManageCodes from "../ManageCodes/ManageCodes";
import MyPayments from "../MyPayments/MyPayments";
import PaymentLogs from "../PaymentLogs/PaymentLogs";
import AdminPayments from "../AdminPayments/AdminPayments";
import AdminFeedback from "../AdminFeedback/AdminFeedback";
import AdminKFW from "../AdminKFW/AdminKFW";
import AdminKFW2 from "../AdminKFW/AdminKFW2/AdminKFW2";
import AdminAdvertiseAvailables from "../AdminAdvertiseAvailables/AdminAdvertiseAvailables";
import AdminLogs from "../AdminLogs/AdminLogs";
import AdminManageBrands from "../AdminManageBrands/AdminManageBrands";
import AdminManageLevels from "../AdminManageLevels/AdminManageLevels";
import AdminEndBalance from "../AdminEndBalance/AdminEndBalance";
import AdminCurrentBalance from "../AdminCurrentBalance/AdminCurrentBalance";
import ClientLineup from "../client/ClientLineup";
import ClientFeedback from "../client/ClientFeedback";
import ClientVenue from "../client/ClientVenue";
import MyFeedback from "../MyFeedback/MyFeedback";
import Home from "../home/Home";
import { checkAndSetTokenFromLocalStorage } from "../auth/actions";
import { connect } from "react-redux";
import VenueBible from "../Bible/VenueBible";
import ClientInfoAndIdeas from "../Bible/ClientInfoAndIdeas";
import UserLayout from "../layouts/UserLayout";
import MyPictures from "../MyPictures/MyPictures";
import ResetPassword from "../loginScreen/ResetPassword";
import { convertDateToAPIDate } from "../lib/dateUtils/dateUtils";
import axios from "axios";
import execall from "execall";
import Adverts from "../Adverts/Adverts";
import AdvertDetails from "../Adverts/AdvertDetails/AdvertDetails";
import Videos from '../Videos/Videos'
import PrivateJobs from '../PrivateJobs/PrivateJobs';


class UserRoute extends React.Component {
	render() {
		const Layout = this.props.layout;
		const renderRoute = (
			<Route path={this.props.path} component={this.props.component} />
		);

		// If there's no layout, just render the route as is
		if (Layout === null) {
			return renderRoute;
		}

		return <Layout>{renderRoute}</Layout>;
	}
}

UserRoute.defaultProps = {
	layout: null
};

UserRoute.propTypes = {
	layout: PropTypes.func,
	component: PropTypes.func,
	path: PropTypes.string
};

export const routes = {
	Login: "/login",
	KFW: "/kfw",
	Shifts: "/shifts",
	PrivateJobs: '/private_jobs',
	MyFeedback: "/my_feedback",
	SkimpyBible: "/skimpy_bible",
	VenueBible: "/venue_bible",
	HotelApplications: "/hrm_checklist",
	Profile: "/profiles",
	MyPayments: "/my_payments",
	Pictures: "/pictures",
	SkimpySearch: "/skimpy_search",
	Venue: "/venues",
	VenueSearch: "/venue_search",
	SkimpyAlerts: "/skimpy_alerts",
	ReportsCodes: "/reports/codes",
	PaymentLogs: "/logs/payments",
	ManageCodes: "/manage_codes",
	AdminAccounts: "/admin_accounts",
	AdminFeedback: "/admin_feedback",
	AdminKFW: "/admin_kfw",
	AdminKFW2: "/admin_kfw2",
	AdminAdvertiseAvailables: "/admin_advertise_availables",
	AdminLogs: "/admin/logs",
	AdminManageBrands: "/admin/manage_brands",
	AdminManageLevels: "/admin/manage_levels",
	AdminEndBalance: "/admin/end_user_credit",
	AdminCurrentBalance: "/admin/current_user_credit",
	ClientLineup: "/mylineup",
	ClientFeedback: "/feedback",
	ClientVenue: "/venue",
	ClientInfoAndIdeas: "/info_and_ideas",
	ResetPassword: "/reset_password",
	Vote: "/vote",
	Adverts: '/admin/adverts',
	Videos: '/admin/videos',
};

class AppRouter extends React.Component {
	componentWillMount() {
		// Load token from local storage into redux before booting the rest of the app
		this.props.dispatch(checkAndSetTokenFromLocalStorage());

		history.listen(() => {
			this.checkVersion();
		})
	}

	checkVersion = () => {
		const nonce = convertDateToAPIDate(new Date());
		axios.get("/index.html?" + nonce, {}).then(r => {
			const searchRegex = /js\/([0-9a-z\.]+.js)/g;
			/*
				Loaded Versions
			 */
			const loadedVersions = execall(searchRegex, document.body.innerHTML).map(
				s => s.sub[0]
			);
			loadedVersions.sort((a, b) => a.localeCompare(b));
			// console.log(loadedVersions)

			/*
				Live Versions
			*/
			const liveVersions = execall(searchRegex, r.data).map(s => s.sub[0]);
			liveVersions.sort((a, b) => a.localeCompare(b));
			// console.log(liveVersions)

			if (loadedVersions[0] === "bundle.js") {
				return;
			}

			const reload = () => {
				window.location.reload(true);
			}

			if (loadedVersions.length !== liveVersions.length) {
				console.log("Reloading due to length")
				reload();
				return;
			}

			for (let i = 0; i < loadedVersions.length; i++) {
				if (loadedVersions[i] != liveVersions[i]) {
					console.log("reloading due to a remote update")
					reload();
					return;
				}
			}
		});
	};

	render() {
		return (
			<Router history={history}>
				<Switch>
					{/*Shifts*/}
					<UserRoute path={routes.KFW} component={KFW} layout={UserLayout} />
					<UserRoute path={routes.Shifts} component={Shifts} layout={UserLayout} />
					<UserRoute path={routes.PrivateJobs} component={PrivateJobs} layout={UserLayout} />
					<UserRoute
						path={routes.MyFeedback}
						component={MyFeedback}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.HotelApplications}
						component={HrmChecklistPending}
						layout={UserLayout}
					/>

					{/*Profile Settings*/}
					<UserRoute
						path={routes.Pictures + "/:id"}
						component={MyPictures}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.MyPayments}
						component={MyPayments}
						layout={UserLayout}
					/>

					{/*Skimpy Bible*/}
					<UserRoute
						path={routes.SkimpyBible}
						component={SkimpyBible}
						layout={UserLayout}
					/>
					{/*Venue Bible*/}
					<UserRoute
						path={routes.VenueBible}
						component={VenueBible}
						layout={UserLayout}
					/>

					{/*Search*/}
					<UserRoute
						path={routes.SkimpySearch}
						component={SkimpySearch}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.VenueSearch}
						component={VenueSearch}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.Venue + "/:id"}
						component={Venue}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.Profile + "/:id"}
						component={Profile}
						layout={UserLayout}
					/>

					{/*Admin Accounts*/}
					<UserRoute
						path={routes.AdminAccounts}
						component={(props) => <AdminPayments {...props}/>}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.ReportsCodes}
						component={ReportCodes}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminEndBalance}
						component={AdminEndBalance}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminCurrentBalance}
						component={AdminCurrentBalance}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.PaymentLogs}
						component={PaymentLogs}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.ManageCodes}
						component={ManageCodes}
						layout={UserLayout}
					/>

					{/*Admin Booking*/}
					<UserRoute
						path={routes.AdminFeedback}
						component={AdminFeedback}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminKFW}
						component={AdminKFW}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminKFW2}
						component={AdminKFW2}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminAdvertiseAvailables}
						component={AdminAdvertiseAvailables}
						layout={UserLayout}
					/>

					{/*Admin General*/}
					<UserRoute
						path={routes.SkimpyAlerts}
						component={SkimpyAlerts}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminLogs}
						component={AdminLogs}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminManageBrands}
						component={AdminManageBrands}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.AdminManageLevels}
						component={AdminManageLevels}
						layout={UserLayout}
					/>

					{/* Adverts and Videos */}
					<UserRoute
						path={`${routes.Adverts}/:id`}
						component={AdvertDetails}
						layout={UserLayout}
					/>
					<UserRoute
						path={routes.Adverts}
						component={Adverts}
						layout={UserLayout}
					/>
					
					<UserRoute
						path={routes.Videos}
						component={Videos}
						layout={UserLayout}
					/>


					{/*Client*/}
					<UserRoute
						path={routes.ClientLineup}
						component={ClientLineup}
						layout={VenueLayout}
					/>
					<UserRoute
						path={routes.ClientFeedback}
						component={ClientFeedback}
						layout={VenueLayout}
					/>
					<UserRoute
						path={routes.ClientVenue}
						component={ClientVenue}
						layout={VenueLayout}
					/>
					<UserRoute
						path={routes.ClientInfoAndIdeas}
						component={ClientInfoAndIdeas}
						layout={VenueLayout}
					/>
					{/*Login*/}
					<UserRoute
						path={routes.ResetPassword + "/:id"}
						component={ResetPassword}
					/>
					<UserRoute path={routes.Login} component={LoginRoute} />

					<Home />
				</Switch>
			</Router>
		);
	}
}

AppRouter = connect()(AppRouter);
export default AppRouter;
