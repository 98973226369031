import * as React from "react";
import { connect } from "react-redux";
import { skimpyBibleSelectorById } from "../poll/selectors";
import { routes } from "../router/router";
import {
	skimpyBibleDELETE,
	skimpyBiblePATCH,
	skimpyBiblePOST,
	SkimpyBibleQueryById
} from "../api/apiEndpoints";
import Bible from "./Bible";

export const skimpyBibleTermsConditionsHeading = "Terms & Conditions";

class SkimpyBible extends React.PureComponent {
	render() {
		return (
			<Bible
				{...this.props}
				updateBibleEntryAPIFn={skimpyBiblePATCH}
				createBibleEntryAPIFn={skimpyBiblePOST}
				deleteBibleEntryAPIFn={skimpyBibleDELETE}
				queryBibleEntriesAPIFn={SkimpyBibleQueryById}
				routerSuffix={routes.SkimpyBible}
				bibleEntriesFromPoll={this.props.skimpyBible}
				termsAndConditionsTitle={skimpyBibleTermsConditionsHeading}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		skimpyBible: skimpyBibleSelectorById(state)
	};
};

export default connect(mapStateToProps)(SkimpyBible);
