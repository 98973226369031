import { uploadSections, uploadsGet } from "../../api/apiEndpoints";

export function getUploadsBySectionType() {
	return uploadsGet({
		UploadSectionTypes: [
			uploadSections.profile_proof,
			uploadSections.profile_main_profile_picture,
			uploadSections.profile_rsa
		]
	}).then(r => {
		const uploads = r.data || [];

		const uploadsByProfileId = uploads.reduce((newVals, currentVal) => {
			if (newVals[currentVal.UploadTypeId] === undefined) {
				newVals[currentVal.UploadTypeId] = {};
			}
			if (
				currentVal.UploadSectionType === uploadSections.profile_main_profile_picture
			) {
				newVals[currentVal.UploadTypeId][
					uploadSections.profile_main_profile_picture
				] = currentVal;
			} else if (currentVal.UploadSectionType === uploadSections.profile_rsa) {
				newVals[currentVal.UploadTypeId][uploadSections.profile_rsa] = currentVal;
			} else if (currentVal.UploadSectionType === uploadSections.profile_proof) {
				newVals[currentVal.UploadTypeId][uploadSections.profile_proof] = currentVal;
			}

			return newVals;
		}, {});
		
		return uploadsByProfileId;
	});
}
