import React from 'react'
import {APP_ENV} from '../../config'
const style= {
  background: 'rgb(180,0,0)',
  position: 'absolute',
  width: '100%',
  color: 'white',
  zIndex: 10000,
  textAlign: 'center'
}

const EnvironmentBanner = () => {
  return APP_ENV.toLowerCase() === 'prod' ? null : (
    <label style={style}>{APP_ENV} environment</label>
  )
}

export default EnvironmentBanner