import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import DateTimePicker from "../components/datetime/DateTimePicker";
import ClientList from "../components/select/ClientList";
import SkimpyList from "../components/select/SkimpyList";
import FormManager from "../components/form/FormManager";
import {
	convertDDMMYYYToJSDate,
	convertJSDateToDDMMYYYY
} from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import { siteLogsPOST } from "../api/apiEndpoints";
import Spinner from "../components/loading/Spinner";
import ResponsiveTableNew, {
	sortDirectionOptions
} from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import { lessThanApiDate } from "../lib/sort/sortByDate";
import NatTime from "../components/text/NatTime";
import DisplayHtml from "../components/html/DisplayHtml";
import MiniContainer from "../components/container/MiniContainer";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";

class AdminLogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			siteLogsParams: {
				CreatedAtStart: convertJSDateToDDMMYYYY(DateFns.startOfDay(new Date())),
				CreatedAtEnd: convertJSDateToDDMMYYYY(
					DateFns.startOfDay(DateFns.addDays(new Date(), 1))
				),
				ClientId: undefined,
				AuthId: undefined
			},
			siteLogsUnfiltered: [],
			loading: false
		};
	}

	componentDidMount() {
		this.handleQuerySiteLogs();
	}

	handleQuerySiteLogs = () => {
		this.setState({
			loading: true
		});
		let params = {
			CreatedAtStart: convertDDMMYYYToJSDate(
				this.state.siteLogsParams.CreatedAtStart
			),
			CreatedAtEnd: convertDDMMYYYToJSDate(this.state.siteLogsParams.CreatedAtEnd),
			AuthId: this.state.siteLogsParams.AuthId
		};

		if (this.state.siteLogsParams.ClientId !== undefined) {
			params.Attributes = {
				Shift: {
					client_id: this.state.siteLogsParams.ClientId
				}
			};
		}
		siteLogsPOST(params).then(r => {
			this.setState({
				siteLogsUnfiltered: r.data,
				loading: false
			});
		});
	};

	render() {
		const colSpacing = [3, 3, 6];
		return (
			<div>
				{this.renderSearch()}
				<Spinner loading={this.state.loading}>
					<MiniContainer>
						<ResponsiveTableNew
							initialSortColumn={"CreatedAt"}
							initialSortDirection={sortDirectionOptions.decending}
							values={this.state.siteLogsUnfiltered}
							headings={[
								{
									label: "Date",
									name: "CreatedAt",
									size: colSpacing[0],
									sortDescFunc: lessThanApiDate
								},
								{
									label: "Log Type",
									name: "SiteLogType",
									size: colSpacing[1],
									disableSort: true
								},
								{
									label: "Description",
									name: "Description",
									size: colSpacing[2],
									disableSort: true
								}
							]}
						>
							{({ sortedValues }) => {
								return (
									<InfiniteScroll
										rowCount={sortedValues.length}
										rowComparison={() => {
											return false;
										}}
										rows={sortedValues}
										overscanRowCount={5}
										rowRender={({ index, row, style, redrawRow }) => {
											return (
												<div style={style} className="div-table-responsive-row">
													<ResponsiveTableColumn size={colSpacing[0]} heading="Date">
														<NatTime startTime={row.CreatedAt} />
													</ResponsiveTableColumn>
													<ResponsiveTableColumn size={colSpacing[1]} heading="Log Type">
														{row.SiteLogType}
													</ResponsiveTableColumn>
													<ResponsiveTableColumn size={colSpacing[2]} heading="Description">
														{row.Attributes.Description && (
															<DisplayHtml value={row.Attributes.Description} />
														)}
													</ResponsiveTableColumn>
												</div>
											);
										}}
									/>
								);
							}}
						</ResponsiveTableNew>
					</MiniContainer>
				</Spinner>
			</div>
		);
	}

	renderSearch() {
		return (
			<div>
				<div className="mini-container">
					<h4 className="heading">Search</h4>
					<div className="row">
						<FormManager
							initialFormValues={this.state.siteLogsParams}
							onChangeCallback={formValues => {
								this.setState({ siteLogsParams: formValues }, this.handleQuerySiteLogs);
							}}
						>
							{/*FormManager children*/}
							{({ formValues, handleOnChange }) => {
								return (
									<div>
										<SearchBarItem
											label={"Start Date"}
											value={
												<DateTimePicker
													name="CreatedAtStart"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.CreatedAtStart}
												/>
											}
										/>

										<SearchBarItem
											label={"End Date"}
											value={
												<DateTimePicker
													name="CreatedAtEnd"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.CreatedAtEnd}
												/>
											}
										/>

										<SearchBarItem
											label={"Venue"}
											value={
												<ClientList
													name="ClientId"
													onChange={handleOnChange}
													value={formValues.ClientId}
												/>
											}
										/>

										<SearchBarItem
											label={"Actioned By"}
											value={
												<SkimpyList
													name="AuthId"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.AuthId}
												/>
											}
										/>
									</div>
								);
							}}
						</FormManager>
					</div>
				</div>
			</div>
		);
	}
}

AdminLogs.propTypes = {};

const mapStateToProps = (state, props) => {
	return {};
};

export default connect(mapStateToProps)(AdminLogs);
