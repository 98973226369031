import * as React from "react";
import PropTypes from "prop-types";
import LoadingButtonWithConfirm from "../button/LoadingButtonWithConfirm";
import AriaModal from "react-aria-modal";
import "./StandardModalWrapper.css";

export default class StandardModalWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modalOpen: this.props.openOnLoad,
			renderModal: this.props.openOnLoad // second state to remove the <Modal> from rendering after close
		};
	}

	handleCloseModal = () => {
		this.setState({ modalOpen: false }, () => {
			this.hideModal();
			if (this.props.onCloseFn) {
				this.props.onCloseFn();
			}
		});
	};

	handleOpenModal = () => {
		this.setState({ modalOpen: true, renderModal: true });
	};

	hideModal = () => {
		this.setState({ renderModal: false });
	};

	render() {
		const closeButtonStyles = {
			float: "right",
			position: "absolute",
			right: 0,
			top: 0
		};

		let closeButton = (
			<button
				className="btn-default shadowbox"
				style={closeButtonStyles}
				onClick={this.handleCloseModal}
			>
				<i className="fas fa-times color-fail" />
			</button>
		);

		if (this.props.warningMessageOnClose !== undefined) {
			closeButton = (
				<LoadingButtonWithConfirm
					className="btn-default"
					confirmAnchor={"bottom-right"}
					style={closeButtonStyles}
					onClick={this.handleCloseModal}
					confirmMessage={this.props.warningMessageOnClose}
				>
					<i className="fas fa-times color-fail" />
				</LoadingButtonWithConfirm>
			);
		}

		if (this.props.disableCloseButton === true) {
			closeButton = null;
		}

		let headingRow = [];
		if (this.props.heading) {
			headingRow.push(<h4 className="heading">{this.props.heading}</h4>);
		}

		if (closeButton !== null) {
			headingRow.push(closeButton);
		}

		return (
			<React.Fragment>
				{this.props.modalOpener({
					handleOpenModal: this.handleOpenModal
				})}

				{this.state.renderModal && (
					<AriaModal
						onExit={this.handleCloseModal}
						titleId="?"
						underlayClickExits={false}
						escapeExits={false}
						scrollDisabled={false}
						titleText={this.props.heading}
					>
						<div id="modal-inner">
							{headingRow.length !== 0 && (
								<div style={{ display: "block", overflow: "auto", paddingBottom: 8 }}>
									{headingRow.map((items, idx) => (
										<React.Fragment key={idx}>{items}</React.Fragment>
									))}
								</div>
							)}
							{this.props.render({
								modalOpen: this.state.modalOpen,
								handleCloseModal: this.handleCloseModal
							})}
						</div>
					</AriaModal>
				)}
			</React.Fragment>
		);
	}
}

StandardModalWrapper.defaultProps = {
	openOnLoad: false,
	disableCloseButton: false
};

StandardModalWrapper.propTypes = {
	heading: PropTypes.string,
	render: PropTypes.func,
	modalOpener: PropTypes.func,
	onCloseFn: PropTypes.func,
	warningMessageOnClose: PropTypes.string,
	openOnLoad: PropTypes.bool,
	disableCloseButton: PropTypes.bool
};
