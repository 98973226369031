import * as React from "react";
import PropTypes from "prop-types";
import { siteLogsPOST } from "../../api/apiEndpoints";
import NatTime from "../../components/text/NatTime";
import { sortByDate } from "../../lib/sort/sortByDate";

export default class ShiftHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			shiftHistory: [],
			shiftHistoryLoading: true
		};

		this.loadSiteLogs = this.loadSiteLogs.bind(this);
	}

	componentDidMount() {
		this.loadSiteLogs();
	}

	loadSiteLogs() {
		siteLogsPOST({
			ShiftId: this.props.shiftId
		}).then(response => {
			this.setState({ shiftHistory: response.data, shiftHistoryLoading: false });
		});
	}

	render() {
		const shiftHistory = this.state.shiftHistory;
		sortByDate(shiftHistory, "CreatedAt");

		return (
			<div>
				{shiftHistory.length > 0 ? (
					shiftHistory.map((item, idx) => {
						return (
							<div key={idx} className="row">
								<div style={{ paddingLeft: 30, paddingRight: 30 }}>
									<div
										className="col-sm-12"
										style={{
											paddingTop: 8,
											paddingBottom: 8,
											borderTop: "1px solid rgb(230, 230, 230)"
										}}
									>
										<div className="col-sm-3">
											<NatTime startTime={item.CreatedAt} />
										</div>
										<div className="col-sm-9">
											<div
												dangerouslySetInnerHTML={{ __html: item.Attributes.Description }}
											/>
										</div>
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div>No history available for this shift</div>
				)}
			</div>
		);
	}
}

ShiftHistory.propTypes = {
	shiftId: PropTypes.number.isRequired,
	modelOpener: PropTypes.func
};
