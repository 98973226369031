import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { lessThanApiDate } from "../lib/sort/sortByDate";
import { myPayments } from "../poll/selectors";
import {
	convertDDMMYYYToJSDate,
	parseAPIDate
} from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import "./MyPayments.css";
import NatTime from "../components/text/NatTime";
import Money from "../components/text/Money";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import SkimpyHeavenBankTransfer from '../Shifts/components/SkimpyHeavenBankTransfer';
import Info from './components/Info';
import SortButton from './components/SortButton';
import SearchBar from './components/SearchBar';

const MyPayments = () => {
	const myPayments = useSelector(
		state =>
			state && state.poll && state.poll.raw
				? state.poll.raw.Payments || null
				: null
	);

	const [filterStart, setFilterStart] = useState("01/01/2016");
	const [sortOrder, setSortOrder] = useState("desc");

	const allTransactions = useMemo(() => {
		if (!myPayments || !myPayments.Transactions) {
			return []
		}
		return myPayments.Transactions

	}, [myPayments])

	const transactions = useMemo(
		() => {
			const filterStartJsDate = convertDDMMYYYToJSDate(filterStart);
			const tmp = allTransactions.filter(transaction =>
				DateFns.isAfter(parseAPIDate(transaction.Date), filterStartJsDate)
			);
			return sortOrder === "desc"
				? tmp.sort((a, b) => lessThanApiDate(a.Date, b.Date))
				: tmp.sort((a, b) => lessThanApiDate(b.Date, a.Date));
		},
		[allTransactions, sortOrder, filterStart]
	);

	const handleFilterChange = event => {
		const newDate = event.target.value;
		console.log(convertDDMMYYYToJSDate(newDate))
		setFilterStart(newDate);
	};

	const handleSortClick = () => {
		setSortOrder(sortOrder === "asc" ? "desc" : "asc");
	};

	if (!myPayments) {
		return null;
	}

	const nowAsEpoch = new Date().getTime();

	return (
		<>
			<SearchBar value={filterStart} onChange={handleFilterChange} allTransactions={allTransactions}/>
			<Info />
			<div className="col-lg-12 my-payments">
				<div className="row">
					<div className="col-sm-12">
						<div
							className="hidden-xs row div-table__thead div-table-responsive"
							style={{ fontWeight: "bold" }}
						>
							<div className="col-sm-12 payments-header-grid">
								<div className="date">
									Date <SortButton direction={sortOrder} onClick={handleSortClick} />
								</div>
								<div className="charge">Charge</div>
								<div className="payment">Payment</div>
								<div className="balance">Balance</div>
							</div>
						</div>
						<div className="row">
							<InfiniteScroll
								rowCount={transactions.length}
								rows={transactions}
								rowRender={({ style, index, row }) => {
									let rowClasses = ["div-table-responsive-row"];

									if (parseAPIDate(row.Date).getTime() > nowAsEpoch) {
										rowClasses.push("payments-row-future");
									}

									return (
										<div key={row} style={style} className={rowClasses.join(" ")}>
											<div style={{ minHeight: 40 }} className="grid-payment">
												<label>Balance</label>
												<div className="balance">
													<Money negativeValuesAreRed={true} value={row.Balance} />
												</div>
												<label>Date</label>
												<div className="date">
													<NatTime startTime={row.Date} />
													{row.ClientId ? <>&nbsp;<SkimpyHeavenBankTransfer clientId={row.ClientId} /></> : []}
												</div>
												<label>Charge</label>
												<div className="charge">
													<Money negativeValuesAreRed={true} value={row.Charge} />
												</div>
												<label>Payment</label>
												<div className="payment">
													<Money negativeValuesAreRed={true} value={row.Credit} />
												</div>
												<label>Notes</label>
												<div className="notes">{row.LineItem}</div>
											</div>
										</div>
									);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyPayments;
