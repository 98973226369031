import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import { clientsPATCH } from "../../api/apiEndpoints";
import ClientEmail from "../../components/links/ClientEmail";
import FormManager from "../../components/form/FormManager";
import FormRow from "../../components/form/FormRow";
import InputText from "../../components/input/InputText";
import InputNumber from "../../components/input/InputNumber";
import InputCheckbox from "../../components/input/InputCheckbox";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";

export default class EditVenueGeneralInfo extends React.Component {
	render() {
		return (
			<FormManager
				initialFormValues={{
					...this.props.initialFormValues,
					DeletedAt: this.props.initialFormValues.DeletedAt === null ? true : false
				}}
				handleValidation={v => {
					v.email({ fieldName: "ClientEmail" });
					v.charLength({ fieldName: "AddressPostcode", charLength: 4 });
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					console.log('PATCH', formValues)
					clientsPATCH({
						Id: this.props.clientId,
						ClientName: formValues.ClientName,
						AddressNumber: formValues.AddressNumber,
						AddressRoad: formValues.AddressRoad,
						AddressState: formValues.AddressState,
						AddressSuburb: formValues.AddressSuburb,
						AddressPostcode: formValues.AddressPostcode,
						Lat: formValues.Lat,
						Lng: formValues.Lng,
						PhoneNumber: formValues.PhoneNumber,
						ClientEmail: formValues.ClientEmail,
						DistanceFromCBD: formValues.DistanceFromCBD,
						DeletedAt: !formValues.DeletedAt
					})
						.then(() => {
							handleSubmitSuccess();
							this.props.handleCloseModal();
						})
						.catch(error => {
							handleSubmitFail();
							throw error;
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting,
					submitError
				}) => {
					return (
						<div>
							<FormRow
								label={"Address Number"}
								value={
									<InputText
										name={"AddressNumber"}
										value={formValues.AddressNumber}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressNumber}
									/>
								}
							/>
							<FormRow
								label={"Street"}
								value={
									<InputText
										name={"AddressRoad"}
										value={formValues.AddressRoad}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressRoad}
									/>
								}
							/>
							<FormRow
								label={"Suburb"}
								value={
									<InputText
										name={"AddressSuburb"}
										value={formValues.AddressSuburb}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressSuburb}
									/>
								}
							/>
							<FormRow
								label={"State"}
								value={
									<InputText
										name={"AddressState"}
										value={formValues.AddressState}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressState}
									/>
								}
							/>
							<FormRow
								label={"Postcode"}
								value={
									<InputNumber
										name={"AddressPostcode"}
										value={formValues.AddressPostcode}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressPostcode}
									/>
								}
							/>
							<FormRow
								label={"Latitude"}
								value={
									<InputNumber
										name={"Lat"}
										value={+formValues.Lat}
										onChange={handleOnChange}
										validationMessages={formValidation.Lat}
									/>
								}
							/>
							<FormRow
								label={"Longitude"}
								value={
									<InputNumber
										name={"Lng"}
										value={+formValues.Lng}
										onChange={handleOnChange}
										validationMessages={formValidation.Lng}
									/>
								}
							/>
							<FormRow
								label={"Phone number"}
								value={
									<InputText
										name={"PhoneNumber"}
										value={formValues.PhoneNumber}
										onChange={handleOnChange}
										validationMessages={formValidation.PhoneNumber}
									/>
								}
							/>
							<FormRow
								label={"Email"}
								value={
									<InputText
										name={"ClientEmail"}
										value={formValues.ClientEmail}
										onChange={handleOnChange}
										validationMessages={formValidation.ClientEmail}
									/>
								}
							/>
							<FormRow
								label={"Distance from CBD"}
								value={
									<InputText
										name={"DistanceFromCBD"}
										value={
											formValues.DistanceFromCBD === null ? "" : formValues.DistanceFromCBD
										}
										onChange={handleOnChange}
										validationMessages={formValidation.DistanceFromCBD}
									/>
								}
							/>
							<FormRow
								label={"Client Active"}
								value={
									<InputCheckbox
										name={"DeletedAt"}
										value={formValues.DeletedAt}
										onChange={handleOnChange}
										validationMessages={formValidation.DeletedAt}
									/>
								}
							/>

							{/* Footer */}

							{submitError && (
								<div className="col-xs-offset-2 col-xs-8 form-validation-box">
									<b>
										There was an issue sending your request, please refresh the page and
										try again.
									</b>
								</div>
							)}

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										loading={submitting}
										disabled={!submitEnabled}
										onClick={handleSubmit}
									>
										Save
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

EditVenueGeneralInfo.propTypes = {
	clientId: PropTypes.number.isRequired,
	initialFormValues: PropTypes.object,
	handleCloseModal: PropTypes.func.isRequired
};
