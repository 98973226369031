import {css, injectGlobal} from "emotion";

export const miniContainer = css`
	min-height: 1px;
	padding: 15px;
	display: inline-block;
	width: 100%;
	box-shadow: 1px 1px 5px 0px #a0a0a0;
`;

export const miniContainerShadow = css`
	box-shadow: 1px 1px 5px 0px #a0a0a0;
`;

injectGlobal({
	".mini-container": miniContainer,
	".mini-container-padding": miniContainer,
	".mini-container-shadow": miniContainerShadow,
});
