import { action, get, observable, set } from "mobx";
import API from "../api/client";
import { IClient } from "../models/client";

class ClientStore {
	@observable
	public clientsById: Map<number, IClient> = new Map([]);
	@observable
	public loadingById: { [clientId: string]: boolean } = {};

	@action
	public getById = (id: number) => {
		return get(this.clientsById, String(id));
	};
	@action
	public getLoadingById = (id: number) => {
		return get(this.loadingById, String(id));
	};

	@action
	public fetchById = (
		ids: number[],
		fieldsToQuery: string[] = [
			"Id",
			"ClientName",
			"PhoneNumber",
			"RegularReappearance",
			"FavouriteReappearance",
			"TravelPay",
			"PaymentMethod",
			"PaymentNote",
			"Notes",
			"AddressNumber",
			"AddressRoad",
			"AddressSuburb",
			"AddressState",
			"AddressPostcode",
			"BrandId",
			"Requirements",
			"CreatedAt",
			"UpdatedAt",
			"DeletedAt",
			"DistanceFromCBD",
			"ClientEmail"
		]
	) => {
		ids.forEach(id => {
			set(this.loadingById, String(id), true);
		});
		API.post("/clients/queryByIds", {
			Ids: ids,
			FieldsToQuery: fieldsToQuery
		}).then(response => {
			const requestedClients = response.data === null ? {} : response.data;

			// TODO: this doesn't work, it should update each key of a map, updating their values

			Object.keys(requestedClients).forEach((clientId: any) => {
				let existing = this.clientsById.get(Number(clientId));
				let newClient: IClient = existing === undefined ? {} : existing;

				fieldsToQuery.forEach(field => {
					newClient[field] = requestedClients[clientId][field];
				});

				this.clientsById.set(Number(clientId), newClient);
			});
		});
	};
}

export const clientStore = new ClientStore();
