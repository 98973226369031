import * as React from "react";
import PropTypes from "prop-types";
import ValidationMessages from "../form/ValidationMessages";

export default class InputNumber extends React.Component {
	// Call the onChange handler with a modified value
	handleOnChange = e => {
		if (this.props.allowEmptyValue) {
			return this.props.onChange({
				target: {
					name: e.target.name,
					value: e.target.value === "" ? undefined : Number(e.target.value)
				}
			});
		}
		return this.props.onChange({
			target: {
				name: e.target.name,
				value: isNaN(e.target.value) ? 0 : Number(e.target.value)
			}
		});
	};

	render() {
		let value = this.props.value;
		if (this.props.value !== undefined) {
			value = Number(this.props.value);
		} else if (!this.props.allowEmptyValue) {
			value = 0;
		} else {
			value = "";
		}
		return (
			<span>
				<input
					type="number"
					name={this.props.name}
					className={this.props.inputClassName}
					onChange={this.handleOnChange}
					value={value}
				/>

				<ValidationMessages messages={this.props.validationMessages} />
			</span>
		);
	}
}

InputNumber.defaultProps = {
	inputClassName: "form-control",
	validaitonClassName: "form-validation-box",
	allowEmptyValue: false
};

InputNumber.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	inputClassName: PropTypes.string,
	validaitonClassName: PropTypes.string,
	validationMessages: PropTypes.arrayOf(PropTypes.string),
	allowEmptyValue: PropTypes.bool
};
