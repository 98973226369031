import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { profileSelectorById } from "../../poll/selectors";
import { profilesPATCH } from "../../api/apiEndpoints";
import { poll } from "../../poll/actions";
import LoadingButton from "../../components/button/LoadingButton";
import { toastr } from "react-redux-toastr";
import { convertDDMMYYYToJSDate } from "../../lib/dateUtils/dateUtils";
import FormRow from "../../components/form/FormRow";
import FormManager from "../../components/form/FormManager";
import InputText from "../../components/input/InputText";
import DateTimePicker from "../../components/datetime/DateTimePicker";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import { Save } from "../../components/icons/Icons";

class ProfileInformationEdit extends React.Component {
	render() {
		if (this.props.profile === null || this.props.profile === undefined) {
			return null;
		}

		return (
			<FormManager
				initialFormValues={{
					Fullname:
						this.props.profile.Fullname === null ? "" : this.props.profile.Fullname,
					DOB: this.props.profile.DOB,
					AddressNumber:
						this.props.profile.AddressNumber === null
							? ""
							: this.props.profile.AddressNumber,
					AddressRoad:
						this.props.profile.AddressRoad === null
							? ""
							: this.props.profile.AddressRoad,
					AddressSuburb:
						this.props.profile.AddressSuburb === null
							? ""
							: this.props.profile.AddressSuburb,
					AddressState:
						this.props.profile.AddressState === null
							? ""
							: this.props.profile.AddressState,
					AddressPostcode:
						this.props.profile.AddressPostcode === null
							? ""
							: this.props.profile.AddressPostcode,
					PrivateEmail:
						this.props.profile.PrivateEmail === null
							? ""
							: this.props.profile.PrivateEmail,
					EmergencyContactName:
						this.props.profile.EmergencyContactName === null
							? ""
							: this.props.profile.EmergencyContactName,
					EmergencyContactPhone:
						this.props.profile.EmergencyContactPhone === null
							? ""
							: this.props.profile.EmergencyContactPhone
				}}
				handleValidation={v => {
					v.number({
						fieldName: "AddressPostcode"
					});
					v.characterCount({
						fieldName: "AddressPostcode",
						characterLimit: 4
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					profilesPATCH({
						Id: this.props.profileId,
						Fullname: formValues.Fullname,
						DOB: formValues.DOB === null ? null : formValues.DOB,
						AddressNumber: formValues.AddressNumber,
						AddressRoad: formValues.AddressRoad,
						AddressState: formValues.AddressState,
						AddressSuburb: formValues.AddressSuburb,
						AddressPostcode:
							formValues.AddressPostcode === ""
								? null
								: Number(formValues.AddressPostcode),
						PrivateEmail: formValues.PrivateEmail,
						EmergencyContactName: formValues.EmergencyContactName,
						EmergencyContactPhone: formValues.EmergencyContactPhone
					})
						.then(() => {
							handleSubmitSuccess();
							this.props.dispatch(poll({}));
							toastr.info("Loading updates..");
							this.props.handleSaveCallback();
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{({
					formValues,
					formValidation,
					handleOnChange,
					handleSubmit,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"Name"}
								value={
									<InputText
										name={"Fullname"}
										value={formValues.Fullname}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Date of Birth"}
								value={
									<DateTimePicker
										name={"DOB"}
										value={formValues.DOB}
										nullable={true}
										formatAsApiDate={true}
										dayPickerOnly={true}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Address Number"}
								value={
									<InputText
										name={"AddressNumber"}
										value={formValues.AddressNumber}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Street"}
								value={
									<InputText
										name={"AddressRoad"}
										value={formValues.AddressRoad}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Suburb"}
								value={
									<InputText
										name={"AddressSuburb"}
										value={formValues.AddressSuburb}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"State"}
								value={
									<InputText
										name={"AddressState"}
										value={formValues.AddressState}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Postcode"}
								value={
									<InputText
										name={"AddressPostcode"}
										value={formValues.AddressPostcode}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressPostcode}
									/>
								}
							/>
							<FormRow
								label={"Private email"}
								value={
									<InputText
										name={"PrivateEmail"}
										value={formValues.PrivateEmail}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Emergency Contact"}
								value={
									<InputText
										name={"EmergencyContactName"}
										value={formValues.EmergencyContactName}
										onChange={handleOnChange}
									/>
								}
							/>
							<FormRow
								label={"Emergency Contact PH"}
								value={
									<InputText
										name={"EmergencyContactPhone"}
										value={formValues.EmergencyContactPhone}
										onChange={handleOnChange}
									/>
								}
							/>
							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										className="btn btn-primary"
										disabled={!submitEnabled}
										loading={submitting}
										onClick={handleSubmit}
									>
										<Save /> Save
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

ProfileInformationEdit.propTypes = {
	profileId: PropTypes.number,
	handleSaveCallback: PropTypes.func
};

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile
	};
};

export default connect(mapStateToProps)(ProfileInformationEdit);
