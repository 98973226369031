import localStorage from "store";
import jwtDecode from "jwt-decode";

const defaultState = {
	authToken: "",
	tokenDecoded: {}
};

export function authReducer(state = defaultState, action) {
	switch (action.type) {
		// Additional reducer in store.js which resets state
		case "LOGOUT": {
			console.log("Logout");
			localStorage.clearAll();
			return {
				...state,
				authToken: "",
				tokenDecoded: {}
			};
		}
		case "LOCAL_STORAGE_LOAD_TOKEN": {
			const token = localStorage.get("authToken");

			return {
				...state,
				authToken: token,
				tokenDecoded: jwtDecode(token)
			};
		}
		case "LOCAL_STORAGE_TOKEN_UPDATED": {
			localStorage.clearAll();
			localStorage.set("authToken", action.payload);

			return {
				...state,
				authToken: action.payload,
				tokenDecoded: jwtDecode(action.payload)
			};
		}
		case "LOCAL_STORAGE_BAD_TOKEN": {
			console.log("BAD");
			localStorage.clearAll();
			return {
				...state,
				authToken: "",
				tokenDecoded: {}
			};
		}
		default: {
			return state;
		}
	}
}
