import * as React from "react";

const ShiftIconTimes = () => (
	<i style={{ fontSize: "1.4rem", color: "blue" }} className="fas fa-times" />
);

const ShiftIconMinus = () => (
	<i style={{ fontSize: "1.4rem", color: "#d9534f" }} className="fas fa-minus" />
);

const ShiftIconPlus = () => (
	<i style={{ fontSize: "1.4rem", color: "green" }} className="fas fa-plus" />
);

const ShiftIconEquals = () => (
	<span
		style={{
			fontSize: "2rem",
			lineHeight: 0,
			fontWeight: "bold"
		}}
	>
		=
	</span>
);

export { ShiftIconTimes, ShiftIconMinus, ShiftIconPlus, ShiftIconEquals };
