import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CancelSeries from "./CancelSeries";
import CancelThisShift from "./CancelThisShift";
import EditSeriesFromThisShiftForward from "./EditSeriesFromThisShiftForward";
import EditRegularShift from "./EditRegularShift";
import TransferShifts from "./TransferShifts";
import MakeShiftGenerallyAvailable, { pageOptions } from "./MakeShiftGenerallyAvailable";
import { seriesSelectorById } from "../../../poll/selectors";
import { poll } from "../../../poll/actions";
import EditParentSeries from "./EditParentSeries";
import EditParentShift from "./EditParentShift";
import { apiShiftTypes } from "../../../api/apiEndpoints";
import { userGroups, userInfo } from "../../../lib/userInfo/userInfo";
import UndoMakeShiftGenerallyAvailable from "./UndoMakeShiftGenerallyAvailable";

class AdminEditSeriesMenu extends React.Component {
	constructor(props) {
		super(props);

		this.pageOptions = {
			CancelSeriesFromThisShiftForward: "Cancel series from this shift forward",
			CancelThisShift: "Cancel this shift",
			EditSeriesFromThisShiftForward: "Edit series from this shift forward",
			EditParentSeries: "Edit series (overall settings) from this shift forward",
			EditParentShift: "Edit shift (overall settings)",
			EditThisShift: "Edit this shift",
			MakeShiftGenerallyAvailable: "Make available",
			ViewKFWList: "View keen for work list",
			UndoMakeShiftGenerallyAvailable: "Re-book in my name",
			TransferShift: "Transfer shift",
			Menu: "Menu"
		};

		this.state = {
			currentPage: this.pageOptions.Menu,
			series: props.seriesById[props.shift.SeriesId]
		};

		this.CancelSeriesFromThisShiftForwardButton = null;
		this.CancelThisShiftButton = null;
		this.EditSeriesFromThisShiftForwardButton = null;
		this.EditThisShiftButton = null;
		this.EditParentSeries = null;
		this.EditParentShift = null;
		this.MakeShiftGenerallyAvailableButton = null;
		this.UndoMakeShiftGenerallyAvailableButton = null;
		this.TransferShiftButton = null;
		this.ViewKFWList = null;

		if ([userGroups.hrm, userGroups.admin].includes(userInfo().userGroupId)) {
			this.CancelSeriesFromThisShiftForwardButton = this.renderMenuOption(
				this.pageOptions.CancelSeriesFromThisShiftForward
			);
			this.CancelThisShiftButton = this.renderMenuOption(
				this.pageOptions.CancelThisShift
			);
			this.EditSeriesFromThisShiftForwardButton = this.renderMenuOption(
				this.pageOptions.EditSeriesFromThisShiftForward
			);
			this.EditThisShiftButton = this.renderMenuOption(
				this.pageOptions.EditThisShift
			);
			this.EditParentSeries = this.renderMenuOption(
				this.pageOptions.EditParentSeries
			);
			this.EditParentShift = this.renderMenuOption(
				this.pageOptions.EditParentShift
			);
			this.TransferShiftButton = this.renderMenuOption(
				this.pageOptions.TransferShift
			);
			this.ViewKFWList = this.renderMenuOption(this.pageOptions.ViewKFWList);

			if (props.shift.ShiftStatus === 5) {
				this.MakeShiftGenerallyAvailableButton = this.renderMenuOption(
					this.pageOptions.MakeShiftGenerallyAvailable
				);
			}
		}

		// If logged in user booked this shift or logged in user is HRM / Admin
		if (
			props.shift.ShiftStatus === 5 &&
			props.shift.UserId === userInfo().userId
		) {
			this.MakeShiftGenerallyAvailableButton = this.renderMenuOption(
				this.pageOptions.MakeShiftGenerallyAvailable
			);
			this.TransferShiftButton = this.renderMenuOption(
				this.pageOptions.TransferShift
			);
		}

		// If logged in user owns the shift, but has made it GA or logged in user is HRM / Admin
		if (
			props.shift.ShiftStatus === 1 &&
			props.shift.UserId === userInfo().userId
		) {
			this.UndoMakeShiftGenerallyAvailableButton = this.renderMenuOption(
				this.pageOptions.UndoMakeShiftGenerallyAvailable
			);
			this.TransferShiftButton = this.renderMenuOption(
				this.pageOptions.TransferShift
			);
			this.ViewKFWList = this.renderMenuOption(this.pageOptions.ViewKFWList);
		}
	}

	handleEditSeriesMenuOptionClick = selectedMenuOption => {
		return () => {
			this.setState({ currentPage: selectedMenuOption });
		};
	};

	renderMenuOption = pageOption => {
		return (
			<div style={{ paddingBottom: 3 }}>
				<i
					className="fas fa-circle"
					style={{
						fontSize: 6,
						paddingRight: 5,
						bottom: 5,
						position: "relative"
					}}
				/>
				<button
					className="btn-clear color-primary"
					style={{ fontSize: 16 }}
					name="Page"
					onClick={this.handleEditSeriesMenuOptionClick(pageOption)}
				>
					{pageOption}
				</button>
			</div>
		);
	};

	handleCloseModalWithPoll = () => {
		this.props.dispatch(poll({}));
		this.props.handleCloseModal();
	};

	render() {
		switch (this.state.currentPage) {
			case this.pageOptions.CancelThisShift:
				return (
					<CancelThisShift
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.CancelSeriesFromThisShiftForward:
				return (
					<CancelSeries
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.EditSeriesFromThisShiftForward:
				return (
					<EditSeriesFromThisShiftForward
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.EditThisShift:
				return (
					<EditRegularShift
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.TransferShift:
				return (
					<TransferShifts
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.MakeShiftGenerallyAvailable:
				return (
					<MakeShiftGenerallyAvailable
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.UndoMakeShiftGenerallyAvailable:
				return (
					<UndoMakeShiftGenerallyAvailable
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.EditParentSeries:
				return (
					<EditParentSeries
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.EditParentShift:
				return (
					<EditParentShift
						shift={this.props.shift}
						handleCloseModal={this.handleCloseModalWithPoll}
					/>
				);

			case this.pageOptions.ViewKFWList:
				return (
					<MakeShiftGenerallyAvailable
						shift={this.props.shift}
						page={pageOptions.ShowKFWers}
						handleCloseModal={() => {
							return;
						}}
					/>
				);
		}

		// // Dont show the main menu as an option
		// if (this.pageOptions[option] === this.pageOptions.Menu) {
		// 	return false;
		// }
		//
		// // Only show 'Make shift generally available' when shift is assigned to someone
		// if (this.pageOptions[option] === this.pageOptions.MakeShiftGenerallyAvailable && this.props.shift.UserId === null && this.props.shift.ShiftStatus === 1) {
		// 	return false;
		// }
		// return true

		let menuItems = [];

		// Child shifts dont have a shift type, this will use the series shift type if its missing from the shift
		const shiftType = this.props.shift.ShiftType || this.state.series.ShiftType;

		switch (shiftType) {
			case apiShiftTypes.singleShift:
				menuItems = [
					this.CancelThisShiftButton,
					this.EditThisShiftButton,
					this.MakeShiftGenerallyAvailableButton,
					this.UndoMakeShiftGenerallyAvailableButton,
					this.TransferShiftButton,
					this.ViewKFWList
				];
				break;
			case apiShiftTypes.customMonthly:
			case apiShiftTypes.series:
				// Regular shifts
				menuItems = [
					this.CancelSeriesFromThisShiftForwardButton,
					this.CancelThisShiftButton,
					this.EditSeriesFromThisShiftForwardButton,
					this.EditThisShiftButton,
					this.MakeShiftGenerallyAvailableButton,
					this.UndoMakeShiftGenerallyAvailableButton,
					this.TransferShiftButton,
					this.ViewKFWList
				];
				break;
			case apiShiftTypes.multidayShift:
				if ("ParentId" in this.props.shift) {
					// Child
					menuItems = [this.CancelThisShiftButton, this.EditThisShiftButton];
				} else {
					// Parent
					menuItems = [
						this.EditParentShift,
						this.CancelThisShiftButton,
						this.MakeShiftGenerallyAvailableButton,
						this.UndoMakeShiftGenerallyAvailableButton,
						this.TransferShiftButton,
						this.ViewKFWList
					];
				}

				break;
			case apiShiftTypes.multidaySeries:
				if ("ParentId" in this.props.shift) {
					// Child
					menuItems = [
						this.CancelThisShiftButton,
						this.CancelSeriesFromThisShiftForwardButton,
						this.EditThisShiftButton,
						this.EditSeriesFromThisShiftForwardButton
					];
				} else {
					// Parent
					menuItems = [
						this.EditParentSeries,
						this.EditParentShift,
						this.CancelSeriesFromThisShiftForwardButton,
						this.CancelThisShiftButton,
						this.MakeShiftGenerallyAvailableButton,
						this.UndoMakeShiftGenerallyAvailableButton,
						this.TransferShiftButton,
						this.ViewKFWList
					];
				}

				break;
			case 5:
				// Single shift - dance show

				break;
			case apiShiftTypes.customMonthly:
				// custom monthly
				break;
		}

		return (
			<div>
				<h4 className="heading">Shift actions</h4>
				<div style={{ fontSize: 18 }}>
					{menuItems.map((i, idx) => {
						if (i === null) {
							return;
						}
						return React.cloneElement(i, { key: idx });
					})}
				</div>

				{this.props.shift.AdminNotes && (
					<div>
						<b>Admin Notes</b>
						<div
							style={{
								border: "1px solid grey",
								padding: 5,
								textAlign: "center",
								background: "lightgrey"
							}}
						>
							{this.props.shift.AdminNotes}
						</div>
					</div>
				)}
			</div>
		);
	}
}

AdminEditSeriesMenu.propTypes = {
	shift: PropTypes.any,
	handleCloseModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
	return {
		seriesById: seriesSelectorById(state)
	};
};

export default connect(mapStateToProps)(AdminEditSeriesMenu);
