import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { levelSelectorById } from "../../poll/selectors";
import Select from "../select/Select";
import ValidationMessages from "../form/ValidationMessages";

class InputSelectLevel extends React.Component {
	render() {
		const { levels } = this.props;
		const options = Object.keys(levels).map(levelId => {
			return { value: Number(levelId), label: levels[levelId].Level };
		});

		return (
			<div>
				<Select
					disabled={this.props.disabled}
					options={options}
					name={this.props.name}
					onChange={this.props.onChange}
					value={this.props.value}
				/>

				<ValidationMessages messages={this.props.validationMessages} />
			</div>
		);
	}
}

InputSelectLevel.defaultProps = {
	validaitonClassName: "form-validation-box",
	disabled: false
};

InputSelectLevel.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	validaitonClassName: PropTypes.string,
	validationMessages: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		levels: levelSelectorById(state)
	};
};

export default connect(mapStateToProps)(InputSelectLevel);
