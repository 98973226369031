import * as React from "react";
import PropTypes from "prop-types";
import ClientNames from "../../components/links/ClientNames";
import NatTime from "../text/NatTime";

const selectedValidators = [
	"HRM Approval Required",
	"Pending Hrm Approval",
	"Out Of Level Range",
	"Occurs In Past",
	"MNR",
	"Booked By Other",
	"Generally Available",
	"Booked By User",
	"Booked By User Flexi",
	"Out Of Credit"
];

const conflictingValidators = [
	"Reappearance Previous",
	"Reappearance Next",
	"Too Close",
	"Direct Conflict"
];

class ValidationMessages extends React.Component {
	render() {
		let displayedSelectedShiftValidators = [];
		let displayedConflictingShiftValidators = [];

		if (this.props.validation === null) {
			return null;
		}

		this.props.validation.forEach(validation => {
			if (this.props.hideValidators.indexOf(validation.Validator) !== -1) {
				return;
			}

			if (selectedValidators.indexOf(validation.Validator) !== -1) {
				displayedSelectedShiftValidators.push(validation);
			}

			if (conflictingValidators.indexOf(validation.Validator) !== -1) {
				displayedConflictingShiftValidators.push(validation);
			}
		});

		if (
			displayedSelectedShiftValidators.length === 0 &&
			displayedConflictingShiftValidators.length === 0
		) {
			return null;
		}

		return (
			<div className="col-md-12">
				{this.props.showSelectedShift ? (
					<div className="row">
						<div>
							<ClientNames clientIds={this.props.selectedShift.ChildClientIds} />
						</div>
						<div>
							<NatTime
								startTime={this.props.selectedShift.StartTime}
								endTime={this.props.selectedShift.EndTime}
							/>
						</div>
					</div>
				) : null}

				{displayedSelectedShiftValidators.map((validator, idx) => {
					return (
						<div key={idx} className="row">
							<div className="shift-validation-box col-md-12">
								{validator.Conflict}
							</div>
						</div>
					);
				})}

				{displayedConflictingShiftValidators.map((validator, idx) => {
					return (
						<div key={idx} className="row">
							<div className="shift-validation-box col-md-12">
								<div>{validator.ClientName}</div>
								<div>
									<b>{validator.Time}</b>
								</div>
								<div>{validator.Conflict}</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

ValidationMessages.defaultProps = {
	validation: [],
	hideValidators: []
};

ValidationMessages.propTypes = {
	validation: PropTypes.arrayOf(
		PropTypes.shape({
			ClientName: PropTypes.string,
			Conflicit: PropTypes.string,
			Time: PropTypes.string,
			Title: PropTypes.string,
			Validator: PropTypes.string
		})
	),
	selectedShift: PropTypes.shape({
		ChildClientIds: PropTypes.arrayOf(PropTypes.number),
		StartTime: PropTypes.string,
		EndTime: PropTypes.string
	}),
	showSelectedShift: PropTypes.bool.isRequired,
	hideValidators: PropTypes.arrayOf(PropTypes.string)
};

export default ValidationMessages;
