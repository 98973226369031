import * as React from "react";
import PropTypes from "prop-types";
import { getProfileNameFromUserId } from "../../components/selectors/profile";
import "./ClickableBadge.css";

class ClickableBadge extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	handleOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		if (this.state.open) {
			return (
				<span
					style={{ display: "block" }}
					className="clickable-badge badge"
					onClick={this.handleClose}
				>
					{this.props.userIds.map((userId, idx) => (
						<div key={idx}>{getProfileNameFromUserId(userId)}</div>
					))}
				</span>
			);
		}

		return (
			<span
				className="clickable-badge badge"
				onClick={this.handleOpen}
				style={{ backgroundColor: "red" }}
			>
				{this.props.userIds.length}
			</span>
		);
	}
}

ClickableBadge.propTypes = {
	userIds: PropTypes.array
};

export default ClickableBadge;
