import * as React from "react";
import { advertsGET } from "../api/apiEndpoints";
import MiniContainer from "../components/container/MiniContainer";
import Spinner from "../components/loading/Spinner";
import NatTime from "../components/text/NatTime";
import { connect } from "react-redux";
import { clientSelectorById } from "../poll/selectors";
import { routes } from "../router/router";
import * as dayjs from "dayjs";
import AdvertCreate from "./AdvertCreate/AdvertCreate";
import { Link } from "react-router-dom";
import Modal from "../components/modal/Modal";
import { shortDateFormat } from "../lib/dateUtils/dateUtils";
import {Pencil} from '../components/icons/Icons'

import "./Adverts.css";

const styles = {
	adGrid: {
		display: "grid",
		gridTemplateRows: "auto",
		gridTemplateColumns: "3fr 3fr 6fr",
		alignItems: "center",
		justifyContent: "flex-start",
		padding: "4px 0"
	},
	gridHeader: {
		borderBottom: "solid 2px #c7a840"
	},
	gridRow: {
		borderBottom: "1px solid #CED8DD"
	},
	toggleLink: {
		border: "none",
		marginLeft: "auto",
		textDecoration: "none"
	},
	link: {
		color: "#1890ff",
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "100%"
	}
};

class Adverts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			adverts: [],
			expiringAdverts: [],
			filter: {
				expiringSoon: false
			},
			showCreateModal: false
		};
	}

	componentDidMount() {
		this.loadAdverts();
	}
	componentDidUpdate() {
		console.log(this.state);
	}

	viewClient(id) {
		this.props.history.push(`${routes.Venue}/${id}`);
	}

	toggleExpiringSoon() {
		const { filter } = this.state;
		filter.expiringSoon = !filter.expiringSoon;
		this.setState({ ...this.state, filter });
	}

	async loadAdverts() {
		this.setState({ ...this.state, loading: true });
		const res = await advertsGET();
		const genericAds = (res.data || []).filter(f => !f.client_id).sort((a, b) => dayjs(a.expires_at).unix() - dayjs(b.expires_at).unix())
		const clientAds = (res.data || []).filter(f => !!f.client_id).sort((a, b) => a.client_name.localeCompare(b.client_name))
		const adverts = [...clientAds, ...genericAds]
		const thirtyDays = dayjs()
			.add(30, "day")
			.toDate();
		const expiringAdverts = adverts.filter(
			f => !!f.expires_at && dayjs(f.expires_at).toDate() <= thirtyDays
		).sort((a, b) => dayjs(a.expires_at).unix() - dayjs(b.expires_at).unix());
		this.setState({
			...this.state,
			loading: false,
			adverts,
			expiringAdverts
		});
	}

	setCreateModalVisible(showCreateModal) {
		this.setState({ ...this.state, showCreateModal });
	}

	render() {
		return (
			<div>
				<Spinner loading={this.state.loading}>
					<div className="row">
						<div className="col-md-12 adBtnsWrapper">
							<button
								className="btn btn-danger"
								onClick={() => this.setCreateModalVisible(true)}
							>
								Create
							</button>
							{!!this.state.showCreateModal ? (
								<Modal
									title="Create Advert"
									onClose={() => this.setCreateModalVisible(false)}
								>
									<AdvertCreate
										onClose={() => {
											this.loadAdverts();
											this.setCreateModalVisible(false);
										}}
									/>
								</Modal>
							) : (
								[]
							)}
							<button
								className="btn btn-primary admin-box-button"
								onClick={() => this.toggleExpiringSoon()}
							>
								{!!this.state.filter.expiringSoon ? "Show All" : "Show Expiring soon"}
							</button>
						</div>
					</div>
					<br />
					<div className="mini-container adGridWrapper">
						<div className="adGrid gridHeader">
							<label>Expires</label>
							<label>Venue</label>
							<label>Comments</label>
						</div>
						{(!this.state.filter.expiringSoon
							? this.state.adverts
							: this.state.expiringAdverts
						).map(ad => (
							<div key={`${ad.Id}`} className="adGrid gridRow labelAndInfo">
								<label>Expires: </label>
								<div>{!!ad.expires_at ? shortDateFormat(ad.expires_at) : "Never"}</div>

								<label>Venue: </label>
								{ad.client_id ? (
									<span onClick={() => this.viewClient(+ad.client_id)}>
										{ad.client_name || 'missing client name?'}
									</span>
								) : (
									<span>-</span>
								)}

								<label>Comments: </label>
								<div>{ad.comments}</div>

								<Link to={`${routes.Adverts}/${ad.id}`}><Pencil /></Link>
							</div>
						))}
					</div>
				</Spinner>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		clients: clientSelectorById(state)
	};
};
export default connect(mapStateToProps)(Adverts);
