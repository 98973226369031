import * as React from "react";
import { videosCreatePOST, videosUploadBlob } from "../../api/apiEndpoints";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import FormRow from "../../components/form/FormRow";
import { Save } from "../../components/icons/Icons";
import Modal from "../../components/modal/Modal";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import UploadInput from "../../components/UploadInput/UploadInput";
import { userGroups } from "../../lib/userInfo/userInfo";

class VideoCreate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			Path: undefined,
			file: undefined
		};
	}

	renderFormRows() {
		return (
			<>
				<FormRow
					label={!!this.state.file ? this.state.file.name : ''}
					value={
						<UploadInput
							onSelected={file => this.setState({ ...this.state, file })}
							accepts={"*.mp4,*.webm,video/mp4,video/webm"}
							showFileName={false}
						/>
					}
				/>
			</>
		);
	}

	async createVideo() {
		if (!!this.state.file) {
			var formData = new FormData();
			formData.append("file", this.state.file);
			let success = false;
			try {
				this.setState({ ...this.state, loading: true });
				const blobRes = await videosUploadBlob(formData);
				const path = blobRes.data.path;
				this.setState({ ...this.state, Path: path });
				const json = { ...this.state };
				json.Path = path;
				json.Description = this.state.file.name
				delete json.loading;
				delete json.file;
				const createRes = await videosCreatePOST(json);
				success = true;
			} catch (err) {
				console.error(err);
			}
			this.setState({ ...this.state, loading: false });
			if (!!success) {
				this.handleClose();
				this.props.onCreated && this.props.onCreated();
			}
		}
	}

	handleClose() {
		this.props.onClose && this.props.onClose();
	}

	render() {
		return (
			<ProtectedComponent allowedGroups={[userGroups.admin]}>
				<div style={{ minWidth: "280px" }}>
					{this.renderFormRows()}

					
						<LoadingButtonWithConfirm
							onClick={() => this.createVideo()}
							loading={this.state.loading}
							disabled={!this.state.file}
							className="btn btn-primary"
						>
							<Save /> Create Video
						</LoadingButtonWithConfirm>

				</div>
			</ProtectedComponent>
		);
	}
}

export default VideoCreate;
