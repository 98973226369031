import * as React from "react";
import PropTypes from "prop-types";
import NatTime from "../../../components/text/NatTime";
import ClientNames from "../../../components/links/ClientNames";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import ListKFWers from "../ListKFW/ListKFWers";
import { shiftActionPOST } from "../../../api/apiEndpoints";
import { Save } from "../../../components/icons/Icons";

export const pageOptions = {
	MakeAvailable: "Make Available",
	ShowKFWers: "Show KFWers"
};

export default class MakeShiftGenerallyAvailable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: this.props.page || pageOptions.MakeAvailable
		};
	}

	render() {
		if (this.state.page === pageOptions.MakeAvailable) {
			return (
				<div>
					<h4 className="heading">Make Shift Available</h4>

					<p>Make this shift available?</p>
					<div>
						<b>
							<ClientNames clientIds={this.props.shift.ChildClientIds} />
						</b>
					</div>
					<div>
						<NatTime
							startTime={this.props.shift.StartTime}
							endTime={this.props.shift.EndTime}
						/>
					</div>

					<AdminContainerMigrate
						align="right"
						buttons={[
							<button
								className="btn btn-success"
								onClick={() => {
									shiftActionPOST({
										Action: "generally_available",
										ShiftId: this.props.shift.Id
									}).then(() => {
										this.setState({ page: pageOptions.ShowKFWers });
									});
								}}
							>
								<Save /> Make Available
							</button>
						]}
					/>
				</div>
			);
		}

		if (this.state.page === pageOptions.ShowKFWers) {
			return (
				<div>
					<h4 className="heading">Make Shift Available</h4>
					<ListKFWers
						shift={this.props.shift}
						handleCloseModal={this.props.handleCloseModal}
					/>
					<AdminContainerMigrate
						align="right"
						buttons={[
							<button
								className="btn btn-primary pull-right"
								onClick={this.props.handleCloseModal}
							>
								Okay
							</button>
						]}
					/>
				</div>
			);
		}
	}
}

MakeShiftGenerallyAvailable.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
	page: PropTypes.string
};
