import * as React from "react";
import PropTypes from "prop-types";
import { userInfo } from "../../lib/userInfo/userInfo";

class ProtectedComponent extends React.Component {
	constructor(props) {
		super(props);
		const u = userInfo();
		this.userGroupId = u.userGroupId;
		this.loggedInUserId = u.userId;
	}

	render() {
		if (!this.props.allowedGroups.includes(this.userGroupId)) {
			if (this.props.allowedIfLoggedInAsUserId !== this.loggedInUserId) {
				return null;
			}
		}

		return this.props.children;
	}
}

ProtectedComponent.propTypes = {
	allowedGroups: PropTypes.arrayOf(PropTypes.number),
	allowedIfLoggedInAsUserId: PropTypes.number
};

export default ProtectedComponent;
