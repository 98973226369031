import { parseAPIDate } from "../dateUtils/dateUtils";

export function sortByDate(collection, key, newestFirst = true) {
	if (newestFirst) {
		collection.sort((a, b) => {
			const aDate = parseAPIDate(a[key]);
			const bDate = parseAPIDate(b[key]);

			if (aDate.getTime() < bDate.getTime()) {
				return true;
			}
			return false;
		});
	} else {
		collection.sort((a, b) => {
			const aDate = parseAPIDate(a[key]);
			const bDate = parseAPIDate(b[key]);

			if (aDate.getTime() > bDate.getTime()) {
				return true;
			}
			return false;
		});
	}
}

export function lessThanApiDate(lessThan, greaterThan) {
	const aDate = parseAPIDate(lessThan);
	const bDate = parseAPIDate(greaterThan);
	return bDate - aDate;
}
