import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";
import { Link } from "react-router-dom";
import { routes } from "../../router/router";

class ClientName extends React.Component {
	render() {
		return (
			<Link to={routes.Venue + "/" + this.props.clientId}>
				{this.props.client.ClientName}
			</Link>
		);
	}
}

ClientName.propTypes = {
	clientId: PropTypes.number.isRequired,
	client: PropTypes.object
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

ClientName = connect(mapStateToProps)(ClientName);
export default ClientName;
