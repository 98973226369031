import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { profileSelector, userSelectorById } from "../../poll/selectors";
import { objectIsEmpty } from "../../lib/object/objectIsEmpty";

class CreateUserModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			emailAddresses: []
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad(props) {
		const emailAddresses = props.profiles
			.filter(profile => {
				return props.users[profile.UserId].UserGroupId !== 0;
			})
			.map(profile => {
				return props.users[profile.UserId].Email;
			});

		emailAddresses.sort((a, b) => {
			return a.localeCompare(b);
		});
		this.setState({ emailAddresses: emailAddresses });
	}

	render() {
		console.log(this.state.emailAddresses);
		if (objectIsEmpty(this.props.users)) {
			return null;
		}

		return <div>{this.state.emailAddresses.join("; ")}</div>;
	}
}

CreateUserModal.propTypes = {
	modalOpen: PropTypes.bool,
	onCloseFn: PropTypes.func
};

const mapStateToProps = state => {
	return {
		users: userSelectorById(state),
		profiles: profileSelector(state)
	};
};

export default connect(mapStateToProps)(CreateUserModal);
