import * as React from "react";
import { connect } from "react-redux";
import ProfileStageName from "../components/links/ProfileStageName";
import Level from "../components/text/Level";
import LevelList from "../components/select/LevelList";
import { profilesAsArray, userSelectorById } from "../poll/selectors";
import CreateUserModal from "./components/CreateUserModal";
import SkimpyEmailAddressesModal from "./components/SkimpyEmailAddressesModal";
import MiniContainer from "../components/container/MiniContainer";
import ResponsiveTableNew, {
	sortDirectionOptions
} from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import ProfileWorkPhone from "../components/links/ProfileWorkPhone";
import ProfileWorkFacebook from "../components/links/ProfileWorkFacebook";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import InputCheckbox from "../components/input/InputCheckbox";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import { poll } from "../poll/actions";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import { lessThanNumeric } from "../lib/sort/sortByNumber";

class SkimpySearch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			profileId: -1,
			profilesUnfiltered: [],
			profilesFiltered: [],
			filterSearchTerm: "",
			filterLevelId: undefined,
			filterShowDeletedUsers: false,
			showAdvancedFilters: false,
			createUserModalOpen: false,
			skimpyEmailAddressesModalOpen: false
		};
	}

	handleFilterChange = e => {
		switch (e.target.name) {
			case "searchFilter": {
				this.setState(
					{ filterSearchTerm: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
			case "levelIdFilter": {
				this.setState(
					{ filterLevelId: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
			case "filterShowDeletedUsers": {
				this.setState(
					{ filterShowDeletedUsers: e.target.value },
					this.filterAndCreateDisplayComponents
				);
				break;
			}
		}
	};

	handleToggleShowAdvancedFilters = () => {
		if (this.state.showAdvancedFilters) {
			this.setState({ showAdvancedFilters: false });
		} else {
			this.setState({ showAdvancedFilters: true });
		}
	};

	onLoad = nextProps => {
		const profilesWithSearchableFields = nextProps.profilesAsArray.map(
			profile => {
				return {
					_StageName: profile.StageName.toLowerCase(),
					_Fullname: profile.Fullname === null ? "" : profile.Fullname.toLowerCase(),
					_WorkPhone:
						profile.WorkPhone === null ? "" : profile.WorkPhone.split(" ").join(""),
					_ABN: profile.ABN === null ? "" : profile.ABN.split(" ").join(""),
					...profile
				};
			}
		);

		this.setState(
			{
				profilesUnfiltered: profilesWithSearchableFields,
				profilesFiltered: profilesWithSearchableFields
			},
			this.filterAndCreateDisplayComponents
		);
	};

	filterAndCreateDisplayComponents = () => {
		if (objectIsEmpty(this.props.users)) {
			return;
		}

		let profilesFiltered = this.state.profilesUnfiltered;

		const filterSearchTerm = this.state.filterSearchTerm.toLowerCase();

		// Filter by search term if set
		if (this.state.filterSearchTerm !== "") {
			profilesFiltered = profilesFiltered.filter(profile => {
				return (
					profile._StageName.indexOf(filterSearchTerm) !== -1 ||
					profile._WorkPhone.indexOf(filterSearchTerm) !== -1 ||
					profile._Fullname.indexOf(filterSearchTerm) !== -1 ||
					profile._ABN.indexOf(filterSearchTerm) !== -1
				);
			});
		}

		if (this.state.filterLevelId) {
			profilesFiltered = profilesFiltered.filter(profile => {
				return profile.LevelId === this.state.filterLevelId;
			});
		}

		if (!this.state.filterShowDeletedUsers) {
			profilesFiltered = profilesFiltered.filter(profile => {
				return this.props.users[profile.UserId].UserGroupId !== 0;
			});
		}

		this.setState({
			profilesFiltered: profilesFiltered
		});
	};

	render() {
		return (
			<div>
				<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
					<div className="admin-box">
						<StandardModalWrapper
							heading={"Create User"}
							modalOpener={({ handleOpenModal }) => {
								return (
									<button
										className="btn btn-primary admin-box-button"
										onClick={handleOpenModal}
									>
										<i className="fas fa-user-plus" /> Create User
									</button>
								);
							}}
							render={({ handleCloseModal }) => {
								return <CreateUserModal onCloseFn={handleCloseModal} />;
							}}
						/>
						<StandardModalWrapper
							heading={"Skimpy Email Addresses"}
							modalOpener={({ handleOpenModal }) => {
								return (
									<button
										className="btn btn-primary admin-box-button"
										onClick={handleOpenModal}
									>
										<i className="fas fa-envelope" /> Skimpy Email Addresses
									</button>
								);
							}}
							render={({ handleCloseModal }) => {
								return <SkimpyEmailAddressesModal onCloseFn={handleCloseModal} />;
							}}
						/>
					</div>
				</ProtectedComponent>

				<div className="row" style={{ marginBottom: 15 }}>
					<div className="col-lg-12">
						<MiniContainer>{this.renderSearch()}</MiniContainer>
					</div>
				</div>

				<div className="row ">
					<div className="col-lg-12">
						<MiniContainer>{this.renderContent()}</MiniContainer>
					</div>
				</div>
			</div>
		);
	}

	renderSearch() {
		if (this.state.showAdvancedFilters) {
			return (
				<div>
					{/*Advanced Search*/}

					<span
						className="heading-edit"
						onClick={this.handleToggleShowAdvancedFilters}
					>
						Simple
					</span>
					<h4 className="heading">Advanced Search</h4>
					<div className="row">
						<div className="col-lg-12">
							{/*Search Box*/}
							<input
								type="text"
								name="searchFilter"
								className="form-control"
								onChange={this.handleFilterChange}
								placeholder="Search..."
							/>
						</div>
					</div>
					<div className="row" style={{ marginTop: 15 }}>
						<SearchBarItem
							label="Level"
							value={
								<LevelList
									name="levelIdFilter"
									value={this.state.filterLevelId}
									onChange={this.handleFilterChange}
								/>
							}
						/>
					</div>
					<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
						<div className="row" style={{ marginTop: 15 }}>
							<SearchBarItem
								label="Show Inactive Users"
								value={
									<InputCheckbox
										name="filterShowDeletedUsers"
										value={this.state.filterShowDeletedUsers}
										onChange={this.handleFilterChange}
									/>
								}
							/>
						</div>
					</ProtectedComponent>
				</div>
			);
		} else {
			return (
				<div>
					{/*Regular Search*/}

					<span
						className="heading-edit"
						onClick={this.handleToggleShowAdvancedFilters}
					>
						Advanced
					</span>
					<h4 className="heading">Search</h4>
					<div className="row">
						<div className="col-lg-12">
							{/*Search Box*/}
							<input
								name="searchFilter"
								type="text"
								onChange={this.handleFilterChange}
								className="form-control"
								placeholder="Search..."
							/>
						</div>
					</div>
				</div>
			);
		}
	}

	renderContent() {
		if (this.state.profilesFiltered.length === 0) {
			return null;
		}

		return (
			<ResponsiveTableNew
				initialSortColumn={"StageName"}
				initialSortDirection={sortDirectionOptions.decending}
				values={this.state.profilesFiltered}
				headings={[
					{
						label: "Stage Name",
						name: "StageName",
						size: 3
					},
					{
						label: "Phone",
						name: "WorkPhone",
						size: 2,
						disableSort: true
					},
					{
						label: "Facebook",
						name: "WorkFacebook",
						size: 3,
						disableSort: true
					},
					{
						label: "Level",
						name: "LevelId",
						size: 2,
						sortDescFunc: lessThanNumeric
					},
					{
						label: "Skimpy since",
						name: "Experience",
						size: 2,
						disableSort: true
					}
				]}
			>
				{({ sortedValues }) => {
					// console.log(sortedValues[0])
					return (
						<InfiniteScroll
							rowCount={sortedValues.length}
							rowComparison={() => {
								return false;
							}}
							rows={sortedValues}
							overscanRowCount={5}
							rowRender={({ index, row, style, redrawRow }) => {
								return (
									<div style={style} className="div-table-responsive-row">
										<ResponsiveTableColumn size={3} heading="StageName">
											{this.props.users[row.UserId].UserGroupId !== 0 ? (
												<ProfileStageName userId={row.UserId} />
											) : (
												<del>
													<ProfileStageName userId={row.UserId} />
												</del>
											)}
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={2} heading="Phone">
											<ProfileWorkPhone userId={row.UserId} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={3} heading="Facebook">
											<ProfileWorkFacebook userId={row.UserId} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={2} heading="Level">
											<Level userId={row.UserId} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={2} heading="Experience">
											<div>{row.Experience}</div>
										</ResponsiveTableColumn>
									</div>
								);
							}}
						/>
					);
				}}
			</ResponsiveTableNew>
		);
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
		this.onLoad(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return nextProps.profilesAsArray.length > 0;
	}
}

SkimpySearch.defaultProps = {
	profiles: null
};

const mapStateToProps = state => {
	return {
		profilesAsArray: profilesAsArray(state),
		users: userSelectorById(state)
	};
};

export default connect(mapStateToProps)(SkimpySearch);
