import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { findClientById } from "../../poll/selectors";
import ClientPaymentMethod from "../../components/text/ClientPaymentMethod";
import ClientPaymentNote from "../../components/text/ClientPaymentNote";
import ClientTravelPay from "../../components/text/ClientTravelPay";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import { clientsPATCH } from "../../api/apiEndpoints";
import InputNumber from "../../components/input/InputNumber";
import FormRow from "../../components/form/FormRow";
import InputHtml from "../../components/input/InputHtml";
import LoadingButton from "../../components/button/LoadingButton";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import Select from "../../components/select/Select";

const venuePaymentMethods = [
	"Invoice SKH - Skimpy Account",
	"Cash Direct from Venue",
	"Bank Transfer from Venue",
	"Cheque from Venue",
	"Invoice Brand Manager"
];

const options = venuePaymentMethods.map(method => {
	return {
		label: method,
		value: method
	};
});
class VenuePayments extends React.Component {
	render() {
		return (
			<div>
				<StandardModalWrapper
					heading={"Payments"}
					modalOpener={({ handleOpenModal }) => {
						// handleOpenModal();
						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										PaymentMethod: this.props.venue.PaymentMethod,
										PaymentNote: this.props.venue.PaymentNote,
										TravelPay: this.props.venue.TravelPay,
										Id: this.props.clientId
									}}
									handleValidation={v => {
										v.required({
											fieldNames: ["PaymentMethod", "TravelPay"]
										});
										v.money({
											fieldName: "TravelPay"
										});
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										clientsPATCH({
											TravelPay: formValues.TravelPay,
											PaymentMethod: formValues.PaymentMethod,
											PaymentNote: formValues.PaymentNote,
											Id: this.props.clientId
										})
											.then(() => {
												handleSubmitSuccess();
												handleCloseModal();
											})
											.catch(error => {
												handleSubmitFail();
												throw error;
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<FormRow
													label={"Payment By"}
													value={
														<Select
															options={options}
															name="PaymentMethod"
															value={formValues.PaymentMethod}
															onChange={handleOnChange}
														/>
													}
												/>
												<FormRow
													label={"Payment Notes"}
													value={
														<InputHtml
															name={"PaymentNote"}
															value={formValues.PaymentNote}
															onChange={handleOnChange}
															validationMessages={formValidation.PaymentNote}
														/>
													}
												/>

												<FormRow
													label={"Travel Pay"}
													value={
														<InputNumber
															name={"TravelPay"}
															value={formValues.TravelPay}
															onChange={handleOnChange}
															validationMessages={formValidation.TravelPay}
														/>
													}
												/>

												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButton
															onClick={handleSubmit}
															loading={submitting}
															disabled={!submitEnabled}
														>
															Save
														</LoadingButton>
													]}
												/>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>

				<h4 className="heading">Payments</h4>
				<FormRow
					label={"Payment by"}
					value={<ClientPaymentMethod clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Payment notes"}
					value={<ClientPaymentNote clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Travel payment rate"}
					value={<ClientTravelPay clientId={this.props.clientId} />}
				/>
			</div>
		);
	}
}

VenuePayments.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(VenuePayments);
