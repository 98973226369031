/*
    Turns:
    [{Client: 1}, {Client: 2}, Client: 2}]
    Into:
    {1: [{Client: 1}], 2:[{Client: 2}, Client: 2}]}
    Where "Client" = key
 */
export function aggregateArrayToObjectById(key = "Id", arr = []) {
	let lookup = {};
	arr.forEach(item => {
		if (!Array.isArray(lookup[item[key]])) {
			lookup[item[key]] = [];
		}
		lookup[item[key]].push(item);
	});

	return lookup;
}

/*
    Converts a one dimensional array to an object, where the object keys are a value of 'key' in the array
 */
export function fromArrayToObjectByKey(key = "Id", arr = []) {
	let lookup = {};

	if (arr === null) {
		arr = [];
	}

	for (let i = 0, len = arr.length; i < len; i++) {
		lookup[arr[i][key]] = arr[i];
	}

	return lookup;
}

export function fromObjectKeysToArray(obj = {}) {
	return Object.keys(obj).map(id => {
		return obj[id];
	});
}
