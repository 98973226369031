import * as React from "react";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import { userGroups } from "../../lib/userInfo/userInfo";
import FormRow from "../../components/form/FormRow";
import FormManager from "../../components/form/FormManager";
import ClientList from "../../components/select/ClientList";
import InputTextArea from "../../components/input/InputTextArea";
import InputText from "../../components/input/InputText";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import { Save } from "../../components/icons/Icons";
import UploadInput from "../../components/UploadInput/UploadInput";
import { advertCreatePOST, advertUploadBlob } from "../../api/apiEndpoints";
import DateTimePicker from "../../components/datetime/DateTimePicker";
import * as dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customDateFormat);

class AdvertCreate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			loading: false,
			ClientId: undefined,
			Hyperlink: undefined,
			AltText: undefined,
			Comments: '',
			Path: undefined,
			ExpiresAt: null
		};
	}

	async createAdvert() {
		if (!!this.state.file) {
			var formData = new FormData();
			formData.append("file", this.state.file);
			let success = false;
			try {
				this.setState({ ...this.state, loading: true });
				const blobRes = await advertUploadBlob(formData);
				const path = blobRes.data.path;
				this.setState({ ...this.state, Path: path });
				const json = { ...this.state };
				json.Path = path;
				delete json.loading;
				delete json.file;
				json.ExpiresAt = !json.ExpiresAt
											? null
											: json.ExpiresAt.indexOf("/") > -1
												? dayjs(json.ExpiresAt, "DD/MM/YYYY").toDate()
												: dayjs(json.ExpiresAt).toDate();
				const createRes = await advertCreatePOST(json);
				success = true;
			} catch (err) {
				console.error(err);
			}
			this.setState({ ...this.state, loading: false });
			if (!!success) {
				this.props.onClose && this.props.onClose();
			}
		}
	}

	handleOnChange(evt) {
		const { name, value } = evt.target;
		this.setState({ ...this.state, [name]: value });
	}

	render() {
		return (
			<ProtectedComponent allowedGroups={[userGroups.admin]}>
				<div>
					<FormRow
						label={"Venue"}
						value={
							<ClientList
								name={"ClientId"}
								onChange={e => this.handleOnChange(e)}
								value={this.state.ClientId}
							/>
						}
					/>
					<FormRow
						label={"Web link"}
						value={
							<InputText
								name={"Hyperlink"}
								value={this.state.Hyperlink}
								onChange={e => this.handleOnChange(e)}
							/>
						}
					/>
					<FormRow
						label={"Alt Text"}
						value={
							<InputText
								name={"AltText"}
								value={this.state.AltText}
								onChange={e => this.handleOnChange(e)}
							/>
						}
					/>
					<FormRow
						label={"Comments"}
						value={
							<InputTextArea
								name={"Comments"}
								value={this.state.Comments}
								onChange={e => this.handleOnChange(e)}
							/>
						}
					/>
					<FormRow
						label={"Expires At"}
						value={
							<div style={{ display: "flex", flexDirection: "row" }}>
								<DateTimePicker
									name="ExpiresAt"
									dayPickerOnly={true}
									onChange={e => this.handleOnChange(e)}
									value={this.state.ExpiresAt}
								/>
								{!!this.state.ExpiresAt ? (
									<button
										className="btn btn-default"
										onClick={() =>
											this.handleOnChange({ target: { name: "ExpiresAt", value: null } })
										}
									>
										Never Expires
									</button>
								) : (
									[]
								)}
							</div>
						}
					/>
					<FormRow
						label={"Image"}
						value={
							<UploadInput
								onSelected={file => this.setState({ ...this.state, file })}
								accepts={".png,.jpg,.jpeg,image/*"}
								showFileName={true}
							/>
						}
					/>

					<AdminContainerMigrate
						align={"right"}
						buttons={[
							<LoadingButtonWithConfirm
								onClick={() => this.createAdvert()}
								loading={this.state.loading}
								disabled={!this.state.file}
								className="btn btn-primary"
							>
								<Save /> Create Advert
							</LoadingButtonWithConfirm>
						]}
					/>
				</div>
			</ProtectedComponent>
		);
	}
}

export default AdvertCreate;
