import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findProfileByUserId } from "../../poll/selectors";
import PhoneNumberLink from "./PhoneNumberLink";

class ProfileWorkPhone extends React.Component {
	render() {
		let { WorkPhone } = this.props.profile || "";
		if (WorkPhone === undefined || WorkPhone == null) {
			return "";
		}

		return <PhoneNumberLink phoneNumber={WorkPhone} />;
	}
}

ProfileWorkPhone.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		profile: findProfileByUserId(state, props.userId)
	};
};

ProfileWorkPhone = connect(mapStateToProps)(ProfileWorkPhone);
export default ProfileWorkPhone;
