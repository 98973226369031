import * as React from "react";
import "./BaseLayout.css";
import { connect } from "react-redux";
import { pollLoading, skimpyBibleSelectorById, venueBibleSelectorById } from "../poll/selectors";
import { poll } from "../poll/actions";
import history from "../history";
import { getAuthUserId } from "../auth/selectors";
import UAParser from "ua-parser-js";
import { userHasReadTermsAndConditions } from "../components/TermsAndConditions/userHasReadTermsAndConditions";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";
import { loadConfigFromApi } from "../config/actions";
import PropTypes from "prop-types";
import { viewState } from "./viewState";
import EnvironmentBanner from "../components/environmentBanner/EnvironmentBanner";

const MOBILE_DEVICE_WIDTH = 767;

class BaseLayout extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { showLeftMenu: false, showMobileView: false };
		this.lastWindowWidth = 0;
		this.historyUnlisten = null;

		this.resizeDebounce = this.handleResize;
	}

	handleToggleCollapseMenuClick = () => {
		if (this.state.showLeftMenu === true) {
			this.setState({ showLeftMenu: false });
		} else if (this.state.showLeftMenu === false) {
			this.setState({ showLeftMenu: true });
		}
	};

	handleResize = () => {
		const newWindowWidth = window.innerWidth;

		/**
		 * if the user is on a mobile, then always show the mobile view
		 * or
		 * if the user is using something other than a 'device' & page width < MOBILE_DEVICE_WIDTH then show the mobile view
		 */
		const parser = new UAParser();
		if (parser.getResult().device.model !== undefined || newWindowWidth <= MOBILE_DEVICE_WIDTH) {
			viewState.setMobileView()
			this.setState({ showLeftMenu: false, showMobileView: true });
			return
		}
		/**
		 * if the user resizes the page from the mobile view to the desktop view
		 */
		viewState.setDesktopView()
		this.setState({
			showLeftMenu: true,
			showMobileView: false,
		});

	};

	componentDidMount() {
		this.props.dispatch(loadConfigFromApi());
		this.props.dispatch(poll({}));
		this.handleResize();

		this.historyUnlisten = history.listen(a => {
			// If page has changed
			if (a.pathname !== this.props.children.props.path) {
				// On page change, if the left menu is visible then hide it
				if (this.state.showMobileView) {
					this.setState({ showLeftMenu: false });
				}
			}
		});
		window.addEventListener("resize", this.resizeDebounce);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeDebounce);
		this.historyUnlisten();
	}

	render() {
		if (!userHasReadTermsAndConditions(this.props.authId)) {
			return <TermsAndConditions />;
		}

		let leftMenuWrapperStyle = { width: 0 };
		if (this.state.showLeftMenu) {
			leftMenuWrapperStyle.width = 250;
		}

		let contentWrapperStyle = { marginLeft: 0 };
		if (!this.state.showMobileView) {
			contentWrapperStyle.marginLeft = 250;
		}

		return (
			<div>
					<EnvironmentBanner />
				<div id="top_menu_wrapper">
					<img
						alt="Skimpy Heaven"
						style={{ height: 46, paddingTop: 4 }}
						src="/assets/img/logo-gold-icon-only-small.png"
					/>

					{this.state.showMobileView && (
						<span
							className="top_menu_item"
							onClick={this.handleToggleCollapseMenuClick}
						>
							<i style={{ fontSize: 17 }} className="fas fa-bars" aria-hidden="true" />
						</span>
					)}

					{this.props.renderTopMenu.map((item, idx) => (
						<React.Fragment key={idx}>{item}</React.Fragment>
					))}
				</div>
				{this.props.pollLoading && (
					<div id="poll-loading">
						<div>
							<i className="fas fa-cog fa-spin fa-fw" />
						</div>
					</div>
				)}
				<div id="left_menu_wrapper" style={leftMenuWrapperStyle}>
					<ul>{this.props.renderLeftMenu}</ul>
				</div>
				<div id="content_wrapper" style={contentWrapperStyle}>
					<div className="container-fluid">{this.props.children}</div>
				</div>
			</div>
		);
	}
}

BaseLayout.propTypes = {
	renderLeftMenu: PropTypes.any,
	renderTopMenu: PropTypes.array
};

const mapStateToProps = state => {
	return {
		skimpyBible: skimpyBibleSelectorById(state),
		venueBible: venueBibleSelectorById(state),
		authId: getAuthUserId(state),
		pollLoading: pollLoading(state)
	};
};

BaseLayout = connect(mapStateToProps)(BaseLayout);
export default BaseLayout;
