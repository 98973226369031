import * as React from "react";
import PropTypes from "prop-types";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import VenueGallery from "./VenueGallery";
import { uploadSections } from "../../api/apiEndpoints";

class RenderMainProfilePicture extends React.PureComponent {
	static propTypes = {
		profileId: PropTypes.number.isRequired,
		uploadsByProfileIdByUploadSectionType: PropTypes.object
	};

	render() {
		if (
			!(this.props.profileId in this.props.uploadsByProfileIdByUploadSectionType)
		) {
			return (
				<img className="img-responsive" src={"/assets/img/logo-black-no-bg.png"} />
			);
		}

		if (
			!(
				uploadSections.profile_main_profile_picture in
				this.props.uploadsByProfileIdByUploadSectionType[this.props.profileId]
			)
		) {
			return (
				<img className="img-responsive" src={"/assets/img/logo-black-no-bg.png"} />
			);
		}

		return (
			<StandardModalWrapper
				heading={"Gallery"}
				modalOpener={({ handleOpenModal }) => {
					const upload = this.props.uploadsByProfileIdByUploadSectionType[this.props.profileId][
						uploadSections.profile_main_profile_picture
					]
					return (
						<UploadRenderer
							uploadId={this.props.profileId}
							src={
								upload.ThumbnailPath || upload.Path
							}
							onClickFn={handleOpenModal}
						/>
					);
				}}
				render={({ handleCloseModal }) => {
					return (
						<div className="row">
							<VenueGallery profileId={this.props.profileId} />
						</div>
					);
				}}
			/>
		);
	}
}

export default RenderMainProfilePicture;
