import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { profileByUserId } from "../../poll/selectors";
import DisplayHtml from "../html/DisplayHtml";

class ProfilePersonality extends React.Component {
	render() {
		return <DisplayHtml value={this.props.profile.Personality} />;
	}
}

ProfilePersonality.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		profile: profileByUserId(state)[props.userId]
	};
};

export default connect(mapStateToProps)(ProfilePersonality);
