import localStorage from "store";

export function checkAndSetTokenFromLocalStorage() {
	return dispatch => {
		const token = localStorage.get("authToken");
		if (token === undefined || token === "") {
			return;
		}

		dispatch(setLocalStorage(token));
	};
}

export function setLocalStorage(token) {
	return { type: "LOCAL_STORAGE_TOKEN_UPDATED", payload: token };
}

export function removeTokenFromStores() {
	return { type: "LOGOUT" };
}
