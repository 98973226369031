import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";
import { paymentCodesAsArray } from "../../poll/selectors";

class PaymentCodeList extends React.Component {
	render() {
		if (!this.props.paymentCodesAsArray) {
			return null;
		}

		const options = this.props.paymentCodesAsArray
			.filter(code => {
				return code.DeletedAt === null;
			})
			.sort((a, b) => {
				return a["Description"].localeCompare(b["Description"]);
			})
			.map(code => {
				return {
					value: code.Id,
					label:
						code.Internal === true
							? code.Description.replace(/([A-Z])/g, " $1").trim()
							: code.Description
				};
			});

		sortAlphaNumeric(options);

		return (
			<Select
				options={options}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
				defaultOptionValue={0}
			/>
		);
	}
}

PaymentCodeList.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	value: PropTypes.any
};

const mapStateToProps = state => {
	return {
		paymentCodesAsArray: paymentCodesAsArray(state)
	};
};

export default connect(mapStateToProps)(PaymentCodeList);
