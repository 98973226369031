import React, {useMemo} from 'react'
import DateFns from 'date-fns'
import Money from '../../components/text/Money'

const CurrentCredit = ({transactions}) => {
    const endOfToday = useMemo(() => {
        return DateFns.endOfDay(new Date())
    }, [])

    const currentTransactions = useMemo(() => {
        if (!endOfToday || !transactions || !transactions.length) {
            return []
        }
        const endOfTodayTime = endOfToday.getTime()
        return transactions
            .map(m => ({...m, timestamp: new Date(m.Date).getTime()}))
            .filter(f => f.timestamp <= endOfTodayTime).sort((a, b) => b.timestamp - a.timestamp)
    }, [endOfToday, transactions])

    const balance = useMemo(() => {
        if (!currentTransactions.length) {
            return 0
        }
        return currentTransactions[0].Balance
    }, [currentTransactions])

    return <Money negativeValuesAreRed={true} value={balance} />
}

export default CurrentCredit