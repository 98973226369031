import { isThisSecond } from "date-fns";
import * as React from "react";
import {
	videosGET,
	videosSetActivePATCH,
	videosSetDefaultPATCH,
	videosDELETE
} from "../api/apiEndpoints";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import MiniContainer from "../components/container/MiniContainer";
import { Check, Times, PlayCircle } from "../components/icons/Icons";
import Spinner from "../components/loading/Spinner";
import Modal from "../components/modal/Modal";
import VideoCreate from "./VideoCreate/VideoCreate";

import './Videos.css'

class Videos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			videos: [],
			showCreateModal: false,
			showPreviewModal: undefined
		};
	}

	componentDidMount() {
		this.fetchVideos();
	}

	setCreateModalVisible(showCreateModal) {
		this.setState({...this.state, showCreateModal})
	}

	async fetchVideos() {
		if (!this.state.loading) {
			this.setState({ ...this.state, loading: true });
			try {
				const res = await videosGET();
				const videos = res.data || [];
				this.setState({ ...this.state, videos });
			} catch (err) {
				console.error(err);
			}
			this.setState({ ...this.state, loading: false });
		}
	}

	async toggleActive(video) {
		console.log(video);
		if (!this.loading) {
			try {
				this.setState({ ...this.state, loading: true });
				await videosSetActivePATCH(video.Id);
			} catch (err) {
				console.error(err);
			}
			this.setState({ ...this.state, loading: false }, () => this.fetchVideos());
		}
	}

	async deleteVideo(video) {
		if (!this.loading) {
			try {
				this.setState({ ...this.state, loading: true });
				await videosDELETE(video.Id);
			} catch (err) {
				console.error(err);
			}
			this.setState({ ...this.state, loading: false }, () => this.fetchVideos());
		}
	}

	render() {
		const colSpacing = [4, 2, 2, 8];
		return (
			<div>
				<Spinner loading={!!this.state.loading}>
					<div className="row">
						<div
							className="col-md-12"
							style={{
								display: "grid",
								gridTemplateColumns: "auto auto 1fr",
								gap: "1em"
							}}
						>
							<button className="btn btn-danger" onClick={() => this.setCreateModalVisible(true)}>Create</button>
							{!!this.state.showCreateModal ? (
								<Modal title="Upload Video" onClose={() =>  this.setCreateModalVisible(false)}>
									<VideoCreate onClose={() => {this.fetchVideos(); this.setCreateModalVisible(false)}} />
								</Modal>
							): ([])}
							{!!this.state.showPreviewModal ? (
								<Modal title="Video Preview" onClose={() => this.setState({...this.state, showPreviewModal: undefined})}>
									<video controls src={this.state.showPreviewModal} style={{width: '100%'}}/>
								</Modal>
							) : []}
						</div>
					</div>
					<br />
					<div className="mini-container videoGridWrapper">
						<div className="videoGrid gridHeader">
							<label>Description</label>
							<label>Active</label>
							<label>Actions</label>
						</div>
						{this.state.videos.map(v => (
							<div key={`${v.Id}`} className="videoGrid gridRow">
								<div className="raw_path link"  onClick={() => this.setState({...this.state, showPreviewModal: v.SasPath})}>{v.Description}</div>
								<div className="is_active">
									<>
										{!!v.IsActive ? <Check style={{color: 'green'}}/> : <Times style={{color: 'red'}}/>}
										{!v.IsActive ? (
											<span
												onClick={() => this.toggleActive(v)}
												loading={false}
												disabled={!!this.state.loading}
												className="toggleLink link"
											>
												Mark as Active
											</span>
										) : (
											<span className="toggleLink">
												Currently Active
											</span>
										)}
									</>
								</div>
								<div className="actions">
									<LoadingButtonWithConfirm
										onClick={() => this.deleteVideo(v)}
										loading={false}
										disabled={!!this.state.loading}
										className="btn btn-danger"
									>
										Permanently Delete
									</LoadingButtonWithConfirm>
								</div>
							</div>
						))}
					</div>
				</Spinner>
			</div>
		);
	}
}

export default Videos;
