import * as React from "react";
import { connect } from "react-redux";
import {
	parseAPIDate,
	newDateWithoutSeconds,
	convertJSDateToDDMMYYYY
} from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import "./KFW.css";
import {
	availablePOST,
	calendarPOST,
	createKFWPOST,
	removeKFWDELETE,
	updateKFWPATCH
} from "../api/apiEndpoints";
import { getAuthUserId } from "../auth/selectors";
import FormManager from "../components/form/FormManager";
import { userInfo } from "../lib/userInfo/userInfo";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import NatTime from "../components/text/NatTime";
import ClientNames from "../components/links/ClientNames";
import { Save } from "../components/icons/Icons";

class KFW extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			start: DateFns.startOfDay(newDateWithoutSeconds()),
			days: 28,
			calendarEntries: {},
			kfwList: [],
			bookedShiftsList: []
		};
	}

	handleLoadCalendar = () => {
		availablePOST({
			UserId: userInfo().userId,
			StartTime: convertJSDateToDDMMYYYY(
				DateFns.startOfDay(newDateWithoutSeconds())
			),
			Limit: 999999
		}).then(shifts => {
			this.setState({
				bookedShiftsList: shifts.data.Shifts ? shifts.data.Shifts : []
			});
		});

		calendarPOST({
			UserId: this.props.authId,
			StartTime: this.state.start,
			EndTime: DateFns.addDays(this.state.start, this.state.days)
		}).then(r => {
			// if the API responds with a null instead of empty array, convert it to an empty array
			let calendarResponse = r.data === null ? [] : r.data;

			const calendarHash = calendarResponse
				// Only return KFW events
				.filter(calendarEntry => {
					if (calendarEntry.TypeId === 1) {
						return true;
					}
					return false;
				})
				// Create a hash of KFW entries where the start time is the key
				.reduce((newHash, entry) => {
					newHash[parseAPIDate(entry.StartTime).getTime()] = entry;
					return newHash;
				}, {});

			// Generate a series of KFW entries for the next 30 days
			const start = DateFns.startOfDay(newDateWithoutSeconds());

			let kfwList = [];
			for (let day = 0; day <= 30; day++) {
				let newDay = DateFns.addDays(start, day);

				const newDayHash = newDay.getTime();

				let kfw = false;
				let id = -1;
				let notes = "";

				if (newDayHash in calendarHash) {
					kfw = true;
					id = calendarHash[newDayHash].Id;
					notes = calendarHash[newDayHash].Notes || "";
				}

				kfwList.push({
					day: day,
					date: newDay,
					dateFormatted: DateFns.format(newDay, "ddd D MMM"),
					kfw,
					id,
					notes
				});
			}

			this.setState({
				kfwList
			});
		});
	};

	handleCreateKFW = e => {
		const newKFWDay = DateFns.addDays(this.state.start, e.target.value);

		createKFWPOST({
			UserId: this.props.authId,
			StartTime: newKFWDay
		}).then(() => {
			this.handleLoadCalendar();
		});
	};

	handleRemoveKFW = e => {
		removeKFWDELETE({ Id: e.target.value }).then(() => {
			this.handleLoadCalendar();
		});
	};

	componentDidMount() {
		this.handleLoadCalendar();
	}

	render() {
		const colSizes = [2, 2, 6, 2];

		return (
			<div className="col-sm-12 kfw">
				<div className="row">
					<h4>Keen for work</h4>
					<p className="heading">
					Please register the dates you would like more work so you get priority offers
						<br />
						If you leave the button <span style={{ color: "#D45A56" }}>RED</span> it
						means you will not be listed to other members or clients as keen for work that day.
						<br />
						If you leave the button <span style={{ color: "#60bb71" }}>
							GREEN
						</span>{" "}
						you may be contacted with any offers by other members.
						<br />
						If your availability is limited, state your schedule using the suburbs and times you already have to be somewhere.  Be specific, eg `avail before 8pm start in Carine` is better than `need early finish`. Hit the Blue Save icon to publish this information to other members
					</p>
				</div>
				<div className="row hidden-sm hidden-xs">
					<div style={{ textAlign: "right" }} className={"col-sm-" + colSizes[0]}>
						Date
					</div>
					<div className={"col-sm-" + colSizes[1]} />
					<div className={"col-sm-" + colSizes[2]}>Notes</div>
				</div>
				{this.state.kfwList.map((line, idx) => {
					return (
						<div key={idx} className={idx % 2 === 0 ? "row kfw-odd" : "row"}>
							<FormManager
								initialFormValues={{
									Notes: line.notes,
									Id: line.id
								}}
								handleSubmit={({ formValues }) => {
									updateKFWPATCH(formValues).then(() => {
										this.handleLoadCalendar();
									});
								}}
							>
								{({ formValues, handleOnChange, handleSubmit, submitEnabled }) => {
									const startTime = line.date;
									const endTime = DateFns.subSeconds(DateFns.addDays(line.date, 1), 1);

									const overlappingShifts = this.state.bookedShiftsList.filter(shift => {
										const bookedShiftStart = parseAPIDate(shift.StartTime);
										const bookedShiftEnd = parseAPIDate(shift.EndTime);

										return DateFns.areRangesOverlapping(
											startTime,
											endTime,
											bookedShiftStart,
											bookedShiftEnd
										);
									});

									const saveButton = submitEnabled ? (
										<button className="btn btn-primary" onClick={handleSubmit}>
											<Save />
										</button>
									) : null;

									const kfwButton = line.kfw ? (
										<button
											onClick={this.handleRemoveKFW}
											value={line.id}
											className="btn btn-success"
										>
											KFW
										</button>
									) : overlappingShifts.length === 0 ? (
										<button
											onClick={this.handleCreateKFW}
											value={line.day}
											className="btn btn-danger"
										>
											Not KFW
										</button>
									) : (
										<StandardModalWrapper
											heading="Keen for work"
											modalOpener={({ handleOpenModal }) => {
												return (
													<button onClick={handleOpenModal} className="btn btn-danger">
														Not KFW
													</button>
												);
											}}
											render={({ handleCloseModal }) => {
												const closeModalCreateKFW = e => {
													this.handleCreateKFW(e);
													handleCloseModal();
												};

												return (
													<div>
														{overlappingShifts.map((shift, shiftIdx) => {
															return (
																<div key={shiftIdx}>
																	<h4>
																		<ClientNames clientIds={shift.ChildClientIds} /> -{" "}
																		<NatTime
																			startTime={shift.StartTime}
																			endTime={shift.EndTime}
																		/>
																	</h4>
																</div>
															);
														})}
														<div>
															You already have these shifts booked, are you sure you want to
															set yourself as KFW for this day?
														</div>
														<AdminContainerMigrate
															align={"right"}
															buttons={[
																<button
																	onClick={closeModalCreateKFW}
																	value={line.day}
																	className="btn btn-success"
																>
																	Make KFW
																</button>
															]}
														/>
													</div>
												);
											}}
										/>
									);

									const notesText = line.kfw && (
										<textarea
											placeholder="add some notes about your availability"
											name="Notes"
											onChange={handleOnChange}
											value={formValues.Notes}
										/>
									);

									return (
										<div>
											{/*Desktop*/}
											<div className="hidden-sm hidden-xs">
												<div
													style={{ textAlign: "right" }}
													className={"col-sm-" + colSizes[0]}
												>
													{line.dateFormatted}
												</div>
												<div className={"col-sm-" + colSizes[1]}>{kfwButton}</div>
												<div className={"col-sm-" + colSizes[2]}>{notesText}</div>
												<div className={"col-sm-" + colSizes[3]}>{saveButton}</div>
											</div>

											{/*Mobile*/}
											<div className="hidden-md hidden-lg">
												<div style={{ textAlign: "left" }} className={"col-xs-6"}>
													{line.dateFormatted}
												</div>

												<div className={"col-xs-6"}>{kfwButton}</div>

												<div className={"col-xs-12"}>{notesText}</div>
												<div className={"col-xs-12"}>{saveButton}</div>
											</div>
										</div>
									);
								}}
							</FormManager>
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authId: getAuthUserId(state)
	};
};

export default connect(mapStateToProps)(KFW);
