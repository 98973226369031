import store, { REDUX_ROOT_AUTH } from "../store";
import axios from "axios";
import history from "../history";
import { routes } from "../router/router";
import { removeTokenFromStores } from "../auth/actions";

class APIClient {
	constructor() {
		const { location } = window;
		this.URL = `${location.protocol}//${location.hostname}:${location.port}/api`;
	}

	login(Email, Password) {
		return axios({ method: "post", data: { Email, Password }, url: `${this.URL}/authenticate` });
	}

	getAuthTokenFromState() {
		return store.getState()[REDUX_ROOT_AUTH].authToken;
	}

	post(endpoint, data, additionalHeaders) {
		return this.requestWithAuth("post", endpoint, data, additionalHeaders);
	}

	patch(endpoint, data, additionalHeaders) {
		return this.requestWithAuth("patch", endpoint, data, additionalHeaders);
	}

	get(endpoint, data, additionalHeaders) {
		return this.requestWithAuth("get", endpoint, data, additionalHeaders);
	}

	delete(endpoint, data, additionalHeaders) {
		return this.requestWithAuth("delete", endpoint, data, additionalHeaders);
	}

	requestWithAuth(method, endpoint, data, additionalHeaders) {
		const headers = {
			Authorization: this.getAuthTokenFromState(),
			...additionalHeaders
		};

		if (!headers.Authorization) {
			delete headers.Authorization;
		}

		return axios({
			timeout: 1000 * 60 * 10,
			method,
			url: this.URL + endpoint,
			data,
			headers
		}).catch(error => {
			// DOCS: when a 401 is returned from the API, boot the user to login screen
			if (error.response.status === 401) {
				console.log("Logout triggered by 401");
				store.dispatch(removeTokenFromStores());
				history.push(routes.Login);
				
				return Promise.reject("Auth token has expired");
			}

			// DOCS: when a response isn't recieved from the API, display an error
			if (error.response === undefined) {
				console.error('no response received from api')
				// TODO: display some pop up
				//throw error;
			}
			throw error;
		});
	}
}

let api = new APIClient();

export default api;
