import * as React from "react";
import PropTypes from "prop-types";
import { apiShiftTypes, editRegularShift } from "../../../api/apiEndpoints";
import { connect } from "react-redux";
import NatTime from "../../../components/text/NatTime";
import { seriesSelectorById } from "../../../poll/selectors";
import { requirementsToBools } from "../lib/requirements";
import EditSeriesWizard from "./EditSeriesWizard";

class EditRegularShift extends React.Component {
	constructor(props) {
		super(props);

		const shift = props.shift;
		this.state = {
			formValues: {
				Notes: shift.Notes,
				ClientId: shift.ClientId,
				ContractStart: shift.StartTime,
				ShiftLength: shift.ShiftLength,
				PayRate: shift.PayRate,
				ShiftPay: shift.ShiftPay,
				FeePay: shift.FeePay,
				AdminPay: shift.AdminPay,
				TravelPay: shift.TravelPay,
				HrmApproval: shift.HrmApproval,
				Extra: shift.Extra !== null,
				...requirementsToBools(shift.Requirements)
			}
		};
	}

	seriesFormValuesCallback = formValues => {
		if (formValues === "done") {
			editRegularShift({
				Id: this.props.shift.Id,
				StartTime: this.state.formValues.ContractStart,
				EndTime: this.state.formValues.ShiftEnd,
				AdminPay: this.state.formValues.AdminPay,
				FeePay: this.state.formValues.FeePay,
				HrmApproval: this.state.formValues.HrmApproval,
				Notes: this.state.formValues.Notes,
				AdminNotes: this.state.formValues.AdminNotes,
				PayRate: this.state.formValues.PayRate,
				ShiftLength: this.state.formValues.ShiftLength,
				ShiftPay: this.state.formValues.ShiftPay,
				TravelPay: this.state.formValues.TravelPay,

				Extra: this.state.formValues.Extra,

				Topless: this.state.formValues.Topless,
			})
				.then(() => {
					this.props.handleCloseModal();
				})
				.catch(error => {
					throw error;
				});
			return;
		}

		this.setState({
			formValues: formValues
		});
	};

	render() {
		const seriesType =
			this.props.shift.ParentId === undefined || this.props.shift.ParentId === null
				? apiShiftTypes.singleShift
				: apiShiftTypes.multidayShift;

		return (
			<div>
				<h4 className="heading">
					Edit shift <NatTime startTime={this.props.shift.StartTime} />
				</h4>
				<div>
					<EditSeriesWizard
						seriesType={seriesType}
						series={this.state.formValues}
						onChangeCallback={this.seriesFormValuesCallback}
					/>
				</div>
			</div>
		);
	}
}

EditRegularShift.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
	return {
		seriesById: seriesSelectorById(state)
	};
};

export default connect(mapStateToProps)(EditRegularShift);
