import * as React from "react";
import PropTypes from "prop-types";
import DisplayHtml from "../html/DisplayHtml";
import { clientStore } from "../../stores/clientStore";
import { observer } from "mobx-react";

class ClientNotes extends React.Component {
	componentDidMount() {
		clientStore.fetchById([this.props.clientId], ["Notes"]);
	}

	render() {
		if (!clientStore.clientsById.has(this.props.clientId)) {
			return "Loading...";
		}

		return (
			<>
			<h3>Read these full job notes before booking or working</h3>
			<br />
			<DisplayHtml
				value={clientStore.clientsById.get(this.props.clientId).Notes || ""}
			/>
			</>
		);
	}
}

ClientNotes.propTypes = {
	clientId: PropTypes.number.isRequired
};

export default observer(ClientNotes);
