import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clientSelectorById, profileByUserId, selectClientUserLists } from "../../poll/selectors";
import { fromObjectKeysToArray } from "../../lib/arrayObj/arrayObj";
import ClientListSelect from "../lists/ClientListSelect";
import {
	addAllClientsToUserId,
	addAllUsersToClientId,
	addHrmMnrFavPreapprovalPOST,
	deleteAllClientsByUserId,
	deleteAllUsersByClientId,
	removeHrmMnrFavPreapprovalDELETE
} from "../../api/apiEndpoints";
import SkimpyListSelector from "../lists/SkimpyListSelector";
import { poll } from "../../poll/actions";
import { clientIsDeleted, userIsDeleted } from "../../lib/filter/filterDeleted";

export const ClientUserListTypes = {
	Hrms: "Hrms",
	Favourites: "Favourites",
	Mnrs: "Mnrs",
	Preapprovals: "Preapprovals",
	SeriesPrepprovals: "SeriesPrepprovals"
};

class EditClientUserList extends React.Component {
	apiMappings = {
		[ClientUserListTypes.Hrms]: "hrms",
		[ClientUserListTypes.Favourites]: "favourites",
		[ClientUserListTypes.Mnrs]: "mnrs",
		[ClientUserListTypes.Preapprovals]: "preapprovals",
		[ClientUserListTypes.SeriesPrepprovals]: "series_preapprovals"
	};

	friendlyNames = {
		[ClientUserListTypes.Hrms]: "Hotel Relationship Manager",
		[ClientUserListTypes.Favourites]: "Favourites",
		[ClientUserListTypes.Mnrs]: "May Not Return",
		[ClientUserListTypes.Preapprovals]: "Pre Approved",
		[ClientUserListTypes.SeriesPrepprovals]: "Series Pre Approvals"
	};

	state = {
		targetList: [],
		clientUserLists: undefined
	};

	static propTypes = {
		listName: PropTypes.string.isRequired,
		byUserId: PropTypes.number,
		byClientId: PropTypes.number
	};

	static defaultProps = {
		byUserId: -1,
		byClientId: -1
	};

	// id can be either client or user ID
	handleOnCreate = id => {
		let params = {};
		if (this.props.byUserId === -1) {
			params = {
				ClientId: this.props.byClientId,
				UserId: id,
				section: this.apiMappings[this.props.listName]
			};
		} else if (this.props.byClientId === -1) {
			params = {
				ClientId: id,
				UserId: this.props.byUserId,
				section: this.apiMappings[this.props.listName]
			};
		}
		addHrmMnrFavPreapprovalPOST(params)
			.then(() => {
				this.props.dispatch(poll({}));
			})
			.catch(() => {
			});
	};

	// id can be either client or user ID
	handleOnDelete = id => {
		let entryToDelete = undefined;
		// Find the list ID for the matching user ID
		if (this.props.byUserId === -1) {
			entryToDelete = this.state.targetList.find(r => r.UserId === id);
		}
		// Find the list ID for the matching client ID
		else if (this.props.byClientId === -1) {
			entryToDelete = this.state.targetList.find(r => r.ClientId === id);
		}
		removeHrmMnrFavPreapprovalDELETE({
			Id: entryToDelete.Id,
			section: this.apiMappings[this.props.listName]
		})
			.then(() => {
				this.props.dispatch(poll({}));
			})
			.catch(() => {
			});
	};

	handleOnCreateAll = () => {
		// Adding by client id
		if (this.props.byUserId === -1) {
			addAllUsersToClientId({ ClientId: this.props.byClientId }).then(r => {
				this.props.dispatch(poll({}));
			});
		}
		// Adding by user id
		else if (this.props.byClientId === -1) {
			addAllClientsToUserId({ UserId: this.props.byUserId }).then(r => {
				this.props.dispatch(poll({}));
			});
		}
	};

	handleOnDeleteAll = () => {
		// Deleting by client id
		if (this.props.byUserId === -1) {
			deleteAllUsersByClientId({ ClientId: this.props.byClientId }).then(r => {
				this.props.dispatch(poll({}));
			});
		}
		// Deleting by user id
		else if (this.props.byClientId === -1) {
			deleteAllClientsByUserId({ UserId: this.props.byUserId }).then(r => {
				this.props.dispatch(poll({}));
			});
		}
	};

	static getDerivedStateFromProps(nextProps, currentState) {
		if (nextProps.clientUserLists !== currentState.clientUserLists) {
			return {
				clientUserLists: nextProps.clientUserLists
			};
		}
		return null;
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.clientUserLists !== this.state.clientUserLists) {
			this.load();
		}
	}

	load = () => {
		let list = [];
		if (this.props.byClientId !== -1) {
			list =
				this.props.clientUserLists.byClientId[this.props.listName][
					this.props.byClientId
					] || [];
		} else if (this.props.byUserId !== -1) {
			list =
				this.props.clientUserLists.byUserId[this.props.listName][
					this.props.byUserId
					] || [];
		} else {
			throw "byClientID & byUserId not valid";
		}

		const listFiltered = list
			.filter(item => {
				return !userIsDeleted(item.UserId);
			})
			.filter(item => {
				return !clientIsDeleted(item.ClientId);
			});

		this.setState({
			targetList: fromObjectKeysToArray(listFiltered)
		});
	};

	render() {
		let displayedIds = [];

		// View Clients
		if (this.props.byUserId === -1) {
			displayedIds = this.state.targetList
				.map(clientUserRow => {
					return clientUserRow.UserId;
				})
				.sort((a, b) => {
					return this.props.profilesByUserId[a].StageName.localeCompare(
						this.props.profilesByUserId[b].StageName
					);
				});
		}
		// View Users
		else if (this.props.byClientId === -1) {
			displayedIds = this.state.targetList
				.map(clientUserRow => {
					return clientUserRow.ClientId;
				})
				.sort((a, b) => {
					return this.props.clientsById[a].ClientName.localeCompare(
						this.props.clientsById[b].ClientName
					);
				});
		}

		return (
			<div>
				<h4 className="heading">Edit {this.friendlyNames[this.props.listName]}</h4>

				{this.props.byClientId === -1 ? (
					<ClientListSelect
						value={displayedIds}
						name={"clientOrUserIds"}
						handleOnCreate={this.handleOnCreate}
						handleOnDelete={this.handleOnDelete}
						handleOnCreateAll={this.handleOnCreateAll}
						handleOnDeleteAll={this.handleOnDeleteAll}
					/>
				) : (
					<SkimpyListSelector
						value={displayedIds}
						name={"clientOrUserIds"}
						handleOnCreate={this.handleOnCreate}
						handleOnDelete={this.handleOnDelete}
						handleOnCreateAll={this.handleOnCreateAll}
						handleOnDeleteAll={this.handleOnDeleteAll}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		clientUserLists: selectClientUserLists(state),
		clientsById: clientSelectorById(state),
		profilesByUserId: profileByUserId(state)
	};
};

EditClientUserList = connect(mapStateToProps)(EditClientUserList);
export default EditClientUserList;
