import * as React from "react";
import PropTypes from "prop-types";

class GroupedInputWithButton extends React.Component {
	static defaultProps = {
		parentStyles: {}
	};

	static propTypes = {
		inputProp: PropTypes.any.isRequired,
		buttonProp: PropTypes.any.isRequired,
		parentStyles: PropTypes.object
	};

	render() {
		return (
			<div
				style={{
					...this.props.parentStyles,
					display: "flex",
					alignItems: "center"
				}}
			>
				<span
					style={{
						flex: "1 1 auto"
					}}
				>
					{this.props.inputProp}
				</span>
				<span>{this.props.buttonProp}</span>
			</div>
		);
	}
}

export default GroupedInputWithButton;
