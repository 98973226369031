import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clientShiftFeedbackTypesAsArray } from "../../config/selectors";

class ReappearanceText extends React.Component {
	render() {
		if (!this.props.value) {
			return "";
		}

		const options = {
			1: "Favourite",
			2: "Pre Approved",
			3: "May Not Return",
			4: "Regular"
		};

		return options[this.props.value];
	}
}

ReappearanceText.propTypes = {
	value: PropTypes.any
};

const mapStateToProps = state => {
	return {
		clientShiftFeedbackTypesAsArray: clientShiftFeedbackTypesAsArray(state)
	};
};

ReappearanceText = connect(mapStateToProps)(ReappearanceText);
export default ReappearanceText;
