import * as React from "react";
import PropTypes from "prop-types";
import ClientListSelect from "../../components/lists/ClientListSelect";
import { ManageVenueState } from "./manageVenueState";
import { observer } from "mobx-react";

class ManageVenues extends React.Component {

	constructor(props) {
		super(props);
		this.venueState = new ManageVenueState(this.props.brandId);
	}

	render() {
		return (
			<ClientListSelect
				handleOnCreate={this.venueState.handleOnAddClientToBrand}
				handleOnDelete={this.venueState.handleOnDeleteClientFromBrand}
				name={"venuesById"}
				value={this.venueState.clientIds}
			/>
		);
	}
}

ManageVenues.propTypes = {
	brandId: PropTypes.number
};

export default observer(ManageVenues)
