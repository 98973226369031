import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { findClientById } from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import ClientNotes from "../../components/text/ClientNotes";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import { clientStore } from "../../stores/clientStore";
import EditVenueNotes from "./EditVenueNotes";

class VenueNotes extends React.Component {
	componentDidMount() {
		clientStore.fetchById([this.props.clientId], ["Notes"]);
	}

	render() {
		return (
			<div>
				<StandardModalWrapper
					heading={"Notes"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<EditVenueNotes
								clientId={this.props.clientId}
								handleSaveFn={handleCloseModal}
							/>
						);
					}}
				/>

				<h4 className="heading">Notes</h4>
				<div>
					<ClientNotes clientId={this.props.clientId} />
				</div>
			</div>
		);
	}
}

VenueNotes.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(VenueNotes);
