import * as React from "react";
import PropTypes from "prop-types";

export default class Money extends React.Component {
	render() {
		let { value } = this.props;

		if (value === undefined || value === null) {
			return null;
		}

		const checkIfZeroMoneys = value.toFixed(2);

		if (value >= 0) {
			return <span>${checkIfZeroMoneys}</span>;
		} else {
			value = -value;
			value = value.toFixed(2);
			if (value === "0.00") {
				return <span>$0.00</span>;
			}
			if (this.props.negativeValuesAreRed) {
				return (
					<span className="color-fail">
						-$
						{value}
					</span>
				);
			}
			return (
				<span>
					-$
					{value}
				</span>
			);
		}
	}
}

Money.defaultProps = {
	negativeValuesAreRed: false
};

Money.propTypes = {
	value: PropTypes.number,
	negativeValuesAreRed: PropTypes.bool
};
