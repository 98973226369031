import * as React from "react";
import PropTypes from "prop-types";
import "./FiveStars.css";

class FiveStars extends React.Component {
	handleOnChange = idx => {
		return () => {
			return this.props.handleOnChange({
				target: {
					name: this.props.name,
					value: idx + 1
				}
			});
		};
	};

	render() {
		if (this.props.value === undefined || this.props.value === null) {
			return "";
		}

		const badStars = 5 - this.props.value;

		let out = [];

		for (let i = 0; i < this.props.value; i++) {
			out.push("fas fa-star");
		}

		for (let i = 0; i < badStars; i++) {
			out.push("fal fa-star");
		}

		let iconStyles = { fontSize: this.props.size };

		return (
			<span>
				{out.map((star, starIdx) => {
					return (
						<i
							key={starIdx}
							onClick={this.props.disabled ? null : this.handleOnChange(starIdx)}
							style={iconStyles}
							className={"five-stars " + star}
						/>
					);
				})}
			</span>
		);
	}
}

FiveStars.defaultProps = {
	size: 14
};

FiveStars.propTypes = {
	name: PropTypes.string,
	size: PropTypes.number,
	value: PropTypes.number,
	disabled: PropTypes.bool,
	handleOnChange: PropTypes.func
};

export default FiveStars;
