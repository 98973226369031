import {
	aggregateArrayToObjectById,
	fromArrayToObjectByKey
} from "../lib/arrayObj/arrayObj";
import { uploadTypes } from "../api/apiEndpoints";

export const pollList = {
	AdminPayments: {},
	Brands: {},
	ClientContacts: {},
	Clients: {},
	ClientTeamTalks: {},
	ClientUsers: {},
	Favourites: {},
	HrmChecklists: {},
	Hrms: {},
	Levels: {},
	Mnrs: {},
	Notifications: {},
	PaymentCategoryCodes: {},
	PaymentCategorySubCodes: {},
	Preapprovals: {},
	Profiles: {},
	Series: {},
	SeriesPreapprovals: {},
	SkimpyBible: {},
	Templates: {},
	Uploads: {},
	UserGroupPermissions: {},
	Users: {},
	VenueBible: {}
};

const userClientLists = {
	Favourites: {},
	Hrms: {},
	Mnrs: {},
	Preapprovals: {},
	SeriesPreapprovals: {}
};

const defaultState = {
	updatedAt: {},
	raw: {
		AdminPayments: [],
		Brands: [],
		ClientContacts: [],
		Clients: [],
		ClientTeamTalks: [],
		ClientUsers: [],
		Favourites: [],
		HrmChecklists: [],
		Hrms: [],
		Levels: [],
		Mnrs: [],
		Notifications: [],
		PaymentCategoryCodes: [],
		PaymentCategorySubCodes: [],
		Preapprovals: [],
		Profiles: [],
		Series: [],
		SeriesPreapprovals: [],
		SkimpyBible: [],
		Templates: [],
		Uploads: [],
		UserGroupPermissions: [],
		Users: [],
		VenueBible: []
	},
	byId: { ...pollList },
	userClientLists: {
		byUserId: {
			...userClientLists
		},
		byClientId: {
			...userClientLists
		}
	},
	profiles: {
		byUserId: {}
	},
	uploads: {
		brands: {
			banners: {
				byBrandId: {}
			}
		}
	},
	loading: true
};

function transposeDeltaPollData(data) {
	const template = {};
	const deltaMapping = {};

	// strip
	Object.keys(data).forEach(key => {
		if (key === "UpdatedAt") {
			return;
		}
		let newKey = key;
		if (key.indexOf("delta_") === 0) {
			newKey = key.slice(6);
			deltaMapping[newKey] = true;
		} else {
			deltaMapping[newKey] = false;
		}
		template[newKey] = null;
	});

	const newArr = [];
	const length = "Id" in data ? data.Id.length : data["delta_Id"].length;
	for (let i = 0; i < length; i++) {
		newArr.push(
			Object.keys(template).reduce(
				(newOb, key) => {
					if (deltaMapping[key]) {
						if (i === 0) {
							newOb[key] = data["delta_" + key][i];
						} else {
							newOb[key] = newArr[i - 1][key] - data["delta_" + key][i];
						}
					} else {
						newOb[key] = data[key][i];
					}

					return newOb;
				},
				{ ...template }
			)
		);
	}
	return newArr;
}

export function pollReducer(state = defaultState, action) {
	switch (action.type) {
		case "POLL_LOADING": {
			return {
				...state,
				loading: action.payload
			};
		}
		case "POLL_SUCCESS_DATA": {
			let payload = { ...action.payload };

			// raw
			let newRaw = { ...state.raw, ...payload };
			let newUpdatedAt = { ...state.updatedAt };

			const transposeDeltaConvFn = columnName => {
				if (!(columnName in payload)) {
					return;
				}
				newUpdatedAt[columnName] = payload[columnName].UpdatedAt;
				payload[columnName] = transposeDeltaPollData(payload[columnName]);

				newRaw[columnName] = payload[columnName];
			};

			transposeDeltaConvFn("Preapprovals");
			transposeDeltaConvFn("Favourites");
			transposeDeltaConvFn("Hrms");
			transposeDeltaConvFn("Mnrs");
			transposeDeltaConvFn("SeriesPreapprovals");
			transposeDeltaConvFn("Series");
			transposeDeltaConvFn("Uploads");
			transposeDeltaConvFn("Profiles");
			// transposeDeltaConvFn("Clients");

			let uploads = { ...defaultState.uploads };
			if ("Uploads" in payload) {
				uploads.brands.banners.byBrandId = payload.Uploads.filter(upload => {
					return upload.UploadType === uploadTypes.Brands && upload.Tag === "Banner";
				}).reduce((bannersByBrandId, current) => {
					bannersByBrandId[current.UploadTypeId] = current;
					return bannersByBrandId;
				}, {});
			}

			// All polling data objects by ID
			let pollById = { ...state.byId };
			Object.keys(pollList).forEach(list => {
				if (list in payload) {
					pollById[list] = fromArrayToObjectByKey("Id", payload[list]);
				}
			});

			// User/Client lists to byUserId
			let userClientListsByUser = { ...state.userClientLists.byUserId };
			Object.keys(userClientLists).forEach(list => {
				if (list in payload) {
					userClientListsByUser[list] = aggregateArrayToObjectById(
						"UserId",
						payload[list]
					);
				}
			});

			// User/Client lists to byClientId
			let userClientListsByClient = { ...state.userClientLists.byClientId };
			Object.keys(userClientLists).forEach(list => {
				if (list in payload) {
					userClientListsByClient[list] = aggregateArrayToObjectById(
						"ClientId",
						payload[list]
					);
				}
			});

			// Profiles by UserId
			let profilesByUserId = { ...state.profiles.byUserId };
			if ("Profiles" in payload) {
				profilesByUserId = fromArrayToObjectByKey("UserId", payload["Profiles"]);
			}

			// Convert null => []
			Object.keys(newRaw).map(key => {
				if (newRaw[key] === null) {
					newRaw[key] = [];
				}
			});

			return {
				...state,
				raw: newRaw,
				byId: pollById,
				userClientLists: {
					byUserId: userClientListsByUser,
					byClientId: userClientListsByClient
				},
				profiles: {
					byUserId: profilesByUserId
				},
				uploads,
				updatedAt: newUpdatedAt
			};
		}
		default: {
			return state;
		}
	}
}
