import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormManager from "../../../components/form/FormManager";
import FormRow from "../../../components/form/FormRow";
import InputNumber from "../../../components/input/InputNumber";
import InputTextArea from "../../../components/input/InputTextArea";
import InputCheckbox from "../../../components/input/InputCheckbox";
import ClientList from "../../../components/select/ClientList";
import DateTimePicker from "../../../components/datetime/DateTimePicker";
import { clientSelectorById } from "../../../poll/selectors";
import { requirementsToBools } from "../lib/requirements";
import { convertDateToAPIDate } from "../../../lib/dateUtils/dateUtils";

class EditParentSeriesWizard extends React.Component {
	render() {
		return (
			<div className="col-sm-12">
				<FormManager
					validateOnLoad={true}
					initialFormValues={{
						...this.props.parentSeries
					}}
					handleValidation={v => {
						v.greaterThanOrEqualTo({ field: "WeeklyFrequency", greaterThanValue: 1 });
					}}
					onChangeCallback={(formValues, formValidation) => {
						this.props.onChangeCallback(formValues, formValidation);
					}}
				>
					{({
						formValues,
						handleOnChange,
						formValidation,
						handleSetFormValues,
						submitEnabled
					}) => {
						const handleClientId = e => {
							const clientId = e.target.value;

							let clientRequirements = [];
							// If they don't select a client, then unset the requirements, without this the page will error
							if (clientId !== undefined) {
								clientRequirements = this.props.clientSelectorById[clientId]
									.Requirements;
							}

							handleSetFormValues({
								...formValues,
								...requirementsToBools(clientRequirements),
								ClientId: clientId
							});
						};

						let inputVenue,
							inputTopless,
							inputBarSkills,
							inputWeeklyFrequency,
							inputHrmApproval,
							inputNotes,
							inputAdminFee,
							inputTravelPay = null;

						if (this.props.showVenue) {
							inputVenue = (
								<FormRow
									label={"Venue"}
									value={
										<ClientList
											name="ClientId"
											value={formValues.ClientId}
											onChange={handleClientId}
										/>
									}
								/>
							);
						}

						if (this.props.showRequirements) {
							inputTopless = (
								<FormRow
									label={"Topless"}
									value={
										<InputCheckbox
											name="Topless"
											value={formValues.Topless}
											onChange={handleOnChange}
										/>
									}
								/>
							);
						}

						if (this.props.showWeeklyFrequency) {
							inputWeeklyFrequency = (
								<FormRow
									label={"Weeks Between Shifts"}
									value={
										<InputNumber
											name="WeeklyFrequency"
											value={formValues.WeeklyFrequency}
											onChange={handleOnChange}
											validationMessages={formValidation.WeeklyFrequency}
										/>
									}
								/>
							);
						}

						if (this.props.showHrmApproval) {
							inputHrmApproval = (
								<FormRow
									label={"Hrm Approval"}
									value={
										<InputCheckbox
											name="HrmApproval"
											value={formValues.HrmApproval}
											onChange={handleOnChange}
										/>
									}
								/>
							);
						}

						if (this.props.showNotes) {
							inputNotes = (
								<FormRow
									label={"Notes"}
									value={
										<InputTextArea
											name="Notes"
											value={formValues.Notes}
											onChange={handleOnChange}
											validationMessages={formValidation.Notes}
										/>
									}
								/>
							);
						}

						if (this.props.showAdminFee) {
							inputAdminFee = (
								<FormRow
									label={"Admin Fee Total (0 or greater)"}
									value={
										<InputNumber
											name="AdminPay"
											value={formValues.AdminPay}
											onChange={handleOnChange}
											validationMessages={formValidation.AdminPay}
										/>
									}
								/>
							);
						}

						if (this.props.showTravelPay) {
							inputTravelPay = (
								<FormRow
									label={"Travel Pay (0 or greater)"}
									value={
										<InputNumber
											name="TravelPay"
											value={formValues.TravelPay}
											onChange={handleOnChange}
											validationMessages={formValidation.TravelPay}
										/>
									}
								/>
							);
						}

						return (
							<div>
								{/* Page 1 */}

								{this.props.showWeeklyFrequency ? (
									<h4 className="heading">Schedule</h4>
								) : null}

								{inputWeeklyFrequency}

								{this.props.showVenue ||
								this.props.showRequirements ||
								this.props.showHrmApproval ||
								this.props.showNotes ? (
									<h4 className="heading">Venue & Shift Requirements</h4>
								) : null}

								{inputVenue}
								{inputTopless}
								{inputHrmApproval}
								{inputNotes}

								{this.props.showAdminFee || this.props.showTravelPay ? (
									<h4 className="heading">Admin Fees & Travel Pay</h4>
								) : null}
								{inputAdminFee}
								{inputTravelPay}

								<button
									className="btn btn-primary pull-right"
									onClick={() => {
										this.props.onChangeCallback("done");
									}}
									disabled={!submitEnabled}
									name="Page"
								>
									<i className="fas fa-arrow-circle-right" /> Next
								</button>
							</div>
						);
					}}
				</FormManager>
			</div>
		);
	}
}

EditParentSeriesWizard.defaultProps = {
	showVenue: false,
	showRequirements: false,
	showWeeklyFrequency: false,
	showHrmApproval: false,
	showNotes: false,
	showAdminFee: false,
	showTravelPay: false
};

EditParentSeriesWizard.propTypes = {
	parentSeries: PropTypes.object.isRequired,
	onChangeCallback: PropTypes.func,

	showVenue: PropTypes.bool,
	showRequirements: PropTypes.bool,
	showWeeklyFrequency: PropTypes.bool,
	showHrmApproval: PropTypes.bool,
	showNotes: PropTypes.bool,
	showAdminFee: PropTypes.bool,
	showTravelPay: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		clientSelectorById: clientSelectorById(state)
	};
};

export default connect(mapStateToProps)(EditParentSeriesWizard);
