import { configGET } from "../api/apiEndpoints";

export function loadConfigFromApi() {
	return dispatch => {
		configGET().then(response => {
			dispatch(configData(response.data));
		});
	};
}

export function configData(data) {
	return {
		type: "CONFIG_SUCCESS_DATA",
		payload: data
	};
}
