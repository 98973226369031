import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sortAlphaNumeric } from "./sort";
import Select from "./Select";
import { brandSelectorAsArray } from "../../poll/selectors";

class SelectBrand extends React.Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string,
		value: PropTypes.any
	};

	render() {
		if (!this.props.brandSelectorAsArray) {
			return null;
		}

		const options = this.props.brandSelectorAsArray.map(brand => {
			return {
				value: brand.Id,
				label: brand.BrandName
			};
		});

		sortAlphaNumeric(options);

		return (
			<Select
				clearable={false}
				options={options}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		brandSelectorAsArray: brandSelectorAsArray(state)
	};
};

SelectBrand = connect(mapStateToProps)(SelectBrand);
export default SelectBrand;
