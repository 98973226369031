import { computed, observable } from "mobx";
// @ts-ignore
import jwtDecode from "jwt-decode";

class AuthState {
	public localStorageKey = "authToken";
	public tokenRaw: string = "";

	@observable
	public token: {
		UserGroupId: number;
		exp: number;
		userId: number;
		clientId?: number;
	};

	constructor() {
		const localStorageCopy = localStorage.getItem(this.localStorageKey) as
			| string
			| undefined
			| null;

		if (localStorageCopy !== null && localStorageCopy !== undefined) {
			this.tokenRaw = localStorageCopy;
			this.token = jwtDecode(this.tokenRaw);
		}
	}

	@computed
	public get isAuthed(): boolean {
		return this.tokenRaw !== "";
	}

	@computed
	public get isAdmin() {
		if (!this.isAuthed) {
			return false;
		}
		return this.token.UserGroupId === 4;
	}
}

export const authState = new AuthState();
