import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";
import { paymentSubCodesAsArray } from "../../poll/selectors";

class PaymentSubCodeList extends React.Component {
	render() {
		if (!this.props.paymentSubCodesAsArray) {
			return null;
		}

		let options = [];

		if (this.props.paymentCode !== undefined) {
			options = this.props.paymentSubCodesAsArray
				.filter(subCode => {
					if (subCode.DeletedAt !== null) {
						return false;
					}
					return subCode.ParentId === this.props.paymentCode;
				})
				.map(code => {
					return {
						value: code.Id,
						label: code.Description
					};
				});

			sortAlphaNumeric(options);
		}

		return (
			<Select
				options={options}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
				defaultOptionValue={0}
			/>
		);
	}
}

PaymentSubCodeList.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	value: PropTypes.any,
	paymentCode: PropTypes.number
};

const mapStateToProps = state => {
	return {
		paymentSubCodesAsArray: paymentSubCodesAsArray(state)
	};
};

export default connect(mapStateToProps)(PaymentSubCodeList);
