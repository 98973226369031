import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	clientUsersSelectorAsArray,
	findClientById,
	userSelectorById
} from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import LoadingButton from "../../components/button/LoadingButton";
import FormRow from "../../components/form/FormRow";
import InputText from "../../components/input/InputText";
import {
	addClientLoginPATCH,
	deleteUserDELETE,
	usersPATCH
} from "../../api/apiEndpoints";
import InputPassword from "../../components/input/InputPassword";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import { userGroups } from "../../lib/userInfo/userInfo";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { toastr } from "react-redux-toastr";
import GroupedInputWithButton from "../../components/GroupedInput/GroupedInputWithButton";
import { PlusCircle, Trash } from "../../components/icons/Icons";

class VenueUserAccounts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			clientLogins: [],
			tmpPassword: null,
			tmpPasswordUserId: -1
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad = props => {
		this.setState({
			clientLogins: props.userAccounts.filter(ua => {
				return ua.ClientId === this.props.clientId;
			})
		});
	};

	renderAddNewClientLogin() {
		return (
			<FormManager
				initialFormValues={{
					ClientId: this.props.clientId,
					Email: "",
					Password: "",
					ConfirmPassword: ""
				}}
				handleValidation={v => {
					v.required({
						fieldNames: ["Email", "Password", "ConfirmPassword"]
					});
					v.email({ fieldName: "Email" });
					v.mustNotExistInDB({
						fieldName: "Email",
						storeKey: "Users",
						objectKey: "Email"
					});
					v.confirmPassword({
						fieldName: "ConfirmPassword",
						passwordFieldName: "Password"
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					addClientLoginPATCH({
						ClientId: formValues.ClientId,
						Email: formValues.Email,
						Password: formValues.Password,
						ConfirmPassword: formValues.ConfirmPassword
					})
						.then(() => {
							handleSubmitSuccess();
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"Email"}
								value={
									<InputText
										name={"Email"}
										value={formValues.Email}
										onChange={handleOnChange}
										validationMessages={formValidation.Email}
									/>
								}
							/>

							<FormRow
								label={"Password"}
								value={
									<InputPassword
										name={"Password"}
										value={formValues.Password}
										onChange={handleOnChange}
										validationMessages={formValidation.Password}
									/>
								}
							/>

							<FormRow
								label={"Confirm Password"}
								value={
									<InputPassword
										name={"ConfirmPassword"}
										value={formValues.ConfirmPassword}
										onChange={handleOnChange}
										validationMessages={formValidation.ConfirmPassword}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										className="btn btn-success"
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										<PlusCircle /> Add Account
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}

	renderEditExistingLogins() {
		return this.state.clientLogins.map((ua, idx) => {
			return (
				<div key={idx} style={{ borderTop: "1px solid lightgrey", padding: 5 }}>
					<FormRow
						label={"Email"}
						value={
							<span className="color-nice-blue" style={{ fontWeight: "bold" }}>
								{this.props.userById[ua.UserId].Email}
							</span>
						}
					/>

					<FormRow
						label={"Reset Password"}
						value={
							<FormManager
								initialFormValues={{ newPassword: "" }}
								handleValidation={v => {
									v.password({ fieldName: "newPassword" });
								}}
								handleSubmit={({
									formValues,
									handleSubmitFail,
									handleSubmitSuccess
								}) => {
									usersPATCH({
										Id: ua.UserId,
										Password: formValues.newPassword
									})
										.then(() => {
											handleSubmitSuccess();
											toastr.success("Password updated successfully.");
										})
										.catch(() => {
											handleSubmitFail();
										});
								}}
							>
								{({
									formValues,
									handleOnChange,
									handleSetFormValues,
									handleSubmit,
									formValidation,
									submitEnabled,
									submitting
								}) => {
									const handleSubmitClear = e => {
										handleSubmit(e);
										handleSetFormValues({
											...formValues,
											newPassword: ""
										});
									};

									const updateButtonWidth = 120;
									return (
										<div>
											<GroupedInputWithButton
												inputProp={
													<InputText
														name={"newPassword"}
														validationMessages={
															formValues.newPassword !== "" && formValidation.newPassword
														}
														onChange={handleOnChange}
														value={formValues.newPassword}
													/>
												}
												buttonProp={
													<LoadingButtonWithConfirm
														style={{ padding: 9, width: updateButtonWidth }}
														className="btn btn-danger"
														confirmMessage="Update password?"
														disabled={
															submitting || !submitEnabled || formValues.newPassword === ""
														}
														loading={submitting}
														onClick={handleSubmitClear}
													>
														<i className="fas fa-key" /> Update
													</LoadingButtonWithConfirm>
												}
											/>
										</div>
									);
								}}
							</FormManager>
						}
					/>

					<FormRow
						label={"Delete"}
						value={
							<FormManager
								handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
									deleteUserDELETE({ Id: ua.UserId })
										.then(() => {
											handleSubmitSuccess();
										})
										.catch(() => {
											handleSubmitFail();
										});
								}}
							>
								{({ handleSubmit, submitting }) => {
									return (
										<AdminContainerMigrate
											align={"left"}
											buttons={[
												<LoadingButtonWithConfirm
													className="btn btn-danger"
													disabled={submitting}
													loading={submitting}
													onClick={handleSubmit}
												>
													<Trash /> Delete Login
												</LoadingButtonWithConfirm>
											]}
										/>
									);
								}}
							</FormManager>
						}
					/>
				</div>
			);
		});
	}

	render() {
		// TODO: before doing this, when creating a client user relationship - also create the user (like with profiles)
		// TODO: deleting the relationship should also delete the user account
		return (
			<div>
				<StandardModalWrapper
					heading={"Create New Login"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								{this.renderAddNewClientLogin()}
								<h4 className="heading">Existing Logins</h4>
								{this.renderEditExistingLogins()}
							</div>
						);
					}}
				/>
				<h4 className="heading">Venue Logins</h4>
				{this.state.clientLogins.map((ua, idx) => {
					return (
						<div key={idx} style={{ borderTop: "1px solid lightgrey", padding: 5 }}>
							{this.props.userById[ua.UserId].Email}
						</div>
					);
				})}
			</div>
		);
	}
}

VenueUserAccounts.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId),
		userAccounts: clientUsersSelectorAsArray(state),
		userById: userSelectorById(state)
	};
};

export default connect(mapStateToProps)(VenueUserAccounts);
