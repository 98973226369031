import * as React from "react";
import PropTypes from "prop-types";
import NatTime from "../../components/text/NatTime";

class NatTimePop extends React.Component {
	render() {
		return (
			<span className="color-fail" style={{ fontSize: 20 }}>
				<NatTime startTime={this.props.startTime} endTime={this.props.endTime} />
			</span>
		);
	}
}

NatTimePop.propTypes = {
	startTime: PropTypes.any,
	endTime: PropTypes.any
};

export default NatTimePop;
