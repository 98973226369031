import * as React from "react";
import PropTypes from "prop-types";
import ClientName from "../../components/links/ClientName";
import ClientTelephone from "../../components/links/ClientTelephone";
import ClientAddress from "../../components/text/ClientAddress";
import ShiftHRMList from "./ShiftHRMList";
import Requirements from "./Requirements";
import NatTime from "../../components/text/NatTime";
import { roundTo } from "../../lib/number/roundTo";
import BookingAdminButtons from "./BookingAdminButtons";
import Money from "../../components/text/Money";
import PaymentRow from "./payment/PaymentRow";
import {
	ShiftIconEquals,
	ShiftIconMinus,
	ShiftIconPlus,
	ShiftIconTimes
} from "./icon/ShiftIcons";
import SkimpyHeavenBankTransfer from "./SkimpyHeavenBankTransfer";
import ShiftBrand from "./brandImage/ShiftBrand";
import { observer } from "mobx-react";
import { SHIFT_EXPECTED_HOURS_MESSAGE } from "./AdminEdit/shiftFeeUtils";

class RegularShift extends React.Component {
	renderDanceShowPayments() {
		const shift = this.props.Shift;
		return (
			<div className="row text-left row-padding-top">
				{/*Total Pay*/}
				<div className="col-md-4">
					<div className="col-xs-6">
						<strong>Total pay:</strong>
					</div>
					<div className="col-xs-6 text-right">
						<span>
							<Money value={shift.ShiftPay + shift.TravelPay} />
						</span>
					</div>
				</div>

				{/*Fees*/}
				<div className="col-md-4">
					<div className="col-xs-6">
						<strong>Total fees:</strong>
					</div>
					<div className="col-xs-6 text-right">
						<Money
							negativeValuesAreRed={true}
							value={-(shift.AdminPay + shift.FeePay)}
						/>
					</div>
				</div>

				{/*Take Home*/}
				<div className="col-md-4">
					<div className="col-xs-6">
						<strong>Take home:</strong>
					</div>
					<div className="col-xs-6 text-right">
						<Money
							negativeValuesAreRed={true}
							value={shift.ShiftPay + shift.TravelPay - shift.AdminPay - shift.FeePay}
						/>
					</div>
				</div>

				<label className="col-md-12" style={{ color: "red", marginTop: '8px', fontWeight: 'normal', fontSize: '11px', textAlign: 'center' }}>{SHIFT_EXPECTED_HOURS_MESSAGE}</label>
			</div>
		);
	}

	renderRegularPayments() {
		const shift = this.props.Shift;
		return (
			<div>
				<PaymentRow
					label="Hours"
					value={roundTo(shift.ShiftLength / 60, 2)}
					icon={null}
				/>

				<PaymentRow
					label="Pay Rate"
					value={shift.PayRate}
					valueIsMoney={true}
					icon={<ShiftIconTimes />}
				/>

				<PaymentRow
					label="Travel Rate"
					value={shift.TravelPay}
					valueIsMoney={true}
					icon={<ShiftIconPlus />}
				/>

				<PaymentRow
					label="Total you bill"
					value={shift.ShiftPay + shift.TravelPay}
					hasTopBlackBorder={true}
					valueIsMoney={true}
					textIsBold={true}
					icon={<ShiftIconEquals />}
				/>

				<PaymentRow
					label="Shift Fee"
					value={shift.AdminPay + shift.FeePay}
					textIsRed={true}
					textIsBold={true}
					valueIsMoney={true}
					icon={<ShiftIconMinus />}
				/>

				<label
					style={{
						color: "red",
						marginTop: "8px",
						fontWeight: "normal",
						fontSize: "11px"
					}}
				>
					{SHIFT_EXPECTED_HOURS_MESSAGE}
				</label>
			</div>
		);
	}

	render() {
		const shift = this.props.Shift;
		const { ClientId, StartTime, EndTime } = shift;

		return (
			<div className="row">
				<div className="col-md-3 col-lg-3">
					<div>
						<ClientName clientId={ClientId} /> -{" "}
						<ClientTelephone clientId={ClientId} />
					</div>
					<div>
						<ClientAddress clientId={ClientId} />
					</div>
					<div>
						<ShiftBrand clientId={ClientId} />
					</div>

					<div>
						<span>
							<b>Hotel Relationship Manager</b>
						</span>
					</div>
					<ShiftHRMList clientId={ClientId} />
				</div>
				<div className="col-md-2 col-lg-4">
					<div>
						<span style={{ fontWeight: 600 }}>
							<NatTime startTime={StartTime} endTime={EndTime} />
						</span>
					</div>
					<Requirements
						requirements={shift.Requirements}
						hrmApprovalRequired={shift.HrmApproval === true}
						clientId={shift.ClientId}
					/>
					<div>{shift.Notes}</div>
				</div>
				<div className="col-md-4 col-lg-3 text-right">
					<div className="col-xs-12">
						{shift.Extra === 1
							? this.renderDanceShowPayments()
							: this.renderRegularPayments()}
					</div>
				</div>

				<div className="col-md-3 col-lg-2">
					<BookingAdminButtons shift={shift} />
				</div>
			</div>
		);
	}
}

RegularShift.propTypes = {
	Shift: PropTypes.shape(
		{ Action: PropTypes.string },
		{ AdminPay: PropTypes.number },
		{ ButtonClass: PropTypes.string },
		{ ClientId: PropTypes.number },
		{ EndTime: PropTypes.string },
		{ Extra: PropTypes.any },
		{ FeePay: PropTypes.number },
		{ Id: PropTypes.number },
		{ Label: PropTypes.string },
		{ Notes: PropTypes.string },
		{ PayRate: PropTypes.number },
		{ Requirements: PropTypes.any },
		{ SeriesId: PropTypes.number },
		{ ShiftLength: PropTypes.number },
		{ ShiftPay: PropTypes.number },
		{ ShiftStatus: PropTypes.number },
		{ ShiftType: PropTypes.number },
		{ StartTime: PropTypes.string },
		{ TotalCost: PropTypes.any },
		{ TravelPay: PropTypes.number },
		{ UserId: PropTypes.any },
		{ ValidationMessages: PropTypes.array }
	)
	// Client: PropTypes.object,
	// [{
	// ClientName : "JUNCTION HOTEL - Moora"
	// Conflict : "Shift is owned by Bella Mia"
	// ShiftId : 44273
	// Time : "Thu 05 Oct 07:00 PM - 11:00 PM"
	// Title: "Selected Shift"
	// Validator: "Booked By Other"
	// }]

	// [{Id: 4, Description: "Merch Sales"}, {Id: 5, Description: "Games"}, {Id: 6, Description: "Jug Run"}]
};

export default observer(RegularShift);
