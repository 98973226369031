import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { levelSelectorById, profileSelectorById } from "../../poll/selectors";
import Select from "./Select";

class LevelList extends React.Component {
	render() {
		const { levels } = this.props;
		let options = [];

		Object.keys(levels).forEach(levelId => {
			options.push({ value: Number(levelId), label: levels[levelId].Level });
		});

		return (
			<Select
				options={options}
				name={this.props.name}
				onChange={this.props.onChange}
				value={this.props.value}
			/>
		);
	}
}

LevelList.propTypes = {
	levels: PropTypes.object,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string
};

const mapStateToProps = state => {
	return {
		levels: levelSelectorById(state)
	};
};

export default connect(mapStateToProps)(LevelList);
