import * as React from "react";
import PropTypes from "prop-types";
import ValidationMessages from "../../components/shift/validationMessages";

export default class ShiftValidation extends React.Component {
	render() {
		return (
			<div className="row">
				<div className="col-sm-12" style={{ paddingLeft: 30, paddingRight: 30 }}>
					<ValidationMessages
						showSelectedShift={true}
						validation={this.props.shift.ValidationMessages}
						selectedShift={{
							ChildClientIds: this.props.shift.ChildClientIds,
							StartTime: this.props.shift.StartTime,
							EndTime: this.props.shift.EndTime
						}}
					/>
				</div>
			</div>
		);
	}
}

ShiftValidation.propTypes = {
	shift: PropTypes.object.isRequired
};
