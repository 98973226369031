import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { attemptLogin, redirectHomeIfLoggedIn } from "./actions";
import LoadingButton from "../components/button/LoadingButton";
import { loginScreenSelector } from "./selectors";
import PhoneNumberLink from "../components/links/PhoneNumberLink";
import InputText from "../components/input/InputText";
import { resetPasswordPOST } from "../api/apiEndpoints";
import FormManager from "../components/form/FormManager";
import InputPassword from "../components/input/InputPassword";
import { NavLink } from "react-router-dom";
import { checkAndSetTokenFromLocalStorage } from "../auth/actions";

class LoginRoute extends React.Component {
	//
	constructor(props) {
		super(props);
		this.state = {
			page: "login",
			recoverEmail: "",
			recoverSent: false,
			recoverSending: false
		};
	}

	componentWillMount() {
		// Check if already logged in
		this.props.dispatch(checkAndSetTokenFromLocalStorage());
		this.props.dispatch(redirectHomeIfLoggedIn());
	}

	render() {
		return (
			<div
				className="container-fluid"
				style={{
					background: "#EEE",
					height: "100%",
					width: "100%",
					position: "absolute"
				}}
			>
				<div className="row">
					<div
						className="col-sm-12 col-md-4 col-md-offset-4 mini-container-shadow"
						style={{ background: "#FFF", marginTop: 50, padding: 20 }}
					>
						<div>
							<NavLink to="/">
								<img
									alt="skimpy heaven"
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										display: "block",
										height: "200px"
									}}
									src="assets/img/logo-black-no-bg.png"
								/>
							</NavLink>
						</div>
						{this.state.page === "login" && (
							<div>
								<FormManager
									initialFormValues={{
										username: "",
										password: ""
									}}
									handleValidation={v => {
										v.required({
											fieldNames: ["username", "password"]
										});
									}}
									handleSubmit={({ formValues }) => {
										this.props.dispatch(
											attemptLogin(
												formValues.username.split(" ").join(""),
												formValues.password
											)
										);
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<div className="form-group">
													<label>Email</label>
													<InputText
														name="username"
														onChange={handleOnChange}
														value={formValues.username}
														inputClassName="form-control"
													/>
												</div>
												<div className="form-group">
													<label>Password</label>
													<InputPassword
														name="password"
														onChange={handleOnChange}
														value={formValues.password}
													/>
												</div>
												<div>
													<LoadingButton
														loading={this.props.loading}
														disabled={this.props.loading || !submitEnabled}
														style={{ width: "100%", marginTop: 25 }}
														onClick={handleSubmit}
													>
														Login
													</LoadingButton>
													{/*TODO: Recover password*/}
													{this.props.authFail ? (
														<div className="alert danger" style={{ marginTop: 15 }}>
															Invalid email or password.
														</div>
													) : null}
													{this.props.otherError ? (
														<div className="alert danger" style={{ marginTop: 15 }}>
															There was an unexpected error :(
														</div>
													) : null}
												</div>
											</div>
										);
									}}
								</FormManager>
								<div style={{ textAlign: "center", paddingTop: 10 }}>
									<button
										className="btn-clear color-primary"
										onClick={() => {
											this.setState({ page: "recovery" });
										}}
									>
										Recover Password
									</button>
								</div>
							</div>
						)}

						{this.state.page === "recovery" && (
							<div>
								<p>
									Please enter the email address you signed up with. If you don't know
									your email address, please contact the office on{" "}
									<PhoneNumberLink phoneNumber={"0435754679"} />.
								</p>
								<p style={{ textAlign: "center" }}>
									<InputText
										name={"recoverEmail"}
										value={this.state.recoverEmail}
										onChange={e => {
											this.setState({ recoverEmail: e.target.value });
										}}
									/>
									<LoadingButton
										loading={this.state.recoverSending && !this.state.recoverSent}
										disabled={this.state.recoverSending}
										onClick={() => {
											this.setState({ recoverSending: true });
											resetPasswordPOST({ Email: this.state.recoverEmail }).then(() => {
												this.setState({ recoverSent: true, recoverSending: false });
											});
										}}
									>
										Request password reset
									</LoadingButton>
								</p>
								<p>
									{this.state.recoverSent && (
										<div>
											Please check your email account for instructions on resetting your
											password.
										</div>
									)}
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

LoginRoute.propTypes = {
	loading: PropTypes.bool,
	authFail: PropTypes.bool,
	otherError: PropTypes.bool,
};

const mapStateToProps = state => {
	return {
		loading: loginScreenSelector(state).loading,
		authFail: loginScreenSelector(state).authFail,
		otherError: loginScreenSelector(state).otherError
	};
};

export default connect(mapStateToProps)(LoginRoute);
