import * as React from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./AnimateFadeInOut.css";

class AnimateFadeInOut extends React.PureComponent {
	static propTypes = {
		renderRow: PropTypes.func.isRequired,
		rows: PropTypes.array,
		rowKey: PropTypes.string,
		useRowValueAsKey: PropTypes.bool // if rows is an array of ids, then use the id
	};

	static defaultProps = {
		useRowValueAsKey: false
	};

	render() {
		return (
			<TransitionGroup>
				{this.props.rows.map(row => {
					let key = undefined;
					if (this.props.useRowValueAsKey) {
						key = row;
					} else {
						key = row[this.props.rowKey];
					}

					return (
						<CSSTransition key={key} classNames="fade" timeout={200}>
							{this.props.renderRow(row)}
						</CSSTransition>
					);
				})}
			</TransitionGroup>
		);
	}
}

export default AnimateFadeInOut;
