import * as React from "react";
import PropTypes from "prop-types";
import { uploadSections, uploadsGet } from "../../api/apiEndpoints";
import Gallery from "../../MyPictures/components/Gallery";
import UploadRenderer from "../../components/uploads/UploadRenderer";

class VenueGallery extends React.Component {
	state = {
		profileUploads: [],
		displayedUploads: []
	};

	static propTypes = {
		profileId: PropTypes.number
	};

	componentDidMount() {
		return uploadsGet({
			UploadSectionTypes: [
				uploadSections.profile_main_profile_picture,
				uploadSections.profile_venue_promo
			],
			UploadTypeId: [this.props.profileId]
		}).then(r => {
			let profileUploads = r.data || [];

			// Set main profile pic as the main photo
			profileUploads = profileUploads.sort((a, b) => {
				if (a.UploadSectionType === uploadSections.profile_main_profile_picture) {
					return -1;
				}
				return b.Id - a.Id;
			});

			this.setState({ profileUploads: profileUploads });
		});
	}

	render() {
		const renderedUploads = this.state.profileUploads.map(upload => {
			return (
				<a href={upload.Path} target="_blank">
					<UploadRenderer src={upload.ThumbnailPath || upload.Path} />
				</a>
			);
		});

		return <Gallery uploads={renderedUploads} />;
	}
}

export default VenueGallery;
