import * as React from "react";
import ClientNames from "../components/links/ClientNames";
import NatTime from "../components/text/NatTime";
import ProfileStageName from "../components/links/ProfileStageName";
import PropTypes from "prop-types";
import { availablePOST, hrmChecklistPOST } from "../api/apiEndpoints";
import ValidationMessages from "../components/shift/validationMessages";
import Spinner from "../components/loading/Spinner";
import Select from "../components/select/Select";
import LoadingButton from "../components/button/LoadingButton";
import { pollLoading } from "../poll/selectors";
import { connect } from "react-redux";
import "./HrmChecklistRow.css";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import FormManager from "../components/form/FormManager";

class HrmChecklistPendingRow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ValidationMessages: [],
			availableLoading: true,
			selectedApprovalOption: null
		};

		this.labelSize = "4";
		this.valueSize = "8";

		this.approvalOptions = [
			{
				label: "Approve for Venue",
				value: "all_shifts"
			},
			{
				label: "Approve for Series",
				value: "this_series"
			},
			{
				label: "Approve for This Shift",
				value: "this_shift"
			},
			{
				label: "Deny",
				value: "deny"
			}
		];
	}

	componentDidMount() {
		// this.props.dispatch(poll({}));
		availablePOST({
			ShiftId: this.props.checklistItem.ShiftId,
			AuthId: this.props.checklistItem.UserId
		})
			.then(response => {
				let messages = [];
				if (response.data.Shifts[0].ValidationMessages !== null) {
					messages = response.data.Shifts[0].ValidationMessages;
				}
				this.setState({ ValidationMessages: messages, availableLoading: false });
			})
			.catch(() => {
				this.setState({ availableLoading: false });
			});
	}

	renderMobileHeader(text) {
		return (
			<div
				className={
					" col-md-" +
					this.labelSize +
					" col-sm-" +
					this.labelSize +
					" col-xs-" +
					this.labelSize +
					" visible-xs visible-sm visible-md"
				}
				style={{
					fontWeight: "bold",
					textAlign: "right"
				}}
			>
				{text}:
			</div>
		);
	}

	render() {
		const validationMessages = (
			<ValidationMessages
				showSelectedShift={false}
				selectedShift={{
					StartTime: this.props.checklistItem.StartTime,
					EndTime: this.props.checklistItem.StartTime,
					ChildClientIds: this.props.checklistItem.ChildClientIds
				}}
				validation={this.state.ValidationMessages}
				hideValidators={["HRM Approval Required"]}
			/>
		);

		return (
			<div className="row div-table" key={this.props.checklistItem.Id}>
				<div className="col-sm-12 div-table__tr">
					<div className="row" style={{ padding: 3 }}>
						<ResponsiveTableColumn
							heading="Venue"
							size={this.props.fullCollumnSizes[0]}
						>
							<ClientNames clientIds={this.props.checklistItem.ChildClientIds} />
						</ResponsiveTableColumn>

						<ResponsiveTableColumn
							heading="Shift Time"
							size={this.props.fullCollumnSizes[1]}
						>
							<NatTime
								startTime={this.props.checklistItem.StartTime}
								endTime={this.props.checklistItem.EndTime}
							/>
						</ResponsiveTableColumn>

						<ResponsiveTableColumn
							heading="Stage Name"
							size={this.props.fullCollumnSizes[2]}
						>
							<ProfileStageName userId={this.props.checklistItem.UserId} />
						</ResponsiveTableColumn>

						<ResponsiveTableColumn
							heading="Date Applied"
							size={this.props.fullCollumnSizes[3]}
						>
							<NatTime startTime={this.props.checklistItem.CreatedAt} />
						</ResponsiveTableColumn>

						{/*Validation for mobile*/}
						<div className="visible-sm visible-xs">
							<Spinner
								fontSize={16}
								loading={this.props.pollLoading || this.state.availableLoading}
							>
								<div style={{ paddingTop: 7 }} className="col-sm-12">
									{validationMessages}
								</div>
							</Spinner>
						</div>

						{/*Display normal size for medium, full row for small and below*/}
						<div
							className={"col-md-" + this.props.fullCollumnSizes[4] + " col-sm-12"}
						>
							<FormManager
								initialFormValues={{
									Id: this.props.checklistItem.Id,
									Selected: undefined
								}}
								handleSubmit={({
									handleSubmitFail,
									handleSubmitSuccess,
									formValues
								}) => {
									hrmChecklistPOST({
										Endpoint: formValues.Selected,
										HrmChecklistId: this.props.checklistItem.Id
									})
										.then(() => {
											handleSubmitSuccess();
										})
										.catch(() => {
											handleSubmitFail();
										});
								}}
							>
								{({
									formValues,
									handleSubmit,
									submitting,
									submitEnabled,
									handleOnChange
								}) => {
									return (
										<div>
											<Select
												disabled={this.state.availableLoading}
												options={this.approvalOptions}
												searchable={false}
												name="Selected"
												value={formValues.Selected}
												onChange={handleOnChange}
											/>
											<LoadingButton
												className="btn btn-primary btn-big-phone"
												style={{ width: "100%" }}
												disabled={!submitEnabled}
												loading={submitting}
												onClick={handleSubmit}
											>
												Submit
											</LoadingButton>
										</div>
									);
								}}
							</FormManager>
						</div>

						{/*Validation for desktop*/}
						<div className="hidden-sm hidden-xs">
							<Spinner
								fontSize={16}
								loading={this.props.pollLoading || this.state.availableLoading}
							>
								<div style={{ paddingTop: 7 }} className="col-sm-12">
									{validationMessages}
								</div>
							</Spinner>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

HrmChecklistPendingRow.propTypes = {
	checklistItem: PropTypes.shape({
		Id: PropTypes.number.isRequired,
		ChildClientIds: PropTypes.arrayOf(PropTypes.number),
		StartTime: PropTypes.string,
		EndTime: PropTypes.string,
		UserId: PropTypes.number,
		CreatedAt: PropTypes.string,
		ShiftId: PropTypes.number
	}),
	fullCollumnSizes: PropTypes.arrayOf(PropTypes.number)
};

const mapStateToProps = state => {
	return {
		pollLoading: pollLoading(state)
	};
};

export default connect(mapStateToProps)(HrmChecklistPendingRow);
