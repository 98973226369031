import React, {useState} from 'react'
import MiniContainer from '../components/container/MiniContainer'


function PrivateJobs() {
    const [title, setTitle] = useState('I am a private jobs title from useState')

    return (
        <MiniContainer>
            <h3>{title}</h3>
        </MiniContainer>
    )
}

export default PrivateJobs