import * as React from "react";
import PropTypes from "prop-types";
import EditSeriesWizard from "../AdminEdit/EditSeriesWizard";
import { requirementsToBools } from "../lib/requirements";
import {
	convertDateToAPIDate,
	newDateWithoutSeconds
} from "../../../lib/dateUtils/dateUtils";
import Spinner from "../../../components/loading/Spinner";
import { apiShiftTypes, createSeriesPOST } from "../../../api/apiEndpoints";
import axios from "axios/index";

class CreateSeries extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false,
			formValues: {
				Notes: "",
				ClientId: undefined,
				ContractStart: convertDateToAPIDate(newDateWithoutSeconds()),
				ContractEndOrNull: null,
				WeeklyFrequency: this.props.singleShiftOnly ? undefined : 1,
				ShiftLength: 60,
				PayRate: 0,
				ShiftPay: 0,
				FeePay: 0,
				AdminPay: 0,
				TravelPay: 0,
				HrmApproval: false,
				Extra: false,
				ShiftType: this.props.singleShiftOnly
					? apiShiftTypes.singleShift
					: apiShiftTypes.series,
				...requirementsToBools([])
			}
		};
	}

	handleCreateSeries = formValues => {
		const bulkCreateSeriesPost = [];

		for (let count = 0; count < this.props.numberOfUsersRequired; count++) {
			bulkCreateSeriesPost.push(createSeriesPOST(formValues));
		}

		axios.all(bulkCreateSeriesPost).then(() => {
			this.props.handleCloseModal();
		});
	};

	seriesFormValuesCallback = formValues => {
		if (formValues === "done") {
			this.setState({ submitting: true });

			this.handleCreateSeries(this.state.formValues);
			return;
		}

		this.setState({
			formValues: formValues
		});
	};

	render() {
		if (this.state.submitting) {
			return (
				<div className="row">
					<Spinner loading={true} />
				</div>
			);
		}

		return (
			<EditSeriesWizard
				seriesType={
					this.props.singleShiftOnly
						? apiShiftTypes.singleShift
						: apiShiftTypes.series
				}
				series={this.state.formValues}
				handleCloseModal={this.props.handleCloseModal}
				onChangeCallback={this.seriesFormValuesCallback}
			/>
		);
	}
}

CreateSeries.propTypes = {
	handleCloseModal: PropTypes.func.isRequired,
	numberOfUsersRequired: PropTypes.number.isRequired,
	singleShiftOnly: PropTypes.bool.isRequired
};

export default CreateSeries;
