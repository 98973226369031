import * as React from "react";
import PropTypes from "prop-types";
import "./EmbedGoogleMap.css";

export default class EmbedGoogleMap extends React.Component {
	render() {
		const address =
			// remove any missing parts of the address
			this.props.addressAsArray
				.filter(addressPart => {
					return String(addressPart) !== "";
				})
				// format string with '+' delimiters
				.join("+")
				.replace(" ", "+");

		return (
			<div className="google-map-embed-wrapper">
				<div className="google-map-embed-h_iframe">
					<iframe
						frameBorder="0"
						src={
							"https://www.google.com/maps/embed/v1/place?key=AIzaSyB-4A7u41qwoCRD5lJrGk2NYgiUnZIzUQ4&q=" +
							address
						}
					/>
				</div>
			</div>
		);
	}
}

EmbedGoogleMap.propTypes = {
	addressAsArray: PropTypes.array
};
