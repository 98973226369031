import * as React from "react";
import PropTypes from "prop-types";

export default class ValidationMessages extends React.Component {
	render() {
		if (this.props.messages === undefined) {
			return null;
		}

		return (
			<div>
				{this.props.messages.map((message, idx) => {
					return (
						<div className="form-validation-box" key={idx}>
							{message}
						</div>
					);
				})}
			</div>
		);
	}
}

ValidationMessages.propTypes = {
	messages: PropTypes.any
};
