import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findProfileByUserId, levelSelectorById } from "../../poll/selectors";

class Level extends React.Component {
	render() {
		return <span>{this.props.level.Level}</span>;
	}
}

Level.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	const profile = findProfileByUserId(state, props.userId);
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile,
		level: levelSelectorById(state)[profile.LevelId]
	};
};

export default connect(mapStateToProps)(Level);
