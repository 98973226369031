import history from "../../history";
import { removeTokenFromStores } from "../../auth/actions";
import { routes } from "../../router/router";

export function logoutUser() {
	return function(dispatch) {
		dispatch(removeTokenFromStores());
		history.push(routes.Login);
	};
}
