import * as React from "react";
import PropTypes from "prop-types";
import EditParentSeriesWizard from "../AdminEdit/EditParentSeriesWizard";
import { editRegularShift } from "../../../api/apiEndpoints";
import Spinner from "../../../components/loading/Spinner";

class EditParentSeries extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formValues: {
				Notes: props.shift.Notes,
				AdminPay: props.shift.AdminPay,
				TravelPay: props.shift.TravelPay,
				HrmApproval: props.shift.HrmApproval
			},
			submittingUpdate: false
		};
	}

	submitSeriesPatch = () => {
		return editRegularShift({
			Id: this.props.shift.Id,
			...this.state.formValues
		});
	};

	handleSubmitUpdate = () => {
		this.setState({
			submittingUpdate: true
		});

		this.submitSeriesPatch().then(() => {
			this.props.handleCloseModal();
		});
	};

	seriesFormValuesCallback = formValues => {
		if (formValues === "done") {
			this.setState({ submitting: true });

			this.handleSubmitUpdate();
			return;
		}

		this.setState({
			formValues: formValues
		});
	};

	render() {
		return (
			<div>
				<h4 className="heading">Edit Shift</h4>
				<div className="row">
					<EditParentSeriesWizard
						showNotes={true}
						showTravelPay={true}
						showAdminFee={true}
						showHrmApproval={true}
						parentSeries={this.state.formValues}
						handleCloseModal={this.props.handleCloseModal}
						onChangeCallback={this.seriesFormValuesCallback}
					/>
					<Spinner loading={this.state.submittingUpdate} />
				</div>
			</div>
		);
	}
}

EditParentSeries.propTypes = {
	handleCloseModal: PropTypes.func.isRequired,
	shift: PropTypes.object
};

export default EditParentSeries;
