import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentSubCodesById } from "../../poll/selectors";

class PaymentSubCodeText extends React.Component {
	render() {
		if (!(this.props.paymentSubCodeId in this.props.paymentSubCodesById)) {
			return null;
		}

		const subCode = this.props.paymentSubCodesById[this.props.paymentSubCodeId];

		if (subCode.DeletedAt !== null) {
			return (
				<span style={{ textDecoration: "line-through" }}>
					{subCode.Description}
				</span>
			);
		}

		return (
			<span>
				{this.props.paymentSubCodesById[this.props.paymentSubCodeId].Description}
			</span>
		);
	}
}

PaymentSubCodeText.propTypes = {
	paymentSubCodeId: PropTypes.number
};

const mapStateToProps = state => {
	return {
		paymentSubCodesById: paymentSubCodesById(state)
	};
};

export default connect(mapStateToProps)(PaymentSubCodeText);
