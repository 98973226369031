import * as React from "react";
import { connect } from "react-redux";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import ClientList from "../components/select/ClientList";
import SkimpyList from "../components/select/SkimpyList";
import Select from "../components/select/Select";
import DateTimePicker from "../components/datetime/DateTimePicker";
import { convertJSDateToDDMMYYYY, parseAPIDate } from "../lib/dateUtils/dateUtils";
import { shiftFilterOptions } from "../components/select/ShiftTypeFilter";
import { availablePOST } from "../api/apiEndpoints";
import { poll } from "../poll/actions";
import NatTime from "../components/text/NatTime";
import ClientName from "../components/links/ClientName";
import ProfileStageName from "../components/links/ProfileStageName";
import Money from "../components/text/Money";
import Spinner from "../components/loading/Spinner";
import "./AdminAdvertiseAvailables.css";
import { hrmChecklistSelectorAsArray } from "../poll/selectors";
import ClickableBadge from "./components/ClickableBadge";
import DateFns from "date-fns";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";

class AdminAdvertiseAvailables extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			AvailableParams: {
				ShiftAvailability: undefined,
				Limit: 99999,
				Offset: 0,
				StartTime: convertJSDateToDDMMYYYY(new Date()),
				UpdatedAt: 0,
				ClientId: undefined,
				UserId: undefined,
				TypeFilter: undefined,
				ShiftFilter: "FILTER_AVAILABLE_SHIFTS"
			},
			ShiftsLoading: false,
			ShiftPageNumber: 0,
			ShiftsPerPage: 10,
			AvailableResults: {
				Shifts: [],
				Results: {
					Total: 0,
					UpdatedAt: ""
				}
			},
			appliedUsersByShiftId: {}
		};
	}

	handleOnFilterChange = e => {
		// reset (ignore) the pagination when changing these filters
		const { name, value } = e.target;

		let newParams = {};
		// reset Shift filter is Skimpy filter used
		if (name === "UserId") {
			newParams.ShiftFilter = undefined;
		}
		// reset Skimpy filter if Shift filter used
		if (name === "ShiftFilter") {
			newParams.UserId = undefined;
		}
		this.setState(
			{
				AvailableParams: {
					...this.state.AvailableParams,
					Offset: 0,
					[name]: value,
					...newParams
				},
				ShiftPageNumber: 0
			},
			//this.getShifts
		);
	};

	getShifts = () => {
		this.setState({ ShiftsLoading: true });

		const lastShift = DateFns.addWeeks(new Date(), 12).getTime();

		availablePOST(this.state.AvailableParams).then(response => {
			const shifts = response.data.Shifts.filter(shift => {
				if (lastShift < parseAPIDate(shift.StartTime).getTime()) {
					return false;
				}
				return true;
			});

			response.data.Shifts = shifts;

			this.setState({ ShiftsLoading: false, AvailableResults: response.data });
		});

		this.props.dispatch(poll({}));
	};

	sumMultidayColumn(shift, key) {
		const children = shift.Multiday || [];
		const val = children.reduce(
			(total = 0, shiftVal) => total + shiftVal[key],
			0
		);
		return val;
	}

	componentDidMount() {
		//this.getShifts();
	}

	componentWillReceiveProps(props) {
		let appliedUsersByShiftId = {};
		props.hrmChecklistAsArray.forEach(checklistItem => {
			if (!(checklistItem.ShiftId in appliedUsersByShiftId)) {
				appliedUsersByShiftId[checklistItem.ShiftId] = [checklistItem.UserId];
				return;
			}
			appliedUsersByShiftId[checklistItem.ShiftId].push(checklistItem.UserId);
		});

		this.setState({
			appliedUsersByShiftId: appliedUsersByShiftId
		});
	}

	renderGA(shift) {
		if (shift.ShiftStatus === 1 && shift.UserId !== null) {
			return (
				<React.Fragment>
					Available From <ProfileStageName userId={shift.UserId} />
				</React.Fragment>
			);
		}
		if (shift.ShiftStatus === 5 && shift.UserId !== null) {
			return <ProfileStageName userId={shift.UserId} />;
		}
		return "";
	}

	renderShiftStatus(shift) {
		let out = [];
		if (shift.ShiftStatus === 5) {
			out.push("Booked");
		}
		if (shift.ShiftStatus === 1) {
			out.push("Available");
		}

		if (shift.Id in this.state.appliedUsersByShiftId) {
			out.push(
				<ClickableBadge userIds={this.state.appliedUsersByShiftId[shift.Id]} />
			);
		}

		return out.map((o, idx) => <React.Fragment key={idx}>{o}</React.Fragment>);
	}

	render() {
		return (
			<div>
				{this.renderSearch()}
				<Spinner loading={this.state.ShiftsLoading}>
					<table className="advertise-availables">
						<thead style={{ padding: 5 }}>
							<tr>
								<th style={{ padding: 5 }}>Venue</th>
								<th style={{ padding: 5 }} width="250">
									Shift Time
								</th>
								<th style={{ padding: 5, textAlign: "center" }}>Pay</th>
								<th style={{ padding: 5, textAlign: "center" }}>Fees</th>
								<th style={{ padding: 5, textAlign: "center" }}>Booked By</th>
							</tr>
						</thead>
						<tbody>
							{this.state.AvailableResults.Shifts.map((shift, shiftIdx) => {
								let shiftPay = 0,
									feePay = 0;
								let style = {};

								if ([3, 4].includes(shift.ShiftType)) {
									shiftPay = this.sumMultidayColumn(shift, "ShiftPay") + shift.TravelPay;
									feePay = this.sumMultidayColumn(shift, "FeePay") + shift.AdminPay;
									style = { backgroundColor: "#CCDFFF" };
								} else {
									shiftPay = shift.ShiftPay + shift.TravelPay;
									feePay = shift.FeePay + shift.AdminPay;
								}

								if (shift.Extra === 1) {
									style = { backgroundColor: "#FFD2E8" };
								}

								return (
									<React.Fragment key={shiftIdx}>
										<tr style={{ ...style, borderTop: "1px solid #BDBDBD" }}>
											<td>
												<ClientName clientId={shift.ChildClientIds[0]} />
											</td>
											<td>
												<NatTime startTime={shift.StartTime} endTime={shift.EndTime} />
											</td>
											<td style={{ textAlign: "center" }}>
												<Money value={shiftPay} />
											</td>
											<td style={{ textAlign: "center" }}>
												<Money value={feePay} />
											</td>
											<td style={{ textAlign: "center" }}>
												{this.renderGA(shift) === "" ? (
													this.renderShiftStatus(shift)
												) : (
													<React.Fragment>
														{this.renderGA(shift)}
													</React.Fragment>
												)}
											</td>
										</tr>
										<tr>
											<td colSpan={6} style={{ padding: 3, ...style }}>
												{shift.Notes}
											</td>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
				</Spinner>
			</div>
			
		);
	}

	renderSearch() {
		return (
			<div>
				<div className="mini-container">
					<h4 className="heading">Search</h4>
					<div className="row">
						<SearchBarItem
							label={"Shifts"}
							value={
								<Select
									options={[shiftFilterOptions[0], shiftFilterOptions[1]]}
									name="ShiftFilter"
									onChange={this.handleOnFilterChange}
									value={this.state.AvailableParams.ShiftFilter}
								/>
							}
						/>

						<SearchBarItem
							label={"Venue"}
							value={
								<ClientList
									name="ClientId"
									onChange={this.handleOnFilterChange}
									value={this.state.AvailableParams.ClientId}
								/>
							}
						/>
						<SearchBarItem
							label={"Shifts occuring after"}
							value={
								<DateTimePicker
									name="StartTime"
									dayPickerOnly={true}
									onChange={this.handleOnFilterChange}
									value={this.state.AvailableParams.StartTime}
								/>
							}
						/>

						<SearchBarItem
							label={"Skimpy"}
							value={
								<SkimpyList
									name="UserId"
									dayPickerOnly={true}
									onChange={this.handleOnFilterChange}
									value={this.state.AvailableParams.UserId}
								/>
							}
						/>

						<SearchBarItem
							label={"Type"}
							value={
								<Select
									options={[
										{
											value: "FILTER_MULTIDAY",
											label: "Multiday events"
										},
										{
											value: "FILTER_SHOWS",
											label: "Shows"
										},
										{
											value: "FILTER_REGULAR_SHIFTS",
											label: "Single shifts"
										}
									]}
									name="TypeFilter"
									dayPickerOnly={true}
									onChange={this.handleOnFilterChange}
									value={this.state.AvailableParams.TypeFilter}
								/>
							}
						/>

						
						<SearchBarItem/>
						<SearchBarItem
							label={""}
							value={
								<button
									className="btn btn-primary admin-box-button"
									onClick={this.getShifts}
								>
									<i className="fas fa-search" /> SEARCH
								</button>
							}
						/>

					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		hrmChecklistAsArray: hrmChecklistSelectorAsArray(state)
	};
};

AdminAdvertiseAvailables = connect(mapStateToProps)(AdminAdvertiseAvailables);
export default AdminAdvertiseAvailables;
