import * as React from "react";
import { connect } from "react-redux";
import { hrmChecklistSelectorAsArray } from "../poll/selectors";
import "./HrmChecklistNotificationBadge.css";

class HrmChecklistNotificationBadge extends React.Component {
	render() {
		if (this.props.hrmChecklistAsArray.length === 0) {
			return null;
		}

		return (
			<span className="badge hrm-checklist-notification-badge">
				{this.props.hrmChecklistAsArray.length}
			</span>
		);
	}
}

HrmChecklistNotificationBadge.propTypes = {};

const mapStateToProps = state => {
	return {
		hrmChecklistAsArray: hrmChecklistSelectorAsArray(state)
	};
};

HrmChecklistNotificationBadge = connect(mapStateToProps)(
	HrmChecklistNotificationBadge
);
export default HrmChecklistNotificationBadge;
