import { differenceInMinutes, JS_DAY_TO_TEXT, natTimeFromAPIDateAsText, parseAPIDate } from "../dateUtils/dateUtils";
import store from "../../store";
import { rootSelector } from "../../poll/selectors";

export default class Validation {
	constructor(fields, friendlyNames) {
		this.fields = fields;
		this.validation = {};
		this.friendlyNames = friendlyNames;
		this.valid = true;

		this.apiDateGreaterThan = this.apiDateGreaterThan.bind(this);
	}

	mustNotExistInDB({ fieldName, storeKey, objectKey }) {
		if (
			this.fields[fieldName] === undefined ||
			this.fields[fieldName] === null ||
			this.fields[fieldName] === ""
		) {
			return;
		}

		const pollData = rootSelector(store.getState())["raw"];
		let exists = false;

		if (storeKey in pollData) {
			for (let i = 0; i < pollData[storeKey].length; i++) {
				if (objectKey in pollData[storeKey][i]) {
					if (
						pollData[storeKey][i][objectKey].toLowerCase() ===
						this.fields[fieldName].toLowerCase()
					) {
						exists = true;
						break;
					}
				}
			}
		} else {
			throw "validatior mustNotExistInDB " +
				storeKey +
				" doesn't exist in pollData";
		}

		if (exists) {
			this.appendValidationToField(fieldName, "already exists.");
		}
	}

	formValueIsEmpty(fieldName) {
		if (
			this.fields[fieldName] === undefined ||
			this.fields[fieldName] === null ||
			this.fields[fieldName] === ""
		) {
			return true;
		}
		return false;
	}

	required({ fieldNames = [] }) {
		fieldNames.forEach(fieldName => {
			if (this.formValueIsEmpty(fieldName)) {
				this.appendValidationToField(fieldName, "Field is required.");
			}
		});
	}

	// https://stackoverflow.com/a/16242575
	money({ fieldName }) {
		if (
			/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/.test(
				this.fields[fieldName]
			) === false
		) {
			this.appendValidationToField(
				fieldName,
				" must be a valid dollar amount, ie (150, 150.10, 1.50, 0.50, 0)."
			);
		}
	}



	password({ fieldName, passwordLength = 8 }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		if (this.fields[fieldName].length < passwordLength) {
			this.appendValidationToField(
				fieldName,
				"Must contain more than " + passwordLength + " characters."
			);
		}
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
		const isStrongPassword = strongRegex.test(this.fields[fieldName])
		if(!isStrongPassword){
			this.appendValidationToField(
				fieldName,
				"Please include one upper, one lower and one number"
			);
		}
	}

	confirmPassword({ fieldName, passwordFieldName }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		if (this.fields[fieldName] !== this.fields[passwordFieldName]) {
			this.appendValidationToField(fieldName, "Passwords must match.");
		}
	}

	date({ fieldName }) {
		if (
			/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
				this.fields[fieldName]
			) === false
		) {
			this.appendValidationToField(
				fieldName,
				" must be a valid date (dd/mm/yyyy)"
			);
		}
	}

	// 0 - Sunday
	// 1 - Monday
	// 6 - Saturday
	occursOnSameWeekday({ fieldName, weekDayFieldName }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		if (
			parseAPIDate(this.fields[fieldName]).getDay() !==
			this.fields[weekDayFieldName]
		) {
			this.appendValidationToField(
				fieldName,
				" must occur on a " + JS_DAY_TO_TEXT[this.fields[weekDayFieldName]]
			);
		}
	}

	occursOnSameOccuranceOfMonth({
		fieldName,
		weekDayFieldName,
		weekOfMonthFieldName
	}) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		const d = new parseAPIDate(this.fields[fieldName]);

		const year = d.getFullYear(),
			month = d.getMonth();
		const daysInMonth = new Date(year, month + 1, 0).getDate();

		let dayOccurance = [];
		for (var dayOfMonth = 1; dayOfMonth < daysInMonth; dayOfMonth++) {
			const searchDate = new Date(year, month, dayOfMonth);
			if (searchDate.getDay() === this.fields[weekDayFieldName]) {
				dayOccurance.push(searchDate);
			}
		}

		const friendlyNames = {
			1: "first",
			2: "second",
			3: "third",
			4: "fourth",
			5: "last"
		};

		if (this.fields[weekOfMonthFieldName] === 5) {
			if (dayOccurance[dayOccurance.length -1].getDate() !== d.getDate()) {
				this.appendValidationToField(
					fieldName,
					" must be the " +
					friendlyNames[this.fields[weekOfMonthFieldName]] +
					" occurance of the month"
				);
			}
			return
		}

		if (
			dayOccurance[this.fields[weekOfMonthFieldName] - 1].getDate() !== d.getDate()
		) {
			this.appendValidationToField(
				fieldName,
				" must be the " +
					friendlyNames[this.fields[weekOfMonthFieldName]] +
					" occurance of the month"
			);
		}
	}

	number({ fieldName }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}
		if (/^\d*$/.test(this.fields[fieldName]) === false) {
			this.appendValidationToField(fieldName, " must be a number");
		}
	}

	characterCount({ fieldName, characterLimit = 0 }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}
		if (String(this.fields[fieldName]).length > characterLimit) {
			this.appendValidationToField(
				fieldName,
				" must be less than " + characterLimit + " characters"
			);
		}
	}

	greaterThanOrEqualTo({ field, greaterThanValue }) {
		if (this.fields[field] < greaterThanValue) {
			this.appendValidationToField(
				field,
				" must be greater than or equal to " + greaterThanValue
			);
		}
	}

	lessThanOrEqualTo({ field, lessThanValue }) {
		if (this.fields[field] > lessThanValue) {
			this.appendValidationToField(
				field,
				" must be less than or equal to " + lessThanValue
			);
		}
	}

	apiDateGreaterThan({ lessThanFieldName, greaterThanFieldName }) {
		const diff = differenceInMinutes(
			this.fields[lessThanFieldName],
			this.fields[greaterThanFieldName]
		);

		if (diff > 0) {
			this.appendValidationToField(
				lessThanFieldName,
				" must occur before " +
					natTimeFromAPIDateAsText({ startTime: this.fields[greaterThanFieldName] })
			);
		}
	}

	// https://www.w3resource.com/javascript/form/email-validation.php
	email({ fieldName }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		if (
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				this.fields[fieldName]
			) === false
		) {
			this.appendValidationToField(fieldName, "must be a valid email");
		}
	}

	// https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
	url({ fieldName }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		if (
			/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
				this.fields[fieldName]
			) === false
		) {
			this.appendValidationToField(
				fieldName,
				"must be a valid url (https://www.facebook.com...)"
			);
		}
	}

	charLength({ fieldName, charLength }) {
		if (String(this.fields[fieldName]).length !== charLength) {
			this.appendValidationToField(
				fieldName,
				"must contain exactly " + charLength + " characters"
			);
		}
	}

	validFileExtension({ fieldName, validFileExtensions = [] }) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		var valid = false;
		validFileExtensions.forEach(validExt => {
			if (this.fields[fieldName].toLowerCase().includes(validExt.toLowerCase())) {
				valid = true;
			}
		});

		if (!valid) {
			this.appendValidationToField(
				fieldName,
				" Can only upload " + validFileExtensions.join(", ") + " files"
			);
		}
	}

	invalidFileExtension({
		fieldName,
		invalidFileExtensions = [],
		validFileExtensions = []
	}) {
		if (this.formValueIsEmpty(fieldName)) {
			return;
		}

		var valid = true;
		invalidFileExtensions.forEach(validExt => {
			if (this.fields[fieldName].toLowerCase().includes(validExt.toLowerCase())) {
				valid = false;
			}
		});

		if (!valid) {
			this.appendValidationToField(
				fieldName,
				" Can't upload " +
					invalidFileExtensions.join(", ") +
					" files, please use a " +
					validFileExtensions.join(", ") +
					" file instead."
			);
		}
	}

	appendValidationToField(field, validationMessage) {
		if (this.validation[field] === undefined) {
			this.validation[field] = [];
		}

		this.valid = false;
		this.validation[field].push(validationMessage);
	}
}
