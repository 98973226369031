import API from "../api/client";
import store, { REDUX_ROOT_POLL } from "../store";
import { pollList } from "./reducers";

export function poll({
	HrmChecklists,
	Templates,
	Notifications,
	Series,
	Profiles,
	Brands,
	ClientUsers,
	Levels,
	Clients,
	ClientContacts,
	ClientTeamTalks,
	Hrms,
	Mnrs,
	Favourites,
	Preapprovals,
	SeriesPreapprovals,
	UserGroupPermissions,
	Users,
	Uploads,
	AdminPayments,
	PaymentCategoryCodes,
	PaymentCategorySubCodes,
	SkimpyBible
}) {
	return dispatch => {
		// arguments[0]
		const toPoll = {
			HrmChecklists: true,
			Templates: true,
			Notifications: true,
			Series: true,
			Profiles: true,
			Brands: true,
			ClientUsers: true,
			Levels: true,
			Clients: true,
			ClientContacts: true,
			ClientTeamTalks: true,
			Hrms: true,
			Mnrs: true,
			Favourites: true,
			Preapprovals: true,
			SeriesPreapprovals: true,
			UserGroupPermissions: true,
			Users: true,
			Uploads: true,
			AdminPayments: true,
			PaymentCategoryCodes: true,
			PaymentCategorySubCodes: true,
			SkimpyBible: true,
			VenueBible: true
		};

		const existingPollData = store.getState()[REDUX_ROOT_POLL]["raw"];
		const updatedAtStore = store.getState()[REDUX_ROOT_POLL].updatedAt;

		const params = Object.keys(toPoll)
			.filter(p => toPoll[p] === true)
			.map(key => {
				return key;
			})
			.reduce((params, current) => {
				const count =
					existingPollData[current] === null ? 0 : existingPollData[current].length;
				let updatedAt = "1970-01-01T00:00:00Z";
				if (count > 0) {
					if (
						[
							"Preapprovals",
							"Favourites",
							"Hrms",
							"Mnrs",
							"SeriesPreapprovals",
							"Series",
							"Uploads",
							"Profiles"
						].includes(current)
					) {
						updatedAt = updatedAtStore[current];
					} else {
						updatedAt = existingPollData[current][0].ua;
					}
				}

				params[current] = {
					Count: count,
					UpdatedAt: updatedAt
				};
				return params;
			}, {});

		dispatch(pollLoading(true));
		API.post("/poll", params)
			.then(response => {
				dispatch(pollData(response.data));
				dispatch(dispatch(pollLoading(false)));
			})
			.catch(e => {
				dispatch(pollLoading(false));
				throw e;
			});
	};
}

export function pollLoading(data) {
	return {
		type: "POLL_LOADING",
		payload: data
	};
}

export function pollData(data) {
	return {
		type: "POLL_SUCCESS_DATA",
		payload: data
	};
}
