import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {findClientById} from "../../poll/selectors";

class ClientNameAsText extends React.Component {
	render() {

		if (!this.props.client) {
			return null
		}

		return this.props.client.ClientName;
	}
}

ClientNameAsText.propTypes = {
	clientId: PropTypes.number.isRequired,
	client: PropTypes.object
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

ClientNameAsText = connect(mapStateToProps)(ClientNameAsText);
export default ClientNameAsText;
