import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentTypeById } from "../../config/selectors";

class PaymentTypeText extends React.Component {
	render() {
		if (!this.props.paymentTypeById) {
			return null;
		}

		return this.props.paymentTypeById[this.props.paymentTypeId].Description;
	}
}

PaymentTypeText.propTypes = {
	paymentTypeId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
	return {
		paymentTypeById: paymentTypeById(state)
	};
};

export default connect(mapStateToProps)(PaymentTypeText);
