import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findProfileByUserId } from "../../poll/selectors";
import { Link } from "react-router-dom";
import { userIsDeleted } from "../../lib/filter/filterDeleted";
import { loggedInUserIs, loggedInUserIsAdmin, loggedInUserIsAdminHRMOrVenue } from "../permissions/validateUserPermissions";

class ProfileStageName extends React.Component {
	render() {
		let { StageName = "", WorkFacebook = "" } = this.props.profile;

		if (userIsDeleted(this.props.userId)) {
			StageName = <del>{StageName}</del>;
		}

		if (userIsDeleted(this.props.userId) && !loggedInUserIsAdmin()) {
			return <>{StageName}</>;
		}

		if (!this.props.clickable) {
			return <>{StageName}</>;
		}

		if(!loggedInUserIs(this.props.userId) && !loggedInUserIsAdminHRMOrVenue()) {
			return WorkFacebook ? <a href={WorkFacebook} target="_blank">{StageName}</a> : <>{StageName}</>
		}
		return <Link to={"/profiles/" + this.props.userId}>{StageName}</Link>;
	}
}

ProfileStageName.defaultProps = {
	clickable: true
};

ProfileStageName.propTypes = {
	userId: PropTypes.number.isRequired,
	profile: PropTypes.object,
	clickable: PropTypes.bool
};

const mapStateToProps = (state, props) => {
	return {
		profile: findProfileByUserId(state, props.userId)
	};
};

ProfileStageName = connect(mapStateToProps)(ProfileStageName);
export default ProfileStageName;
