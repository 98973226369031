import React, { useContext } from "react";
import MiniContainer from "../../components/container/MiniContainer";
import { Pencil } from "../../components/icons/Icons";
import InfiniteScroll from "../../components/infiniteScroll/InfiniteScroll";
import ProfileStageName from "../../components/links/ProfileStageName";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import EditPaymentLine from "../../components/payments/EditPaymentLine";

import Money from "../../components/text/Money";
import NatTime from "../../components/text/NatTime";
import PaymentCodeText from "../../components/text/PaymentCodeText";
import PaymentMethodText from "../../components/text/PaymentMethodText";
import PaymentSubCodeText from "../../components/text/PaymentSubCodeText";
import { parseAPIDate } from "../../lib/dateUtils/dateUtils";
import { lessThanApiDate } from "../../lib/sort/sortByDate";
import PaymentLogs from "../../PaymentLogs/PaymentLogs";
import SkimpyHeavenBankTransfer from "../../Shifts/components/SkimpyHeavenBankTransfer";
import { AdminPaymentsContext } from "../AdminPaymentsContext";
import "../Payments.css";

const colSpacing = [3, 2, 1, 1, 2, 1, 1, 1];
const HEADINGS = [
	{
		label: "Date",
		name: "Date",
		size: colSpacing[0],
		sortDescFunc: lessThanApiDate,
		disableSort: true
	},
	{
		label: "Actioned By",
		name: "AuthId",
		size: colSpacing[1],
		disableSort: true
	},
	{
		label: "Code",
		name: "PaymentCode",
		size: colSpacing[2],
		disableSort: true
	},
	{
		label: "SubCode",
		name: "PaymentCategorySubCodesId",
		size: colSpacing[3],
		disableSort: true
	},
	{
		label: "Payment Method",
		name: "PaymentMethod",
		size: colSpacing[4],
		disableSort: true
	},
	{
		label: "Charge",
		name: "Charge",
		size: colSpacing[5],
		disableSort: true
	},
	{
		label: "Payment",
		name: "Credit",
		size: colSpacing[6],
		disableSort: true
	},
	{
		label: "Balance",
		name: "Balance",
		size: colSpacing[7],
		disableSort: true
	}
];

const PaymentResults = () => {
	const nowAsEpoch = new Date().getTime();
	const { reloadPayments, transactions } = useContext(AdminPaymentsContext);
	return (
		<MiniContainer>
			<div className="col-lg-12">
				<div className="row">
					<div className="col-sm-12">
						<div
							className="hidden-xs row div-table__thead div-table-responsive"
							style={{ fontWeight: "bold" }}
						>
							<div className="col-sm-12 payments-header-grid">
								<div className="balance">New Balance</div>
								<div className="date">Date</div>
								<div className="actioned-by">Actioned By</div>
								<div className="code">Code</div>
								<div className="subcode">SubCode</div>
								<div className="payment-method">Payment Method</div>
								<div className="charge">Charge</div>
								<div className="payment">Payment</div>
							</div>
						</div>
						<div className="row">
							<InfiniteScroll
								rowCount={transactions.length}
								rowComparison={() => {
									return false;
								}}
								rows={transactions}
								overscanRowCount={5}
								rowRender={({ index, row, style }) => {
									let rowClasses = ["div-table-responsive-row"];

									if (parseAPIDate(row.Date).getTime() > nowAsEpoch) {
										rowClasses.push("payments-row-future");
									}

									return (
										<div style={style} key={row} className={rowClasses.join(" ")}>
											<div style={{ minHeight: 40 }} className="grid-payment">
												<label>New Balance</label>
												<div className="balance">
													<Money negativeValuesAreRed={true} value={row.Balance} />
												</div>
												<label>Date</label>
												<div className="date">
													<NatTime startTime={row.Date} />
													{row.ClientId ? (
														<>
															&nbsp;
															<SkimpyHeavenBankTransfer clientId={row.ClientId} />
														</>
													) : (
														[]
													)}
												</div>
												<label>Actioned By</label>
												<div className="actioned-by">
													{row.AuthId && <ProfileStageName userId={row.AuthId} />}
												</div>
												<label>Code</label>
												<div className="code">
													<PaymentCodeText paymentCodeId={row.PaymentCode} />
												</div>
												<label>SubCode</label>
												<div className="subcode">
													<PaymentSubCodeText
														paymentSubCodeId={row.PaymentCategorySubCodesId}
													/>
												</div>
												<label>Payment Method</label>
												<div className="payment-method">
													<PaymentMethodText paymentMethodId={row.PaymentMethod} />
												</div>
												<label>Charge</label>
												<div className="charge">
													<Money negativeValuesAreRed={true} value={row.Charge} />
												</div>
												<label>Payment</label>
												<div className="payment">
													<Money negativeValuesAreRed={true} value={row.Credit} />
												</div>
												<label>Line Item</label>
												<div className="line-item">{row.LineItem}</div>
												{!row.IsShift && (
													<>
														<label>Edit</label>
														<div className="edit">
															<StandardModalWrapper
																modalOpener={({ handleOpenModal }) => {
																	return (
																		<button className="btn btn-success" onClick={handleOpenModal}>
																			<Pencil />
																		</button>
																	);
																}}
																heading={"Edit"}
																render={({ handleCloseModal }) => {
																	return (
																		<EditPaymentLine
																			showInactiveUsers={false}
																			payment={row}
																			onSuccessUpdateFn={() => {
																				reloadPayments();
																				handleCloseModal();
																			}}
																		/>
																	);
																}}
															/>

															<StandardModalWrapper
																modalOpener={({ handleOpenModal }) => {
																	return (
																		<button className="btn btn-default" onClick={handleOpenModal}>
																			<i className="fas fa-history" />
																		</button>
																	);
																}}
																render={() => {
																	return <PaymentLogs filterByPaymentId={row.PaymentId} />;
																}}
															/>
														</div>
													</>
												)}
											</div>
										</div>
									);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</MiniContainer>
	);
};
export default PaymentResults;
