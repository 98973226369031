import * as React from "react";
import { connect } from "react-redux";
import BaseLayout from "./BaseLayout";
import { userGroups } from "../lib/userInfo/userInfo";
import { venueBibleTermsConditionsHeading } from "../Bible/VenueBible";
import { skimpyBibleTermsConditionsHeading } from "../Bible/SkimpyBible";
import { routes } from "../router/router";
import Notifications from "../Notifications/Notifications";
import "./BaseLayout.css";
import { NavLink } from "react-router-dom";
import Logout from "./components/Logout";
import { pollLoading, skimpyBibleSelectorById, venueBibleSelectorById } from "../poll/selectors";
import { getAuthUserId } from "../auth/selectors";
import CollapsibleMenu from "./components/CollapsibleMenu";
import MenuItem from "./components/MenuItem";
import SingleMenu from "./components/SingleMenu";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import HrmChecklistNotificationBadge from "../HrmChecklist/HrmChecklistNotificationBadge";
import UserCredit from "../UserCredit/UserCredit";
import { PlusCircle } from "../components/icons/Icons";
import AppVersion from "../components/appVersion/AppVersion";

class UserLayout extends React.Component {
	render() {
		const { skimpyBible, venueBible } = this.props;
		// Conv from obj => array
		let skimpyBibleOrdered = Object.keys(skimpyBible).map(id => {
			return skimpyBible[id];
		});
		let venueBibleOrdered = Object.keys(venueBible).map(id => {
			return venueBible[id];
		});

		// sort the entries by Order key
		skimpyBibleOrdered.sort((a, b) => {
			return a.Heading.localeCompare(b.Heading);
		});

		skimpyBibleOrdered = skimpyBibleOrdered.filter(entry => {
			if (entry.Id === 1) {
				return false;
			}
			return true;
		});
		venueBibleOrdered.sort((a, b) => {
			return a.Heading.localeCompare(b.Heading);
		});

		venueBibleOrdered = venueBibleOrdered.filter(entry => {
			if (entry.Id === 1) {
				return false;
			}
			return true;
		});

		return (
			<BaseLayout
				renderLeftMenu={
					<React.Fragment>
						<ProtectedComponent
							allowedGroups={[
								userGroups.trial,
								userGroups.fullAccess,
								userGroups.hrm,
								userGroups.admin
							]}
						>
							<CollapsibleMenu
								initCollapsed={false}
								heading="MY WORK"
								icon={"fas fa-home"}
								collapsable={true}
							>
								<MenuItem route={routes.KFW}>My Keen For Work Register</MenuItem>
								{/* <MenuItem route={routes.PrivateJobs}>Private Jobs</MenuItem> */}
								<MenuItem route={routes.Shifts}>Shifts</MenuItem>
								<MenuItem route={routes.MyFeedback}>My Feedback</MenuItem>
								<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
									<MenuItem route={routes.HotelApplications}>
										My Shift Applications
										<HrmChecklistNotificationBadge />
									</MenuItem>
								</ProtectedComponent>
							</CollapsibleMenu>
						</ProtectedComponent>

						<SingleMenu 
							heading="MY PROFILE" 
							icon="fas fa-cog" 
							route={routes.Profile + "/" + this.props.authId} 
						/>

						<SingleMenu 
							heading="MY ACCOUNT" 
							icon="fas fa-cog" 
							route={routes.MyPayments} 
						/>

						<CollapsibleMenu
							heading="Skimpy Bible"
							icon="fas fa-book"
							initCollapsed={true}
						>
							<MenuItem
								route={routes.SkimpyBible + "/" + skimpyBibleTermsConditionsHeading}
							>
								{"Terms & Conditions"}
							</MenuItem>
							{skimpyBibleOrdered.map((bibleEntry, idx) => {
								return (
									<MenuItem
										key={idx}
										route={routes.SkimpyBible + "/" + bibleEntry.Heading}
									>
										{bibleEntry.Heading}
									</MenuItem>
								);
							})}
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<MenuItem route={routes.SkimpyBible + "/newitem"}>
									<span className="color-info">
										<PlusCircle />
									</span>
								</MenuItem>
							</ProtectedComponent>
						</CollapsibleMenu>

						<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
							<CollapsibleMenu
								heading="Venue Bible"
								icon="fas fa-book"
								initCollapsed={true}
							>
								<MenuItem
									route={routes.VenueBible + "/" + venueBibleTermsConditionsHeading}
								>
									{"Terms & Conditions"}
								</MenuItem>
								{venueBibleOrdered.map((bibleEntry, idx) => {
									return (
										<MenuItem
											key={idx}
											route={routes.VenueBible + "/" + bibleEntry.Heading}
										>
											{bibleEntry.Heading}
										</MenuItem>
									);
								})}
								<ProtectedComponent allowedGroups={[userGroups.admin]}>
									<MenuItem route={routes.VenueBible + "/newitem"}>
										<span className="color-info">
											<PlusCircle />
										</span>
									</MenuItem>
								</ProtectedComponent>
							</CollapsibleMenu>
						</ProtectedComponent>

						<CollapsibleMenu
							initCollapsed={false}
							heading="Search"
							icon={"fas fa-search"}
							collapsable={true}
						>
							<ProtectedComponent
								allowedGroups={[
									userGroups.admin
								]}
							>
								<li className="left_menu_item_sub">
									<NavLink
										activeClassName="left_menu_item_active"
										to={routes.SkimpySearch}
										isActive={(match, location) => {
											// ["", "profiles", "301"]
											// ["", "skimpy_search"]
											const pathnameParts = location.pathname.split("/");

											// Don't match on short paths
											if (pathnameParts.length < 2) {
												return false;
											}

											// Match for Skimpy Search
											if ("/" + pathnameParts[1] === routes.SkimpySearch) {
												return true;
											}
											// Match for someone else's profile
											if (
												"/" + pathnameParts[1] === routes.Profile ||
												"/" + pathnameParts[1] === routes.Pictures
											) {
												// Don't match for logged in user's profile
												if (Number(pathnameParts[2]) === this.props.authId) {
													return false;
												}
												return true;
											}
										}}
									>
										By Skimpy
									</NavLink>
								</li>
							</ProtectedComponent>

							<li className="left_menu_item_sub">
								<NavLink
									activeClassName="left_menu_item_active"
									to={routes.VenueSearch}
									isActive={(match, location) => {
										// ["", "venues", "301"]
										// ["", "venue_search"]
										const pathnameParts = location.pathname.split("/");

										// Don't match on short paths
										if (pathnameParts.length < 2) {
											return false;
										}

										// Match for Venue Search
										if ("/" + pathnameParts[1] === routes.VenueSearch) {
											return true;
										}
										// Match for Venue
										if ("/" + pathnameParts[1] === routes.Venue) {
											return true;
										}
									}}
								>
									By Venue
								</NavLink>
							</li>
						</CollapsibleMenu>

						<ProtectedComponent allowedGroups={[userGroups.admin]}>
							<CollapsibleMenu
								initCollapsed={true}
								heading="Admin Accounts"
								icon={"fas fa-lock"}
								collapsable={true}
							>
								<MenuItem route={routes.AdminAccounts}>Admin Accounts</MenuItem>
								<MenuItem route={routes.ReportsCodes}>Codes Report</MenuItem>
								<MenuItem route={routes.AdminEndBalance}>End Balances</MenuItem>
								<MenuItem route={routes.AdminCurrentBalance}>Current Day Balances</MenuItem>
								<MenuItem route={routes.PaymentLogs}>Payment Logs</MenuItem>
								<MenuItem route={routes.ManageCodes}>Manage Codes</MenuItem>
							</CollapsibleMenu>
						</ProtectedComponent>

						<ProtectedComponent allowedGroups={[userGroups.admin]}>
							<CollapsibleMenu
								initCollapsed={true}
								heading="Admin Booking"
								icon={"fas fa-lock"}
								collapsable={true}
							>
								<MenuItem route={routes.AdminAdvertiseAvailables}>
									Advertise Availables
								</MenuItem>
								<MenuItem route={routes.AdminKFW2}>Keen For Work</MenuItem>
								<MenuItem route={routes.AdminKFW}>(OLD)Keen For Work</MenuItem>
								<MenuItem route={routes.AdminFeedback}>Feedback</MenuItem>
							</CollapsibleMenu>
						</ProtectedComponent>

						<ProtectedComponent allowedGroups={[userGroups.hrm]}>
							<CollapsibleMenu
								initCollapsed={false}
								heading="Admin Booking"
								icon={"fas fa-lock"}
								collapsable={false}
							>
								<MenuItem route={routes.AdminKFW2}>Keen For Work</MenuItem>
								<MenuItem route={routes.AdminKFW}>(OLD)Keen For Work</MenuItem>
								<MenuItem route={routes.AdminFeedback}>Feedback</MenuItem>
							</CollapsibleMenu>
						</ProtectedComponent>

						<ProtectedComponent allowedGroups={[userGroups.admin]}>
							<CollapsibleMenu
								initCollapsed={true}
								heading="Admin General"
								icon={"fas fa-lock"}
								collapsable={true}
							>
								<MenuItem route={routes.SkimpyAlerts}>Skimpy Alerts</MenuItem>
								<MenuItem route={routes.AdminLogs}>Logs</MenuItem>
								<MenuItem route={routes.AdminManageBrands}>Manage Brands</MenuItem>
								<MenuItem route={routes.AdminManageLevels}>Manage Levels</MenuItem>
							</CollapsibleMenu>
						</ProtectedComponent>
						
						<ProtectedComponent allowedGroups={[userGroups.admin]}>
							<CollapsibleMenu
								initCollapsed={true}
								heading="Adverts and Videos"
								icon={"fas fa-lock"}
								collapsable={true}
							>
								<MenuItem route={routes.Adverts}>Adverts</MenuItem>
								<MenuItem route={routes.Videos}>Videos</MenuItem>
							</CollapsibleMenu>
						</ProtectedComponent>

						<Logout />
						<AppVersion />
					</React.Fragment>
				}
				renderTopMenu={[
					<Notifications />,
				]}
				{...this.props}
			/>
		);
	}
}

UserLayout.propTypes = {};

const mapStateToProps = (state, props) => {
	return {
		skimpyBible: skimpyBibleSelectorById(state),
		venueBible: venueBibleSelectorById(state),
		authId: getAuthUserId(state),
		pollLoading: pollLoading(state)
	};
};

UserLayout = connect(mapStateToProps)(UserLayout);
export default UserLayout;
