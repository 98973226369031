import * as React from "react";
import PropTypes from "prop-types";
import ValidationMessages from "../form/ValidationMessages";

export default class InputCheckbox extends React.Component {
	// Call the onChange handler with a modified value
	handleOnChange = e => {
		return this.props.onChange({
			target: {
				name: e.target.name,
				value: e.target.checked
			}
		});
	};

	render() {
		return (
			<span>
				<input
					type="checkbox"
					name={this.props.name}
					checked={this.props.value}
					onChange={this.handleOnChange}
					disabled={this.props.disabled}
				/>

				<ValidationMessages messages={this.props.validationMessages} />
			</span>
		);
	}
}

InputCheckbox.defaultProps = {
	inputClassName: "form-control",
	validaitonClassName: "form-validation-box",
	disabled: false
};

InputCheckbox.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	inputClassName: PropTypes.string,
	validaitonClassName: PropTypes.string,
	validationMessages: PropTypes.arrayOf(PropTypes.string)
};
