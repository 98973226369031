import * as React from "react";
import PropTypes from "prop-types";
import equals from "deep-equal";

class Gallery extends React.Component {
	static propTypes = {
		uploads: PropTypes.array
	};

	state = {
		columns: {
			1: [],
			2: [],
			3: [],
			4: []
		},
		uploads: []
	};

	*generateColumnNumber() {
		var index = 1;
		while (index <= 5) {
			if (index === 5) {
				index = 1;
			}
			yield index++;
		}
	}

	static getDerivedStateFromProps(nextProps, currentState) {
		if (equals(nextProps.uploads, currentState.uploads)) {
			return null;
		}

		return {
			uploads: nextProps.uploads
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.uploads === this.state.uploads) {
			return;
		}

		let colNumberGenerator = this.generateColumnNumber();

		let columns = {
			1: [],
			2: [],
			3: [],
			4: []
		};

		this.state.uploads.forEach(upload => {
			columns[colNumberGenerator.next().value].push(upload);
		});

		this.setState({
			columns: columns
		});
	}

	render() {
		return (
			<div>
				{Object.keys(this.state.columns).map((colNumber, colIdx) => {
					return (
						<div key={colIdx} style={{ padding: 2 }} className={"col-lg-3"}>
							{this.state.columns[colNumber].map((upload, uploadIdx) => {
								// return upload;
								return <React.Fragment key={uploadIdx}>{upload}</React.Fragment>;
							})}
						</div>
					);
				})}
			</div>
		);
	}
}

export default Gallery;
