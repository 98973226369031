import * as React from "react";
import { connect } from "react-redux";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import ResponsiveTableNew, { sortDirectionOptions } from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import { brandSelectorAsArray, clientSelectorAsArray } from "../poll/selectors";
import MiniContainer from "../components/container/MiniContainer";
import ProfileStageName from "../components/links/ProfileStageName";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import PhoneNumberLink from "../components/links/PhoneNumberLink";
import ManageVenues from "./components/ManageVenues";
import CreateBrand from "./components/CreateBrand";
import { deleteBrandDELETE, uploadDELETE, uploadSections, uploadsGet } from "../api/apiEndpoints";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import FormManager from "../components/form/FormManager";
import UploadCroppedImg from "../components/uploads/UploadCroppedImg";
import UploadRenderer from "../components/uploads/UploadRenderer";
import { Trash } from "../components/icons/Icons";
import InputText from "../components/input/InputText";
import FormRow from "../components/form/FormRow";
import EditBrand from "./components/EditBrand";

class AdminManageBrands extends React.Component {
	state = {
		brandImagesByBrandId: {}
	};

	componentDidMount() {
		this.queryBrandBanner();
	}

	queryBrandBanner = () => {
		uploadsGet({
			UploadSectionTypes: [uploadSections.brand_banner]
		}).then(r => {
			const uploads = r.data || [];

			const brandImagesByBrandId = uploads.reduce((byId, curVal) => {
				byId[curVal.UploadTypeId] = curVal;

				return byId;
			}, {});
			this.setState({ brandImagesByBrandId: brandImagesByBrandId });
		});
	};

	render() {
		if (this.props.brandAsArray.length === 0) {
			return null;
		}
		const colSpacing = [2, 2, 2, 2, 2, 2];

		return (
			<div>
				<StandardModalWrapper
					heading={"Create Brand"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<AdminContainerMigrate
								buttons={[
									<button onClick={handleOpenModal} className="btn btn-primary">
										<i className="fas fa-plus"/> Create Brand
									</button>
								]}
							/>
						);
					}}
					render={({ handleCloseModal }) => {
						return <CreateBrand onCreateCallbackFn={handleCloseModal}/>;
					}}
				/>
				<MiniContainer>
					<ResponsiveTableNew
						initialSortColumn={"BrandName"}
						initialSortDirection={sortDirectionOptions.ascending}
						values={this.props.brandAsArray}
						headings={[
							{
								label: "Stage Name",
								size: colSpacing[0],
								disableSort: true
							},
							{
								label: "Brand Name",
								name: "BrandName",
								size: colSpacing[1],
								disableSort: true
							},
							{
								label: "Phone Number",
								size: colSpacing[2],
								disableSort: true
							},
							{
								label: "Sales Pitch",
								size: colSpacing[3],
								disableSort: true
							},
							{
								label: "Web Link",
								size: colSpacing[4],
								disableSort: true
							},
							{
								label: "Manage",
								size: colSpacing[5],
								disableSort: true
							}
						]}
					>
						{({ sortedValues }) => {
							return sortedValues.map((row, rowIdx) => {
								return (
									<div key={rowIdx} className="row col-sm-12 div-table-responsive-row">
										<ResponsiveTableColumn size={colSpacing[0]} heading="Stage Name">
											<ProfileStageName userId={row.UserId}/>
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[1]} heading="Brand Name">
											{row.BrandName}
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[2]} heading="Phone Number">
											<PhoneNumberLink phoneNumber={row.PhoneNumber}/>
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[3]} heading="Sales Pitch">
											{row.SalesPitch === null ? "" : row.SalesPitch}
										</ResponsiveTableColumn>
										<ResponsiveTableColumn size={colSpacing[4]} heading="Web Link">
											<a style={{ wordWrap: "break-word" }} href={row.WebLink}>
												{row.WebLink}
											</a>
										</ResponsiveTableColumn>

										<div className={"col-sm-" + colSpacing[5]}>
											<div className="admin-box">
												{/*Edit Brand*/}
												<StandardModalWrapper
													heading={"Edit Brand"}
													modalOpener={({ handleOpenModal }) => {
														return (
															<button
																style={{ width: "100%" }}
																className="admin-box-button btn btn-primary"
																onClick={handleOpenModal}
															>
																<i className="fas fa-backspace"/> Edit Brand
															</button>
														);
													}}
													render={({ handleCloseModal }) => {
														return <EditBrand onEditCallbackFn={handleCloseModal} brandId={row.Id} />
													}}
												/>
												{/*Manage Venues*/}
												<StandardModalWrapper
													heading={row.BrandName + " Venues"}
													modalOpener={({ handleOpenModal }) => {
														return (
															<button
																style={{ width: "100%" }}
																className="admin-box-button btn btn-primary"
																onClick={handleOpenModal}
															>
																<i className="fas fa-wrench"/> Venues
															</button>
														);
													}}
													render={({ handleCloseModal }) => {
														return <ManageVenues brandId={row.Id}/>;
													}}
												/>

												{/*Upload Banner*/}
												<StandardModalWrapper
													heading={row.BrandName + " Images"}
													modalOpener={({ handleOpenModal }) => {
														return (
															<button
																style={{ width: "100%" }}
																className="admin-box-button btn btn-primary"
																onClick={handleOpenModal}
															>
																<i className="fas fa-cloud-upload"/> Banner
															</button>
														);
													}}
													render={({ handleCloseModal }) => {
														const closeReload = () => {
															this.queryBrandBanner();
															handleCloseModal();
														};

														return (
															<UploadCroppedImg
																uploadSectionType={uploadSections.brand_banner}
																uploadSectionId={row.Id}
																onSubmitSuccessFn={closeReload}
																aspectRatio={17.4 / 4.14}
															/>
														);
													}}
												/>
												{/*Delete Banner*/}
												<FormManager
													handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
														uploadDELETE({ Id: this.state.brandImagesByBrandId[row.Id].Id })
															.then(() => {
																handleSubmitSuccess();
																this.queryBrandBanner();
															})
															.catch(() => {
																handleSubmitFail();
															});
													}}
												>
													{({ handleSubmit, submitting }) => {
														return (
															<LoadingButtonWithConfirm
																style={{ width: "100%" }}
																className="admin-box-button btn btn-danger"
																loading={submitting}
																disabled={
																	submitting ||
																	this.state.brandImagesByBrandId[row.Id] === undefined
																}
																onClick={handleSubmit}
															>
																<Trash/> Banner
															</LoadingButtonWithConfirm>
														);
													}}
												</FormManager>

												{/*Delete Brand*/}
												<FormManager
													handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
														deleteBrandDELETE({ Id: row.Id })
															.then(() => {
																handleSubmitSuccess();
															})
															.catch(() => {
																handleSubmitFail();
															});
													}}
												>
													{({ handleSubmit, submitting }) => {
														return (
															<LoadingButtonWithConfirm
																style={{ width: "100%" }}
																className="admin-box-button btn btn-danger"
																loading={submitting}
																disabled={submitting}
																onClick={handleSubmit}
															>
																<Trash/> Brand
															</LoadingButtonWithConfirm>
														);
													}}
												</FormManager>
											</div>
										</div>
										{row.Id in this.state.brandImagesByBrandId && (
											<div style={{ width: 250 }}>
												<UploadRenderer
													showThumbnail={false}
													src={this.state.brandImagesByBrandId[row.Id].Path}
												/>
											</div>
										)}
									</div>
								);
							});
						}}
					</ResponsiveTableNew>
				</MiniContainer>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		brandAsArray: brandSelectorAsArray(state),
		clientsAsArray: clientSelectorAsArray(state)
	};
};

export default connect(mapStateToProps)(AdminManageBrands);
