import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";

class ClientTelephone extends React.Component {
	render() {
		return (
			<a href={"mailto:" + this.props.client.ClientEmail}>
				{this.props.client.ClientEmail}
			</a>
		);
	}
}

ClientTelephone.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ClientTelephone);
