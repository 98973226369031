import React from "react";
import MiniContainer from "../../components/container/MiniContainer";

const Info = () => {
	return (
		<MiniContainer>
			<div className="row">
				<div className="col-md-12">
					<h6>HOW TO READ YOUR ACCOUNT</h6>
					<ul>
						<li>
							Oldest transactions are at the bottom. Future expected transactions are
							in grey at the top. Read this statement from the bottom up.
						</li>
						<li>
							Black amounts are payments you made to us or invoices you sent to us;
							these add to your account balance.
						</li>
						<li>
							Red amounts are charges for products or services you bought, or pay outs
							to your bank account; these subtract from your account balance.
						</li>
						<li>
							Email{" "}
							<a href="mailto:accounts@skimpyheaven.com.au">
								accounts@skimpyheaven.com.au
							</a>{" "}
							with your bank details to withdraw any or all of your account balance at
							any time.
						</li>
						<li>
							Contact us to get help booking more shifts if you have insufficient
							credit to self book.
						</li>
					</ul>
					<h6>VENUES THAT PAY VIA SKH</h6>
					<ul>
						<li>`SH` marks venue shifts that pay via skimpy account.</li>
						<li>
							The pay for working these venues will always be credited to your skimpy
							account upon receipt of your invoice to{" "}
							<a href="mailto:accounts@skimpyheaven.com.au">
								accounts@skimpyheaven.com.au
							</a>
						</li>
						<li>
							Money will then paid out to your bank account if requested, after we
							receive payment from the client, and be deducted from the skimpy account
							balance.
						</li>
					</ul>
				</div>
			</div>
		</MiniContainer>
	);
};

export default Info;
