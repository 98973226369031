import * as React from "react";
import { connect } from "react-redux";
import Select from "../components/select/Select";
import ClientList from "../components/select/ClientList";
import ShiftRow from "./components/ShiftRow";
import Spinner from "../components/loading/Spinner";
import DateTimePicker from "../components/datetime/DateTimePicker";
import SkimpyList from "../components/select/SkimpyList";
import CreateNewShifts from "./components/CreateNewShifts/CreateNewShifts";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import { pollLoading } from "../poll/selectors";
import GenerateDailyLineup from "./components/generateDailyLineup/GenerateDailyLineup";
import { History, PlusCircle } from "../components/icons/Icons";
import { observer } from "mobx-react";
import { shiftsState } from "./shiftsState";
import { css } from "emotion";
import LoadingButton from "../components/button/LoadingButton";
import { poll } from "../poll/actions";

@observer
class Shifts extends React.Component {
	componentDidMount() {
		//shiftsState.resetShifts();
		//shiftsState.getShifts();
	}

	state = {
		searchInitiated: false,
	};

	static getDerivedStateFromProps(props, state) {
		if (!shiftsState.initialPollLoaded && !props.pollLoading) {
			shiftsState.initialPollLoaded = true;
		}

		return state;
	}

	render() {
		if (!shiftsState.initialPollLoaded) {
			return <Spinner loading={true} />;
		}

		return (
			<div style={{ height: "100%" }}>
				<div className="" style={{ paddingBottom: 15 }}>
					<div className="">
						<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
							<div className="admin-box">
								<StandardModalWrapper
									heading="Create New Shifts"
									warningMessageOnClose={"Are you sure you want to close the wizard?"}
									modalOpener={({ handleOpenModal }) => {
										return (
											<button
												onClick={handleOpenModal}
												className="admin-box-button btn btn-danger"
											>
												<PlusCircle /> Create New Shifts
											</button>
										);
									}}
									render={({ handleCloseModal }) => {
										const closeModalPoll = () => {
											shiftsState.getShifts();
											this.props.dispatch(poll({}));
											handleCloseModal();
										};

										return <CreateNewShifts pollShiftsFn={closeModalPoll} />;
									}}
								/>
								<StandardModalWrapper
									heading="FB Lineup"
									modalOpener={({ handleOpenModal }) => {
										return (
											<button
												onClick={handleOpenModal}
												className="admin-box-button btn btn-primary"
											>
												<i className="fab fa-facebook" /> View Daily Shifts List
											</button>
										);
									}}
									render={({ handleCloseModal }) => {
										return <GenerateDailyLineup />;
									}}
								/>
							</div>
						</ProtectedComponent>
						<div className="mini-container">
							<h4 className="heading">Search Hotel Shifts</h4>
							<div className="row">
								<SearchBarItem
									label={"Shifts"}
									value={
										<Select
											name="ShiftFilter"
											options={shiftsState.shiftFilterOptions}
											onChange={shiftsState.handleOnChangeFilterShiftFilter}
											value={shiftsState.liveSearchParams.ShiftFilter}
										/>
									}
								/>

								<SearchBarItem
									label={"Venue"}
									value={
										<ClientList
											name="ClientId"
											onChange={shiftsState.handleOnChangeFilterClientId}
											value={shiftsState.liveSearchParams.ClientId}
										/>
									}
								/>
								<SearchBarItem
									label={"Date"}
									value={
										<DateTimePicker
											name="StartTime"
											dayPickerOnly={true}
											onChange={shiftsState.handleOnChangeFilterStartTime}
											value={shiftsState.liveSearchParams.StartTime}
										/>
									}
								/>

								<SearchBarItem
									label={"Skimpy"}
									value={
										<SkimpyList
											name="UserId"
											dayPickerOnly={true}
											onChange={shiftsState.handleOnChangeFilterUserId}
											value={shiftsState.liveSearchParams.UserId}
										/>
									}
								/>

								<SearchBarItem
									label={"Type"}
									value={
										<Select
											options={[
												{ value: "FILTER_MULTIDAY", label: "Multiday events" },
												{ value: "FILTER_SHOWS", label: "Shows" },
												{ value: "FILTER_REGULAR_SHIFTS", label: "Single shifts" }
											]}
											name="TypeFilter"
											dayPickerOnly={true}
											onChange={shiftsState.handleOnChangeFilterTypeFilter}
											value={shiftsState.liveSearchParams.TypeFilter}
										/>
									}
								/>

								<SearchBarItem/>

								<SearchBarItem
									label=""
									value={
										<button
										className="btn btn-primary admin-box-button"
										onClick={() => {
											shiftsState.getShifts();
											this.setState({ searchInitiated: true });
										}}
										>
										<i className="fas fa-search" /> SEARCH
										</button> 
									}
								/>

							</div>
						</div>
					</div>
				</div>

				<Spinner loading={shiftsState.isGettingShifts} floating={true}>
					{this.state.searchInitiated && shiftsState.availableResults.Shifts.length === 0 && (
						<div>No shifts in the current search.</div>
					)}
				</Spinner>


				<p>To read full venue notes which will advise everything you need to know about the shift, click on the name of the hotel (in blue)</p>
				<InfiniteScroll
					overscanRowCount={0}
					scrollToIndex={0}
					rowCount={shiftsState.availableResults.Shifts.length}
					rows={shiftsState.availableResults.Shifts}
					redrawRow={redrawRowFn => {
						shiftsState.redrawFn = redrawRowFn;
					}}
					rowComparison={({ oldRow, newRow }) => {
						const colsToCheck = [
							"Action",
							"AdminPay",
							"ButtonClass",
							"ClientId",
							"EndTime",
							"Extra",
							"FeePay",
							"HrmApproval",
							"Id",
							"Label",
							"Notes",
							"PayRate",
							"ShiftLength",
							"ShiftPay",
							"ShiftStatus",
							"ShiftType",
							"StartTime",
							"TotalCost",
							"TravelPay",
							"UserId"
						];

						for (let i = 0; i < colsToCheck.length; i++) {
							if (oldRow[colsToCheck[i]] !== newRow[colsToCheck[i]]) {
								return false;
							}
						}

						return true;
					}}
					rowRender={({ index, row, style, redrawRow }) => {
						shiftsState.setRedrawFnOnce(redrawRow);

						let modStyle = style;
						let end = null;

						if (
							index === shiftsState.availableResults.Shifts.length - 1 &&
							shiftsState.availableResults.Shifts.length <
								shiftsState.availableResults.Results.Total
						) {
							modStyle = { ...style, height: 50 };

							end = (
								<div
									className={css`
										text-align: center;
									`}
								>
									<LoadingButton
										onClick={shiftsState.handleLoadMoreShiftsAfterDate}
										className="admin-box-button btn btn-success"
									>
										<History /> Load shifts after this date
									</LoadingButton>
								</div>
							);
						}

						return (
							<div style={modStyle}>
								<ShiftRow
									lastShift={
										index - 1 in shiftsState.availableResults.Shifts
											? shiftsState.availableResults.Shifts[index - 1]
											: null
									}
									Shift={shiftsState.availableResults.Shifts[index]}
								/>
								{end}
							</div>
						);
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		pollLoading: pollLoading(state)
	};
};

export default connect(mapStateToProps)(observer(Shifts));
