import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { profileSelectorById } from "../../poll/selectors";
import { convertAPIDateToDDMMYYYY } from "../../lib/dateUtils/dateUtils";
import FormRow from "../../components/form/FormRow";

class ProfileInformationView extends React.Component {
	render() {
		if (this.props.profile === null) {
			return null;
		}

		return (
			<div>
				<FormRow label={"Name"} value={this.props.profile.Fullname} />
				<FormRow
					label={"Date of Birth"}
					value={convertAPIDateToDDMMYYYY(this.props.profile.DOB)}
				/>
				<FormRow
					label={"Address Number"}
					value={this.props.profile.AddressNumber}
				/>
				<FormRow label={"Street"} value={this.props.profile.AddressRoad} />
				<FormRow label={"Suburb"} value={this.props.profile.AddressSuburb} />
				<FormRow label={"State"} value={this.props.profile.AddressState} />
				<FormRow label={"Postcode"} value={this.props.profile.AddressPostcode} />
				<FormRow label={"Private email"} value={this.props.profile.PrivateEmail} />
				<FormRow
					label={"Emergency Contact"}
					value={this.props.profile.EmergencyContactName}
				/>
				<FormRow
					label={"Emergency Contact PH"}
					value={this.props.profile.EmergencyContactPhone}
				/>
			</div>
		);
	}
}

ProfileInformationView.propTypes = {
	profileId: PropTypes.number
};

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile
	};
};

export default connect(mapStateToProps)(ProfileInformationView);
