import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import ShiftHistory from "./ShiftHistory";
import ShiftValidation from "./ShiftValidation";
import AdminEditSeriesMenu from "./AdminEdit/AdminEditSeriesMenu";
import BookShift from "./BookShift/BookShift";
import "./Shifts.css";
import { shiftActionPOST } from "../../api/apiEndpoints";
import ProfileStageName from "../../components/links/ProfileStageName";
import ProfileWorkPhone from "../../components/links/ProfileWorkPhone";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import { Pencil } from "../../components/icons/Icons";
import { shiftsState } from "../shiftsState";
import StyledButton, { EButtonBackgroundColors } from "../../styled/Button/StyledButton";
import { css } from "emotion";

class BookingAdminButtons extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showClientNotes: false
		};
	}

	render() {
		let bookModal = null;
		let modalHeading = "";
		switch (this.props.shift.Action) {
			case "Book":
				modalHeading = "Book shift";
				bookModal = handleCloseModalReloadShifts => {
					return (
						<BookShift
							shift={this.props.shift}
							handleCloseModal={handleCloseModalReloadShifts}
						/>
					);
				};
				break;
			case "Apply":
				modalHeading = "Apply for shift";
				bookModal = handleCloseModalReloadShifts => {
					return (
						<BookShift
							shift={this.props.shift}
							handleCloseModal={handleCloseModalReloadShifts}
						/>
					);
				};
				break;
			case "Unavailable":
			case "Applied":
				modalHeading = "Why Can't I Book?";
				bookModal = () => {
					return <ShiftValidation shift={this.props.shift} />;
				};
				break;
			case "Booked":
				modalHeading = "";
				bookModal = () => {
					return <ShiftValidation shift={this.props.shift} />;
				};
				break;
		}

		return (
			<div>
				{/*Booking button*/}

				<div className={css`padding-bottom: 15px;`}>
					<StandardModalWrapper
						heading={modalHeading}
						modalOpener={({ handleOpenModal }) => {
							const colourMap = {
								"warning": EButtonBackgroundColors.yellow,
								"info": EButtonBackgroundColors.lightBlue,
								"primary": EButtonBackgroundColors.blue,
								"success": EButtonBackgroundColors.green,
								"danger": EButtonBackgroundColors.red,
								"unavailable": EButtonBackgroundColors.grey,
							}

							return (
								<StyledButton onClick={handleOpenModal} backgroundColor={colourMap[this.props.shift.ButtonClass]}>
									{this.props.shift.Label}
								</StyledButton>
							)
						}}
						render={({ handleCloseModal }) => {
							const handleCloseModalReloadShifts = () => {
								handleCloseModal();
								shiftsState.getShifts();
							};

							return bookModal(handleCloseModalReloadShifts);
						}}
					/>
					{/* Show "Withdraw Application text */}
					{this.props.shift.Action === "Applied" && (
						<StyledButton
							backgroundColor={EButtonBackgroundColors.red}
							onClick={() => {
								shiftActionPOST({
									Action: "remove_application",
									ShiftId: this.props.shift.Id
								}).then(() => {
									shiftsState.getShifts();
								});
							}}
						>
							Withdraw Application
						</StyledButton>
					)}
				</div>

					{this.props.shift.UserId !== null && (
						<div className={"color-primary"}>
							{this.props.shift.ShiftStatus === 1 && <div>Available from:</div>}
							<div>
								<ProfileStageName userId={this.props.shift.UserId} />
							</div>
							<div>
								<ProfileWorkPhone userId={this.props.shift.UserId} />
							</div>
						</div>
					)}

				<div className="btn-group btn-group-justified row-padding-top" role="group">
					{/*Shift History button*/}
					<div className="btn-group" role="group">
						<StandardModalWrapper
							heading={"Shift History"}
							modalOpener={({ handleOpenModal }) => {
								return (
									<LoadingButton
										className="btn btn-default"
										title="Shift History"
										onClick={handleOpenModal}
									>
										<i className="fas fa-history" />
									</LoadingButton>
								);
							}}
							render={() => {
								return <ShiftHistory shiftId={this.props.shift.Id} />;
							}}
						/>
					</div>

					<ProtectedComponent
						allowedGroups={[userGroups.admin, userGroups.hrm]}
						allowedIfLoggedInAsUserId={this.props.shift.UserId}
					>
						{/*Shift actions button*/}
						<div className="btn-group" role="group" title="Admin Edit">
							<StandardModalWrapper
								modalOpener={({ handleOpenModal }) => {
									return (
										<LoadingButton
											className="btn admin-edit-button btn-primary pull-right"
											title="Shift Actions"
											onClick={handleOpenModal}
										>
											<Pencil />
										</LoadingButton>
									);
								}}
								onCloseFn={shiftsState.getShifts}
								render={({ handleCloseModal }) => {
									return (
										<AdminEditSeriesMenu
											shift={this.props.shift}
											handleCloseModal={handleCloseModal}
										/>
									);
								}}
							/>
						</div>
					</ProtectedComponent>
				</div>
			</div>
		);
	}
}

BookingAdminButtons.propTypes = {
	shift: PropTypes.object.isRequired
};

export default BookingAdminButtons;
