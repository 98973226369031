import * as React from "react";
import { connect } from "react-redux";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import DateTimePicker from "../components/datetime/DateTimePicker";
import { convertDDMMYYYToJSDate, convertJSDateToDDMMYYYY } from "../lib/dateUtils/dateUtils";
import { adminKFWPOST, createKFWPOST, removeKFWDELETE } from "../api/apiEndpoints";
import DateFns from "date-fns";
import AdminKFWRow from "./AdminKFWRow";
import InputCheckbox from "../components/input/InputCheckbox";
import MiniContainer from "../components/container/MiniContainer";
import Spinner from "../components/loading/Spinner";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import axios from "axios";
import SendNotifications from "./SendNotifications";
import { toastr } from "react-redux-toastr";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";

class AdminKFW extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			kfwQueryParams: {
				Date: convertJSDateToDDMMYYYY(new Date())
			},
			keenForWorkUsers: [],
			usersWithNoShifts: [],
			usersWithShifts: [],
			usersToMessage: {},
			categoriesChecked: {},
			loading: false
		};
	}

	componentDidMount() {

		this.setState({
			...this.state,
			loading: true,

		})
		setTimeout(() => {
			this.setState({
				...this.state,
			loading: false
		})
			this.getKFWList();
		}, 5000);
	}

	getKFWList = () => {
		const searchDate = convertDDMMYYYToJSDate(this.state.kfwQueryParams.Date);
		this.setState({
			loading: true
		});

		adminKFWPOST({
			StartTime: DateFns.startOfDay(searchDate),
			EndTime: DateFns.endOfDay(searchDate)
		}).then(r => {
			if (r.data === null) {
				return;
			}
			let keenForWorkUsers = [],
				usersWithNoShifts = [],
				usersWithShifts = [];

			r.data.forEach(row => {
				if (row.KFW) {
					keenForWorkUsers.push(row);
					return;
				}

				if (row.ShiftInfo === null) {
					usersWithNoShifts.push(row);
					return;
				}

				usersWithShifts.push(row);
			});

			this.setState({
				keenForWorkUsers,
				usersWithNoShifts,
				usersWithShifts,
				loading: false
			});
		});
	};

	handleSelectedUsersBulkAddKFW = () => {
		const bulkKFWPOST = Object.keys(this.state.usersToMessage).map(userId => {
			return createKFWPOST({
				UserId: userId,
				StartTime: DateFns.startOfDay(
					convertDDMMYYYToJSDate(this.state.kfwQueryParams.Date)
				)
			});
		});

		axios
			.all(bulkKFWPOST)
			.then(() => {
				toastr.info(
					"Successfully set " +
					Object.keys(this.state.usersToMessage).length +
					" KFW statuses"
				);
				this.setState(
					{
						usersToMessage: {}
					},
					this.getKFWList
				);
			})
			.catch(() => {
				this.setState(
					{
						usersToMessage: {}
					},
					this.getKFWList
				);
			});
	};

	handleSelectedUsersBulkRemoveKFW = () => {
		const kfwToRemove = this.state.keenForWorkUsers.filter(kfw => {
			return kfw.UserId in this.state.usersToMessage;
		});

		const bulkKFWPOST = kfwToRemove.map(kfw => {
			return removeKFWDELETE({
				Id: kfw.KFWId
			});
		});

		axios
			.all(bulkKFWPOST)
			.then(() => {
				toastr.info(
					"Successfully set " +
					Object.keys(this.state.usersToMessage).length +
					" KFW statuses"
				);
				this.setState(
					{
						usersToMessage: {}
					},
					this.getKFWList
				);
			})
			.catch(() => {
				this.setState(
					{
						usersToMessage: {}
					},
					this.getKFWList
				);
			});
	};

	render() {
		const usersSelectedArray = Object.keys(this.state.usersToMessage).map(key => {
			return Number(key);
		});

		return (
			<div>
				<div className="admin-box">
					<StandardModalWrapper
						heading={"Send Notifications"}
						modalOpener={({ handleOpenModal }) => {
							return (
								<button
									disabled={this.state.loading}
									className="admin-box-button btn btn-primary"
									onClick={handleOpenModal}
								>
									<i className="fas fa-envelope"/> Send Notifications
								</button>
							);
						}}
						render={({ handleCloseModal }) => {
							return (
								<SendNotifications
									handleOnSendSuccess={handleCloseModal}
									initialUserIdsSelected={usersSelectedArray}
								/>
							);
						}}
					/>
					<LoadingButtonWithConfirm
						disabled={this.state.loading || objectIsEmpty(this.state.usersToMessage)}
						loading={this.state.loading}
						confirmMessage={"Clicking confirm will mark the selected users as KFW"}
						className="admin-box-button btn btn-primary"
						confirmAnchor={"bottom-left"}
						onClick={this.handleSelectedUsersBulkAddKFW}
					>
						<i className="fal fa-calendar-check"/> Add KFW
					</LoadingButtonWithConfirm>

					<LoadingButtonWithConfirm
						disabled={this.state.loading || objectIsEmpty(this.state.usersToMessage)}
						loading={this.state.loading}
						confirmMessage={"Clicking confirm will remove KFW for the selected users"}
						className="admin-box-button btn btn-primary"
						confirmAnchor={"bottom-left"}
						onClick={this.handleSelectedUsersBulkRemoveKFW}
					>
						<i className="fal fa-calendar-times"/> Remove KFW
					</LoadingButtonWithConfirm>
				</div>

				{this.renderSearch()}
				<Spinner loading={this.state.loading}>
					{this.renderTable(this.state.keenForWorkUsers, "Keen for work users")}
					{this.renderTable(this.state.usersWithNoShifts, "Users with no shifts")}
					{this.renderTable(this.state.usersWithShifts, "Users with shifts")}
				</Spinner>
			</div>
		);
	}

	handleCheckboxUpdate = e => {
		let usersToMessage = { ...this.state.usersToMessage };

		if (e.target.value === false) {
			delete usersToMessage[e.target.name];
		} else {
			usersToMessage[e.target.name] = true;
		}

		this.setState({
			usersToMessage
		});
	};

	handleCategoryChecked = e => {
		let categoriesChecked = { ...this.state.categoriesChecked };
		let usersToMessage = { ...this.state.usersToMessage };

		switch (e.target.name) {
			case "Keen for work users": {
				if (e.target.value === true) {
					this.state.keenForWorkUsers.forEach(row => {
						usersToMessage[row.UserId] = true;
					});
				} else {
					this.state.keenForWorkUsers.forEach(row => {
						delete usersToMessage[row.UserId];
					});
				}

				break;
			}
			case "Users with no shifts": {
				if (e.target.value === true) {
					this.state.usersWithNoShifts.forEach(row => {
						usersToMessage[row.UserId] = true;
					});
				} else {
					this.state.usersWithNoShifts.forEach(row => {
						delete usersToMessage[row.UserId];
					});
				}
				break;
			}
			case "Users with shifts": {
				if (e.target.value === true) {
					this.state.usersWithShifts.forEach(row => {
						usersToMessage[row.UserId] = true;
					});
				} else {
					this.state.usersWithShifts.forEach(row => {
						delete usersToMessage[row.UserId];
					});
				}
				break;
			}
		}

		if (e.target.value === true) {
			categoriesChecked[e.target.name] = true;
		} else {
			delete categoriesChecked[e.target.name];
		}

		this.setState({
			categoriesChecked,
			usersToMessage
		});
	};

	renderTable(rows, heading) {
		const colSpacing = [1, 2, 2, 2, 2, 3];

		return (
			<React.Fragment>
				<h4 className="heading">{heading}</h4>
				<MiniContainer>
					<div className="row">
						<div className={"col-sm-" + colSpacing[0]}>
							<b>
								<InputCheckbox
									name={heading}
									value={heading in this.state.categoriesChecked}
									onChange={this.handleCategoryChecked}
								/>
							</b>
						</div>
						<div className={"col-sm-" + colSpacing[1]}>
							<b>Skimpy</b>
						</div>
						<div className={"col-sm-" + colSpacing[2]}>
							<b>Number</b>
						</div>
						<div className={"col-sm-" + colSpacing[3]}>
							<b>Facebook</b>
						</div>
						<div className={"col-sm-" + colSpacing[4]}>
							<b>Notes</b>
						</div>
						<div className={"col-sm-" + colSpacing[5]}>
							<b>Shifts</b>
						</div>
					</div>

					{rows.map((row, rowIdx) => {
						return (
							<div key={rowIdx} className="row div-table-responsive-row">
								<AdminKFWRow
									colSpacing={colSpacing}
									userId={row.UserId}
									notes={row.Notes}
									handleOnChangeCheckbox={this.handleCheckboxUpdate}
									kfw={row.KFW}
									kfwHidden={row.KFWHidden}
									shiftInfo={row.ShiftInfo}
									ticked={row.UserId in this.state.usersToMessage}
								/>
							</div>
						);
					})}
				</MiniContainer>
			</React.Fragment>
		);
	}

	renderSearch() {
		return (
			<div>
				<div className="mini-container">
					<h4 className="heading">Search</h4>
					<SearchBarItem
						label={"Date"}
						value={
							<DateTimePicker
								dayPickerOnly={true}
								onChange={e => {
									this.setState(
										{
											kfwQueryParams: {
												...this.state.kfwQueryParams,
												Date: e.target.value
											},
											keenForWorkUsers: [],
											usersWithNoShifts: [],
											usersWithShifts: [],
											usersToMessage: {},
											categoriesChecked: {},
											loading: false
										},
										this.getKFWList
									);
								}}
								value={this.state.kfwQueryParams.Date}
							/>
						}
					/>
				</div>
			</div>
		);
	}
}

export default connect()(AdminKFW);
