import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findProfileByUserId } from "../../../poll/selectors";
import { Check, Times } from "../../icons/Icons";

class ProfilePublicProfileEnabled extends React.Component {
	render() {
		const { PublicProfileEnabled } = this.props.profile;

		if (PublicProfileEnabled === null || PublicProfileEnabled === false) {
			return <Times />;
		}
		return <Check />;
	}
}

ProfilePublicProfileEnabled.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		profile: findProfileByUserId(state, props.userId)
	};
};

export default connect(mapStateToProps)(ProfilePublicProfileEnabled);
