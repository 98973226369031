import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notificationSelectorAsArray } from "../poll/selectors";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import MiniContainer from "../components/container/MiniContainer";
import NatTime from "../components/text/NatTime";
import FormManager from "../components/form/FormManager";
import "./Notification.css";
import { notificationDELETE } from "../api/apiEndpoints";
import { poll } from "../poll/actions";
import LoadingButton from "../components/button/LoadingButton";
import { Comment, Times } from "../components/icons/Icons";
import { toastr } from "react-redux-toastr";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";

const Notifications = () => {
	const notifications = useSelector(state => notificationSelectorAsArray(state));
	const dispatch = useDispatch();

	const openModal = () => document.getElementById('notification-bell').click()

	const deleteNotification = async (id, successCb = null, errorCb = null) => {
		return notificationDELETE({
			Id: id
		})
			.then(() => {
				successCb && successCb();
				dispatch(poll({}));
			})
			.catch(err => {
				errorCb && errorCb();
				throw err;
			});
	};

	const deleteAllNotifications = () => {
		const promises = notifications.map(n => notificationDELETE({ Id: n.Id }));
		return Promise.all(promises)
			.then(() => dispatch(poll({})))
			.catch(err => toastr.error("failed to clear all notifications"));
	};

	useEffect(
		() => {
			if (notifications && notifications.length) {
				const sorted = notifications.sort(
					(a, b) => new Date(b.ua).getTime() - new Date(a.ua).getTime()
				);
				if (notifications.length <= 5) {
					sorted.forEach(n => {
						toastr.light("", n.Message, {
							icon: <i style={{ fontSize: 30, marginTop: 20 }} className="fas fa-bell" aria-hidden="true" />,
							id: n.Id,
							onCloseButtonClick: () => deleteNotification(n.Id)
						});
					});
				} else {
					toastr.light('View Notifications?', `You have ${notifications.length} notifications. Click to review`, {
						showCloseButton: false,
						onToastrClick: openModal,
						icon: <i style={{ fontSize: 30, marginTop: 20 }} className="fas fa-bell" aria-hidden="true" />,
					})
				}
			}
		},
		[notifications]
	);

	return (
		<StandardModalWrapper
			modalOpener={({ handleOpenModal }) => {
				if (!notifications) {
					return null;
				}
				return (
					<span onClick={handleOpenModal} id="notification-bell" className="top_menu_item">
						<i style={{ fontSize: 17 }} className="fas fa-bell" aria-hidden="true" />

						{notifications.length > 0 && (
							<span className="badge notification-badge">{notifications.length}</span>
						)}
					</span>
				);
			}}
			render={() => {
				return (
					<div>
						<h4 className="heading">
							Notifications{" "}
							<LoadingButtonWithConfirm
								className="btn btn-link edit-heading"
								style={{ float: "right" }}
								confirmAnchor={"bottom-right"}
								onClick={deleteAllNotifications}
							>
								Clear All
							</LoadingButtonWithConfirm>
						</h4>
						{notifications.map(notification => {
							return (
								<MiniContainer key={notification.Id}>
									<FormManager
										handleSubmit={({
											formValues,
											handleSubmitFail,
											handleSubmitSuccess
										}) =>
											deleteNotification(
												formValues.Id,
												handleSubmitSuccess,
												handleSubmitFail
											)
										}
										initialFormValues={{
											Id: notification.Id
										}}
									>
										{({ formValues, handleSubmit, submitting }) => {
											return (
												<div>
													<div>
														<LoadingButton
															loading={submitting}
															disabled={submitting}
															className="btn btn-danger notification-button"
															name="Id"
															value={formValues.Id}
															onClick={handleSubmit}
														>
															<Times />
														</LoadingButton>
														<b>
															<NatTime startTime={notification.CreatedAt} />
														</b>
													</div>
													<div>{notification.Message}</div>
												</div>
											);
										}}
									</FormManager>
								</MiniContainer>
							);
						})}
					</div>
				);
			}}
		/>
	);
};

export default Notifications;
