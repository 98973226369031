import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	convertDateToAPIDate,
	differenceInMinutes,
	offsetApiTime,
	parseAPIDate
} from "../../../lib/dateUtils/dateUtils";
import FormManager from "../../../components/form/FormManager";
import FormRow from "../../../components/form/FormRow";
import InputNumber from "../../../components/input/InputNumber";
import DateTimePicker from "../../../components/datetime/DateTimePicker";
import InputTextArea from "../../../components/input/InputTextArea";
import InputCheckbox from "../../../components/input/InputCheckbox";
import ClientList from "../../../components/select/ClientList";
import { clientSelectorById } from "../../../poll/selectors";
import { requirementsToBools } from "../lib/requirements";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { apiShiftTypes } from "../../../api/apiEndpoints";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import LoadingButton from "../../../components/button/LoadingButton";
import Select from "../../../components/select/Select";
import { SHIFT_EXPECTED_HOURS_MESSAGE, shiftFeePayModifierForDate } from "./shiftFeeUtils";

const isNullOrUndefined = (prop) => [undefined, null].includes(prop)

class EditSeriesWizard extends React.Component {
	render() {
		return (
			<div>
				<FormManager
					validateOnLoad={true}
					initialFormValues={{
						Page: 1,

						// used internally for calculating shift length
						ShiftEnd: offsetApiTime(
							this.props.series.ContractStart,
							this.props.series.ShiftLength
						),

						...this.props.series
					}}
					handleValidation={v => {
						v.apiDateGreaterThan({
							lessThanFieldName: "ContractStart",
							greaterThanFieldName: "ShiftEnd"
						});
						v.greaterThanOrEqualTo({ field: "ShiftLength", greaterThanValue: 0 });
						v.lessThanOrEqualTo({ field: "ShiftLength", lessThanValue: 1440 });
						v.required({ fieldNames: ["ClientId"] });
						if (!this.props.weeklyFrequencyHidden) {
							v.greaterThanOrEqualTo({
								field: "WeeklyFrequency",
								greaterThanValue: 1
							});
						}

						if (this.props.seriesType === apiShiftTypes.customMonthly) {
							v.occursOnSameWeekday({
								fieldName: "ContractStart",
								weekDayFieldName: "MonthlyDay"
							});
							v.occursOnSameOccuranceOfMonth({
								fieldName: "ContractStart",
								weekDayFieldName: "MonthlyDay",
								weekOfMonthFieldName: "MonthlyWeek"
							});
						}
					}}
					onChangeCallback={(formValues, formValidation) => {
						this.props.onChangeCallback(formValues, formValidation);
					}}
				>
					{({
						formValues,
						handleOnChange,
						formValidation,
						handleSetFormValues,
						submitEnabled
					}) => {
						const handleAffectedFieldsChange = e => {
							let { name, value } = e.target;

							const affectedFields = {};
							let payRate = formValues.PayRate;
							let shiftLength = formValues.ShiftLength;

							switch (name) {
								case "ContractStart": {
									affectedFields["ShiftEnd"] = offsetApiTime(
										value,
										formValues.ShiftLength
									);
									break;
								}
								case "ShiftEnd": {
									affectedFields["ShiftLength"] = differenceInMinutes(
										parseAPIDate(value),
										parseAPIDate(formValues.ContractStart)
									);

									shiftLength = affectedFields["ShiftLength"];
									break;
								}
								case "ShiftLength": {
									affectedFields["ShiftEnd"] = offsetApiTime(
										formValues.ContractStart,
										value
									);
									shiftLength = value;
									break;
								}
								case "Extra": {
									// If setting series to a show, reset the PayRate to 0
									if (value) {
										affectedFields.PayRate = 0;
									}
									break;
								}
								case "ShiftPay": {
									affectedFields["ShiftPay"] = value;
									break;
								}
								case "PayRate": {
									payRate = value;
								}
							}

							// Don't calculate when clicking on the 'extra' checkbox
							if (name !== "Extra") {
								// automatically calculate pays, unless series is a show - where shift pay should be 0
								if (!formValues.Extra) {
									affectedFields["ShiftPay"] = Number(
										((shiftLength / 60) * payRate).toFixed(2)
									);
								}

								const multiplier = shiftFeePayModifierForDate(
									name === "ContractStart" ? value : formValues.ContractStart
								);
								affectedFields.FeePay = Number(
									(affectedFields["ShiftPay"] * multiplier).toFixed(2)
								);
							}
							const newValues = {
								...formValues,
								...affectedFields,
								[name]: value
							}
							if (name === 'FeePay') {
								console.info('expected FeePay:', value, 'actual:', newValues['FeePay'])

							}
							handleSetFormValues(newValues);
						};

						const inputContractStart = (
							<FormRow
								label={"First Shift Starts"}
								value={
									<DateTimePicker
										name="ContractStart"
										value={formValues.ContractStart}
										onChange={handleAffectedFieldsChange}
										validationMessages={formValidation.ContractStart}
									/>
								}
							/>
						);

						const inputShiftEnd = (
							<FormRow
								label={"First Shift Ends"}
								value={
									<DateTimePicker
										name="ShiftEnd"
										value={formValues.ShiftEnd}
										onChange={handleAffectedFieldsChange}
										validationMessages={formValidation.ShiftEnd}
									/>
								}
							/>
						);

						const inputWeeklyFrequency =
							formValues.WeeklyFrequency === undefined ? null : (
								<FormRow
									label={"Weeks Between Shifts"}
									value={
										<InputNumber
											name="WeeklyFrequency"
											value={formValues.WeeklyFrequency}
											onChange={handleOnChange}
											validationMessages={formValidation.WeeklyFrequency}
										/>
									}
								/>
							);

						let inputCustomMonthly =
							formValues.MonthlyDay === undefined ? null : (
								<FormRow
									label={"Shift Frequency"}
									value={
										<div style={{ display: "flex" }}>
											<Select
												name="MonthlyWeek"
												clearable={false}
												value={formValues.MonthlyWeek}
												options={[
													{
														label: "First",
														value: 1
													},
													{
														label: "Second",
														value: 2
													},
													{
														label: "Third",
														value: 3
													},
													{
														label: "Fourth",
														value: 4
													},
													{
														label: "Last",
														value: 5
													}
												]}
												onChange={handleAffectedFieldsChange}
												validationMessages={formValidation.MonthlyWeek}
											/>
											<Select
												name="MonthlyDay"
												value={formValues.MonthlyDay}
												clearable={false}
												options={[
													{
														label: "Sunday",
														value: 0
													},
													{
														label: "Monday",
														value: 1
													},
													{
														label: "Tuesday",
														value: 2
													},
													{
														label: "Wednesday",
														value: 3
													},
													{
														label: "Thursday",
														value: 4
													},
													{
														label: "Friday",
														value: 5
													},
													{
														label: "Saturday",
														value: 6
													}
												]}
												onChange={handleAffectedFieldsChange}
												validationMessages={formValidation.MonthlyDay}
											/>
										</div>
									}
								/>
							);

						const inputShiftLength = (
							<FormRow
								label={"Shift Length (Minutes)"}
								value={
									<InputNumber
										name="ShiftLength"
										value={formValues.ShiftLength}
										onChange={handleAffectedFieldsChange}
										validationMessages={formValidation.ShiftLength}
									/>
								}
							/>
						);

						// If series is a show then dont show pay rate
						const inputPayRate = formValues.Extra ? null : (
							<FormRow
								label={"Pay Rate per hour (0 or greater)"}
								value={
									<InputNumber
										name="PayRate"
										value={formValues.PayRate}
										onChange={handleAffectedFieldsChange}
										validationMessages={formValidation.PayRate}
									/>
								}
							/>
						);

						const inputPayTotal = (
							<FormRow
								label={"Pay Total (0 or greater)"}
								value={
									<InputNumber
										name="ShiftPay"
										value={formValues.ShiftPay}
										onChange={handleAffectedFieldsChange}
										validationMessages={formValidation.ShiftPay}
									/>
								}
							/>
						);

						const inputShiftFee = (
							<FormRow
								label={"Shift Fee Total (0 or greater)"}
								value={
									<>
										<InputNumber
											name="FeePay"
											value={formValues.FeePay}
											onChange={handleOnChange}
											validationMessages={formValidation.FeePay}
										/>

										<label
											style={{
												color: "red",
												marginTop: "8px",
												fontWeight: "normal",
												fontSize: "11px",
												textAlign: "left"
											}}
										>
											{SHIFT_EXPECTED_HOURS_MESSAGE}
										</label>
									</>
								}
							/>
						);

						const inputAdminFee = isNullOrUndefined(formValues.AdminPay) ? null : (
								<FormRow
									label={"Admin Fee Total (0 or greater)"}
									value={
										<InputNumber
											name="AdminPay"
											value={formValues.AdminPay}
											onChange={handleOnChange}
											validationMessages={formValidation.AdminPay}
										/>
									}
								/>
							);

						const inputTravelPay = isNullOrUndefined(formValues.TravelPay) ? null : (
								<FormRow
									label={"Travel Pay (0 or greater)"}
									value={
										<InputNumber
											name="TravelPay"
											value={formValues.TravelPay}
											onChange={handleOnChange}
											validationMessages={formValidation.TravelPay}
										/>
									}
								/>
							);

						const handleClientId = e => {
							const clientId = e.target.value;

							handleSetFormValues({
								...formValues,
								...requirementsToBools(
									this.props.clientSelectorById[clientId].Requirements
								),
								ClientId: clientId
							});
						};

						const inputVenue = (
							<FormRow
								label={"Venue"}
								value={
									<ClientList
										name="ClientId"
										value={formValues.ClientId}
										onChange={handleClientId}
									/>
								}
							/>
						);

						const inputNotes = (
							<FormRow
								label={"Notes"}
								value={
									<InputTextArea
										name="Notes"
										value={formValues.Notes}
										onChange={handleOnChange}
										validationMessages={formValidation.Notes}
									/>
								}
							/>
						);
						const inputHrmApproval = isNullOrUndefined(formValues.HrmApproval) ? null : (
								<FormRow
									label={"Hrm Approval"}
									value={
										<InputCheckbox
											name="HrmApproval"
											value={formValues.HrmApproval}
											onChange={handleOnChange}
										/>
									}
								/>
							);
						const inputExtra = (
							<FormRow
								label={"Series/shift is a show?"}
								value={
									<InputCheckbox
										name="Extra"
										value={formValues.Extra}
										onChange={handleAffectedFieldsChange}
									/>
								}
							/>
						);
						const inputTopless = (
							<FormRow
								label={"Topless"}
								value={
									<InputCheckbox
										name="Topless"
										value={formValues.Topless}
										onChange={handleOnChange}
									/>
								}
							/>
						);

						const inputContractEndRunForever = (
							<FormRow
								label={"Run series forever"}
								value={
									<InputCheckbox
										name="ContractEndOrNull"
										// Display a check if contract end is set to null
										value={formValues.ContractEndOrNull === null}
										onChange={e => {
											// Set ContractEndOrNull to a new end date when checked or null when unchecked
											handleSetFormValues({
												...formValues,
												["ContractEndOrNull"]:
													e.target.value === true ? null : convertDateToAPIDate(new Date())
											});
										}}
									/>
								}
							/>
						);

						const inputContractEnd =
							formValues.ContractEndOrNull === null ? null : (
								<FormRow
									label={"Series end date"}
									value={
										<DateTimePicker
											name="ContractEndOrNull"
											value={formValues.ContractEndOrNull}
											onChange={handleOnChange}
											// validationMessages={formValidation.ContractEnd}
										/>
									}
								/>
							);

						const createPage = pageElements => pageElements.map((ele, idx) => <div key={idx}>{ele}</div>);

						const navigateToPage = (pageNumber, next) => (
							<button
								className="btn btn-primary"
								onClick={handleOnChange}
								name="Page"
								value={pageNumber}
							>
								<i
									className={
										next ? "fas fa-arrow-circle-right" : "fas fa-arrow-circle-left"
									}
								/>{" "}
								{next ? "Next" : "Previous"}
							</button>
						);

						const finishWizard = (
							<LoadingButtonWithConfirm
								disabled={!submitEnabled}
								className="btn btn-primary"
								onClick={() => {
									this.props.onChangeCallback("done");
								}}
								name="Page"
							>
								<i className="fas fa-arrow-circle-right" /> Done
							</LoadingButtonWithConfirm>
						);

						const finishWizardNoConfirm = (
							<LoadingButton
								disabled={!submitEnabled}
								className="btn btn-primary"
								onClick={() => {
									this.props.onChangeCallback("done");
								}}
								name="Page"
							>
								<i className="fas fa-arrow-circle-right" /> Next
							</LoadingButton>
						);

						if (this.props.hideComponents) {
							inputCustomMonthly =
								"inputCustomMonthly" in this.props.hideComponents
									? null
									: inputCustomMonthly;
						}

						let pageToRender = null;
						let wizardButtonsToRender = [];
						switch (this.props.seriesType) {
							case apiShiftTypes.singleShift: {
								if (formValues.Page == 1) {
									pageToRender = createPage([
										inputContractStart,
										inputShiftEnd,
										inputShiftLength,
										inputExtra,
										inputPayRate,
										inputPayTotal,
										inputShiftFee,
										inputAdminFee,
										inputTravelPay
									]);
									wizardButtonsToRender = [navigateToPage(2, true)];
								} else if (formValues.Page == 2) {
									pageToRender = createPage([
										inputVenue,
										inputNotes,
										inputHrmApproval,
										inputTopless,
									]);
									wizardButtonsToRender = [navigateToPage(1, false), finishWizard];
								}
								break;
							}
							case apiShiftTypes.multidayShift: {
								if (formValues.Page == 1) {
									pageToRender = createPage([
										inputContractStart,
										inputShiftEnd,
										inputShiftLength,
										inputExtra,
										inputPayRate,
										inputPayTotal,
										inputShiftFee
									]);
									wizardButtonsToRender = [finishWizardNoConfirm];
								}
								break;
							}

							case apiShiftTypes.series: {
								if (formValues.Page == 1) {
									pageToRender = createPage([
										inputContractStart,
										inputShiftEnd,
										inputShiftLength,
										inputExtra,
										inputPayRate,
										inputPayTotal,
										inputShiftFee,
										inputAdminFee,
										inputTravelPay
									]);
									wizardButtonsToRender = [navigateToPage(2, true)];
								} else if (formValues.Page == 2) {
									pageToRender = createPage([
										inputVenue,
										inputNotes,
										inputHrmApproval,
										inputTopless,
									]);
									wizardButtonsToRender = [
										navigateToPage(1, false),
										navigateToPage(3, true)
									];
								} else if (formValues.Page == 3) {
									pageToRender = createPage([
										inputWeeklyFrequency,
										inputContractEndRunForever,
										inputContractEnd
									]);
									wizardButtonsToRender = [navigateToPage(2, false), finishWizard];
								}
								break;
							}
							case apiShiftTypes.multidaySeries: {
								if (formValues.Page == 1) {
									pageToRender = createPage([
										inputContractStart,
										inputShiftEnd,
										inputShiftLength,
										inputExtra,
										inputPayRate,
										inputPayTotal,
										inputShiftFee
									]);
									wizardButtonsToRender = [finishWizardNoConfirm];
								}
								break;
							}

							case apiShiftTypes.customMonthly: {
								if (formValues.Page == 1) {
									pageToRender = createPage([
										inputCustomMonthly,
										inputContractStart,
										inputShiftEnd,
										inputShiftLength,
										inputExtra,
										inputPayRate,
										inputPayTotal,
										inputShiftFee,
										inputAdminFee,
										inputTravelPay
									]);
									wizardButtonsToRender = [navigateToPage(2, true)];
								} else if (formValues.Page == 2) {
									pageToRender = createPage([
										inputVenue,
										inputNotes,
										inputHrmApproval,
										inputTopless,
									]);
									wizardButtonsToRender = [
										navigateToPage(1, false),
										navigateToPage(3, true)
									];
								} else if (formValues.Page == 3) {
									pageToRender = createPage([
										inputContractEndRunForever,
										inputContractEnd
									]);
									wizardButtonsToRender = [navigateToPage(2, false), finishWizard];
								}
								break;
							}
						}

						return (
							<div>
								<div>{pageToRender}</div>
								<AdminContainerMigrate align="right" buttons={wizardButtonsToRender} />
							</div>
						);
					}}
				</FormManager>
			</div>
		);
	}
}

EditSeriesWizard.defaultProps = {
	venueHidden: false,
	weeklyFrequencyHidden: false
};

EditSeriesWizard.propTypes = {
	series: PropTypes.object.isRequired,
	onChangeCallback: PropTypes.func.isRequired,
	venueHidden: PropTypes.bool,
	weeklyFrequencyHidden: PropTypes.bool,
	seriesType: PropTypes.number,
	hideComponents: PropTypes.object
};

const mapStateToProps = state => ({
	clientSelectorById: clientSelectorById(state)
});

export default connect(mapStateToProps)(EditSeriesWizard);
