// https://stackoverflow.com/a/5366862

export function padLeft(str, pad) {
	return (pad + str).substring(str.length);
}

// From redux
export const padLeftWithMax = (
	input: any,
	maxLength: number,
	tokenToRepeat: string
) => repeat(tokenToRepeat, maxLength - input.toString().length) + input;
export const repeat = (str: string, times: number) =>
	new Array(times + 1).join(str);
