import * as React from "react";
import { connect } from "react-redux";
import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import {
	SkimpyBibleQueryById,
	usersPATCH,
	VenueBibleQueryById
} from "../../api/apiEndpoints";
import { poll } from "../../poll/actions";
import { convertDateToAPIDate } from "../../lib/dateUtils/dateUtils";
import DisplayHtml from "../html/DisplayHtml";
import AdminContainerMigrate from "../container/AdminContainerMigrate";
import StandardModalWrapper from "../modal/StandardModalWrapper";
import { loggedInUserIsClient } from "../permissions/validateUserPermissions";

class TermsAndConditions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tcBody: ""
		};
	}

	handleAcceptTermsAndConditions = () => {
		usersPATCH({
			Id: userInfo().userId,
			EulaRead: convertDateToAPIDate(new Date())
		}).then(() => {
			this.props.dispatch(poll({}));
		});
	};

	componentDidMount() {
		if (loggedInUserIsClient()) {
			VenueBibleQueryById({ Ids: [1] }).then(response => {
				this.setState({
					tcBody: response.data[1].Body
				});
			});
		} else {
			SkimpyBibleQueryById({ Ids: [1] }).then(response => {
				this.setState({
					tcBody: response.data[1].Body
				});
			});
		}
	}

	render() {
		return (
			<div style={{ padding: 4 }}>
				{userInfo().userGroupId === userGroups.client && (
					<h4 className="color-fail">
						<p>
							Please read and accept the Terms of SkimpyHeaven.com.au before proceeding
						</p>
						<p>
							This outlines our guarantees to your venue and what we need from you in
							return.
						</p>
					</h4>
				)}
				{userInfo().userGroupId !== userGroups.client && (
					<h4 className="color-fail">
						TERMS AND CONDITIONS HAVE RECENTLY BEEN UPDATED AND YOU ARE REQUIRED TO
						READ AND ACCEPT THESE TO CONTINUE USING THE WEBSITE
					</h4>
				)}
				<DisplayHtml value={this.state.tcBody} />
				<AdminContainerMigrate
					align="right"
					buttons={[
						<button
							onClick={this.handleAcceptTermsAndConditions}
							className="btn btn-primary"
						>
							<i className="fal fa-check-square" /> Accept Terms and Conditions
						</button>
					]}
				/>
			</div>
		);
	}
}

TermsAndConditions.props = {};

const mapStateToProps = state => {
	return {};
};

TermsAndConditions = connect(mapStateToProps)(TermsAndConditions);
export default TermsAndConditions;
