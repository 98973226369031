import * as React from "react";
import PropTypes from "prop-types";
import RegularShift from "./RegularShift";
import MultidayParentShift from "./MultidayParentShift";
import { apiShiftTypes } from "../../api/apiEndpoints";
import { convertAPIDateToDDMMYYYY, parseAPIDate } from "../../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import { observer } from "mobx-react";
import { css } from "emotion";
import { miniContainer } from "../../styles/global/mini-container";

class ShiftRow extends React.Component {
	shiftRowStyle = css`
		${miniContainer};
		margin-bottom: 15px;
		border: 1px solid lightgrey;
	`;

	render() {
		const shift = this.props.Shift;
		const lastShift = this.props.lastShift;
		let renderShift,
			renderHeader = null;

		let parentDivStyles = {};

		switch (this.props.Shift.ShiftType) {
			case apiShiftTypes.singleShift:
			case apiShiftTypes.series:
			case apiShiftTypes.customMonthly:
				if (this.props.Shift.Extra === 1) {
					parentDivStyles.backgroundColor = "#FFD2E8";
				} else {
					parentDivStyles.backgroundColor = "#FFFFFF";
				}

				renderShift = <RegularShift Shift={shift} />;
				break;
			case apiShiftTypes.multidayShift:
			case apiShiftTypes.multidaySeries:
				parentDivStyles.backgroundColor = "#CCDFFF";
				parentDivStyles.paddingBottom = 0; // For the "Show Multiday Shifts" button"
				renderShift = <MultidayParentShift Shift={shift} />;

				break;
			default:
				renderShift = null;
		}

		const currentShiftDDMMYYYY = convertAPIDateToDDMMYYYY(shift.StartTime);
		if (
			lastShift === null ||
			convertAPIDateToDDMMYYYY(lastShift.StartTime) != currentShiftDDMMYYYY
		) {
			renderHeader = (
				<div>
					<h4 className="heading">
						{DateFns.format(parseAPIDate(shift.StartTime), "ddd D MMM")}
					</h4>
				</div>
			);
		}

		return (
			<div style={this.props.style}>
				{renderHeader}
				<div className={this.shiftRowStyle} style={parentDivStyles}>
					{renderShift}
				</div>
			</div>
		);
	}
}

RegularShift.propTypes = {
	Shift: PropTypes.shape(
		{ Action: PropTypes.string },
		{ AdminPay: PropTypes.number },
		{ ButtonClass: PropTypes.string },
		{ ClientId: PropTypes.number },
		{ EndTime: PropTypes.string },
		{ Extra: PropTypes.any },
		{ FeePay: PropTypes.number },
		{ Id: PropTypes.number },
		{ Label: PropTypes.string },
		{ Notes: PropTypes.string },
		{ PayRate: PropTypes.number },
		{ Requirements: PropTypes.any },
		{ SeriesId: PropTypes.number },
		{ ShiftLength: PropTypes.number },
		{ ShiftPay: PropTypes.number },
		{ ShiftStatus: PropTypes.number },
		{ ShiftType: PropTypes.number },
		{ StartTime: PropTypes.string },
		{ TotalCost: PropTypes.any },
		{ TravelPay: PropTypes.number },
		{ UserId: PropTypes.any },
		{ ValidationMessages: PropTypes.array }
	),
	lastShift: PropTypes.string,
	style: PropTypes.object
};

export default observer(ShiftRow);
