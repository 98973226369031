import React from "react";
import MiniContainer from "../../components/container/MiniContainer";
import DateTimePicker from "../../components/datetime/DateTimePicker";
import SearchBarItem from "../../components/searchBar/SearchBarItem";
import CurrentCredit from "./CurrentCredit";

const SearchBar = ({ value, onChange, allTransactions }) => {
	return (
		<MiniContainer>
			<h4 className="heading">My Skimpy Heaven Account</h4>
			<div className="row">
				<div className="col-md-12">
				</div>
			</div>
		</MiniContainer>
	);
};

export default SearchBar;
