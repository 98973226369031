import * as React from 'react'
import './Fireflies.css'
// https://www.csscodelab.com/html-css-only-tranquil-fireflies-effect-on-background/

const baseState = {
  count: 60,
  fireflies: ((new Array(60)).fill(1)).map((m, i) => i)
}

class FireFlies extends React.Component {
  constructor(props) {
    super(props)
    this.state = {...baseState}
  }

  componentDidMount() {
    const {count} = this.state
    if(count > 0) {
      const tmp = []
      for(let i = 0; i < count; i++) {
        tmp.push(i)
      }
      this.setState({...this.state, fireflies: tmp})
    }
  }

  render() {
    const {count, fireflies} = this.state
    return !!count && <div className={`fireflies`}>{fireflies.map(m => <div className="firefly" key={`firefly-${m}`}></div>)}</div>
  }

}

export default FireFlies