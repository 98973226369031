import * as dayjs from 'dayjs'

export const shiftFeePayModifierForDate = (startDate) => {
  const elevenPercentCutoff = dayjs('2022-01-01').startOf('day')
  if (dayjs(startDate).isBefore(elevenPercentCutoff)) {
    return 0.11
  }
  return 0.13
}

export const SHIFT_EXPECTED_HOURS_MESSAGE = `Please advise SKH office of any changes to expected hours straight after your shift`