import * as React from "react";
import PropTypes from "prop-types";
import "./Spinner.css";

class Spinner extends React.Component {
	render() {
		const loading = this.props.loading;
		if (!loading) {
			if (this.props.children === undefined) {
				return null;
			}
			return this.props.children;
		}

		let wrappedDivClass = this.props.divClass;
		if (this.props.floating) {
			wrappedDivClass = " spinner-floating";

			return (
				<div className="spinner-floating-wrapper">
					<div className="spinner-floating-loading">
						<i className="fas fa-cog fa-spin fa-fw" />
					</div>
				</div>
			);
		}

		if (loading) {
			return (
				<div className={wrappedDivClass}>
					<i
						style={{ fontSize: this.props.fontSize }}
						className="fas fa-cog fa-spin fa-fw"
					/>
					<span style={{ fontSize: this.props.fontSize }}>Loading...</span>
				</div>
			);
		}

		return <div>{this.props.children}</div>;
	}
}

Spinner.defaultProps = {
	fontSize: 20,
	divClass: "col-xs-12",
	floating: false
};

Spinner.propTypes = {
	loading: PropTypes.bool.isRequired,
	fontSize: PropTypes.number,
	divClass: PropTypes.string,
	floating: PropTypes.bool
};

export default Spinner;
