import API from "./client";
import { convertDateToAPIDate, convertDDMMYYYToJSDate } from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import ProfilePublicProfileEnabled from '../components/profile/display/ProfilePublicProfileEnabled'

export const apiShiftTypes = {
	singleShift: 1,
	series: 2,
	multidayShift: 3,
	multidaySeries: 4,
	customMonthly: 6
};

function objWithoutUndefinedProperties(obj) {
	const newObj = {};
	Object.keys(obj).forEach(key => {
		if (obj[key] !== undefined) {
			newObj[key] = obj[key];
		}
		return;
	});
	return newObj;
}

function toNumberIfDefined(thing) {
	if (thing === undefined) {
		return undefined;
	}

	return Number(thing);
}

function toIntIfDefined(thing) {
	if (thing === undefined) {
		return undefined;
	}

	return parseInt(thing, 10);
}

function toIntOrNullIfUndefined(thing) {
	if (thing === undefined) {
		return null;
	}

	return parseInt(thing, 10);
}

function toBoolIfDefined(thing) {
	if (thing === undefined) {
		return undefined;
	}

	return Boolean(thing);
}

function toAPIDateIfDefined(thing) {
	if (thing === undefined) {
		return undefined;
	}

	return convertDateToAPIDate(thing);
}

function toAPIDateIfDefinedOrNull(thing) {
	if (thing === null) {
		return null;
	} else if (thing === undefined) {
		return undefined;
	}
	return convertDateToAPIDate(thing);
}

// Mainly used for DeletedAt
function deletedAtToAPIDateOrNull(thing) {
	if (thing === true) {
		return convertDateToAPIDate(new Date());
	} else if (thing === false) {
		return null;
	}

	return undefined;
}

export function clientReadNotesGET({ ClientId }) {
	return API.get(`/clients/${ClientId}/read_notes`);
}

export function clientReadNotesPATCH({ ClientId }) {
	return API.patch(`/clients/${ClientId}/read_notes`);
}

export function shiftActionPOST({ Action, ShiftId }) {
	return API.post(`/shifts/${ShiftId}/${Action}`);
}

export function allPaymentsPOST({
	StartDate,
	EndTime,
	PaymentCode,
	PaymentCategorySubCodesId,
	UserId,
	ClientId,
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);
	const paramsToSend = {};

	paramsToSend.StartDate = convertDateToAPIDate(
		convertDDMMYYYToJSDate(params.StartDate)
	);
	paramsToSend.EndTime = convertDateToAPIDate(
		DateFns.addDays(convertDDMMYYYToJSDate(params.EndTime), 1)
	);
	paramsToSend.PaymentCode = toIntIfDefined(params.PaymentCode);
	paramsToSend.PaymentCategorySubCodesId = toIntIfDefined(
		params.PaymentCategorySubCodesId
	);
	paramsToSend.PaymentMethod = toIntIfDefined(params.PaymentMethod);
	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.CLientId = toIntIfDefined(params.ClientId);

	return API.post("/payments/all_payments", paramsToSend);
}

export function createPaymentPOST({
	Amount,
	Notes,
	PaymentCategorySubCodesId,
	PaymentCode,
	PaymentMethod,
	PaymentType,
	UserId
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);
	const paramsToSend = {};

	// Charges are input as positive numbers, but sent to the backend as negatives
	if (params.PaymentType === 2) {
		params.Amount = -params.Amount;
	}

	// Backend expects cents instead of dollars
	paramsToSend.Amount = toNumberIfDefined(
		Number(params.Amount.toFixed(2)) * 100
	);
	paramsToSend.Notes = params.Notes;
	paramsToSend.PaymentCategorySubCodesId = toIntIfDefined(
		params.PaymentCategorySubCodesId
	);
	paramsToSend.PaymentCode = toIntIfDefined(params.PaymentCode);
	paramsToSend.PaymentMethod = toIntIfDefined(params.PaymentMethod);
	paramsToSend.PaymentType = toIntIfDefined(params.PaymentType);
	paramsToSend.UserId = toIntIfDefined(params.UserId);

	return API.post("/payments", paramsToSend);
}

export function allPaymentsPATCH({
	PaymentId,
	PaymentCode,
	PaymentCategorySubCodesId,
	UserId,
	Amount,
	Notes,
	PaymentType,
	PaymentMethod,
	CreatedAt
}) {
	let params = objWithoutUndefinedProperties(arguments[0]);
	let paramsToSend = {};

	paramsToSend.PaymentCode = toIntIfDefined(params.PaymentCode);
	paramsToSend.PaymentCategorySubCodesId = toIntOrNullIfUndefined(
		params.PaymentCategorySubCodesId
	);
	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.Amount = toNumberIfDefined(params.Amount);
	paramsToSend.Notes = params.Notes;
	paramsToSend.PaymentType = toIntIfDefined(params.PaymentType);
	paramsToSend.PaymentMethod = toIntIfDefined(params.PaymentMethod);
	paramsToSend.CreatedAt = toAPIDateIfDefined(params.CreatedAt);

	return API.patch(`/payments/${PaymentId}`, paramsToSend);
}

export function transferPOST({ Action, Transfers = [] }) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};
	paramsToSend.Action = params.Action;
	paramsToSend.Transfers = params.Transfers.map(transfer => {
		return {
			ShiftId: toIntIfDefined(transfer.ShiftId),
			UserId: toIntIfDefined(transfer.UserId)
		};
	});
	return API.post("/transfer", paramsToSend);
}

export function availablePOST({
	ShiftFilter,
	Offset,
	Limit,
	ShiftId,
	ClientId,
	UserId,
	UpdatedAt,
	StartTime,
	AuthId
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	params.UpdatedAt = params.UpdatedAt || "1970-01-01T08:00:00+08:00"; // if not set, retrieve all of time
	params.StartTime = params.StartTime || "01/01/1970"; // if not set, retrieve all of time
	params.Offset = params.Offset || 0;
	params.Limit = params.Limit || 1;

	const paramsToSend = {};
	paramsToSend.Offset = toIntIfDefined(params.Offset);
	paramsToSend.Limit = toIntIfDefined(params.Limit);
	paramsToSend.ShiftId = toIntIfDefined(params.ShiftId);
	paramsToSend.ClientId = toIntIfDefined(params.ClientId);
	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.AuthId = toIntIfDefined(params.AuthId);
	paramsToSend.StartTime = convertDateToAPIDate(
		convertDDMMYYYToJSDate(params.StartTime)
	);
	paramsToSend.UpdatedAt = toAPIDateIfDefined(params.UpdatedAt);
	paramsToSend.ShiftFilter = params.ShiftFilter;
	paramsToSend.TypeFilter = params.TypeFilter;

	return API.post("/available", paramsToSend);
}

export function hrmChecklistPOST({ Endpoint, HrmChecklistId }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	params.HrmChecklistId = toIntIfDefined(params.HrmChecklistId);

	return API.post(
		`/hrm_checklists/${params.HrmChecklistId}/${params.Endpoint}`,
		{}
	);
}

export function siteLogsPOST({
	CreatedAtStart,
	CreatedAtEnd,
	ShiftId,
	Attributes
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	params.ShiftId = toIntIfDefined(params.ShiftId);
	params.CreatedAtStart = toAPIDateIfDefined(CreatedAtStart);
	params.CreatedAtEnd = toAPIDateIfDefined(CreatedAtEnd);
	params.Attributes = params.Attributes;

	if (params.CreatedAtStart === undefined) {
		params.CreatedAtStart = "1970-01-01T08:00:00+08:00";
	}

	if (params.CreatedAtEnd === undefined) {
		params.CreatedAtEnd = "2200-01-01T08:00:00+08:00";
	}

	return API.post("/site_logs", params);
}

export function seriesDeleteDELETE({ DryRun, SeriesId, DeleteFrom }) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	params.SeriesId = toIntIfDefined(params.SeriesId);
	params.DeleteFrom = toAPIDateIfDefined(params.DeleteFrom);

	return API.delete(`/series/${params.SeriesId}/${DryRun === true ? 'dry_run' : 'soft_delete'}`, {
		DeleteFrom: params.DeleteFrom
	});
}

export function shiftDeleteDELETE({ Id }) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	return API.delete(`/shifts/${params.Id}`);
}

export function editMultidayParentSeriesPATCH({
	DryRun,
	Id,
	Notes,
	AdminPay,
	TravelPay,
	HrmApproval,
	ContractStart
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};
	paramsToSend.Id = toIntIfDefined(params.Id);

	paramsToSend.Notes = params.Notes;
	paramsToSend.AdminPay = toNumberIfDefined(params.AdminPay);
	paramsToSend.TravelPay = toNumberIfDefined(params.TravelPay);
	paramsToSend.HrmApproval = toBoolIfDefined(params.HrmApproval);
	paramsToSend.ContractStart = toAPIDateIfDefined(params.ContractStart);

	return API.patch(
		`/series/${paramsToSend.Id}/multiday_parent${DryRun === true ? '_dryrun' : ''}`,
		paramsToSend
	);
}

export function editRegularSeriesPATCH({
	DryRun,
	Id,
	ChildSeries = false,
	ContractStart,
	AdminPay,
	Extra, // For shows - if this will become a show, then 1 else null
	FeePay,
	HrmApproval,
	Notes,
	PayRate,
	Requirements,
	ShiftLength,
	ShiftPay,
	TravelPay,
	// Requirements
	BarSkills, // 1
	PatronSide, // 2
	Topless, // 3
	MerchSales, // 4
	Games, // 5
	JugRun // 6
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};
	paramsToSend.Id = toIntIfDefined(params.Id);
	paramsToSend.ContractStart = toAPIDateIfDefined(params.ContractStart);
	paramsToSend.AdminPay = toNumberIfDefined(params.AdminPay);

	paramsToSend.FeePay = toNumberIfDefined(params.FeePay);
	paramsToSend.HrmApproval = params.HrmApproval;
	paramsToSend.Notes = params.Notes;
	paramsToSend.PayRate = toNumberIfDefined(params.PayRate);

	paramsToSend.ShiftLength = toIntIfDefined(params.ShiftLength);
	paramsToSend.ShiftPay = toNumberIfDefined(params.ShiftPay);
	paramsToSend.TravelPay = toNumberIfDefined(params.TravelPay);
	paramsToSend.Requirements = [];

	paramsToSend.Extra = Extra ? 1 : null;

	if (BarSkills) {
		paramsToSend.Requirements.push({
			Description: "BarSkills",
			Id: 1
		});
	}
	if (PatronSide) {
		paramsToSend.Requirements.push({
			Description: "PatronSide",
			Id: 2
		});
	}
	if (Topless) {
		paramsToSend.Requirements.push({
			Description: "Topless",
			Id: 3
		});
	}
	if (MerchSales) {
		paramsToSend.Requirements.push({
			Description: "MerchSales",
			Id: 4
		});
	}
	if (Games) {
		paramsToSend.Requirements.push({
			Description: "Games",
			Id: 5
		});
	}
	if (JugRun) {
		paramsToSend.Requirements.push({
			Description: "JugRun",
			Id: 6
		});
	}

	const endpoint = `/series/${params.Id}/${ChildSeries ? 'multiday_child' : 'regular'}${DryRun === true ? '_dryrun' : ''}`
	return API.patch(endpoint, paramsToSend);
}

export function editRegularShift({
	Id,
	Notes,
	StartTime,
	EndTime,
	ShiftLength,
	PayRate,
	ShiftPay,
	FeePay,
	AdminPay,
	TravelPay,
	HrmApproval,
	Extra,
	AdminNotes,
	// Requirements
	BarSkills, // 1
	PatronSide, // 2
	Topless, // 3
	MerchSales, // 4
	Games, // 5
	JugRun // 6
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};

	paramsToSend.Notes = params.Notes;
	paramsToSend.AdminNotes = params.AdminNotes;
	paramsToSend.StartTime = toAPIDateIfDefined(params.StartTime);
	paramsToSend.EndTime = toAPIDateIfDefined(params.EndTime);
	paramsToSend.ShiftLength = toIntIfDefined(params.ShiftLength);
	paramsToSend.PayRate = toNumberIfDefined(params.PayRate);
	paramsToSend.ShiftPay = toNumberIfDefined(params.ShiftPay);
	paramsToSend.FeePay = toNumberIfDefined(params.FeePay);
	paramsToSend.AdminPay = toNumberIfDefined(params.AdminPay);
	paramsToSend.TravelPay = toNumberIfDefined(params.TravelPay);
	paramsToSend.HrmApproval = toBoolIfDefined(params.HrmApproval);

	paramsToSend.Requirements = [];

	if (Extra) {
		paramsToSend.Extra = 1;
	} else {
		paramsToSend.Extra = null;
	}

	if (BarSkills) {
		paramsToSend.Requirements.push({
			Description: "BarSkills",
			Id: 1
		});
	}
	if (PatronSide) {
		paramsToSend.Requirements.push({
			Description: "PatronSide",
			Id: 2
		});
	}
	if (Topless) {
		paramsToSend.Requirements.push({
			Description: "Topless",
			Id: 3
		});
	}
	if (MerchSales) {
		paramsToSend.Requirements.push({
			Description: "MerchSales",
			Id: 4
		});
	}
	if (Games) {
		paramsToSend.Requirements.push({
			Description: "Games",
			Id: 5
		});
	}
	if (JugRun) {
		paramsToSend.Requirements.push({
			Description: "JugRun",
			Id: 6
		});
	}

	return API.patch(`/shifts/${params.Id}`, paramsToSend);
}

export function configGET() {
	return API.get("/config");
}

export function profilesPATCH({
	Id,
	StageName,
	WorkPhone,
	WorkFacebook,
	ABN,
	GST,
	Experience,
	DOB,
	PrivateEmail,
	PrivateFacebook,
	EmergencyContactName,
	EmergencyContactPhone,
	Personality,
	BasedIn,
	AddressNumber,
	AddressRoad,
	AddressSuburb,
	AddressPostcode,
	AddressState,
	KFWHidden,
	Fullname,
	LevelId,
	PublicProfileEnabled,
	PersonnelFile,
	PromotionalNotes,
	// Services              skh_types.JSON
	// Merch                 skh_types.JSON
	// DeletedAt             skh_types.PerthTime
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};
	paramsToSend.StageName = params.StageName;
	paramsToSend.WorkPhone = params.WorkPhone;
	paramsToSend.WorkFacebook = params.WorkFacebook;
	paramsToSend.ABN = params.ABN;
	paramsToSend.GST = toBoolIfDefined(params.GST);
	paramsToSend.PublicProfileEnabled = toBoolIfDefined(params.PublicProfileEnabled);
	paramsToSend.Experience = params.Experience;
	paramsToSend.DOB = params.DOB;
	paramsToSend.PrivateEmail = params.PrivateEmail;
	paramsToSend.PrivateFacebook = params.PrivateFacebook;
	paramsToSend.EmergencyContactName = params.EmergencyContactName;
	paramsToSend.EmergencyContactPhone = params.EmergencyContactPhone;
	paramsToSend.Personality = params.Personality;
	paramsToSend.BasedIn = params.BasedIn;
	paramsToSend.AddressNumber = params.AddressNumber;
	paramsToSend.AddressRoad = params.AddressRoad;
	paramsToSend.AddressSuburb = params.AddressSuburb;
	paramsToSend.AddressPostcode = params.AddressPostcode;
	paramsToSend.AddressState = params.AddressState;
	paramsToSend.KFWHidden = params.KFWHidden;
	paramsToSend.Fullname = params.Fullname;
	paramsToSend.LevelId = toIntIfDefined(params.LevelId);
	paramsToSend.PromotionalNotes = params.PromotionalNotes
	paramsToSend.PersonnelFile = params.PersonnelFile

	return API.patch(`/profiles/${Id}`, paramsToSend);
}

export function createSeriesPOST({
	ClientId,
	ContractStart,
	ContractEndOrNull,
	ShiftLength,
	WeeklyFrequency,
	MonthlyDay,
	MonthlyWeek,

	AdminPay,
	FeePay,
	ShiftPay,
	TravelPay,
	PayRate,

	HrmApproval,
	Notes,

	// Requirements
	BarSkills, // 1
	PatronSide, // 2
	Topless, // 3
	MerchSales, // 4
	Games, // 5
	JugRun, // 6
	Extra, // For shows - if this will become a show, then 1 else null

	ShiftType,

	Multiday // The children
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	return API.post("/series", formatCreateSeriesApiRequest(params));
}

export function formatCreateSeriesApiRequest(params) {
	const paramsToSend = {};

	paramsToSend.Notes = params.Notes;
	paramsToSend.HrmApproval = params.HrmApproval;
	paramsToSend.ShiftType = toIntIfDefined(params.ShiftType);
	paramsToSend.Extra = convertShowToAPIFormat(params);
	paramsToSend.AdminPay = toNumberIfDefined(params.AdminPay);
	paramsToSend.TravelPay = toNumberIfDefined(params.TravelPay);

	switch (params.ShiftType) {
		case apiShiftTypes.multidaySeries:
		case apiShiftTypes.series: {
			paramsToSend.WeeklyFrequency = toIntIfDefined(params.WeeklyFrequency);
			break;
		}
		case apiShiftTypes.customMonthly:
			paramsToSend.MonthlyDay = toIntIfDefined(params.MonthlyDay);
			paramsToSend.MonthlyWeek = toIntIfDefined(params.MonthlyWeek);
			break;
	}

	switch (params.ShiftType) {
		case apiShiftTypes.customMonthly:
		case apiShiftTypes.singleShift:
		case apiShiftTypes.series: {
			paramsToSend.ClientId = toIntIfDefined(params.ClientId);
			paramsToSend.ContractStart = toAPIDateIfDefined(params.ContractStart);
			// NOTE: The API can't handle if ContractEnd is set to null when it validates ContractEnd > ContractStart,
			// current fix is to not send it.
			if (params.ContractEndOrNull !== null) {
				paramsToSend.ContractEnd = toAPIDateIfDefinedOrNull(
					params.ContractEndOrNull
				);
			}
			paramsToSend.ShiftLength = toIntIfDefined(params.ShiftLength);
			paramsToSend.FeePay = toNumberIfDefined(params.FeePay);
			paramsToSend.ShiftPay = toNumberIfDefined(params.ShiftPay);
			paramsToSend.PayRate = toNumberIfDefined(params.PayRate);
			paramsToSend.Requirements = convertRequirementsToAPIFormat(params);

			break;
		}
		case apiShiftTypes.multidayShift:
		case apiShiftTypes.multidaySeries: {
			paramsToSend.Multiday = params.Multiday.map(child => {
				const newChild = {
					ClientId: toIntIfDefined(child.ClientId),
					FeePay: toNumberIfDefined(child.FeePay),
					PayRate: toNumberIfDefined(child.PayRate),
					ShiftLength: toNumberIfDefined(child.ShiftLength),
					ShiftPay: toNumberIfDefined(child.ShiftPay),
					ContractStart: toAPIDateIfDefined(child.ContractStart),
					Extra: convertShowToAPIFormat(child),
					Requirements: convertRequirementsToAPIFormat(child),
					Notes: child.Notes
				};
				if (child.ContractEndOrNull !== null) {
					newChild.ContractEnd = toAPIDateIfDefinedOrNull(child.ContractEndOrNull);
				}
				return newChild;
			});
			break;
		}
	}
	return paramsToSend;
}

export function convertShowToAPIFormat(args) {
	return args.Extra ? 1 : null;
}

export function convertRequirementsToAPIFormat(args) {
	const requirements = [];

	if (args.BarSkills) {
		requirements.push({
			Description: "BarSkills",
			Id: 1
		});
	}
	if (args.PatronSide) {
		requirements.push({
			Description: "PatronSide",
			Id: 2
		});
	}
	if (args.Topless) {
		requirements.push({
			Description: "Topless",
			Id: 3
		});
	}
	if (args.MerchSales) {
		requirements.push({
			Description: "MerchSales",
			Id: 4
		});
	}
	if (args.Games) {
		requirements.push({
			Description: "Games",
			Id: 5
		});
	}
	if (args.JugRun) {
		requirements.push({
			Description: "JugRun",
			Id: 6
		});
	}
	return requirements;
}

export function usersPATCH({
	Id,
	Email,
	Password,
	UserGroupId,
	EulaRead // Time
}) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Email = params.Email;
	paramsToSend.Password = params.Password;
	paramsToSend.UserGroupId = toIntIfDefined(params.UserGroupId);
	paramsToSend.EulaRead = toAPIDateIfDefined(params.EulaRead);

	return API.patch("/users/" + Id, paramsToSend);
}

export function deleteUserDELETE({ Id }) {
	return API.delete("/users/" + Id);
}

export function profilesPOST({ StageName, Email, Password }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.StageName = params.StageName;
	paramsToSend.Email = params.Email;
	paramsToSend.Password = params.Password;

	return API.post("/profiles", paramsToSend);
}

export function clientsPOST({ ClientName, BrandId }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.ClientName = params.ClientName;
	paramsToSend.BrandId = toIntIfDefined(params.BrandId);

	return API.post("/clients", paramsToSend);
}

export function createBrandPOST({
	BrandName,
	UserId,
	WebLink,
	SalesPitch,
	PhoneNumber,
	AddressNumber,
	AddressPostcode,
	AddressRoad,
	AddressState,
	AddressSuburb
}) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.BrandName = params.BrandName;
	paramsToSend.UserId = params.UserId;
	paramsToSend.WebLink = params.WebLink;
	paramsToSend.SalesPitch = params.SalesPitch;
	paramsToSend.PhoneNumber = params.PhoneNumber;
	paramsToSend.AddressNumber = params.AddressNumber;
	paramsToSend.AddressPostcode = params.AddressPostcode;
	paramsToSend.AddressRoad = params.AddressRoad;
	paramsToSend.AddressState = params.AddressState;
	paramsToSend.AddressSuburb = params.AddressSuburb;

	return API.post("/brands", paramsToSend);
}

export function updateBrandPATCH({
	Id,
	BrandName,
	UserId,
	WebLink,
	SalesPitch,
	PhoneNumber,
	AddressNumber,
	AddressPostcode,
	AddressRoad,
	AddressState,
	AddressSuburb
}) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.BrandName = params.BrandName;
	paramsToSend.UserId = params.UserId;
	paramsToSend.WebLink = params.WebLink;
	paramsToSend.SalesPitch = params.SalesPitch;
	paramsToSend.PhoneNumber = params.PhoneNumber;
	paramsToSend.AddressNumber = params.AddressNumber;
	paramsToSend.AddressPostcode = params.AddressPostcode;
	paramsToSend.AddressRoad = params.AddressRoad;
	paramsToSend.AddressState = params.AddressState;
	paramsToSend.AddressSuburb = params.AddressSuburb;

	return API.patch("/brands/" + params.Id, paramsToSend);
}

export function deleteBrandDELETE({ Id }) {
	return API.delete("/brands/" + Id);
}

export function clientsPATCH({
	Id,
	ClientName,
	PhoneNumber,
	RegularReappearance,
	FavouriteReappearance,
	TravelPay,
	PaymentMethod,
	PaymentNote,
	Notes,
	AddressNumber,
	AddressRoad,
	AddressSuburb,
	AddressState,
	AddressPostcode,
	BrandId,
	Requirements,
	DistanceFromCBD,
	ClientEmail,
	DeletedAt,
	Lat,
	Lng
}) {
	const params = objWithoutUndefinedProperties(arguments[0]);

	const paramsToSend = {};
	paramsToSend.ClientName = params.ClientName;
	paramsToSend.PhoneNumber = params.PhoneNumber;
	paramsToSend.RegularReappearance = toIntIfDefined(params.RegularReappearance);
	paramsToSend.FavouriteReappearance = toIntIfDefined(
		params.FavouriteReappearance
	);
	paramsToSend.TravelPay = toNumberIfDefined(params.TravelPay);
	paramsToSend.PaymentMethod = params.PaymentMethod;
	paramsToSend.PaymentNote = params.PaymentNote;
	paramsToSend.Notes = params.Notes;
	paramsToSend.AddressNumber = params.AddressNumber;
	paramsToSend.AddressRoad = params.AddressRoad;
	paramsToSend.AddressSuburb = params.AddressSuburb;
	paramsToSend.AddressState = params.AddressState;
	paramsToSend.AddressPostcode = toNumberIfDefined(params.AddressPostcode);
	paramsToSend.BrandId = toIntIfDefined(params.BrandId);
	// paramsToSend.Requirements = params.Requirements; // json
	paramsToSend.DistanceFromCBD = params.DistanceFromCBD;
	paramsToSend.ClientEmail = params.ClientEmail;
	paramsToSend.DeletedAt = deletedAtToAPIDateOrNull(params.DeletedAt);
	if (params.Lat) {
		paramsToSend.Lat = params.Lat || 0;
	}
	if (params.Lng) {
		paramsToSend.Lng = params.Lng || 0;
	}

	return API.patch(`/clients/${Id}`, paramsToSend);
}

export function clientContactsPATCH({ Id, FullName, PhoneNumber }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.FullName = params.FullName;
	paramsToSend.PhoneNumber = params.PhoneNumber;

	return API.patch("/client_contacts/" + Id, paramsToSend);
}

export function clientContactsPOST({ ClientId, FullName, PhoneNumber }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.ClientId = params.ClientId;
	paramsToSend.FullName = params.FullName;
	paramsToSend.PhoneNumber = params.PhoneNumber;

	return API.post("/client_contacts", paramsToSend);
}

export function clientContactsDELETE({ Id }) {
	return API.delete("/client_contacts/" + Id, null);
}

export function clientTeamTalksPATCH({ Id, Message }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Message = params.Message;

	return API.patch("/client_team_talks/" + Id, paramsToSend);
}

export function clientTeamTalksPOST({ UserId, ClientId, Message }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.ClientId = toIntIfDefined(params.ClientId);
	paramsToSend.Message = params.Message;

	return API.post("/client_team_talks", paramsToSend);
}

export function clientTeamTalksDELETE({ Id }) {
	return API.delete("/client_team_talks/" + Id, null);
}

export function skimpyBiblePATCH({ Id, Body, Heading, ListOrder }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Body = params.Body;
	paramsToSend.Heading = params.Heading;
	paramsToSend.ListOrder = toIntIfDefined(params.ListOrder);

	return API.patch("/skimpy_bible/" + Id, paramsToSend);
}

export function venueBiblePATCH({ Id, Body, Heading, ListOrder }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Body = params.Body;
	paramsToSend.Heading = params.Heading;
	paramsToSend.ListOrder = toIntIfDefined(params.ListOrder);

	return API.patch("/venue_bible/" + Id, paramsToSend);
}

export function templatesPatch({ Id, Description }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Description = params.Description;

	return API.patch("/templates/" + Id, paramsToSend);
}

export function VenueBibleQueryById({ Ids }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Ids = params.Ids;

	return API.post("/venue_bible/queryByIds", paramsToSend);
}

export function SkimpyBibleQueryById({ Ids }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Ids = params.Ids;

	return API.post("/skimpy_bible/queryByIds", paramsToSend);
}

export function skimpyBiblePOST({ Heading, Body }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Heading = params.Heading;
	paramsToSend.Body = params.Body;

	return API.post("/skimpy_bible", paramsToSend);
}

export function skimpyBibleDELETE({ Id }) {
	return API.delete("/skimpy_bible/" + Id);
}

export function venueBiblePOST({ Heading, Body }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Heading = params.Heading;
	paramsToSend.Body = params.Body;

	return API.post("/venue_bible", paramsToSend);
}

export function venueBibleDELETE({ Id }) {
	return API.delete("/venue_bible/" + Id);
}

export function notificationDELETE({ Id }) {
	return API.delete("/notifications/" + Id);
}

export function notificationPOST({ UserId, Message }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.Message = params.Message;

	return API.post("/notifications", paramsToSend);
}

export const uploadTypes = {
	Profiles: "profiles",
	Clients: "clients",
	Brands: "brands"
};

export const uploadSections = {
	// depreciated
	RSA: "RSA",
	MainProfilePicture: "Main Profile Picture",
	Gallery: "Gallery",
	Banner: "Banner",

	brand_banner: "brand_banner",
	client_banner: "client_banner",
	client_gallery: "client_gallery",
	profile_main_profile_picture: "profile_main_profile_picture",
	profile_venue_promo: "profile_venue_promo",
	profile_private_promo: "profile_private_promo",
	profile_proof: "profile_proof",
	profile_rsa: "profile_rsa"
};

// depreciated
export const uploadTags = {
	MainProfilePicture: "Main Profile Picture",
	Promo: "Promo",
	Proof: "Proof",
	Gallery: "Gallery",
	RSA: "RSA"
};

export function uploadsGet({ UploadSectionTypes, UploadTypeId }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.UploadSectionTypes = params.UploadSectionTypes;
	paramsToSend.UploadTypeId = params.UploadTypeId;

	return API.post("/uploads", paramsToSend);
}

export function uploadPOST({ sectionType, sectionId, mimeType, file }) {
	// Backend only supports RSA as a section

	let url = "/uploads/" + sectionType + "/" + sectionId;

	return API.post(url, file, { "Content-Type": mimeType });
}

export function uploadCropPATCH({ Id, Top, Left, Width, Height }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Top = toIntIfDefined(params.Top);
	paramsToSend.Left = toIntIfDefined(params.Left);
	paramsToSend.Width = toIntIfDefined(params.Width);
	paramsToSend.Height = toIntIfDefined(params.Height);

	return API.patch("/uploads/" + Id + "/crop", paramsToSend);
}

export function uploadPATCH({ Id, Tag, Description }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Tag = params.Tag;
	paramsToSend.Description = params.Description;

	return API.patch("/uploads/" + Id, paramsToSend);
}

export function uploadDELETE({ Id }) {
	return API.delete("/uploads/" + Id);
}

export function paymentCategorySubCodesPOST({ Description, ParentId }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.ParentId = params.ParentId;
	paramsToSend.Description = params.Description;

	return API.post("/payment_category_sub_codes", paramsToSend);
}

export function paymentCategorySubCodesPATCH({ Id, Description }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Description = params.Description;

	return API.patch("/payment_category_sub_codes/" + Id, paramsToSend);
}

export function paymentCategorySubCodesDELETE({ Id }) {
	let paramsToSend = {};
	paramsToSend.DeletedAt = convertDateToAPIDate(new Date());
	return API.patch("/payment_category_sub_codes/" + Id, paramsToSend);
}

export function paymentCategoryCodesPOST({ Description }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Description = params.Description;

	return API.post("/payment_category_codes", paramsToSend);
}

export function paymentCategoryCodesPATCH({ Id, Description }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};
	paramsToSend.Description = params.Description;

	return API.patch("/payment_category_codes/" + Id, paramsToSend);
}

export function paymentCategoryCodesDELETE({ Id }) {
	let paramsToSend = {};
	paramsToSend.DeletedAt = convertDateToAPIDate(new Date());
	return API.patch("/payment_category_codes/" + Id, paramsToSend);
}

export function calendarPOST({ UserId, StartTime, EndTime, TypeId }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.TypeId = toIntIfDefined(params.TypeId);
	paramsToSend.StartTime = toAPIDateIfDefined(params.StartTime);
	paramsToSend.EndTime = toAPIDateIfDefined(params.EndTime);

	return API.post("/calendars/withShifts", paramsToSend);
}

export function createKFWPOST({ UserId, StartTime }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.StartTime = toAPIDateIfDefined(params.StartTime);
	paramsToSend.EndTime = toAPIDateIfDefined(DateFns.endOfDay(params.StartTime));
	paramsToSend.TypeId = 1;

	return API.post("/calendars", paramsToSend);
}

export function updateKFWPATCH({ Id, Notes }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.Id = toIntIfDefined(params.Id);
	paramsToSend.Notes = params.Notes;

	return API.patch("/calendars/" + paramsToSend.Id, paramsToSend);
}

export function removeKFWDELETE({ Id }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.Id = toIntIfDefined(params.Id);

	return API.delete("/calendars/" + paramsToSend.Id);
}

export function resetPasswordPOST({ Email }) {
	let paramsToSend = {};

	paramsToSend.Email = Email;
	return API.post("/reset_password", paramsToSend);
}

export function paymentLogsPOST() {
	return API.post("/payment_logs");
}

export function clientLineupGET() {
	return API.get("/reports/clientLineup");
}

export function clientLineupByClientIdGET({ Id }) {
	return API.get("/clients/" + Id + "/lineup");
}

export function clientFeedbackPOST({ ShiftId, Reappear, Rating, Notes }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.ShiftId = toIntIfDefined(params.ShiftId);
	paramsToSend.Rating = toIntIfDefined(params.Rating);
	paramsToSend.Reappear = toIntIfDefined(params.Reappear);
	paramsToSend.Notes = params.Notes;

	return API.post("/client_feedback", paramsToSend);
}

export function adminKFWPOST({ StartTime, EndTime }) {
	let params = objWithoutUndefinedProperties(arguments[0]);

	let paramsToSend = {};

	paramsToSend.StartTime = toAPIDateIfDefined(params.StartTime);
	paramsToSend.EndTime = toAPIDateIfDefined(params.EndTime);

	return API.post("/admin/kfw", paramsToSend);
}

export function updateLevelsPATCH({ Id, BookAheadWeeks }) {
	let params = objWithoutUndefinedProperties(arguments[0]);
	let paramsToSend = {};

	paramsToSend.BookAheadWeeks = toIntIfDefined(params.BookAheadWeeks);

	return API.patch("/levels/" + Id, paramsToSend);
}

export function getPaymentsByUserId() {
	return API.get("/reports/paymentsByUserId");
}

export function getPaymentsByCurrentDateByUserId() {
	return API.get("/reports/paymentsByCurrentDateByUserId");
}

export function removeHrmMnrFavPreapprovalDELETE({ Id, section }) {
	return API.delete("/" + section + "/" + Id);
}

export function addHrmMnrFavPreapprovalPOST({ UserId, ClientId, section }) {
	let params = objWithoutUndefinedProperties(arguments[0]);
	let paramsToSend = {};

	paramsToSend.UserId = toIntIfDefined(params.UserId);
	paramsToSend.ClientId = toIntIfDefined(params.ClientId);

	return API.post("/" + section, paramsToSend);
}

export function addClientLoginPATCH({
	ClientId,
	Password,
	ConfirmPassword,
	Email
}) {
	let params = objWithoutUndefinedProperties(arguments[0]);
	let paramsToSend = {};

	paramsToSend.ClientId = toIntIfDefined(params.ClientId);
	paramsToSend.Password = params.Password;
	paramsToSend.ConfirmPassword = params.ConfirmPassword;
	paramsToSend.Email = params.Email;

	return API.patch("/clients/" + ClientId + "/create_login", paramsToSend);
}

// POST addAllClientsToUserId/:Id
export function addAllClientsToUserId({ UserId }) {
	return API.post(`/preapprovals_bulk/addAllClientsToUserId/${UserId}`);
}

// POST addAllUsersToClientId/:Id
export function addAllUsersToClientId({ ClientId }) {
	return API.post(`/preapprovals_bulk/addAllUsersToClientId/${ClientId}`);
}

// DELETE deleteAllClientsByUserId/:Id
export function deleteAllClientsByUserId({ UserId }) {
	return API.delete(`/preapprovals_bulk/deleteAllClientsByUserId/${UserId}`);
}

// DELETE deleteAllUsersByClientId/:Id
export function deleteAllUsersByClientId({ ClientId }) {
	return API.delete(`/preapprovals_bulk/deleteAllUsersByClientId/${ClientId}`);
}


export function getClientsByIds({ ids, fieldsToQuery }) {
	let params = {};

	if (ids != undefined) {
		params.Ids = ids;
	}

	return API.post("/clients/queryByIds", {
		Ids: ids,
		FieldsToQuery: fieldsToQuery
	})
}

export function advertsGET(id) {
	if (!!id) {
		return API.get(`/content/${id}`)
	}
	return API.get("/content")
}

export function advertsUPDATE(ad) {
	if (!ad || !ad.Id) {
		throw new Error("unable to update")
	}
	return API.patch(`/content/${ad.Id}`, ad)
}

export function advertsDELETE(id) {
	return API.delete(`/content/${id}`)
}

export function advertUploadBlob(formData) {
	return API.post(`/content/blob`, formData, { "Content-Type": 'multipart/form-data' });
}

export function advertCreatePOST(json) {
	return API.post(`/content`, json, { "Content-Type": 'application/json' });
}

export function videosUploadBlob(formData) {
	return API.post(`/videos/blob`, formData, { "Content-Type": 'multipart/form-data' });
}

export function videosCreatePOST(json) {
	return API.post(`/videos`, json, { "Content-Type": 'application/json' });
}

export function videosDELETE(id) {
	return API.delete(`/videos/${id}`)
}
export function videosSetActivePATCH(id) {
	return API.patch(`/videos/${id}`)
}

export function videosGET(id) {
	if (!!id) {
		return API.get(`/videos/${id}`)
	}
	return API.get("/videos")
}