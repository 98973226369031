import * as React from "react";
import PropTypes from "prop-types";
import FormManager from "../form/FormManager";
import LoadingButton from "../button/LoadingButton";
import { uploadPOST } from "../../api/apiEndpoints";
import AdminContainerMigrate from "../container/AdminContainerMigrate";
import ReactCrop, { makeAspectCrop, getPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Save } from "../icons/Icons";

// copied mostly from https://codesandbox.io/s/vmvjl2q023
class UploadCroppedImg extends React.Component {
	debug = false;

	state = {
		src: undefined,
		crop: {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			aspect: 0
		},
		image: undefined,
		pixelCrop: undefined
	};

	static defaultProps = {
		aspectRatio: 4 / 3
	};

	static propTypes = {
		uploadSectionType: PropTypes.string.isRequired,
		uploadSectionId: PropTypes.number.isRequired, // profile ID, client ID or brand ID
		onSubmitSuccessFn: PropTypes.func.isRequired,
		aspectRatio: PropTypes.number
	};

	onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			/*
			* On (probably only apple devices) the EXIF data automatically rotates images embedded in a
			* canvas, but wont automatically rotate <img src>. This is a workaround which takes the
			* selected image and embeds it in a canvas (rotating if nessesary based on the platform),
			* then converts the image to a data url.
			*/
			const reader = new FileReader();
			reader.onloadend = e => {
				let image = new Image();
				image.src = e.target.result;
				image.onload = () => {
					let canvas = document.createElement("canvas");
					canvas.width = image.width;
					canvas.height = image.height;
					let ctx = canvas.getContext("2d");
					ctx.drawImage(image, 0, 0);

					this.setState({
						src: canvas.toDataURL("image/jpeg")
					});
				};
			};
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	onImageLoaded = image => {
		const defaultCrop = makeAspectCrop(
			{
				x: 0,
				y: 0,
				aspect: this.props.aspectRatio,
				width: 50
			},
			image.width / image.height
		);

		this.setState({
			crop: defaultCrop,
			image: image,
			pixelCrop: getPixelCrop(image, defaultCrop)
		});
	};

	createCroppedCanvasFromState = () => {
		const canvas = document.createElement("canvas");
		canvas.width = this.state.pixelCrop.width;
		canvas.height = this.state.pixelCrop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			this.state.image,
			this.state.pixelCrop.x,
			this.state.pixelCrop.y,
			this.state.pixelCrop.width,
			this.state.pixelCrop.height,
			0,
			0,
			this.state.pixelCrop.width,
			this.state.pixelCrop.height
		);

		return canvas;
	};

	onCropChange = (crop, pixelCrop) => {
		if (this.debug) {
			const imgCropped = this.createCroppedCanvasFromState().toDataURL(
				"image/jpeg"
			);
			this.setState({ crop, pixelCrop, imgCropped });
			return;
		}

		this.setState({ crop, pixelCrop });
	};

	render() {
		if (this.state.src === undefined) {
			return <input type="file" onChange={this.onSelectFile} />;
		}

		// https://stackoverflow.com/questions/21201548/how-to-prevent-auto-rotating-images-on-ios-on-html-with-css-javascript
		return (
			<React.Fragment>
				<FormManager
					handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
						this.createCroppedCanvasFromState().toBlob(blob => {
							uploadPOST({
								sectionType: this.props.uploadSectionType,
								sectionId: this.props.uploadSectionId,
								file: blob,
								mimeType: "image/jpeg"
							})
								.then(() => {
									handleSubmitSuccess();
									this.props.onSubmitSuccessFn();
								})
								.catch(() => {
									handleSubmitFail();
								});
						}, "image/jpeg");
					}}
				>
					{({ handleSubmit, submitting }) => {
						return (
							<div>
								<ReactCrop
									keepSelection={true}
									minHeight={5}
									minWidth={5}
									src={this.state.src}
									crop={this.state.crop}
									onImageLoaded={this.onImageLoaded}
									onComplete={this.onCropChange}
									onChange={this.onCropChange}
								/>
								{this.debug && <img src={this.state.imgCropped} width={300} />}
								<AdminContainerMigrate
									align="right"
									buttons={[
										<LoadingButton
											className="btn btn-danger"
											loading={submitting}
											onClick={handleSubmit}
										>
											<Save /> Upload Image
										</LoadingButton>
									]}
								/>
							</div>
						);
					}}
				</FormManager>
			</React.Fragment>
		);
	}
}

export default UploadCroppedImg;
