import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clientSelectorAsArray } from "../../poll/selectors";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";

class ClientList extends React.Component {
	render() {
		console.log('ClientList name', this.props.name)
		let options = [];

		if (this.props.clientsAsArray.length > 0) {
			let filteredClients = this.props.clientsAsArray;

			if (this.props.hideClientIds.length > 0) {
				filteredClients = filteredClients.filter(client => {
					return !this.props.hideClientIds.includes(client.Id);
				});
			}

			filteredClients = filteredClients.filter(client => {
				return client.DeletedAt === null;
			});
			options = filteredClients.map(client => {
				return { value: Number(client.Id), label: client.ClientName };
			});
			sortAlphaNumeric(options);
		}

		return (
			<Select
				options={options}
				name={this.props.name}
				onChange={this.props.onChange}
				value={this.props.value}
			/>
		);
	}
}

ClientList.defaultProps = {
	hideClientIds: []
};

ClientList.propTypes = {
	name: PropTypes.string,
	client: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	hideClientIds: PropTypes.array
};

const mapStateToProps = state => {
	return {
		clientsAsArray: clientSelectorAsArray(state)
	};
};

export default connect(mapStateToProps)(ClientList);
