import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pollLoading } from "../poll/selectors";
import { poll } from "../poll/actions";
import LoadingButton from "../components/button/LoadingButton";
import history from "../history";
import Spinner from "../components/loading/Spinner";
import { toastr } from "react-redux-toastr";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import InputHtml from "../components/input/InputHtml";
import DisplayHtml from "../components/html/DisplayHtml";
import { Prompt } from "react-router-dom";
import sanitize from "sanitize-html";
import { Pencil, Eye, Save, Trash } from "../components/icons/Icons";

class Bible extends React.PureComponent {
	state = {
		page: "VIEW",
		createSwitch: false,
		subPageHeading: "",
		subPageContent: "",
		listOrder: -1,
		id: -1,
		saving: false
	};

	static getDerivedStateFromProps(nextProps, currentState) {
		const nextBibleId = Bible.findBibleIdFromPathName(nextProps);
		if (nextBibleId === 0) {
			if (nextBibleId !== currentState.id) {
				return {
					page: "EDIT",
					createSwitch: true,
					subPageContent: "",
					subPageHeading: "",
					id: 0,
					listOrder: -1,
					saving: false
				};
			}
		}
		if (nextBibleId !== currentState.id) {
			return {
				id: nextBibleId
			};
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		// if changing pages
		if (prevState.id !== this.state.id && this.state.id !== 0) {
			this.loadBible(this.state.id);
		}
	}

	componentDidMount() {
		if (this.state.id > 0) {
			this.loadBible(this.state.id);
		}
	}

	loadBible = id => {
		this.setState({
			saving: true
		});
		this.props
			.queryBibleEntriesAPIFn({ Ids: [id] })
			.then(response => {
				const pageDetails = response.data[id];

				this.setState({
					page: "VIEW",
					createSwitch: false,
					subPageContent: pageDetails.Body,
					subPageHeading: pageDetails.Heading,
					id: id,
					listOrder: pageDetails.ListOrder,
					saving: false
				});
			})
			.catch(err => {});
	};

	getSanitizedHtml = html => {
		// Sanitise HTML
		let clean = sanitize(html, {
			allowedTags: [
				"b",
				"i",
				"s",
				"em",
				"u",
				"strong",
				"blockquote",
				"a",
				"p",
				"h1",
				"h2",
				"h3",
				"br",
				"ul",
				"li",
				"ol",
				"span",
				"img"
			],
			allowedSchemes: ["data", "http"], // embedded img uses data scheme
			allowedAttributes: {
				a: ["href"],
				span: ["style"],
				img: ["src"]
			},
			allowedStyles: {
				"*": {
					// Match HEX and RGB
					color: [
						/^\#(0x)?[0-9a-f]+$/i,
						/^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
					],
					"text-align": [/^left$/, /^right$/, /^center$/],
					// Match any number with px, em, or %
					"font-size": [/^\d+(?:px|em|%)$/]
				},
				p: {
					"font-size": [/^\d+rem$/]
				}
			}
		});

		// Stop extra lines from being generated by Quill?
		clean = clean.replace(/<p><br \/><\/p><p><br \/><\/p>/g, `<p><br /></p>`);
		return clean;
	};

	handlePreviewClick = () => {
		this.setState({
			subPageContent: this.getSanitizedHtml(this.state.subPageContent),
			page: "VIEW"
		});
	};

	handleEditClick = () => {
		this.setState({
			subPageContent: this.getSanitizedHtml(this.state.subPageContent),
			page: "EDIT"
		});
	};

	handleUpdateClick = () => {
		toastr.info("Updating bible entry...");
		this.setState({ saving: true });
		const heading = this.state.subPageHeading;
		this.props
			.updateBibleEntryAPIFn({
				Id: this.state.id,
				Body: this.state.subPageContent,
				Heading: this.state.subPageHeading,
				ListOrder: this.state.listOrder
			})
			.then(() => {
				toastr.success("Successfully updated bible entry");
				this.props.dispatch(poll({}));

				this.setState({ saving: false, page: "VIEW" });
				history.push(this.props.routerSuffix + "/" + heading);
			})
			.catch(() => {
				this.setState({ saving: false });
				toastr.warning("Failed to update bible entry, please try again");
			});
	};

	handleCreateClick = () => {
		toastr.info("Creating bible entry...");
		this.setState({ saving: true });
		const heading = this.state.subPageHeading;
		this.props
			.createBibleEntryAPIFn({
				Heading: heading,
				Body: this.state.subPageContent
			})
			.then(() => {
				toastr.success("Successfully created bible entry");
				this.props.dispatch(poll({}));

				this.setState({ saving: false, page: "VIEW" });
				history.push(this.props.routerSuffix + "/" + heading);
			})
			.catch(() => {
				this.setState({ saving: false });
				toastr.warning("Failed to create bible entry, please try again");
				// toastr.bad('Saving entry failed', null, { closeButton: true })
			});
	};

	handleDeleteClick = () => {
		toastr.info("Deleting bible entry...");
		this.setState({ saving: true });
		this.props
			.deleteBibleEntryAPIFn({
				Id: this.state.id
			})
			.then(() => {
				toastr.success("Successfully deleted bible entry");
				this.props.dispatch(poll({}));

				this.setState({ saving: false, page: "VIEW" });
				history.push(this.props.routerSuffix + "/newitem");
			})
			.catch(() => {
				this.setState({ saving: false });
				// toastr.bad('Saving entry failed', null, { closeButton: true })
				toastr.warning("Failed to delete bible entry, please try again");
			});
	};

	handleEditHeading = e => {
		const { value } = e.target;
		this.setState({ subPageHeading: value });
	};

	static findBibleIdFromPathName = props => {
		const subPageLocation = props.location.pathname.substring(
			props.routerSuffix.length + 1
		);

		if (subPageLocation === "newitem") {
			return 0;
		}
		let bibleId = 0;
		Object.keys(props.bibleEntriesFromPoll).forEach(id => {
			if (props.bibleEntriesFromPoll[id].Heading === subPageLocation) {
				bibleId = Number(id);
			}
		});
		return bibleId;
	};

	renderEdit() {
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-12">
						{/*Buttons*/}
						<AdminContainerMigrate
							buttons={[
								<button
									className="btn btn-primary col-xs-4 col-sm-2"
									onClick={this.handlePreviewClick}
								>
									<Eye /> Preview
								</button>
							]}
						/>
					</div>
				</div>
				{this.state.subPageHeading !== this.props.termsAndConditionsTitle && (
					<div className="row">
						{/*Heading*/}
						<div className="col-md-12">
							<h4 className="heading">Heading</h4>
							<input
								type="text"
								onChange={this.handleEditHeading}
								className="form-control"
								maxLength="26"
								style={{
									height: 50,
									fontSize: "2em",
									margin: "0.67em 0"
								}}
								value={this.state.subPageHeading}
							/>
						</div>
					</div>
				)}

				{/*Editor*/}
				<h4 className="heading">Body</h4>
				<InputHtml
					name={""}
					allowBulletPointInput={true}
					allowTextFormattingInput={true}
					allowColorInput={true}
					allowLinkInput={true}
					allowSizeInput={true}
					allowImageInput={true}
					value={this.state.subPageContent}
					onChange={e => {
						this.setState({ subPageContent: e.target.value });
					}}
				/>
			</div>
		);
	}

	renderView() {
		const buttons = [];

		if (this.state.createSwitch) {
			buttons.push(
				<LoadingButton
					loading={this.state.saving}
					className="btn btn-danger col-xs-4 col-sm-2"
					onClick={this.handleCreateClick}
					spinnerSize={14}
				>
					<Save /> Create
				</LoadingButton>
			);
		} else {
			buttons.push(
				<LoadingButton
					loading={this.state.saving}
					className="btn btn-success col-xs-4 col-sm-2"
					onClick={this.handleUpdateClick}
					spinnerSize={14}
				>
					<Save /> Save
				</LoadingButton>
			);
			if (this.state.id !== 1) {
				buttons.push(
					<LoadingButtonWithConfirm
						confirmAnchor="bottom-left"
						loading={this.state.saving}
						className="btn btn-danger col-xs-4 col-sm-2"
						onClick={this.handleDeleteClick}
						spinnerSize={14}
					>
						<Trash /> Delete
					</LoadingButtonWithConfirm>
				);
			}
		}

		buttons.push(
			<button
				className="btn btn-primary col-xs-4 col-sm-2"
				onClick={this.handleEditClick}
			>
				<Pencil /> Edit
			</button>
		);
		// put logic inside inline array?
		return (
			<div className="col-md-12">
				<ProtectedComponent allowedGroups={[userGroups.admin]}>
					<div className="row">
						<div className="col-md-12">
							{/*Buttons*/}
							<AdminContainerMigrate buttons={buttons} />
						</div>
					</div>
				</ProtectedComponent>
				<div className="row">
					{/*Heading*/}
					<span
						className="col-xs-8"
						style={{
							fontSize: "2em",
							margin: "0.67em 0"
						}}
					>
						{this.state.subPageHeading}
					</span>
				</div>

				{/*Content*/}
				<DisplayHtml value={this.state.subPageContent} />
			</div>
		);
	}

	render() {
		return (
			<div className="row">
				<Prompt
					when={this.state.page === "EDIT"}
					message={"Are you sure you want to navigate away from this page?"}
				/>
				<Spinner loading={this.props.pollLoading}>
					<div>
						{this.state.page === "VIEW" && this.renderView()}
						{this.state.page === "EDIT" && this.renderEdit()}
					</div>
				</Spinner>
			</div>
		);
	}
}

Bible.propTypes = {
	updateBibleEntryAPIFn: PropTypes.func,
	createBibleEntryAPIFn: PropTypes.func,
	deleteBibleEntryAPIFn: PropTypes.func,
	queryBibleEntriesAPIFn: PropTypes.func,
	routerSuffix: PropTypes.string, // venue_bible or skimpy_bible
	bibleEntriesFromPoll: PropTypes.object,
	termsAndConditionsTitle: PropTypes.string
};

const mapStateToProps = state => {
	return {
		pollLoading: pollLoading(state)
	};
};

export default connect(mapStateToProps)(Bible);
