import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { profileSelectorById } from "../../poll/selectors";
import ProfilePersonality from "../../components/text/ProfilePersonality";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import InputHtml from "../../components/input/InputHtml";
import { profilesPATCH } from "../../api/apiEndpoints";
import LoadingButton from "../../components/button/LoadingButton";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";

class Personality extends React.Component {
	render() {
		if (this.props.profile === undefined) {
			return null;
		}

		return (
			<div>
				<StandardModalWrapper
					heading={"Personality"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent
								allowedGroups={[userGroups.admin, userGroups.hrm]}
								allowedIfLoggedInAsUserId={this.props.profile.UserId}
							>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										Personality:
											this.props.profile.Personality === null
												? ""
												: this.props.profile.Personality,
										Id: this.props.clientId
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										profilesPATCH({
											Personality: formValues.Personality,
											Id: this.props.profileId
										})
											.then(() => {
												handleSubmitSuccess();
												handleCloseModal();
											})
											.catch(error => {
												handleSubmitFail();
												throw error;
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<InputHtml
													name={"Personality"}
													value={formValues.Personality}
													onChange={handleOnChange}
													validationMessages={formValidation.Personality}
												/>

												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButton
															onClick={handleSubmit}
															loading={submitting}
															disabled={!submitEnabled}
														>
															Save
														</LoadingButton>
													]}
												/>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>
				<h4 className="heading">Personality</h4>
				<div>
					<ProfilePersonality userId={this.props.profile.UserId} />
				</div>
			</div>
		);
	}
}

Personality.defaultProps = {
	profileId: null
};

Personality.propTypes = {
	profileId: PropTypes.number,
	profile: PropTypes.shape({
		Personality: PropTypes.string
	})
};

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile
	};
};

export default connect(mapStateToProps)(Personality);
