import * as React from "react";
import PropTypes from "prop-types";
import { availablePOST, transferPOST } from "../../../api/apiEndpoints";
import NatTime from "../../../components/text/NatTime";
import ClientNames from "../../../components/links/ClientNames";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import ProfileStageName from "../../../components/links/ProfileStageName";
import SkimpyList from "../../../components/select/SkimpyList";
import ResponsiveTableColumn from "../../../components/table/ResponsiveTableColumn";
import ValidationMessages from "../../../components/shift/validationMessages";
import axios from "axios";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { userGroups, userInfo } from "../../../lib/userInfo/userInfo";
import ResponsiveTableNew from "../../../components/table/ResponsiveTableNew";
import { lessThanApiDate } from "../../../lib/sort/sortByDate";
import { Check } from "../../../components/icons/Icons";

export default class TransferShift extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			transfers: [
				{
					ShiftId: props.shift.Id,
					UserId: undefined,
					Transferable: undefined,
					TransferOk: undefined,
					Messages: []
				}
			],
			submittable: false,
			overrideable: false,
			loading: false
		};
	}

	handleOnChange = transferIdx => {
		return e => {
			// When editing a previous transfer in the window, cleanup all rows after;
			let transfers = this.state.transfers.slice(0, transferIdx + 1);
			transfers[transferIdx].UserId = e.target.value;

			// Don't allow empty UserId to be submit to the API
			if (e.target.value === undefined) {
				return;
			}

			this.setState({
				transfers: transfers,
				loading: true,
				overrideable: true
			});

			transferPOST({ Action: "Dryrun", Transfers: transfers })
				.then(response => {
					this.updateTransfersWithShiftInformation(response.data, true);
				})
				.catch(err => {
					if (err.response.status === 400) {
						let newTransfers = err.response.data;

						newTransfers.forEach(transfer => {
							// If the transfer has no validaiton messages then move onto the next transfer.
							if (!("Messages" in transfer)) {
								// console.log("skipping ", transfer.ShiftId, 'with no validation messages')
								return;
							}

							transfer.Messages.forEach(message => {
								// If the validation message is for a shift which is already in the list of transfers then don't create a new row.
								const existing = newTransfers.find(transfer => {
									// console.log("skipping ", transfer.ShiftId, "as it's already in the transfer window")
									return transfer.ShiftId === message.ShiftId;
								});

								// When the message doesn't exist in the transfer window, add it as a new row.
								if (existing === undefined) {
									// console.log("adding ", message.ShiftId, 'from ', transfer.ShiftId)
									newTransfers.push({
										ShiftId: message.ShiftId,
										UserId: undefined
									});
								}
							});
						});

						this.updateTransfersWithShiftInformation(newTransfers, false);
					}
				});
		};
	};

	updateTransfersWithShiftInformation = (transfers, submittable) => {
		this.setState({ loading: true, submittable: submittable });

		const bulkAvailablePost = transfers.map(transfer => {
			return availablePOST({ ShiftId: transfer.ShiftId });
		});

		axios.all(bulkAvailablePost).then(availableResponse => {
			availableResponse.forEach(response => {
				const shift = response.data.Shifts[0];
				const transferIdx = transfers.findIndex(transfer => {
					return transfer.ShiftId === shift.Id;
				});
				transfers[transferIdx].Shift = shift;
			});

			this.setState({
				transfers: transfers,
				loading: false,
				submittable: submittable
			});
		});
	};

	handleOnSubmit = action => {
		// Remove any incomplete transfer lines before submitting
		const transfers = this.state.transfers.filter(transfer => {
			if (transfer.UserId !== undefined) {
				return true;
			}
			return false;
		});

		return () => {
			transferPOST({ Action: action, Transfers: transfers }).then(() => {
				this.props.handleCloseModal();
			});
		};
	};

	render() {
		let enabledTransferButtons = [
			<LoadingButtonWithConfirm
				className="btn btn-success"
				loading={this.state.loading}
				disabled={!this.state.submittable}
				onClick={this.handleOnSubmit("Transfer")}
			>
				<Check /> Transfer
			</LoadingButtonWithConfirm>
		];

		if (
			userInfo().userGroupId === userGroups.admin ||
			userInfo().userGroupId === userGroups.hrm
		) {
			enabledTransferButtons.push(
				<LoadingButtonWithConfirm
					className="btn btn-warning"
					disabled={!this.state.overrideable}
					onClick={this.handleOnSubmit("Override")}
				>
					<i className="fas fa-exclamation-triangle" /> Override
				</LoadingButtonWithConfirm>
			);
		}

		const colSpacing = [2, 3, 1, 2, 4];

		return (
			<div>
				<h4 className="heading">Shift Transfer</h4>

				<ResponsiveTableNew
					values={this.state.transfers}
					headings={[
						{
							label: "Current Shift Owner",
							size: colSpacing[0],
							disableSort: true
						},
						{
							label: "Shift",
							size: colSpacing[1],
							disableSort: true
						},
						{
							label: "",
							size: colSpacing[2],
							disableSort: true
						},
						{
							label: "Shift",
							size: colSpacing[3],
							disableSort: true
						},
						{
							label: "Issues Preventing Transfer",
							size: colSpacing[4],
							disableSort: true
						}
					]}
				>
					{() => {
						return this.state.transfers.map((transfer, transferIdx, transfers) => {
							let currentUserId = undefined;
							let clientName,
								time = "";
							if (transferIdx === 0) {
								currentUserId = this.props.shift.UserId;
								clientName = (
									<ClientNames clientIds={this.props.shift.ChildClientIds} />
								);
								time = (
									<NatTime
										startTime={this.props.shift.StartTime}
										endTime={this.props.shift.EndTime}
									/>
								);
							} else {
								currentUserId = transfers[transferIdx - 1].UserId;
								clientName = <ClientNames clientIds={transfer.Shift.ChildClientIds} />;
								time = (
									<NatTime
										startTime={transfer.Shift.StartTime}
										endTime={transfer.Shift.EndTime}
									/>
								);
							}

							let messageCol = null;
							if ("Messages" in transfer) {
								messageCol = (
									<ValidationMessages
										showSelectedShift={false}
										validation={transfer.Messages}
									/>
								);
							} else if (transfer.UserId !== undefined) {
								messageCol = (
									<i
										className="fas fa-check "
										style={{ color: "green", fontSize: 30 }}
									/>
								);
							} else {
								messageCol = null;
							}

							return (
								<div key={transferIdx} className="row div-table-responsive-row">
									<div className="col-sm-12">
										{/*
                            Current Shift Owner
                        */}
										<ResponsiveTableColumn
											size={colSpacing[0]}
											heading={"Current Shift Owner"}
										>
											{currentUserId && <ProfileStageName userId={currentUserId} />}
										</ResponsiveTableColumn>

										{/*
                            Shift Details
                        */}
										<ResponsiveTableColumn size={colSpacing[1]} heading={"Shift"}>
											<div>
												<div>{clientName}</div>
												<div>{time}</div>
												{/*SID: {transfer.ShiftId}*/}
											</div>
										</ResponsiveTableColumn>

										{/*
                            Arrow
                        */}
										<ResponsiveTableColumn size={colSpacing[2]} heading={""}>
											<i
												className="fas fa-long-arrow-right"
												style={{ color: "green", fontSize: 30 }}
											/>
										</ResponsiveTableColumn>

										{/*
                            UserId
                        */}
										<ResponsiveTableColumn size={colSpacing[3]} heading={"Shift"}>
											<SkimpyList
												name="UserId"
												value={transfer.UserId}
												onChange={this.handleOnChange(transferIdx)}
											/>
										</ResponsiveTableColumn>

										{/*
                            Validation
                        */}
										<ResponsiveTableColumn
											size={colSpacing[4]}
											heading={"Issues preventing transfer"}
										>
											{messageCol}
										</ResponsiveTableColumn>
									</div>
								</div>
							);
						});
					}}
				</ResponsiveTableNew>

				<AdminContainerMigrate align="right" buttons={enabledTransferButtons} />
			</div>
		);
	}
}

TransferShift.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};
