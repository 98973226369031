import {
	convertAPIDateToDDMMYYYY,
	convertJSDateToDDMMYYYY
} from "../lib/dateUtils/dateUtils";
import { action, get, observable, set } from "mobx";
import { availablePOST } from "../api/apiEndpoints";
import {
	IGETAvailableRequest,
	IGETAvailableResults
} from "../types/api/available";

class ShiftsState {
	@observable
	public liveSearchParams: IGETAvailableRequest;

	@observable
	public previousAvailableParams: IGETAvailableRequest;

	@observable
	public availableResults: IGETAvailableResults = {
		Shifts: [],
		Results: {
			Total: 0,
			UpdatedAt: undefined
		}
	};

	@observable
	public initialPollLoaded: boolean = false;

	@observable
	public showMultidayChildShifts: { [shiftId: string]: boolean } = {};

	@observable
	public isGettingShifts: boolean = false;

	@observable
	public shiftFilterOptions = [
		{ label: "All Shifts", value: "FILTER_ALL_SHIFTS" },
		{ label: "Available shifts", value: "FILTER_AVAILABLE_SHIFTS" },
		{ label: "My bookings", value: "FILTER_MY_BOOKED_SHIFTS" },
		{ label: "Shifts applied for", value: "FILTER_MY_PENDING_SHIFTS" }
	];

	public redrawFn: Function | null;

	constructor() {
		const initialShiftFilter = "FILTER_AVAILABLE_SHIFTS";

		this.liveSearchParams = {
			Limit: 500,
			Offset: 0,
			StartTime: convertJSDateToDDMMYYYY(new Date()),
			UpdatedAt: "",
			ClientId: undefined,
			UserId: undefined,
			TypeFilter: undefined,
			ShiftFilter: initialShiftFilter
		};
	}

	@observable
	public resetShifts = () => {
		this.redrawFn = null;
		this.availableResults.Shifts = [];
		this.availableResults.Results = {
			Total: 0,
			UpdatedAt: undefined
		};
	};

	public setRedrawFnOnce = (redrawFn: (id: number) => void) => {
		if (this.redrawFn) {
			return;
		}
		this.redrawFn = redrawFn;
	};

	public getShowMultidayChild = (shiftId: number): boolean => {
		// console.log("GET", shiftId, get(this.showMultidayChildShifts, String(shiftId)))
		return get(this.showMultidayChildShifts, String(shiftId));
	};

	@action
	public handleLoadMoreShiftsAfterDate = () => {
		const lastShift = this.availableResults.Shifts[
			this.availableResults.Shifts.length - 1
		] as any;
		this.liveSearchParams.StartTime = convertAPIDateToDDMMYYYY(
			lastShift.StartTime
		);
		this.getShifts();
	};

	@action
	public handleOnChangeToggleShowMultidayChildShifts = (shiftId: number) => {
		const shiftIdString = String(shiftId);

		set(
			this.showMultidayChildShifts,
			shiftIdString,
			!this.showMultidayChildShifts[shiftIdString]
		);

		// @ts-ignore
		this.redrawFn(shiftId);
	};

	@action
	public handleOnChangeFilterShiftFilter = (e: any) => {
		this.liveSearchParams.ShiftFilter = e.target.value;

	};
	@action
	public handleOnChangeFilterClientId = (e: any) => {
		this.liveSearchParams.ClientId = e.target.value;

	};
	@action
	public handleOnChangeFilterStartTime = (e: any) => {
		this.liveSearchParams.StartTime = e.target.value;

	};
	@action
	public handleOnChangeFilterUserId = (e: any) => {
		this.liveSearchParams.UserId = e.target.value;

	};
	@action
	public handleOnChangeFilterTypeFilter = (e: any) => {
		this.liveSearchParams.TypeFilter = e.target.value;
;
	};

	@action
	getShifts = () => {
		this.isGettingShifts = true;

		this.liveSearchParams.UpdatedAt = "";
		// If the search changed ie 'load more', return to the top of the page
		if (this.equal(this.liveSearchParams, this.previousAvailableParams)) {
			window.scrollTo(0, 0);
			this.liveSearchParams.UpdatedAt = this.availableResults.Results.UpdatedAt;
		}
		availablePOST(this.liveSearchParams as any)
			.then(response => {
				if (response.status === 204) {
					this.isGettingShifts = false;
					return;
				}

				if (response.data.Shifts === null) {
					response.data.Shifts = [];
				}

				response.data.Shifts.forEach((shift: any) => {
					const shiftIdString = String(shift.Id);
					if (!(shiftIdString in this.showMultidayChildShifts)) {
						set(this.showMultidayChildShifts, shiftIdString, true);
					}
				});

				this.availableResults = response.data as IGETAvailableResults;
				this.isGettingShifts = false;

				this.previousAvailableParams = JSON.parse(
					JSON.stringify(this.liveSearchParams)
				);
				// this.liveSearchParams.UpdatedAt = this.availableResults.Results.UpdatedAt;
			})
			.catch(() => {
				this.isGettingShifts = false;
			});
	};

	equal = (obj1: any, obj2: any): boolean => {
		if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
			return true;
		}
		return false;
	};
}

export const shiftsState = new ShiftsState();
// @ts-ignore
window.shiftState = shiftsState;
