import * as React from "react";
import { Trash } from "../icons/Icons";
import {validate} from './UploadValidator'

class UploadInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: undefined,
			inputRef: undefined,
			err: undefined
		};
	}

	onClear() {
		this.setState({ ...this.state, file: undefined });
		if (this.props && this.props.onSelected) {
			this.props.onSelected(null);
		}
	}

	onSelected(files) {
		if (files && files.length) {
			this.setState({ ...this.state, file: files[0] }, () => this.validateFile(this.state.file));
			if (this.props && this.props.onSelected) {
				this.props.onSelected(files[0]);
			}
		}
	}

	handleInputClick() {
		console.log(this.state);
		if (this.state && this.state.inputRef) {
			this.state.inputRef.click();
		}
	}

	async validateFile(file) {
		if (!!file) {
			try {
				const accepts = (`${this.props.accepts}` || '').split(',')
				console.log('validateFile', file, accepts)

				const valid = await validate(file, accepts)
				if (!valid) {
					this.setState({...this.state, err: new Error('Invalid file type or extension, expected ', this.props.accepts)})
				} else {
					this.setState({...this.state, err: undefined})
				}
				console.log(this.state)
			} catch(err) {
				console.error(err)
				this.setState({...this.state, err})
			}
		}
	}

	render() {
		return (
			<div>
				<input
					ref={r =>
						!this.state.inputRef && this.setState({ ...this.state, inputRef: r })
					}
					onChange={e => this.onSelected(e.target.files)}
					type="file"
					multiple={false}
					name="FileUploadInput"
					style={{ display: "none" }}
          accept={this.props.accepts || '*/*'}
				/>
				{this.state && this.state.file ? (
					<React.Fragment>
						<button onClick={() => this.onClear()} className="btn btn-danger">
							<Trash style={{ cursor: "pointer" }} /> Clear
						</button>{" "}
						{this.props.showFileName && this.state.file.name}
						{!!this.state.err ? <><br/><label>{this.state.err.message}</label></> : []}
					</React.Fragment>
				) : (
					<button
						className="btn btn-primary"
						onClick={() => this.handleInputClick()}
					>
						Select File
					</button>
				)}
			</div>
		);
	}
}

export default UploadInput;
