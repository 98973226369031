import * as React from "react";
import { connect } from "react-redux";
import "./home.css";
import { redirectHomeIfLoggedIn } from "../loginScreen/actions";
import { checkAndSetTokenFromLocalStorage } from "../auth/actions";
import { routes } from "../router/router";
import { NavLink } from "react-router-dom";
import FireFlies from "../components/Fireflies/Fireflies";

class Home extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.dispatch(checkAndSetTokenFromLocalStorage());
		this.props.dispatch(redirectHomeIfLoggedIn());
	}

	render() {

		const links = {
			color: "white",
			textDecoration: "underline",
		}

		const badge = {
			height: 64,
			padding: 8
		}

		return (
			<div className="home_wrapper">
				<FireFlies />
				<div id="main_container">
					<div className="content_container">
						

						<div className="center_container">

							<div id="logo_container">
								<img src="/assets/home/White-noBG.png"/>
							</div>

							<div className="home login-btn">
								<NavLink to={routes.Login}>
								<span id="login_button">
									Skimpies and Venues Only login
								</span>
								</NavLink>
							</div>

							<label className="home center-label">PUBLIC SKIMPY SEARCH</label>
							<a href="https://www.skimpyheaven.com.au" target="_blank" rel="noreferrer">www.skimpyheaven.com.au</a>
							<label className="home center-label">SKIMPY SHOP</label>
							<a href="https://www.shopskimpyheaven.com.au" target="_blank" rel="noreferrer">www.shopskimpyheaven.com.au</a>
							<label className="home center-label">GUEST BAR STAFF</label>
							<a href="https://www.guestbarstaff.com.au" target="_blank" rel="noreferrer">www.guestbarstaff.com.au</a>
							<label className="home center-label">STUDIO AND VENUE FOR HIRE</label>
							<a href="tel://+61417079397">Enquire to 0417 079 397</a>
						</div>


					</div>
					<div className="home headquarters">
						<label>Skimpy Headquarters</label>
						<div>1 / 35 Guthrie Street</div>
						<div>Osborne Park WA 6019</div>
						<a href="tel://+61435754679">Call 0435 754 679</a>
					</div>
					<div className="home social">
						<a href="https://www.facebook.com/skimpy.heaven/" target="_blank"><img style={badge} src="/assets/home/facebook.png"/></a>
						<a href="https://www.instagram.com/skimpyheaven/" target="_blank"><img style={badge} src="/assets/home/insta.png"/></a>
					</div>
				</div>
			</div>
		);
	}
}

Home.propTypes = {};

const mapStateToProps = (state, props) => {
	return {};
};

Home = connect(mapStateToProps)(Home);
export default Home;
