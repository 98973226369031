import * as React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import "./ResponsiveTable.css";
import Select from "../select/Select";

export const sortDirectionOptions = {
	ascending: "ASC",
	decending: "DESC"
};

export default class ResponsiveTableNew extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sortedColumnName: undefined,
			sortDirection: undefined,
			values: [],
			sortFns: {},
			pagination: {
				resultsPerPage: props.resultsPerPage,
				pageNumber: 0
			}
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad(nextProps) {
		if (nextProps.values === undefined) {
			return;
		}

		const sortFns = nextProps.headings.reduce((sortFns, current) => {
			// Exclude headings that are missing a sort function
			if (current.sortDescFunc === undefined) {
				return sortFns;
			}
			sortFns[current.name] = current.sortDescFunc;
			return sortFns;
		}, {});

		let values = [];
		let initPropsToState = {};
		if (nextProps.initialSortColumn) {
			initPropsToState = {
				sortedColumnName: nextProps.initialSortColumn,
				sortDirection: nextProps.initialSortDirection
			};

			values = this.sortValues(
				nextProps.initialSortColumn,
				nextProps.initialSortDirection,
				nextProps.values,
				sortFns[nextProps.initialSortColumn]
			);
		} else {
			values = nextProps.values;
		}

		this.setState({
			sortFns: sortFns,
			values: values,
			pagination: {
				...this.state.pagination,
				totalPages: values.length / this.state.pagination.resultsPerPage,
				pageNumber: 0
			},
			...initPropsToState
		});
	}

	sortValues = (name, direction, values, sortFn = undefined) => {
		if (sortFn !== undefined) {
			// Sort the values by their respective sort function
			values.sort((a, b) => {
				return sortFn(a[name], b[name]);
			});
		} else {
			// Sort the values
			values.sort((a, b) => {
				return String(a[name]).localeCompare(String(b[name]));
			});
		}
		// Flip the results if ascending
		if (direction === sortDirectionOptions.ascending) {
			values = values.reverse();
		}

		return values;
	};

	handleSortValues = e => {
		const name = e.currentTarget.name;
		const direction = e.currentTarget.value;

		this.setState({
			values: this.sortValues(
				name,
				direction,
				this.props.values,
				this.state.sortFns[name]
			),
			sortedColumnName: name,
			sortDirection: direction
		});
	};

	renderHeading(heading) {
		const { name, label, disableSort } = heading;

		if (disableSort) {
			return (
				<button className="btn-clear" disabled={true}>
					{label}
				</button>
			);
		}

		if (name === this.state.sortedColumnName) {
			if (this.state.sortDirection === sortDirectionOptions.decending) {
			} else if (this.state.sortDirection === sortDirectionOptions.ascending) {
			}
		}

		const sortButton = (icon, direction, colourize = false) => {
			let classNames = "fas " + icon;
			if (colourize) {
				classNames += " color-fail";
			}
			return (
				<button
					onClick={this.handleSortValues}
					name={name}
					value={direction}
					className="btn-clear"
				>
					{label} <i className={classNames} />
				</button>
			);
		};

		// Not currently sorted
		if (name !== this.state.sortedColumnName) {
			return sortButton("fa-sort", sortDirectionOptions.decending);
		}

		if (this.state.sortDirection === sortDirectionOptions.decending) {
			return sortButton("fa-sort-up", sortDirectionOptions.ascending, true);
		} else if (this.state.sortDirection === sortDirectionOptions.ascending) {
			return sortButton("fa-sort-down", sortDirectionOptions.decending, true);
		}
	}

	renderPagination = () => {
		return (
			<div>
				<ReactPaginate
					forcePage={this.state.pagination.pageNumber}
					previousLabel={"previous"}
					nextLabel={"next"}
					breakLabel={null}
					pageCount={this.state.pagination.totalPages}
					marginPagesDisplayed={1}
					pageRangeDisplayed={5}
					onPageChange={e => {
						this.setState({
							pagination: {
								...this.state.pagination,
								pageNumber: e.selected
							}
						});
					}}
					containerClassName={"pagination"}
					subContainerClassName={"pages pagination"}
					activeClassName={"active"}
				/>
				{/*Padding to match react-paginate*/}
				<span style={{ float: "right", padding: "20px 3px" }}>
					<Select
						options={[
							{
								label: "10",
								value: 10
							},
							{
								label: "25",
								value: 25
							},
							{
								label: "50",
								value: 50
							},
							{
								label: "75",
								value: 75
							},
							{
								label: "100",
								value: 100
							}
						]}
						value={this.state.pagination.resultsPerPage}
						clearable={false}
						onChange={e => {
							this.setState({
								pagination: { ...this.state.pagination, resultsPerPage: e.target.value }
							});
						}}
					/>
				</span>
				<span
					style={{
						float: "right",
						padding: "25px 3px",
						fontSize: 16,
						color: "grey"
					}}
				>
					Items per page
				</span>
			</div>
		);
	};

	paginateResults = results => {
		return results.slice(
			this.state.pagination.resultsPerPage * this.state.pagination.pageNumber,
			this.state.pagination.resultsPerPage * (this.state.pagination.pageNumber + 1)
		);
	};

	render() {
		let values = this.state.values;
		if (
			this.props.showPagination &&
			this.state.values.length > this.state.pagination.resultsPerPage
		) {
			values = this.paginateResults(this.state.values);
		}

		return (
			<div className="row">
				<div className="col-sm-12">
					<div
						className="hidden-xs row div-table__thead div-table-responsive"
						style={{ fontWeight: "bold" }}
					>
						<div className="col-sm-12">
							{this.props.headings.map((heading, headingIdx) => {
								return (
									<div key={headingIdx} className={"col-sm-" + heading.size}>
										{this.renderHeading(heading)}
									</div>
								);
							})}
						</div>
					</div>
					{this.props.children({
						sortedValues: values
					})}
				</div>
				{this.props.showPagination &&
					this.state.values.length > this.state.pagination.resultsPerPage && (
						<div>{this.renderPagination()}</div>
					)}
			</div>
		);
	}
}

ResponsiveTableNew.defaultProps = {
	initialSortDirection: sortDirectionOptions.decending,
	showPagination: false,
	resultsPerPage: 10
};

ResponsiveTableNew.propTypes = {
	headings: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			name: PropTypes.string, // matches a key on props.values
			size: PropTypes.number, // column size if visible
			sortDescFunc: PropTypes.func, // custom sort function (a, b) => {return a > b;}
			disableSort: PropTypes.bool
		})
	),
	resultsPerPage: PropTypes.number,
	values: PropTypes.arrayOf(PropTypes.object),
	initialSortColumn: PropTypes.string,
	initialSortDirection: PropTypes.string,
	showPagination: PropTypes.bool
};
