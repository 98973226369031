import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clientSelectorById } from "../../poll/selectors";
import { Link } from "react-router-dom";

class ClientNames extends React.Component {
	render() {
		return (
			<span>
				{this.props.clientIds.map(clientId => {
					return (
						<Link key={clientId} to={"/venues/" + clientId}>
							{this.props.clientsById[clientId].ClientName}
						</Link>
					);
				})}
			</span>
		);
	}
}

ClientNames.propTypes = {
	clientIds: PropTypes.arrayOf(PropTypes.number).isRequired,
	clientsById: PropTypes.object
};

const mapStateToProps = state => {
	return {
		clientsById: clientSelectorById(state)
	};
};

export default connect(mapStateToProps)(ClientNames);
