import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";

class ClientRegularReappearance extends React.Component {
	render() {
		if ("FavouriteReappearance" in this.props.client) {
			return <span>{this.props.client.FavouriteReappearance} Days.</span>;
		}

		return null;
	}
}

ClientRegularReappearance.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ClientRegularReappearance);
