import * as React from "react";
import PropTypes from "prop-types";
import { formatShiftDiff } from "../../../lib/formatting/shiftDiffs";
import ResponsiveTableNew from "../../../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../../../components/table/ResponsiveTableColumn";
import NatTime from "../../../components/text/NatTime";
import ProfileStageName from "../../../components/links/ProfileStageName";
import "./DryRunSeries.css";

class DryRunSeries extends React.Component {
	constructor(props) {
		super(props);
	}

	renderDiffLabel = fieldName => {
		const result = fieldName.replace(/([A-Z])/g, " $1");
		return result.charAt(0).toUpperCase() + result.slice(1);
	};

	renderDiffValue = (fieldName, value) => {
		switch (fieldName) {
			case "StartTime":
			case "EndTime":
				return <NatTime startTime={value} />;
			default:
				return value;
		}
	};

	render() {
		if (this.props.dryRunResults.length === 0) {
			return null;
		}
		return this.props.dryRunResults.map((shift, shiftIdx) => {
			const formattedDiffs = formatShiftDiff(shift.Diff);

			return (
				<div key={shiftIdx} className="DryRunSeries-Row">
					<h4 style={{ color: "#733334" }}>
						<NatTime startTime={shift.StartTime} />
						{shift.UserId && (
							<span>
								{" "}
								- <ProfileStageName userId={shift.UserId} />
							</span>
						)}
					</h4>

					<div className="col-sm-12">
						<ResponsiveTableNew
							headings={[
								{
									label: "Field",
									name: "Field",
									size: 2,
									disableSort: true
								},
								{
									label: "Was",
									name: "Before",
									size: 5,
									disableSort: true
								},
								{
									label: "Now",
									name: "After",
									size: 5,
									disableSort: true
								}
							]}
						>
							{() => {
								return formattedDiffs.map((diff, diffIdx) => {
									return (
										<div key={diffIdx} className="row div-table-responsive-row">
											<div className="col-sm-12">
												<ResponsiveTableColumn size={2} heading={"Field"}>
													{this.renderDiffLabel(diff.Field)}
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={5} heading={"Was"}>
													{this.renderDiffValue(diff.Field, diff.Before)}
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={5} heading={"Now"}>
													{this.renderDiffValue(diff.Field, diff.After)}
												</ResponsiveTableColumn>
											</div>
										</div>
									);
								});
							}}
						</ResponsiveTableNew>
					</div>
				</div>
			);
		});
	}
}

DryRunSeries.propTypes = {
	dryRunResults: PropTypes.array
};

export default DryRunSeries;
