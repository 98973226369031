import * as React from "react";
import { connect } from "react-redux";
import MiniContainer from "../components/container/MiniContainer";
import { clientFeedbackPOST, clientLineupByClientIdGET } from "../api/apiEndpoints";
import { userInfo } from "../lib/userInfo/userInfo";
import { profileByUserId, selectClientUserLists, userSelectorById } from "../poll/selectors";
import { parseAPIDate } from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import NatTime from "../components/text/NatTime";
import { ClientUserListTypes } from "../components/clientUserList/ClientUserList";
import FiveStars from "../components/FiveStars/FiveStars";
import FormManager from "../components/form/FormManager";
import InputTextArea from "../components/input/InputTextArea";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import PhoneNumberLink from "../components/links/PhoneNumberLink";
import ReappearanceList from "../components/select/ReappearanceList";
import LoadingButton from "../components/button/LoadingButton";
import ReappearanceText from "../components/text/ReappearanceText";
import DisplayHtml from "../components/html/DisplayHtml";
import NatTimePop from "./components/NatTimePop";
import { getUploadsBySectionType } from "./lib/commonAPICalls";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import RenderMainProfilePicture from "./components/RenderMainProfilePicture";
import RenderRSA from "./components/RenderRSA";
import Spinner from "../components/loading/Spinner";

class ClientFeedback extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lineup: [],
			uploadsByProfileIdByUploadSectionType: {},
			loading: true
		};
	}

	componentDidMount() {
		this.refreshFeedback();

		getUploadsBySectionType().then(uploadsByProfileIdByUploadSectionType => {
			this.setState({
				uploadsByProfileIdByUploadSectionType
			});
		});
	}

	refreshFeedback = () => {
		// this.onload(this.props);
		clientLineupByClientIdGET({ Id: userInfo().clientId }).then(r => {
			const d = r.data || [];

			this.setState({ lineup: d, loading: false });
		});
	};

	isUserInList = (userId, list) => {
		const clList = this.props.clientUserLists.byClientId[list][
			userInfo().clientId
		];

		if (clList === undefined) {
			return false;
		}

		for (let i = 0; i < clList.length; i++) {
			if (clList[i].UserId === userId) {
				return true;
			}
		}
		return false;
	};

	renderCurrentUserStatus = userId => {
		const stageName = this.props.profilesByUserId[userId].StageName;

		if (this.isUserInList(userId, ClientUserListTypes.Favourites)) {
			return (
				<div style={{ color: "#324899" }}>{stageName} is currently a Favourite</div>
			);
		}
		if (this.isUserInList(userId, ClientUserListTypes.Preapprovals)) {
			return (
				<div style={{ color: "#324899" }}>
					{stageName} is currently Pre Approved
				</div>
			);
		}

		return null;
	};

	getInitReappearance = userId => {
		let initReappearance = undefined;
		if (this.isUserInList(userId, ClientUserListTypes.Favourites)) {
			initReappearance = 1;
		} else if (this.isUserInList(userId, ClientUserListTypes.Preapprovals)) {
			initReappearance = 2;
		}
		return initReappearance;
	};

	render() {
		if (this.state.loading) {
			return <Spinner loading={true} />;
		}

		if (objectIsEmpty(this.props.usersById)) {
			return null;
		}

		let lineup = this.state.lineup;
		const startOfDay = DateFns.startOfDay(new Date());
		const startOfDayEpoch = startOfDay.getTime();

		const fourMonthsAgo = DateFns.addMonths(startOfDay, -4);
		const fourMonthsAgoEpoch = fourMonthsAgo.getTime();

		lineup = lineup.filter(row => {
			const shiftTimeEpoch = parseAPIDate(row.StartTime).getTime();

			return (
				fourMonthsAgoEpoch < shiftTimeEpoch &&
				startOfDayEpoch > shiftTimeEpoch &&
				row.UserId !== null
			);
		});

		lineup.sort((a, b) => {
			// Newest entries at the top
			return (
				parseAPIDate(b.StartTime).getTime() - parseAPIDate(a.StartTime).getTime()
			);
		});

		return (
			<MiniContainer>
				{lineup.map((row, idx) => {
					let content = null;
					const profile =
						row.UserId in this.props.profilesByUserId
							? this.props.profilesByUserId[row.UserId]
							: undefined;

					if (!row.FeedbackLeft) {
						content = (
							<div key={idx} className="row lineup-row">
								<FormManager
									initialFormValues={{
										Rating: 0,
										Reappear: this.getInitReappearance(row.UserId),
										Notes: ""
									}}
									handleValidation={v => {
										v.required({
											fieldNames: ["Reappear"]
										});
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										clientFeedbackPOST({
											ShiftId: row.ShiftId,
											Reappear: formValues.Reappear,
											Rating: formValues.Rating,
											Notes: formValues.Notes
										})
											.then(() => {
												handleSubmitSuccess();
												this.refreshFeedback();
											})
											.catch(() => {
												handleSubmitFail();
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<div className="col-sm-2">
													<RenderMainProfilePicture
														profileId={profile.Id}
														uploadsByProfileIdByUploadSectionType={
															this.state.uploadsByProfileIdByUploadSectionType
														}
													/>
												</div>
												<div className="col-sm-10">
													<div className="row">
														<h4>{this.props.profilesByUserId[row.UserId].StageName}</h4>
													</div>

													<div className="row">
														<RenderRSA
															stageName={profile.StageName}
															profileId={profile.Id}
															uploadsByProfileIdByUploadSectionType={
																this.state.uploadsByProfileIdByUploadSectionType
															}
														/>
													</div>

													<div className="row">
														<NatTimePop startTime={row.StartTime} endTime={row.EndTime} />
													</div>

													<div className="row">
														<FiveStars
															size={50}
															name={"Rating"}
															value={formValues.Rating}
															handleOnChange={handleOnChange}
														/>
													</div>
													<div className="row">
														<b>
															How often would you like this skimpy to return to your venue?
														</b>
														{this.renderCurrentUserStatus(row.UserId)}
													</div>
													<div className="row">
														<ReappearanceList
															name={"Reappear"}
															value={formValues.Reappear}
															onChange={handleOnChange}
														/>
													</div>

													<div className="row">
														We highly value your constructive comments, especially when not a
														5 star review. Please detail how we could improve below
													</div>
													<div className="row">
														<InputTextArea
															name="Notes"
															value={formValues.Notes}
															onChange={handleOnChange}
															rows={3}
														/>
													</div>

													<div className="row">
														<AdminContainerMigrate
															align={"right"}
															buttons={[
																<LoadingButton
																	className="btn btn-info"
																	onClick={handleSubmit}
																	disabled={!submitEnabled}
																	loading={submitting}
																>
																	<i className="fas fa-envelope" /> Leave feedback
																</LoadingButton>
															]}
														/>
													</div>
													<div className="row">
														Your feedback is released to skimpy contractors one week after you
														submit it. To change the reappearance rules for favourites and
														pre-approved skimpies, please contact Skimpy Heaven on{" "}
														<PhoneNumberLink phoneNumber={"0435754679"} />.
													</div>
												</div>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					} else {
						content = (
							<div key={idx} className="row lineup-row lineup-row-disabled">
								<div>
									<div className="col-sm-2">
										<RenderMainProfilePicture
											profileId={profile.Id}
											uploadsByProfileIdByUploadSectionType={
												this.state.uploadsByProfileIdByUploadSectionType
											}
										/>
									</div>
									<div className="col-sm-10">
										<div className="row">
											<h4>{this.props.profilesByUserId[row.UserId].StageName}</h4>
										</div>

										<div className="row">
											<RenderRSA
												stageName={profile.StageName}
												profileId={profile.Id}
												uploadsByProfileIdByUploadSectionType={
													this.state.uploadsByProfileIdByUploadSectionType
												}
											/>
										</div>

										<div className="row">
											<NatTime startTime={row.StartTime} endTime={row.EndTime} />
										</div>

										<div className="row">
											<FiveStars size={50} value={row.FeedbackRating} disabled={true} />
										</div>

										<div className="row">
											<ReappearanceText value={row.FeedbackReappearance} />
										</div>

										<div className="row">
											<DisplayHtml value={row.FeedbackNotes} />
										</div>

										{row.AuthId !== null && (
											<div className="row">
												<span style={{ textAlign: "right" }} className="pull-right">
													Feedback left by: <br />
													<b>
														{this.props.usersById[row.AuthId] === undefined ? (
															<span style={{ textDecoration: "line-through", color: "red" }}>
																deleted user
															</span>
														) : (
															this.props.usersById[row.AuthId].Email
														)}
													</b>
													<br />
													<NatTime startTime={row.CreatedAt} />
												</span>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					}
					return content;
				})}
			</MiniContainer>
		);
	}
}

ClientFeedback.propTypes = {};

const mapStateToProps = state => {
	return {
		profilesByUserId: profileByUserId(state),
		clientUserLists: selectClientUserLists(state),
		usersById: userSelectorById(state)
	};
};

ClientFeedback = connect(mapStateToProps)(ClientFeedback);
export default ClientFeedback;
