import React from "react";
import "./MiniContainer.css";

const MiniContainer = ({className = "", children = [], ...rest}) => {
	return (
		<div className={["mini-container", className].join(' ')} style={{ marginBottom: 15 }}>
			{children}
		</div>
	);
}

export default MiniContainer