import * as React from "react";
import PropTypes from "prop-types";

export default class ResponsiveTableColumn extends React.Component {
	render() {
		const children = this.props.children;

		if (children === null) {
			return null;
		}

		let classNames = [];

		classNames.push("col-sm-" + this.props.size);
		if (this.props.offsetSize) {
			classNames.push("col-sm-offset-" + this.props.offsetSize);
		}

		return (
			<div>
				{/*Mobile view*/}
				<div className="row visible-xs">
					<div style={{ textAlign: "right", paddingRight: 4 }} className="col-xs-5">
						{this.props.heading && <b>{this.props.heading}</b>}
					</div>
					<div style={{ minHeight: 1, paddingLeft: 4 }} className="col-xs-7">
						{children}
					</div>
				</div>

				{/*Desktop view*/}
				<div className="hidden-xs">
					<div style={{ minHeight: 1 }} className={classNames.join(" ")}>
						{children}
					</div>
				</div>
			</div>
		);
	}
}

ResponsiveTableColumn.propTypes = {
	heading: PropTypes.string,
	size: PropTypes.number.isRequired,
	offsetSize: PropTypes.number
};
