import * as React from "react";
import PropTypes from "prop-types";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import VenueGallery from "./VenueGallery";
import { uploadSections } from "../../api/apiEndpoints";

class RenderProofPicture extends React.PureComponent {
	static propTypes = {
		profileId: PropTypes.number.isRequired,
		uploadsByProfileIdByUploadSectionType: PropTypes.object
	};

	render() {
		if (
			!(this.props.profileId in this.props.uploadsByProfileIdByUploadSectionType)
		) {
			return "Proof Picture Unavailable";
		}

		if (
			!(
				uploadSections.profile_proof in
				this.props.uploadsByProfileIdByUploadSectionType[this.props.profileId]
			)
		) {
			return "Proof Picture Unavailable";
		}

		return (
			<StandardModalWrapper
				modalOpener={({ handleOpenModal }) => {
					return (
						<button className={"btn-clear color-nice-blue"} onClick={handleOpenModal}>
							View Proof Picture
						</button>
					);
				}}
				render={({ handleCloseModal }) => {
					const upload = this.props.uploadsByProfileIdByUploadSectionType[
						this.props.profileId
					][uploadSections.profile_proof]
					return (
						<div className="row">
							<h2 className="color-fail text-center">NOT FOR PUBLIC PROMOTION</h2>
							<div
								style={{ display: "flex", justifyContent: "center", width: "100%" }}
							>
								<UploadRenderer
									uploadId={this.props.profileId}
									src={
										upload.ThumbnailPath || upload.Path
									}
								/>
							</div>
						</div>
					);
				}}
			/>
		);
	}
}

export default RenderProofPicture;
