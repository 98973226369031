import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { profilesAsArray as profileSelector, userSelectorById } from "../../poll/selectors";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";

const ADMIN = 4
const HRM = 6
const SHOW_ALL_TO = [ADMIN, HRM]
const hideRegex = new RegExp(/^admin|aaa/i)

const SkimpyList = ({ onChange, name, value, inactiveUsersVisible = false, hideUserIds = [] }) => {
	const profilesAsArray = useSelector(state => profileSelector(state))
	const usersById = useSelector(state => userSelectorById(state))
	
	const isAdminOrHrm = useMemo(() => {
		const jwt = localStorage.getItem('authToken')
		if(jwt) {
			const chunks = jwt.split('.')
			const decoded = JSON.parse(atob(chunks[1]))
			
			return SHOW_ALL_TO.indexOf(decoded.UserGroupId) > -1
		}
		return false
	}, [])

	const displayedProfiles = useMemo(() => {
		if (!profilesAsArray || !profilesAsArray.length) {
			return []
		}
		let tmp = [...profilesAsArray]
		if (!inactiveUsersVisible) {			
			tmp = tmp.filter(p => usersById[p.UserId].UserGroupId != 0 || p.UserId === value)
		}
		if (hideUserIds && hideUserIds.length) {
			const hideUserDict = hideUserIds.reduce((tmp, cur) => ({...tmp, [cur]: true}), {})
			tmp = tmp.filter(profile => !hideUserDict[profile.UserId])
		}
		const sorted = tmp.sort((a, b) => a.StageName.localeCompare(b.StageName))
		if (isAdminOrHrm) {
			return sorted
		}
		return sorted.filter(f => !hideRegex.test(f.StageName))
	}, [profilesAsArray, inactiveUsersVisible, hideUserIds, value, isAdminOrHrm])

	const inactiveUsersSelected = useMemo(() => {
		return profilesAsArray && profilesAsArray.length && !inactiveUsersVisible ? profilesAsArray.find(f => f.userId === value && usersById[f.UserId].Group === 0) || {} : {}
	}, [profilesAsArray, inactiveUsersVisible])

	const options = useMemo(() => {
		const sorted = (displayedProfiles || [])
			.map(m => ({ value: m.UserId, label: m.StageName }))
		if (Object.keys(inactiveUsersSelected).length > 0) {
			return [
				{ value: inactiveUsersSelected.UserId, label: `${inactiveUsersSelected.StageName} - INACTIVE` },
				...sorted
			]
		}
		return sorted
	}, [displayedProfiles])

	return (
		<Select
			options={options}
			name={name}
			value={value}
			onChange={onChange}
			defaultOptionValue={0}
		/>
	);

}

export default SkimpyList;
