import * as React from "react";
import PropTypes from "prop-types";

export default class InputText extends React.Component {
	render() {
		return (
			<div>
				<input
					type="text"
					disabled={this.props.disabled}
					name={this.props.name}
					className={this.props.inputClassName}
					onChange={this.props.onChange}
					value={this.props.value}
				/>

				{this.props.validationMessages &&
					this.props.validationMessages.map((message, idx) => {
						return (
							<div className={this.props.validaitonClassName} key={idx}>
								{message}
							</div>
						);
					})}
			</div>
		);
	}
}

InputText.defaultProps = {
	inputClassName: "form-control",
	validaitonClassName: "form-validation-box",
	disabled: false
};

InputText.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	inputClassName: PropTypes.string,
	validaitonClassName: PropTypes.string,
	validationMessages: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool
};
