import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import { findClientById } from "../../poll/selectors";
import FormManager from "../../components/form/FormManager";
import SelectBrand from "../../components/select/SelectBrand";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import LoadingButton from "../../components/button/LoadingButton";
import FormRow from "../../components/form/FormRow";
import { clientsPATCH } from "../../api/apiEndpoints";
import { Save, Sync } from "../../components/icons/Icons";

class VenueAdminBar extends React.Component {
	state = {};

	static propTypes = {
		clientId: PropTypes.number.isRequired
	};

	render() {
		return (
			<div className="admin-box">
				<StandardModalWrapper
					heading={"Change Brand"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<button
								className="admin-box-button btn btn-primary"
								onClick={handleOpenModal}
							>
								<Sync /> Change Brand
							</button>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<FormManager
								initialFormValues={{
									BrandId: this.props.venue.BrandId
								}}
								handleSubmit={({
									formValues,
									handleSubmitFail,
									handleSubmitSuccess
								}) => {
									clientsPATCH({
										Id: this.props.clientId,
										BrandId: formValues.BrandId
									})
										.then(() => {
											handleSubmitSuccess();
											handleCloseModal();
										})
										.catch(error => {
											handleSubmitFail();
											throw error;
										});
								}}
							>
								{/*FormManager children*/}
								{({
									formValues,
									handleOnChange,
									handleSubmit,
									formValidation,
									submitEnabled,
									submitting
								}) => {
									return (
										<div>
											<FormRow
												label={"Brand"}
												value={
													<SelectBrand
														onChange={handleOnChange}
														name={"BrandId"}
														value={formValues.BrandId}
													/>
												}
											/>
											<AdminContainerMigrate
												align={"right"}
												buttons={[
													<LoadingButton
														onClick={handleSubmit}
														loading={submitting}
														disabled={!submitEnabled}
													>
														<Save /> Save
													</LoadingButton>
												]}
											/>
										</div>
									);
								}}
							</FormManager>
						);
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return { venue: findClientById(state, props.clientId) };
};

VenueAdminBar = connect(mapStateToProps)(VenueAdminBar);
export default VenueAdminBar;
