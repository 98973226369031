import React, {useContext} from "react";
import ProfileStageName from '../../components/links/ProfileStageName';
import SearchBarItem from "../../components/searchBar/SearchBarItem";
import SkimpyList from "../../components/select/SkimpyList";
import Money from "../../components/text/Money";
import { AdminPaymentsContext } from '../AdminPaymentsContext';

const SearchBar = () => {
	const { userId, history, currentCredit } = useContext(AdminPaymentsContext);
    const onSkimpyChanged = e => {
        if (e && e.target && e.target.value) {
            history.push('/admin_accounts?userId=' + e.target.value)
        }
    }
	return (
		<div>
			<div className="mini-container">
				<h4 className="heading">Search</h4>
				<div className="row">
					<SearchBarItem
						label={"Skimpy"}
						value={
							<SkimpyList
								name="UserId"
								dayPickerOnly={true}
								onChange={onSkimpyChanged}
								value={userId}
							/>
						}
					/>

					{userId && (
						<SearchBarItem
							label={"End Balance"}
							value={<Money negativeValuesAreRed={true} value={currentCredit} />}
						/>
					)}

					{userId && (
						<SearchBarItem
							label={"Profile Link"}
							value={<ProfileStageName userId={userId} />}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchBar;
