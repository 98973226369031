import * as React from "react";
import { connect } from "react-redux";
import { uploadSections, uploadsGet } from "../api/apiEndpoints";
import PictureEditContainer from "./components/PictureEditContainer";
import { poll } from "../poll/actions";
import { profileByUserId } from "../poll/selectors";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import { userGroups, userInfo } from "../lib/userInfo/userInfo";
import { Link } from "react-router-dom";
import { routes } from '../router/router';

const Subtitle = ({text}) =>
	<span style={{ fontSize: 14, color: "grey" }}>({text})</span>

class MyPictures extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			profile_main_profile_picture: [],
			profile_proof: [],
			profile_venue_promo: [],
			profile_rsa: [],
			profileId: undefined,
			userId: undefined,
			uploadsFetched: false,
			loading: false,
			userInfo: undefined
		};
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
		const uInfo = userInfo()
		this.setState({...this.state, userInfo: uInfo})
	}

	static getDerivedStateFromProps(nextProps, currentState) {
		if (
			nextProps.profileByUserId === undefined ||
			objectIsEmpty(nextProps.profileByUserId)
		) {
			return null;
		}
		const foundProfileId =
			nextProps.profileByUserId[nextProps.match.params.id].Id;
		if (foundProfileId === currentState.profileId) {
			return null;
		}
		return {
			profileId: foundProfileId,
			userId: Number(nextProps.match.params.id)
		};
	}

	componentDidUpdate(prevProps, prevState) {
		// handle profileId not loaded yet
		if (prevState.profileId !== this.state.profileId) {
			this.getUploadsByProfileId();
			return;
		}

		// if uploads haven't been fetched and aren't already in the process of being fetched
		if (
			!this.state.uploadsFetched &&
			!this.state.loading &&
			this.state.profileId !== undefined
		) {
			this.getUploadsByProfileId();
			return;
		}
	}

	getUploadsByProfileId = () => {
		this.setState({
			loading: true,
			uploadsFetched: false
		});
		uploadsGet({
			UploadSectionTypes: [
				uploadSections.profile_main_profile_picture,
				uploadSections.profile_proof,
				uploadSections.profile_venue_promo,
				uploadSections.profile_rsa
			],
			UploadTypeId: [this.state.profileId]
		}).then(r => {
			const uploads = r.data || [];

			let newState = {
				profile_main_profile_picture: [],
				profile_proof: [],
				profile_venue_promo: [],
				profile_rsa: [],
				loading: false,
				uploadsFetched: true
			};
			uploads.forEach(upload => {
				switch (upload.UploadSectionType) {
					case uploadSections.profile_main_profile_picture:
						newState.profile_main_profile_picture.push(upload);
						break;
					case uploadSections.profile_proof:
						newState.profile_proof.push(upload);
						break;
					case uploadSections.profile_venue_promo:
						newState.profile_venue_promo.push(upload);
						break;
					case uploadSections.profile_rsa:
						newState.profile_rsa.push(upload);
						break;
				}
			});
			this.setState(newState);
		});
	};

	render() {
		return (
			<React.Fragment>
				<h6>
				<Link to={`${routes.Profile}/${this.state.userId}`} style={{marginLeft: '15px'}} className="btn btn-primary">Back to Profile</Link>

				</h6>
				{this.state.userInfo && this.state.userInfo.userGroupId === userGroups.admin ?
				<PictureEditContainer
						heading={
							<React.Fragment>
								Public Profile Picture
								<br />
								<Subtitle text="visible to Hotels and Public. Contact Office to Change" />
							</React.Fragment>
						}
						uploads={this.state.profile_main_profile_picture}
						uploadText={"Upload Public Profile Picture"}
						colWidth={4}
						onSubmitSuccessFn={this.getUploadsByProfileId}
						uploadSectionType={uploadSections.profile_main_profile_picture}
						uploadSectionId={this.state.profileId}
						userId={this.state.userId}
					/> 
				 : <PictureEditContainer
							heading={
								<React.Fragment>
									Public Profile Picture
									<br />
									<Subtitle text="visible to Hotels and Public. Contact Office to Change" />
								</React.Fragment>
							}
							uploads={this.state.profile_main_profile_picture}
							uploadText={"Upload Public Profile Picture"}
							colWidth={4}
							onSubmitSuccessFn={this.getUploadsByProfileId}
							uploadSectionType={uploadSections.profile_main_profile_picture}
							uploadSectionId={this.state.profileId}
							userId={0}
						/>  
			 }
				
				<PictureEditContainer
					heading={
						<React.Fragment>
							Certificates
							<br />
							<Subtitle text="visible to Hotels" />
						</React.Fragment>
					}
					uploads={this.state.profile_rsa}
					uploadText={"Upload Certificate"}
					colWidth={4}
					onSubmitSuccessFn={this.getUploadsByProfileId}
					uploadSectionType={uploadSections.profile_rsa}
					uploadSectionId={this.state.profileId}
					userId={this.state.userId}
				/>
				<PictureEditContainer
					heading={
						<React.Fragment>
							Proof Picture
							<br />
							<Subtitle text="visible to Hotels, but Not for Promo. Update Quarterly."/>
						</React.Fragment>
					}
					uploads={this.state.profile_proof}
					uploadText={"Upload Proof Picture"}
					colWidth={4}
					onSubmitSuccessFn={this.getUploadsByProfileId}
					uploadSectionType={uploadSections.profile_proof}
					uploadSectionId={this.state.profileId}
					userId={this.state.userId}
				/>
				<PictureEditContainer
					heading={
						<React.Fragment>
							Okay For Hotel Promo
							<br />
							<Subtitle text="visible to Hotels" />
						</React.Fragment>
					}
					uploads={this.state.profile_venue_promo}
					uploadText={"Upload Hotel Promo Picture"}
					colWidth={12}
					onSubmitSuccessFn={this.getUploadsByProfileId}
					uploadSectionType={uploadSections.profile_venue_promo}
					uploadSectionId={this.state.profileId}
					userId={this.state.userId}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profileByUserId: profileByUserId(state)
	};
};

MyPictures = connect(mapStateToProps)(MyPictures);
export default MyPictures;
