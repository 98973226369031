import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clientSelectorById } from "../../poll/selectors";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import {
	uploadDELETE,
	uploadPATCH,
	uploadSections,
	uploadsGet
} from "../../api/apiEndpoints";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import Gallery from "../../MyPictures/components/Gallery";
import FormManager from "../../components/form/FormManager";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import InputText from "../../components/input/InputText";
import LoadingButton from "../../components/button/LoadingButton";
import UploadFileModal from "../../components/uploads/UploadFileModal";
import { Save, Trash } from "../../components/icons/Icons";

class VenuePictures extends React.Component {
	state = {
		venueUploads: []
	};

	static propTypes = {
		clientId: PropTypes.number
	};

	queryVenueGallery = () => {
		uploadsGet({
			UploadSectionTypes: [uploadSections.client_gallery],
			UploadTypeId: [this.props.clientId]
		}).then(r => {
			const uploads = r.data || [];

			uploads.sort((a, b) => a.Id - b.Id);
			this.setState({
				venueUploads: uploads
			});
		});
	};

	componentDidMount() {
		this.queryVenueGallery();
	}

	render() {
		let uploads = this.state.venueUploads.map((upload, uploadIdx) => {
			return (
				<StandardModalWrapper
					key={uploadIdx}
					modalOpener={({ handleOpenModal }) => {
						return (
							<div className="shadowbox" style={{ padding: 2, marginTop: 5 }}>
								{upload.Description && (
									<span
										style={{
											padding: "10px 5px 0px 8px",
											display: "block",
											fontSize: "17px"
										}}
									>
										{upload.Description}
									</span>
								)}

								<UploadRenderer
									src={upload.Path}
									onClickFn={handleOpenModal}
									showThumbnail={true}
								/>
							</div>
						);
					}}
					render={({ handleCloseModal }) => {
						let content = null;

						if (
							userInfo().userGroupId === userGroups.admin ||
							userInfo().userGroupId === userGroups.hrm
						) {
							content = (
								<div className="admin-box">
									<FormManager
										initialFormValues={{
											Description: upload.Description === null ? "" : upload.Description
										}}
										handleSubmit={({
											formValues,
											handleSubmitFail,
											handleSubmitSuccess
										}) => {
											uploadPATCH({
												Id: upload.Id,
												Description: formValues.Description
											})
												.then(() => {
													this.queryVenueGallery();
													handleSubmitSuccess();
													handleCloseModal();
												})
												.catch(() => {
													handleSubmitFail();
												});
										}}
									>
										{/*FormManager children*/}
										{({
											formValues,
											handleOnChange,
											handleSubmit,
											formValidation,
											submitEnabled,
											submitting
										}) => {
											return (
												<React.Fragment>
													<InputText
														name={"Description"}
														onChange={handleOnChange}
														value={formValues.Description}
													/>
													<LoadingButton
														className="btn btn-success pull-right admin-box-button"
														loading={submitting}
														disabled={submitting}
														onClick={handleSubmit}
													>
														<Save /> Update
													</LoadingButton>
												</React.Fragment>
											);
										}}
									</FormManager>
									<FormManager
										handleSubmit={({
											formValues,
											handleSubmitFail,
											handleSubmitSuccess
										}) => {
											uploadDELETE({
												Id: upload.Id
											})
												.then(() => {
													this.queryVenueGallery();
													handleSubmitSuccess();
													handleCloseModal();
												})
												.catch(() => {
													handleSubmitFail();
												});
										}}
									>
										{/*FormManager children*/}
										{({
											formValues,
											handleOnChange,
											handleSubmit,
											formValidation,
											submitEnabled,
											submitting
										}) => {
											return (
												<LoadingButtonWithConfirm
													className="btn btn-danger pull-right admin-box-button"
													loading={submitting}
													disabled={submitting}
													onClick={handleSubmit}
												>
													<Trash /> Delete
												</LoadingButtonWithConfirm>
											);
										}}
									</FormManager>
								</div>
							);
						} else if (userInfo().userGroupId !== userGroups.client) {
							content = <div>{upload.Description}</div>;
						}

						return (
							<React.Fragment>
								<UploadRenderer src={upload.Path} />
								{content}
							</React.Fragment>
						);
					}}
				/>
			);
		});

		return (
			<React.Fragment>
				<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
					<StandardModalWrapper
						heading={"Upload Venue Picture"}
						modalOpener={({ handleOpenModal }) => {
							return (
								<span className="heading-edit" onClick={handleOpenModal}>
									Upload
								</span>
							);
						}}
						render={({ handleCloseModal }) => {
							return (
								<UploadFileModal
									uploadSectionType={uploadSections.client_gallery}
									uploadSectionId={this.props.clientId}
									onSubmitSuccessFn={handleCloseModal}
								/>
							);
						}}
					/>
				</ProtectedComponent>
				<h4 className="heading">Venue Pictures</h4>
				{uploads.map(upload => upload)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		venue: clientSelectorById(state)[props.clientId]
	};
};

export default connect(mapStateToProps)(VenuePictures);
