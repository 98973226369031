import * as React from "react";
import { connect } from "react-redux";
import { venueBibleSelectorById } from "../poll/selectors";
import { routes } from "../router/router";
import {
	venueBibleDELETE,
	venueBiblePATCH,
	venueBiblePOST,
	VenueBibleQueryById
} from "../api/apiEndpoints";
import Bible from "./Bible";

export const venueBibleTermsConditionsHeading = "Terms & Conditions";

class ClientInfoAndIdeas extends React.PureComponent {
	render() {
		return (
			<Bible
				{...this.props}
				updateBibleEntryAPIFn={venueBiblePATCH}
				createBibleEntryAPIFn={venueBiblePOST}
				deleteBibleEntryAPIFn={venueBibleDELETE}
				queryBibleEntriesAPIFn={VenueBibleQueryById}
				routerSuffix={routes.ClientInfoAndIdeas}
				bibleEntriesFromPoll={this.props.venueBible}
				termsAndConditionsTitle={venueBibleTermsConditionsHeading}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		venueBible: venueBibleSelectorById(state)
	};
};

ClientInfoAndIdeas = connect(mapStateToProps)(ClientInfoAndIdeas);
export default ClientInfoAndIdeas;
