import * as React from "react";
import PropTypes from "prop-types";
import EditParentSeriesWizard from "../AdminEdit/EditParentSeriesWizard";
import EditSeriesWizard from "../AdminEdit/EditSeriesWizard";
import { requirementsToBools } from "../lib/requirements";
import { convertDateToAPIDate, newDateWithoutSeconds, parseAPIDate } from "../../../lib/dateUtils/dateUtils";
import NatTime from "../../../components/text/NatTime";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import { apiShiftTypes, convertRequirementsToAPIFormat, createSeriesPOST } from "../../../api/apiEndpoints";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import axios from "axios/index";
import Money from "../../../components/text/Money";
import DateFns from "date-fns";
import { PlusCircle, Save, Trash } from "../../../components/icons/Icons";

class CreateMultidaySeries extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			parentSeries: {
				ClientId: undefined,
				Notes: "",
				WeeklyFrequency: props.singleShiftOnly ? undefined : 1,
				AdminPay: 0,
				TravelPay: 0,
				HrmApproval: true,
				formValidation: {
					valid: false
				},
				...requirementsToBools([]),
				ContractEndOrNull: null
			},
			childSeries: [],
			editing: 0,
			creatingSeries: false
		};
	}

	handleCreateMultiday = () => {
		this.setState({
			creatingSeries: true
		});

		const bulkCreateSeriesPost = [];

		for (let count = 0; count < this.props.numberOfUsersRequired; count++) {
			bulkCreateSeriesPost.push(
				createSeriesPOST({
					...this.state.parentSeries,
					ShiftType: this.props.singleShiftOnly
						? apiShiftTypes.multidayShift
						: apiShiftTypes.multidaySeries,
					Multiday: this.state.childSeries
				})
			);
		}
		axios.all(bulkCreateSeriesPost).then(() => {
			this.props.handleCloseModal();
		});
	};

	allSeriesValid = () => {
		if (!this.state.parentSeries.formValidation.valid) {
			return false;
		}

		let valid = true;
		this.state.childSeries.forEach(child => {
			if (!child.formValidation.valid) {
				return false;
			}
		});

		return valid;
	};

	handleChildSeriesFormValuesCallback = childIdx => {
		return (formValues, formValidation) => {
			let childSeries = this.state.childSeries.slice();

			if (formValues === "done") {
				childSeries[childIdx].Page = 1;
				this.setState({
					childSeries: childSeries,
					editing: -1
				});

				return;
			}

			childSeries[childIdx] = { ...formValues, formValidation };

			this.setState({
				childSeries: childSeries,
				editing: childIdx + 1
			});
		};
	};

	// removing child series via trash button
	handleDeleteChildSeries = idx => {
		return () => {
			const childSeriesCopy = this.state.childSeries.slice();
			// splice affects the original, not a copy
			childSeriesCopy.splice(idx, 1);

			this.setState({
				childSeries: childSeriesCopy,
				editing: -1
			});
		};
	};

	handleAddChildSeries = () => {
		const newChildren = this.state.childSeries.slice();
		let startDate = DateFns.setMinutes(newDateWithoutSeconds(), 0);
		if (this.state.childSeries.length > 0) {
			startDate = DateFns.addDays(
				parseAPIDate(
					this.state.childSeries[this.state.childSeries.length - 1].ContractStart
				),
				1
			);
		}

		// Creates a new child series row with some default values.
		newChildren.push({
			Notes: "",
			ClientId: this.state.parentSeries.ClientId,
			ContractStart: convertDateToAPIDate(startDate),
			ContractEndOrNull: null,
			ShiftLength: 60,
			PayRate: 0,
			ShiftPay: 0,
			FeePay: 0,
			AdminPay: 0,
			TravelPay: 0,
			HrmApproval: false,
			Extra: false,
			...requirementsToBools(
				convertRequirementsToAPIFormat(this.state.parentSeries)
			),
			// default state for form validation
			formValidation: {
				valid: false
			}
		});

		this.setState({
			childSeries: newChildren,
			editing: newChildren.length
		});
	};

	handleEditRow = idx => {
		this.setState({
			editing: Number(idx.target.value)
		});
	};

	renderSummaryRow(content, idx) {
		let buttons = [];
		let rowStyles = {};

		if (idx > 0) {
			buttons.push(
				<button
					className="btn btn-danger"
					value={idx}
					onClick={this.handleDeleteChildSeries(idx - 1)}
				>
					<Trash /> Remove
				</button>
			);
			rowStyles.borderTop = "1px solid #aaa";
		}

		buttons.push(
			<button className="btn btn-primary" value={idx} onClick={this.handleEditRow}>
				<i className="fas fa-wrench" /> Edit
			</button>
		);

		return (
			<div className="row" key={idx} style={rowStyles}>
				<div className="col-sm-9" style={{ paddingTop: 5, paddingBottom: 5 }}>
					{content}
				</div>
				<div className="col-sm-3">
					<AdminContainerMigrate align={"right"} buttons={buttons} />
				</div>
			</div>
		);
	}

	render() {
		const collapseDisabled = !this.allSeriesValid();

		let buttons = [];

		if (this.state.editing === -1) {
			buttons.push(
				<button
					disabled={collapseDisabled}
					className="btn btn-primary"
					onClick={this.handleAddChildSeries}
				>
					<PlusCircle /> Add a day
				</button>
			);
		}
		if (this.state.childSeries.length > 0 && this.state.editing === -1) {
			buttons.push(
				<LoadingButtonWithConfirm
					loading={this.state.creatingSeries}
					disabled={
						collapseDisabled ||
						this.state.childSeries.length === 0 ||
						this.state.creatingSeries
					}
					className="btn btn-success"
					onClick={this.handleCreateMultiday}
				>
					<Save /> Create this series
				</LoadingButtonWithConfirm>
			);
		}

		return (
			<div className="row">
				<div className="col-sm-12">
					{/*Parent summary*/}
					{this.state.editing === -1 &&
						this.renderSummaryRow(
							<span style={{ fontSize: 22 }}>Venue & Shift Requirements</span>,
							0
						)}

					{/* Parent Edit */}
					{this.state.editing === 0 && (
						<EditParentSeriesWizard
							showAdminFee={true}
							showHrmApproval={true}
							showNotes={true}
							showRequirements={true}
							showTravelPay={true}
							showVenue={true}
							showWeeklyFrequency={!this.props.singleShiftOnly}
							parentSeries={this.state.parentSeries}
							handleCloseModal={this.props.handleCloseModal}
							onChangeCallback={(formValues, formValidation) => {
								if (formValues === "done") {
									this.setState({
										editing: -1
									});
									return;
								}

								this.setState({
									parentSeries: { ...formValues, formValidation },
									editing: 0
								});
							}}
						/>
					)}

					{this.state.childSeries.map((child, childIdx) => {
						const shiftIdx = childIdx + 1; // because [0] is the parent shift

						let heading = "Day " + shiftIdx;

						if (child.ClientId !== undefined) {
							heading = (
								<div>
									<div>
										<span style={{ fontSize: 20 }}>
											{heading} -{" "}
											<NatTime startTime={child.ContractStart} endTime={child.ShiftEnd} />
										</span>
									</div>
									<div>
										<div className="row">
											<div className="col-sm-6">
												<b>Shift Length (minutes)</b>
											</div>
											<div className="col-sm-6">{child.ShiftLength}</div>
										</div>
										{child.PayRate > 0 && (
											<div className="row">
												<div className="col-sm-6">
													<b>Pay Rate</b>
												</div>
												<div className="col-sm-6">
													<Money value={child.PayRate} />
												</div>
											</div>
										)}
										<div className="row">
											<div className="col-sm-6">
												<b>Fees</b>
											</div>
											<div className="col-sm-6">
												<Money value={child.FeePay} />
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												<b>Pay</b>
											</div>
											<div className="col-sm-6">
												<Money value={child.ShiftPay} />
											</div>
										</div>
									</div>
								</div>
							);
						}

						// When in summary view, only display the summary for this shift
						if (this.state.editing === -1) {
							return this.renderSummaryRow(heading, shiftIdx);
						}

						// When in edit mode, only display the currently selected shift
						if (this.state.editing === shiftIdx) {
							return (
								<div className="col-sm-12" key={shiftIdx}>
									<EditSeriesWizard
										seriesType={
											this.props.singleShiftOnly
												? apiShiftTypes.multidayShift
												: apiShiftTypes.multidaySeries
										}
										weeklyFrequencyHidden={true}
										onChangeCallback={this.handleChildSeriesFormValuesCallback(
											shiftIdx - 1
										)}
										series={child}
									/>
								</div>
							);
						}

						return null;
					})}
				</div>
				{buttons.length > 0 && (
					<AdminContainerMigrate align="right" buttons={buttons} />
				)}

				{this.state.creatingSeries && (
					<div>
						<h4 className="text-center color-fail">
							Creating series - this can take up to 5 minutes depending on how many
							shifts are create and validated.
						</h4>
					</div>
				)}
			</div>
		);
	}
}

CreateMultidaySeries.propTypes = {
	handleCloseModal: PropTypes.func.isRequired,
	numberOfUsersRequired: PropTypes.number.isRequired,
	singleShiftOnly: PropTypes.bool.isRequired
};

export default CreateMultidaySeries;
