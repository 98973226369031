export function requirementsToBools(requirements) {
	let reqsAsBools = {};

	if (requirements === null) {
		requirements = [];
	}
	reqsAsBools.Topless =
		requirements.find(r => {
			return r.Id === 3;
		}) !== undefined;

	return reqsAsBools;
}
