import * as React from "react";
import PropTypes from "prop-types";

export default class PhoneNumberLink extends React.Component {
	render() {
		if (
			this.props.phoneNumber === null ||
			this.props.phoneNumber === undefined ||
			this.props.phoneNumber === ""
		) {
			return "";
		}
		const phoneNumberWithoutSpaces = this.props.phoneNumber.split(" ").join("");

		return (
			<span
				style={{
					display: "inline-flex",
				}}
			>
				<a href={"tel://" + phoneNumberWithoutSpaces}>
					<i className="fas fa-phone-square color-success" />{" "}
					{phoneNumberWithoutSpaces}
				</a>
			</span>
		);
	}
}

PhoneNumberLink.propTypes = {
	phoneNumber: PropTypes.string
};
