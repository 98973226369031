import * as React from "react";
import PropTypes from "prop-types";
import Loadable from "react-loadable";

const LoadableReactPdfJS = Loadable({
	loader: () => import("./LoadableReactPdfJS"),
	loading: () => <span>Loading...</span>,
	timeout: 10000 // 10 seconds
});

class PDFViewer extends React.Component {
	state = {
		page: 1,
		pages: false
	};

	onDocumentComplete = pages => {
		this.setState({ page: 1, pages });
	};

	onPageComplete = page => {
		this.setState({ page });
	};

	handlePrevious = () => {
		this.setState({ page: this.state.page - 1 });
	};

	handleNext = () => {
		this.setState({ page: this.state.page + 1 });
	};

	renderPagination = (page, pages) => {
		let previousButton = (
			<i onClick={this.handlePrevious} className="fas fa-arrow-left" />
		);
		if (page === 1) {
			previousButton = null;
		}
		let nextButton = (
			<i onClick={this.handleNext} className="fas fa-arrow-right" />
		);
		if (page === pages) {
			nextButton = null;
		}
		return (
			<nav>
				<ul className="pager">
					<span style={{ float: "left" }}>{previousButton}</span>
					<span style={{ float: "right" }}>{nextButton}</span>
				</ul>
			</nav>
		);
	};

	render() {
		let pagination = null;
		if (this.state.pages) {
			pagination = this.renderPagination(this.state.page, this.state.pages);
		}
		return <React.Fragment>
				<LoadableReactPdfJS filePath={this.props.filePath} onDocumentComplete={this.onDocumentComplete} onPageComplete={this.onPageComplete} page={this.state.page} />
				{pagination}
			</React.Fragment>;
	}
}

PDFViewer.propTypes = {
	filePath: PropTypes.string
};

export default PDFViewer;
