import * as React from "react";
import PropTypes from "prop-types";
import Validation from "../../lib/validation/validation";
import { toastr } from "react-redux-toastr";
import { poll } from "../../poll/actions";
import { connect } from "react-redux";

class FormManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formValues: this.props.initialFormValues,
			formValidation: {},
			submitEnabled: false,
			submitting: false,
			submitError: false,
			formFriendlyNames: {}
		};
	}

	componentDidMount() {
		if (this.props.validateOnLoad) {
			this.updateAndValidateState(this.state);
		}
	}

	handleSubmit = () => {
		this.setState({ submitting: true, submitEnabled: false });
		this.props.handleSubmit({
			formValues: this.state.formValues,
			handleSubmitFail: this.handleSubmitFail,
			handleSubmitSuccess: this.handleSubmitSuccess
		});
	};

	handleSubmitFail = () => {
		this.setState({ submitting: false, submitError: true });
		toastr.error(
			"There was an error processing your request, please refresh the browser and try again."
		);
	};

	handleSubmitSuccess = () => {
		this.setState({ submitting: false, submitError: false });
		this.props.dispatch(poll({}));
		toastr.info("Loading updates..");
	};

	handleOnChange = e => {
		let { name, value } = e.target;
		if (e.target.type === "checkbox") {
			value = e.target.checked;
		}

		this.updateAndValidateState({
			formValues: {
				...this.state.formValues,
				[name]: value
			}
		});
	};

	handleOnClick = e => {
		let { name, value } = e.target;
		if (e.target.type === "checkbox") {
			value = e.target.checked;
		}

		this.updateAndValidateState({
			formValues: {
				...this.state.formValues,
				[name]: value
			}
		});
	};

	handleSetFormValues = values => {
		this.updateAndValidateState({ formValues: values });
	};

	handleReset = () => {
		this.componentDidMount();
	};

	updateAndValidateState = newState => {
		let onStateChangeCallback = (formValues, formValidation) => {
			return () => {
				if (this.props.onChangeCallback !== undefined) {
					this.props.onChangeCallback(formValues, formValidation);
				}
			};
		};

		if (this.props.handleValidation === undefined) {
			this.setState(
				{
					...newState,
					submitEnabled: true
				},
				onStateChangeCallback(newState.formValues, newState.formValidation)
			);
			return;
		}

		const v = new Validation(newState.formValues, {});

		this.props.handleValidation(v);

		this.setState(
			{
				...newState,
				formValidation: v.validation,
				submitEnabled: v.valid
			},
			onStateChangeCallback(newState.formValues, v)
		);
	};

	componentWillReceiveProps = nextProps => {
		this.setState(() => ({
			formValues: nextProps.initialFormValues
		}));
	};

	render() {
		return this.props.children({
			formValues: this.state.formValues,
			formValidation: this.state.formValidation,
			handleOnChange: this.handleOnChange,
			handleOnClick: this.handleOnClick,
			handleSubmit: this.handleSubmit,
			handleReset: this.handleReset,
			submitEnabled: this.state.submitEnabled,
			submitting: this.state.submitting,
			submitError: this.state.submitError,
			handleSetFormValues: this.handleSetFormValues,
			handleSubmitFail: this.handleSubmitFail,
			handleSubmitSuccess: this.handleSubmitSuccess
		});
	}
}

FormManager.defaultProps = {
	validateOnLoad: false
};

FormManager.propTypes = {
	initialFormValues: PropTypes.any,
	handleValidation: PropTypes.func,
	handleSubmit: PropTypes.func,
	validateOnLoad: PropTypes.bool,
	onChangeCallback: PropTypes.func
};

FormManager = connect()(FormManager);
export default FormManager;
