import * as React from "react";

export default class ResponsiveTableRow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editMode: false,
			submitText: "Edit"
		};

		this.handleToggleEditMode = this.handleToggleEditMode.bind(this);
	}

	handleToggleEditMode() {
		if (this.state.editMode === true) {
			this.setState({ editMode: false, submitText: "Edit" });
		} else {
			this.setState({ editMode: true, submitText: "Save" });
		}
	}

	render() {
		// const children = this.props.children({editMode: this.state})
		// if (children === null) {
		//     return null;
		// }
		return this.props.children({
			editMode: this.state.editMode,
			handleToggleEditMode: this.handleToggleEditMode,
			submitText: this.state.submitText
		});
	}
}
