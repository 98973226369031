import * as React from "react";
import PropTypes from "prop-types";
import { seriesDeleteDELETE } from "../../../api/apiEndpoints";
import NatTime from "../../../components/text/NatTime";
import { parseAPIDate } from "../../../lib/dateUtils/dateUtils";
import ResponsiveTableColumn from "../../../components/table/ResponsiveTableColumn";
import FormManager from "../../../components/form/FormManager";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { Trash } from "../../../components/icons/Icons";

export default class CancelSeries extends React.Component {
	constructor(props) {
		super(props);

		this.state = { shiftsToDelete: [] };
	}

	componentDidMount() {
		seriesDeleteDELETE({
			DryRun: true,
			SeriesId: this.props.shift.SeriesId,
			DeleteFrom: this.props.shift.StartTime
		}).then(response => {
			// incase null is returned by the api
			if (response.data === null) {
				response.data = [];
			}

			// Sort the dates
			response.data.sort((a, b) => {
				return (
					parseAPIDate(a.StartTime).getTime() - parseAPIDate(b.StartTime).getTime()
				);
			});

			this.setState({ shiftsToDelete: response.data });
		});
	}

	render() {
		return (
			<div>
				<h4 className="heading">Delete a series of shifts.</h4>
				The following shifts will be deleted. Any girls booked to a shift will be
				notified.
				{this.state.shiftsToDelete.map((shiftToDelete, idx) => {
					return (
						<div key={idx} className="row div-table-responsive-row">
							<ResponsiveTableColumn size={4} heading={"Start Time"}>
								<NatTime
									startTime={shiftToDelete.StartTime}
									endTime={shiftToDelete.EndTime}
								/>
							</ResponsiveTableColumn>

							<ResponsiveTableColumn size={4} heading={"Stage Name"}>
								{shiftToDelete.StageName}
							</ResponsiveTableColumn>

							<ResponsiveTableColumn size={4} heading={"Phone Number"}>
								{shiftToDelete.WorkPhone}
							</ResponsiveTableColumn>
						</div>
					);
				})}
				<div>
					<FormManager
						handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
							seriesDeleteDELETE({
								DryRun: false,
								SeriesId: this.props.shift.SeriesId,
								DeleteFrom: this.props.shift.StartTime
							})
								.then(() => {
									handleSubmitSuccess();
									this.props.handleCloseModal();
								})
								.catch(e => {
									handleSubmitFail();
									throw e;
								});
						}}
					>
						{({ handleSubmit, submitting }) => {
							return (
								<AdminContainerMigrate
									align="right"
									buttons={[
										<LoadingButtonWithConfirm
											className="btn btn-danger"
											loading={submitting}
											onClick={handleSubmit}
										>
											<Trash /> Delete
										</LoadingButtonWithConfirm>
									]}
								/>
							);
						}}
					</FormManager>
				</div>
			</div>
		);
	}
}

CancelSeries.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};
