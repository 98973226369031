import * as React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../components/logout/actions";

class Logout extends React.Component {
	handleLogoutClick = () => {
		this.props.dispatch(logoutUser());
	};

	render() {
		return (
			<span>
				<li
					className="left_menu_item_heading"
					onClick={this.handleLogoutClick}
					style={{ border: 0 }}
				>
					<span style={{ color: "white" }}>
						<i className="fas fa-sign-out" /> Logout
					</span>
				</li>
			</span>
		);
	}
}

export default connect()(Logout);
