import * as React from "react";
import { connect } from "react-redux";
import Spinner from "./Spinner";
import { pollLoading } from "../../poll/selectors";

class PollLoading extends React.Component {
	render() {
		return <Spinner loading={this.props.loading}>{this.props.children}</Spinner>;
	}
}

const mapStateToProps = state => {
	return {
		loading: pollLoading(state)
	};
};

export default connect(mapStateToProps)(PollLoading);
