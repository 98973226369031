import * as React from "react";
import PropTypes from "prop-types";
import EditSeriesWizard from "../AdminEdit/EditSeriesWizard";
import { requirementsToBools } from "../lib/requirements";
import Spinner from "../../../components/loading/Spinner";
import {
	apiShiftTypes,
	editRegularSeriesPATCH
} from "../../../api/apiEndpoints";
import { seriesSelectorById } from "../../../poll/selectors";
import { connect } from "react-redux";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import DryRunSeries from "./DryRunSeries";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { Save } from "../../../components/icons/Icons";

const isNullOrUndefined = prop => [undefined, null].includes(prop);

class EditSeriesFromThisShiftForward extends React.Component {
	constructor(props) {
		super(props);

		const series = props.seriesById[props.shift.SeriesId];

		this.state = {
			submittingUpdate: false,
			submittingDryRun: false,
			series,
			displayDryRun: false,
			dryRunResults: [],
			formValues: {
				Notes: series.Notes,
				ClientId: series.ClientId,
				ContractStart: props.shift.StartTime,
				ShiftLength: series.ShiftLength,
				PayRate: series.PayRate,
				ShiftPay: series.ShiftPay,
				FeePay: series.FeePay,
				AdminPay: series.AdminPay,
				TravelPay: series.TravelPay,
				HrmApproval: series.HrmApproval,
				Extra: series.Extra !== null,
				ShiftType: series.ShiftType, // used for validation on custom monthly
				MonthlyDay: series.MonthlyDay, // used for validation on custom monthly
				MonthlyWeek: series.MonthlyWeek, // used for validation on custom monthly
				...requirementsToBools(series.Requirements)
			}
		};
	}

	handleSubmit = dryrun => editRegularSeriesPATCH({
		DryRun: dryrun,
		ChildSeries: this.state.series.ParentId !== null,
		Id: this.state.series.Id,
		ContractStart: this.state.formValues.ContractStart,
		AdminPay: this.state.formValues.AdminPay,
		Extra: this.state.formValues.Extra,
		FeePay: this.state.formValues.FeePay,
		HrmApproval: this.state.formValues.HrmApproval,
		Notes: this.state.formValues.Notes,
		PayRate: this.state.formValues.PayRate,
		ShiftLength: this.state.formValues.ShiftLength,
		ShiftPay: this.state.formValues.ShiftPay,
		TravelPay: this.state.formValues.TravelPay,
		// Requirements
		Topless: this.state.formValues.Topless,
	});

	handleDryRunSeries = () => {
		this.setState({
			displayDryRun: true,
			submittingDryRun: true
		});

		this.handleSubmit(true).then(response => {
			this.setState({
				dryRunResults: response.data.Shifts,
				submittingDryRun: false
			});
		});
	};

	handleUpdateSeries = () => {
		this.setState({
			submittingDryRun: true
		});
		this.handleSubmit(false).then(() => {
			this.setState({
				submittingUpdate: false
			});
			this.props.handleCloseModal();
		});
	};

	seriesFormValuesCallback = formValues => {
		if (formValues === "done") {
			this.handleDryRunSeries();
			return;
		}

		this.setState({
			formValues
		});
	};

	render() {
		// Set the series wizard to multiday if parent id is not set (ShiftType is not present on a child shift)
		let seriesType = isNullOrUndefined(this.props.shift.ParentId)
			? apiShiftTypes.singleShift
			: apiShiftTypes.multidayShift;
		if (this.props.shift.ShiftType === apiShiftTypes.customMonthly) {
			seriesType = apiShiftTypes.customMonthly;
		}

		return (
			<div>
				<h4 className="heading">Edit series</h4>
				{this.state.displayDryRun ? (
					<div>
						<Spinner loading={this.state.submittingDryRun}>
							<div className="col-sm-12`">
								<DryRunSeries dryRunResults={this.state.dryRunResults} />
							</div>
						</Spinner>
						<AdminContainerMigrate
							align="right"
							buttons={[
								<button
									className="btn btn-primary"
									onClick={() => {
										this.setState({ displayDryRun: false });
									}}
								>
									<i className="fas fa-arrow-circle-left" /> Previous
								</button>,
								<LoadingButtonWithConfirm
									loading={this.state.submittingUpdate || this.state.submittingDryRun}
									disabled={this.state.submittingUpdate || this.state.submittingDryRun}
									onClick={this.handleUpdateSeries}
									className="btn btn-danger"
								>
									<Save /> Update Series
								</LoadingButtonWithConfirm>
							]}
						/>
					</div>
				) : (
					<EditSeriesWizard
						seriesType={seriesType}
						series={this.state.formValues}
						onChangeCallback={this.seriesFormValuesCallback}
						hideComponents={{
							inputCustomMonthly: true
						}}
					/>
				)}
			</div>
		);
	}
}

EditSeriesFromThisShiftForward.propTypes = {
	handleCloseModal: PropTypes.func.isRequired,
	shift: PropTypes.object
};

const mapStateToProps = state => ({
	seriesById: seriesSelectorById(state)
});

export default connect(mapStateToProps)(EditSeriesFromThisShiftForward);
