import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormManager from "../../components/form/FormManager";
import { createBrandPOST } from "../../api/apiEndpoints";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import FormRow from "../../components/form/FormRow";
import InputText from "../../components/input/InputText";
import SkimpyList from "../../components/select/SkimpyList";
import InputTextArea from "../../components/input/InputTextArea";
import { PlusCircle } from "../../components/icons/Icons";

class CreateBrand extends React.Component {
	render() {
		return (
			<FormManager
				initialFormValues={{
					BrandName: "",
					UserId: undefined,
					WebLink: "",
					SalesPitch: "",
					PhoneNumber: undefined,
					AddressNumber: undefined,
					AddressPostcode: undefined,
					AddressRoad: undefined,
					AddressState: undefined,
					AddressSuburb: undefined
				}}
				handleValidation={v => {
					v.required({
						fieldNames: [
							"BrandName",
							"UserId",
							"PhoneNumber",
							"AddressNumber",
							"AddressPostcode",
							"AddressRoad",
							"AddressState",
							"AddressSuburb"
						]
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					createBrandPOST(formValues)
						.then(() => {
							handleSubmitSuccess();
							this.props.onCreateCallbackFn();
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"Brand Name"}
								value={
									<InputText
										name={"BrandName"}
										value={formValues.BrandName}
										onChange={handleOnChange}
										validationMessages={formValidation.BrandName}
									/>
								}
							/>
							<FormRow
								label={"Assign User"}
								value={
									<SkimpyList
										name={"UserId"}
										value={formValues.UserId}
										onChange={handleOnChange}
										validationMessages={formValidation.UserId}
									/>
								}
							/>
							<FormRow
								label={"Web Link"}
								value={
									<InputText
										name={"WebLink"}
										value={formValues.WebLink}
										onChange={handleOnChange}
										validationMessages={formValidation.WebLink}
									/>
								}
							/>
							<FormRow
								label={"Sales Pitch"}
								value={
									<InputTextArea
										name={"SalesPitch"}
										value={formValues.SalesPitch}
										onChange={handleOnChange}
										validationMessages={formValidation.SalesPitch}
									/>
								}
							/>

							<FormRow
								label={"Phone Number"}
								value={
									<InputText
										name={"PhoneNumber"}
										value={formValues.PhoneNumber}
										onChange={handleOnChange}
										validationMessages={formValidation.PhoneNumber}
									/>
								}
							/>
							<h4 className="heading">Address Information</h4>
							<FormRow
								label={"Number"}
								value={
									<InputText
										name={"AddressNumber"}
										value={formValues.AddressNumber}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressNumber}
									/>
								}
							/>
							<FormRow
								label={"Postcode"}
								value={
									<InputText
										name={"AddressPostcode"}
										value={formValues.AddressPostcode}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressPostcode}
									/>
								}
							/>
							<FormRow
								label={"Road"}
								value={
									<InputText
										name={"AddressRoad"}
										value={formValues.AddressRoad}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressRoad}
									/>
								}
							/>
							<FormRow
								label={"State"}
								value={
									<InputText
										name={"AddressState"}
										value={formValues.AddressState}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressState}
									/>
								}
							/>
							<FormRow
								label={"Suburb"}
								value={
									<InputText
										name={"AddressSuburb"}
										value={formValues.AddressSuburb}
										onChange={handleOnChange}
										validationMessages={formValidation.AddressSuburb}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButtonWithConfirm
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
										className="btn btn-success"
									>
										<PlusCircle /> Create Brand
									</LoadingButtonWithConfirm>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

CreateBrand.propTypes = {
	brandId: PropTypes.number,
	onCreateCallbackFn: PropTypes.func
};

export default connect()(CreateBrand);
