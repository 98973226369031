import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { profileSelectorById } from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import { profilesPATCH } from "../../api/apiEndpoints";
import LoadingButton from "../../components/button/LoadingButton";
import {
	profileServicesAsArray,
	profileServicesById
} from "../../config/selectors";
import FormRow from "../../components/form/FormRow";
import InputCheckbox from "../../components/input/InputCheckbox";
import { Check, Times } from "../../components/icons/Icons";

class Services extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initialFormValues: {}
		};
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	onLoad(nextProps) {
		if (nextProps.profileServicesAsArray === undefined) {
			return;
		}

		console.log({
			initialFormValues: nextProps.profileServicesAsArray.reduce(
				(services, current) => {
					services["ServicesById." + current.Id] =
						nextProps.profile.ServicesArray !== null &&
						nextProps.profile.ServicesArray.indexOf(current.Id) !== -1;

					return services;
				},
				{}
			)
		});
		this.setState({
			initialFormValues: nextProps.profileServicesAsArray.reduce(
				(services, current) => {
					services["ServicesById." + current.Id] =
						nextProps.profile.ServicesArray !== null &&
						nextProps.profile.ServicesArray.indexOf(current.Id) !== -1;

					return services;
				},
				{}
			)
		});
	}

	render() {
		if (this.props.profile === undefined) {
			return null;
		}

		// console.log(this.state.initialFormValues)

		return (
			<div>
				<StandardModalWrapper
					heading={"Services"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<span className="heading-edit" onClick={handleOpenModal}>
								Edit
							</span>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										Id: this.props.clientId
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										console.log(formValues);
										// profilesPATCH({
										//     Services: formValues.Services,
										//     Id: this.props.profileId,
										//
										// }).then(() => {
										//     handleSubmitSuccess();
										//     handleCloseModal();
										// }).catch((error) => {
										//     handleSubmitFail();
										//     throw error;
										// })
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												{Object.keys(formValues.ServicesById).map((serviceId, idx) => {
													return (
														<FormRow
															key={idx}
															label={this.props.profileServicesById[serviceId].Description}
															value={
																<InputCheckbox
																	name={"ServicesById." + serviceId}
																	value={formValues.ServicesById[serviceId]}
																	onChange={handleOnChange}
																/>
															}
														/>
													);
												})}

												<div className="pull-right">
													<LoadingButton
														onClick={handleSubmit}
														loading={submitting}
														disabled={!submitEnabled}
													>
														Save
													</LoadingButton>
												</div>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>
				<h4 className="heading">Services</h4>
				<div>
					{Object.keys(this.state.initialFormValues).map((serviceId, idx) => {
						return (
							<FormRow
								key={idx}
								label={this.props.profileServicesById[serviceId].Description}
								value={
									this.state.initialFormValues["ServicesById." + serviceId] ? (
										<Check />
									) : (
										<Times />
									)
								}
							/>
						);
					})}
					{/*<ProfileServices userId={this.props.profile.UserId}/>*/}
				</div>
			</div>
		);
	}
}

Services.defaultProps = {
	profileId: null
};

Services.propTypes = {
	profileId: PropTypes.number
};

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile,
		profileServicesAsArray: profileServicesAsArray(state),
		profileServicesById: profileServicesById(state)
	};
};

export default connect(mapStateToProps)(Services);
