import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import SkimpyList from "../components/select/SkimpyList";
import ClientList from "../components/select/ClientList";
import ReappearanceList from "../components/select/ReappearanceList";
import {
	convertDDMMYYYToJSDate,
	convertJSDateToDDMMYYYY,
	newDateWithoutSeconds,
	parseAPIDate
} from "../lib/dateUtils/dateUtils";
import DateTimePicker from "../components/datetime/DateTimePicker";
import FormManager from "../components/form/FormManager";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import LoadingButton from "../components/button/LoadingButton";
import InputCheckbox from "../components/input/InputCheckbox";
import { clientFeedbackPOST, clientLineupGET } from "../api/apiEndpoints";
import ResponsiveTableNew from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import NatTime from "../components/text/NatTime";
import ClientName from "../components/links/ClientName";
import ProfileStageName from "../components/links/ProfileStageName";
import DateFns from "date-fns";
import FiveStars from "../components/FiveStars/FiveStars";
import { userSelectorById } from "../poll/selectors";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import { lessThanApiDate } from "../lib/sort/sortByDate";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import InputTextArea from "../components/input/InputTextArea";
import InputText from "../components/input/InputText";
import Spinner from "../components/loading/Spinner";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";

class AdminFeedback extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			unfilteredFeedback: [],
			filteredFeedback: [],
			searchParams: {
				StartDate: convertJSDateToDDMMYYYY(DateFns.addMonths(new Date(), -3)),
				ClientId: undefined,
				UserId: undefined,
				Reappearance: undefined,
				Pending: false
			},
			loadingFeedback: true
		};
	}

	componentDidMount() {
		this.refreshFeedback();
	}

	refreshFeedback() {
		this.setState({
			loadingFeedback: true
		});
		clientLineupGET().then(r => {
			this.setState(
				{
					unfilteredFeedback: r.data,
					loadingFeedback: false
				},
				this.filterFeedback
			);
		});
	}

	filterFeedback = () => {
		const searchStartDateEpoch = convertDDMMYYYToJSDate(
			this.state.searchParams.StartDate
		).getTime();
		const nowAsEpoch = new Date().getTime();

		const filteredFeedback = this.state.unfilteredFeedback.filter(feedback => {
			// when looking at pending shifts, filter by StartTime otherwise use CreatedAt
			if (this.state.searchParams.Pending) {
				if (feedback.FeedbackLeft) {
					return false;
				}

				const shiftTimeAsEpoch = parseAPIDate(feedback.StartTime).getTime();
				if (searchStartDateEpoch > shiftTimeAsEpoch) {
					return false;
				}

				if (shiftTimeAsEpoch > nowAsEpoch) {
					return false;
				}
			} else {
				if (feedback.CreatedAt === null) {
					return false;
				}
				const createdAtTimeAsEpoch = parseAPIDate(feedback.CreatedAt).getTime();

				if (searchStartDateEpoch > createdAtTimeAsEpoch) {
					return false;
				}
			}

			if (
				this.state.searchParams.ClientId !== undefined &&
				this.state.searchParams.ClientId !== feedback.ChildClientIds[0]
			) {
				return false;
			}

			if (
				this.state.searchParams.UserId !== undefined &&
				this.state.searchParams.UserId !== feedback.UserId
			) {
				return false;
			}

			if (
				this.state.searchParams.Reappearance !== undefined &&
				this.state.searchParams.Reappearance !== feedback.FeedbackReappearance
			) {
				return false;
			}

			return true;
		});

		this.setState({
			filteredFeedback: filteredFeedback
		});
	};

	render() {
		if (objectIsEmpty(this.props.usersById)) {
			return null;
		}

		return (
			<div>
				{this.renderSearch()}
				<div className="col-sm-12">
					<Spinner loading={this.state.loadingFeedback}>
						{this.state.searchParams.Pending
							? this.renderPendingFeedbackView()
							: this.renderExistingFeedbackView()}
					</Spinner>
				</div>
			</div>
		);
	}

	renderPendingFeedbackView() {
		const colSizing = [3, 2, 2, 2, 3];

		return (
			<ResponsiveTableNew
				initialSortColumn={"StartTime"}
				headings={[
					{
						label: "Client",
						size: colSizing[0],
						disableSort: true
					},
					{
						label: "Shift",
						name: "StartTime",
						size: colSizing[1],
						sortDescFunc: lessThanApiDate
					},
					{
						label: "Skimpy",
						size: colSizing[2],
						disableSort: true
					},
					{
						label: "Rating",
						name: "FeedbackRating",
						size: colSizing[3],
						disableSort: true
					},
					{
						label: "Reappearance",
						name: "FeedbackReappearance",
						size: colSizing[4],
						disableSort: true
					}
				]}
				values={this.state.filteredFeedback}
			>
				{({ sortedValues }) => {
					return (
						<InfiniteScroll
							rowCount={sortedValues.length}
							rowComparison={() => {
								return false;
							}}
							rows={sortedValues}
							overscanRowCount={5}
							rowRender={({ index, row, style, redrawRow }) => {
								return (
									<div style={style} className="div-table-responsive-row">
										<ResponsiveTableColumn size={colSizing[0]} heading={"Client"}>
											<ClientName clientId={row.ChildClientIds[0]} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[1]} heading={"Shift"}>
											<NatTime startTime={row.StartTime} endTime={row.EndTime} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[2]} heading={"Skimpy"}>
											<ProfileStageName userId={row.UserId} />
										</ResponsiveTableColumn>

										<FormManager
											initialFormValues={{
												Rating: 0,
												Reappear: undefined,
												Notes: ""
											}}
											handleValidation={v => {
												v.required({
													fieldNames: ["Reappear", "Rating"]
												});
											}}
											handleSubmit={({
												formValues,
												handleSubmitFail,
												handleSubmitSuccess
											}) => {
												clientFeedbackPOST({
													ShiftId: row.ShiftId,
													Reappear: formValues.Reappear,
													Rating: formValues.Rating,
													Notes: formValues.Notes
												})
													.then(() => {
														handleSubmitSuccess();
														this.refreshFeedback();
													})
													.catch(() => {
														handleSubmitFail();
													});
											}}
										>
											{/*FormManager children*/}
											{({
												formValues,
												handleOnChange,
												handleSubmit,
												formValidation,
												submitEnabled,
												submitting
											}) => {
												return (
													<div>
														<ResponsiveTableColumn size={colSizing[3]} heading={"Rating"}>
															<FiveStars
																name={"Rating"}
																size={20}
																handleOnChange={handleOnChange}
																value={formValues.Rating}
															/>
														</ResponsiveTableColumn>

														<ResponsiveTableColumn
															size={colSizing[4]}
															heading={"Reappearance"}
														>
															<ReappearanceList
																onChange={handleOnChange}
																name={"Reappear"}
																value={formValues.Reappear}
															/>
														</ResponsiveTableColumn>

														<ResponsiveTableColumn size={10} heading={"Comment"}>
															<InputText
																onChange={handleOnChange}
																name={"Notes"}
																value={formValues.Notes}
															/>
														</ResponsiveTableColumn>
														<ResponsiveTableColumn size={2} heading={"Save"}>
															<AdminContainerMigrate
																align={"right"}
																buttons={[
																	<LoadingButtonWithConfirm
																		className="btn btn-primary"
																		onClick={handleSubmit}
																		loading={submitting}
																		disabled={!submitEnabled}
																	>
																		Save
																	</LoadingButtonWithConfirm>
																]}
															/>
														</ResponsiveTableColumn>
													</div>
												);
											}}
										</FormManager>
									</div>
								);
							}}
						/>
					);
				}}
			</ResponsiveTableNew>
		);
	}

	renderExistingFeedbackView() {
		const colSizing = [2, 2, 2, 2, 2, 2];

		return (
			<ResponsiveTableNew
				initialSortColumn={"CreatedAt"}
				headings={[
					{
						label: "Feedback Left On",
						name: "CreatedAt",
						size: colSizing[0],
						sortDescFunc: lessThanApiDate
					},
					{
						label: "Client",
						size: colSizing[1],
						disableSort: true
					},
					{
						label: "Shift",
						name: "StartTime",
						size: colSizing[2],
						sortDescFunc: lessThanApiDate
					},
					{
						label: "Skimpy",
						size: colSizing[3],
						disableSort: true
					},
					{
						label: "Rating",
						name: "FeedbackRating",
						size: colSizing[4]
					},
					{
						label: "Reappearance",
						name: "FeedbackReappearance",
						size: colSizing[5]
					}
				]}
				values={this.state.filteredFeedback}
			>
				{({ sortedValues }) => {
					return (
						<InfiniteScroll
							rowCount={sortedValues.length}
							rowComparison={() => {
								return false;
							}}
							rows={sortedValues}
							overscanRowCount={5}
							rowRender={({ index, row, style, redrawRow }) => {
								return (
									<div style={style} className="div-table-responsive-row">
										<ResponsiveTableColumn size={colSizing[0]} heading={"Date/Left by"}>
											<NatTime startTime={row.CreatedAt} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[1]} heading={"Client"}>
											<ClientName clientId={row.ChildClientIds[0]} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[2]} heading={"Shift"}>
											<NatTime startTime={row.StartTime} endTime={row.EndTime} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[3]} heading={"Skimpy"}>
											<ProfileStageName userId={row.UserId} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[4]} heading={"Rating"}>
											{/*{row.FeedbackRating}*/}
											<FiveStars value={row.FeedbackRating} disabled={true} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={colSizing[5]} heading={"Reappearance"}>
											<ReappearanceList disabled={true} value={row.FeedbackReappearance} />
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={12} heading={"Feedback Left By"}>
											<i>
												{this.props.usersById[row.AuthId] &&
													this.props.usersById[row.AuthId].Email}
											</i>
										</ResponsiveTableColumn>

										<ResponsiveTableColumn size={12} heading={"Comment"}>
											<b>{row.FeedbackNotes}</b>
										</ResponsiveTableColumn>
									</div>
								);
							}}
						/>
					);
				}}
			</ResponsiveTableNew>
		);
	}

	renderSearch() {
		return (
			<div>
				<div className="mini-container">
					<h4 className="heading">Search</h4>
					<div className="row">
						<FormManager
							initialFormValues={this.state.searchParams}
							handleValidation={v => {
								v.required({
									fieldNames: []
								});
							}}
							onChangeCallback={formValues => {
								this.setState(
									{
										searchParams: formValues,
										filteredFeedback: []
									},
									this.filterFeedback
								);
							}}
						>
							{/*FormManager children*/}
							{({
								formValues,
								handleOnChange,
								handleSubmit,
								formValidation,
								submitEnabled,
								submitting
							}) => {
								const startDateTitle =
									formValues.Pending === true
										? "Shift occurs after"
										: "Feedback Left After";

								return (
									<div>
										<SearchBarItem
											label={startDateTitle}
											value={
												<DateTimePicker
													name="StartDate"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.StartDate}
												/>
											}
										/>

										<SearchBarItem
											label={"Venue"}
											value={
												<ClientList
													name="ClientId"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.ClientId}
												/>
											}
										/>

										<SearchBarItem
											label={"Skimpy"}
											value={
												<SkimpyList
													name="UserId"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.UserId}
												/>
											}
										/>

										<SearchBarItem
											label={"Reappearance"}
											value={
												<ReappearanceList
													name="Reappearance"
													dayPickerOnly={true}
													onChange={handleOnChange}
													value={formValues.Reappearance}
												/>
											}
										/>

										<SearchBarItem
											label={"Only show shifts with no feedback"}
											value={
												<InputCheckbox
													name="Pending"
													onChange={handleOnChange}
													value={formValues.Pending}
												/>
											}
										/>
									</div>
								);
							}}
						</FormManager>
					</div>
				</div>
			</div>
		);
	}
}

AdminFeedback.propTypes = {};

const mapStateToProps = (state, props) => {
	return {
		usersById: userSelectorById(state)
	};
};

export default connect(mapStateToProps)(AdminFeedback);
