import * as React from "react";
import {css} from "emotion";
import VirtualButton from "./VirtualButton";
import {colors} from "../../global/colours";

export enum EButtonSizes {
	small = "s",
	medium = "m",
	large = "l"
}

export enum EButtonBackgroundColors {
	red = "Red",
	yellow = "Yellow",
	green = "Green",
	lightBlue = "LightBlue",
	blue = "Blue",
	grey = "Grey",
	white = "White",
}

class StyledButton extends React.Component<{
	size?: EButtonSizes;
	backgroundColor: EButtonBackgroundColors;
	onClick?: any;
	value?: any;
	classNameOverrides?: any;
	disabled?: Boolean;
}> {
	static defaultProps = {
		disabled: false
	};

	public configurationClassName = css`
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:focus {
			outline: none;
		}
		border: 0px;
		color: white;
		//letter-spacing: 1px;
		font-size: 15px;
		font-family: Roboto, "Microsoft YaHei", "Helvetica Neue", sans-serif !important;
		//text-transform: uppercase;
		font-weight: 500;
		text-align: center;
		padding: 8px 10px;
		box-sizing: border-box;
		line-height: initial;
		//text-shadow: 1px 1px 0 rgb(40, 40, 40);
		//box-shadow: 1px 1px 5px 0px #a0a0a0;

		svg,
		i {
			padding: 0px 5px;
			color: ${colors.white};
		}
		svg {
			width: 2em !important;
		}
	`;

	render() {
		let className = this.configurationClassName;

		if (this.props.size === EButtonSizes.small) {
			className = css([
				className,
				css`
					padding: 5px 8px;
				`
			]);
		}

		if (this.props.disabled) {
			className = css(
				className,
				css`
					&:hover {
						opacity: 0.5;
					}
				`
			);
		}

		switch (this.props.backgroundColor) {
			case EButtonBackgroundColors.red: {
				className = css([
					className,
					css`
						background-color: ${colors.red} !important;
						border: 1px solid ${colors.redAlt} !important;
						&:hover {
							background-color: ${colors.redAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.green: {
				className = css([
					className,
					css`
						background-color: ${colors.green} !important;
						border: 1px solid ${colors.greenAlt} !important;
						&:hover {
							background-color: ${colors.greenAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.yellow: {
				className = css([
					className,
					css`
						background-color: ${colors.yellow} !important;
						border: 1px solid ${colors.yellowAlt} !important;
						&:hover {
							background-color: ${colors.yellowAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.blue: {
				className = css([
					className,
					css`
						background-color: ${colors.blue} !important;
						border: 1px solid ${colors.blueAlt} !important;
						&:hover {
							background-color: ${colors.blueAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.lightBlue: {
				className = css([
					className,
					css`
						background-color: ${colors.lightBlue} !important;
						&:hover {
							background-color: ${colors.lightBlueAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.grey: {
				className = css([
					className,
					css`
						background-color: ${colors.grey} !important;
						color: black;
						&:hover {
							background-color: ${colors.greyAlt} !important;
						}
					`
				]);
				break;
			}
			case EButtonBackgroundColors.white: {
				className = css([
					className,
					css`
						background-color: ${colors.white} !important;
						color: black;
						&:hover {
							background-color: ${colors.grey} !important;
						}
					`
				]);
				break;
			}
		}
		if (this.props.classNameOverrides) {
			className = css([className, this.props.classNameOverrides]);
		}
		if (this.props.disabled) {
			className = css(
				className,
				css`
					cursor: not-allowed;
				`
			);
		}

		return (
			<VirtualButton
				onClick={!this.props.disabled ? this.props.onClick : null}
				value={this.props.value}
				className={className}
			>
				{this.props.children}
			</VirtualButton>
		);
	}
}

export default StyledButton;
