import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VenueHeading from "./components/VenueHeading";
import VenueGeneralInfo from "./components/VenueGeneralInfo";
import VenueLogos from "./components/VenueLogos";
import VenuePayments from "./components/VenuePayments";
import VenueReappearance from "./components/VenueReappearance";
import VenueNotes from "./components/VenueNotes";
import VenuePictures from "./components/VenuePictures";
import VenueTeamTalk from "./components/VenueTeamTalk";
import VenueUserAccounts from "./components/VenueUserAccounts";
import MiniContainer from "../components/container/MiniContainer";
import ClientUserList, {
	ClientUserListTypes
} from "../components/clientUserList/ClientUserList";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import PhoneNumberLink from "../components/links/PhoneNumberLink";
import { pollLoading } from "../poll/selectors";
import VenueAdminBar from "./components/VenueAdminBar";
import './Venue.css'

class Venue extends React.Component {
	render() {
		let clientId = -1;
		if (this.props.venueId === undefined) {
			clientId = parseInt(this.props.match.params.id, 10);
		} else {
			clientId = this.props.venueId;
		}

		return (
			<div className="row">
				<ProtectedComponent allowedGroups={[userGroups.admin]}>
					<div className="col-sm-12">
						<VenueAdminBar clientId={clientId} />
					</div>
				</ProtectedComponent>
				<div className="col-sm-12">
					<VenueHeading clientId={clientId} />
				</div>
				<div className="col-sm-6">
					<MiniContainer>
						<VenueLogos clientId={clientId} />
					</MiniContainer>
					<ProtectedComponent allowedGroups={[userGroups.client]}>
						<MiniContainer>
							<div>
								<h4 className="heading">Website Support</h4>
								Please call 0435SKIMPY (<PhoneNumberLink phoneNumber={"0435754679"} />)
								if you are having trouble using the website.
							</div>
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer>
						<VenueGeneralInfo clientId={clientId} />
					</MiniContainer>
					<MiniContainer>
						<VenuePayments clientId={clientId} />
					</MiniContainer>
					<MiniContainer className='first-col'>
						<VenueNotes clientId={clientId} />
					</MiniContainer>
					<MiniContainer>
						<VenueReappearance clientId={clientId} />
					</MiniContainer>
					<ProtectedComponent
						allowedGroups={[
							userGroups.admin,
							userGroups.hrm,
							userGroups.fullAccess,
							userGroups.restricted
						]}
					>
						<MiniContainer>
							<ClientUserList
								byClientId={clientId}
								listName={ClientUserListTypes.Hrms}
							/>
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer className='first-col'>
						<VenuePictures clientId={clientId} />
					</MiniContainer>
					<ProtectedComponent
						allowedGroups={[
							userGroups.admin,
							userGroups.hrm,
							userGroups.fullAccess,
							userGroups.trial
						]}
					>
						<MiniContainer className='first-col'>
							<VenueTeamTalk clientId={clientId} />
						</MiniContainer>
					</ProtectedComponent>
					<ProtectedComponent allowedGroups={[userGroups.hrm, userGroups.admin]}>
						<MiniContainer className='first-col'>
							<VenueUserAccounts clientId={clientId} />
						</MiniContainer>
					</ProtectedComponent>



					<MiniContainer>
						<ClientUserList
							byClientId={clientId}
							listName={ClientUserListTypes.Favourites}
						/>
					</MiniContainer>
					<ProtectedComponent
						allowedGroups={[userGroups.admin, userGroups.hrm, userGroups.client]}
					>
						<MiniContainer>
							<ClientUserList
								byClientId={clientId}
								listName={ClientUserListTypes.Mnrs}
							/>
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer>
						<ClientUserList
							byClientId={clientId}
							listName={ClientUserListTypes.Preapprovals}
						/>
					</MiniContainer>
				</div>
				<div className="col-sm-6">
					<MiniContainer className='second-col'>
						<VenueNotes clientId={clientId} />
					</MiniContainer>
					<MiniContainer className='second-col'>
						<VenuePictures clientId={clientId} />
					</MiniContainer>
					<ProtectedComponent
						allowedGroups={[
							userGroups.admin,
							userGroups.hrm,
							userGroups.fullAccess,
							userGroups.trial
						]}
					>
						<MiniContainer className='second-col'>
							<VenueTeamTalk clientId={clientId} />
						</MiniContainer>
					</ProtectedComponent>
					<ProtectedComponent allowedGroups={[userGroups.hrm, userGroups.admin]}>
						<MiniContainer className='second-col'>
							<VenueUserAccounts clientId={clientId} />
						</MiniContainer>
					</ProtectedComponent>
				</div>
			</div>
		);
	}
}

Venue.propTypes = {
	venueId: PropTypes.number
};

const mapStateToProps = state => {
	return {
		pollLoading: pollLoading(state)
	};
};

Venue = connect(mapStateToProps)(Venue);
export default Venue;
