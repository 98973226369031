import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { profilesAsArray, userSelectorById } from "../../poll/selectors";
import SkimpyList from "./SkimpyList";
import AdminContainerMigrate from "../container/AdminContainerMigrate";
import { PlusCircle, Trash } from "../icons/Icons";

class SkimpyListAddRemove extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedUserId: undefined
		};
	}

	handleAddSelectedUserId = () => {
		let selectedUserIds = this.props.value;
		selectedUserIds.push(this.state.selectedUserId);

		const newEvent = {
			target: {
				name: this.props.name,
				value: selectedUserIds
			}
		};

		this.props.handleOnChange(newEvent);

		this.setState({
			selectedUserId: undefined
		});
	};

	handleAddAllUserIds = () => {
		let filteredProfiles = this.props.profilesAsArray.filter(profile => {
			// hide user if UserGroupId is Inactive
			return this.props.usersById[profile.UserId].UserGroupId !== 0;
		});

		filteredProfiles.sort((a, b) => {
			return a.StageName.localeCompare(b.StageName);
		});

		this.props.handleOnChange({
			target: {
				name: this.props.name,
				value: filteredProfiles.map(profile => {
					return profile.UserId;
				})
			}
		});

		this.setState({
			selectedUserId: undefined
		});
	};

	handleRemoveAllUserIds = () => {
		this.props.handleOnChange({
			target: {
				name: this.props.name,
				value: []
			}
		});

		this.setState({
			selectedUserId: undefined
		});
	};

	render() {
		return (
			<div className="row">
				<div className="col-sm-8">
					<SkimpyList
						hideUserIds={this.props.value}
						value={this.state.selectedUserId}
						onChange={e => {
							this.setState({
								selectedUserId: e.target.value
							});
						}}
					/>
				</div>
				<div className="col-sm-4">
					<AdminContainerMigrate
						buttons={[
							<button
								className="btn btn-success"
								disabled={this.state.selectedUserId === undefined}
								onClick={this.handleAddSelectedUserId}
							>
								<PlusCircle />
							</button>,
							<button className="btn btn-success" onClick={this.handleAddAllUserIds}>
								<PlusCircle /> All
							</button>,
							<button className="btn btn-danger" onClick={this.handleRemoveAllUserIds}>
								<Trash /> All
							</button>
						]}
					/>
				</div>
			</div>
		);
	}
}

SkimpyListAddRemove.propTypes = {
	handleOnChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.array
};

const mapStateToProps = state => {
	return {
		profilesAsArray: profilesAsArray(state),
		usersById: userSelectorById(state)
	};
};

export default connect(mapStateToProps)(SkimpyListAddRemove);
