import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import {
	profileByUserId,
	profileSelectorById,
	selectClientUserLists
} from "../../poll/selectors";
import store from "../../store";

export function loggedInUserIsInactive() {
	return userInfo().userGroupId === userGroups.inactive;
}

export function loggedInUserIsTrial() {
	return userInfo().userGroupId === userGroups.trial;
}

export function loggedInUserIsRestricted() {
	return userInfo().userGroupId === userGroups.restricted;
}

export function loggedInUserIsfullAccess() {
	return userInfo().userGroupId === userGroups.fullAccess;
}

export function loggedInUserIsAdmin() {
	return userInfo().userGroupId === userGroups.admin;
}

export function loggedInUserIsClient() {
	return userInfo().userGroupId === userGroups.client;
}

export function loggedInUserIsAdminHRMOrVenue () {
	const {userGroupId} = userInfo()
	return [userGroups.admin, userGroups.hrm, userGroups.client].indexOf(userGroupId) > -1
}

export function loggedInUserIsHrmOfClientId(clientId) {
	const hrms = selectClientUserLists(store.getState())["byUserId"]["Hrms"];
	// get the list of venues the logged in user is HRM at
	if (!(userInfo().userId in hrms)) {
		return false;
	}

	// filter list by client Id for match
	if (
		hrms[userInfo().userId].filter(e => {
			return e.ClientId === clientId;
		}).length !== -1
	) {
		return true;
	}

	return false;
}

export function loggedInUserIs(userId) {
	return userInfo().userId === userId;
}

export function profileIdBelongsToUserId(profileId) {
	const profilesById = profileSelectorById(store.getState());
	if (!(profileId in profilesById)) {
		throw profileId + " is not a valid profile id";
	}

	return profilesById[profileId].UserId;
}

export function userIdBelongsToProfileId(userId) {
	const profilesByUserId = profileByUserId(store.getState());

	if (!(userId in profilesByUserId)) {
		throw userId + " is not a valid profile id";
	}

	return profilesByUserId[userId].Id;
}
