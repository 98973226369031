import * as React from "react";
import PropTypes from "prop-types";
import "./DisplayHtml.css";
import decodeHtmlOld from "decode-html"
import { decodeHTML } from "entities/lib";


// Class will be replaced once db has been rewritten to handle json or cleaned HTML valuess
export default class DisplayHtml extends React.Component {

	render() {
		if (!this.props.value) {
			return null;
		}

		let parsedHtml = decodeHtmlOld(this.props.value);
		parsedHtml = decodeHTML(parsedHtml)

		return (
			<div className="display-html" dangerouslySetInnerHTML={{ __html: parsedHtml }} />
		);
	}
}

DisplayHtml.propTypes = {
	value: PropTypes.string
};
