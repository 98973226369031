import * as React from "react";
import PropTypes from "prop-types";

export default class CollapsibleMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			collapsed: this.props.initCollapsed
		};
	}

	handleToggleCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed
		});
	};

	render() {
		return (
			<span>
				<li className="left_menu_item_heading" onClick={this.handleToggleCollapse}>
					<span style={{ color: "white" }}>
						<i className={this.props.icon} /> {this.props.heading}
					</span>
					<span className="pull-right">
						{this.state.collapsed ? (
							<i
								style={{ color: "white", paddingRight: 5 }}
								className="fas fa-caret-right"
							/>
						) : (
							<i
								style={{ color: "white", paddingRight: 5 }}
								className="fas fa-caret-down"
							/>
						)}
					</span>
				</li>
				{this.state.collapsed ? null : this.props.children}
			</span>
		);
	}
}

CollapsibleMenu.propTypes = {
	heading: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	initCollapsed: PropTypes.bool,
	collapsable: PropTypes.bool
};
