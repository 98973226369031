import * as React from "react";
import { css } from "emotion";
import * as PropTypes from "prop-types";
import { colors } from "../../../global/colours";
import Money from "../../../components/text/Money";

class PaymentRow extends React.PureComponent {
	paymentRowCss = css`
		display: grid;
		grid-template-columns: 30px 1fr 1fr;
		div:nth-child(1),
		div:nth-child(2) {
			text-align: right;
		}
		div:nth-child(3) {
			padding-left: 5px;
			text-align: left;
		}
	`;

	render() {
		let colCss = null,
			value = this.props.value;

		if (this.props.textIsBold) {
			colCss = css`
				font-weight: bold;
			`;
		}
		if (this.props.hasTopBlackBorder) {
			colCss = css`
				${colCss};
				border-top: solid black 1px;
			`;
		}

		if (this.props.textIsRed) {
			colCss = css`
				${colCss};
				color: ${colors.red};
			`;
		}

		if (this.props.valueIsMoney) {
			value = <Money value={this.props.value} />;
		}

		return (
			<div className={this.paymentRowCss}>
				<div className={colCss}>
					<span>{this.props.icon || ""}</span>
				</div>
				<div className={colCss}>
					<span>{value}</span>
				</div>
				<div className={colCss}>
					<span>{this.props.label}</span>
				</div>
			</div>
		);
	}
}

PaymentRow.propTypes = {
	icon: PropTypes.any,
	value: PropTypes.any.isRequired,
	label: PropTypes.any.isRequired,
	textIsBold: PropTypes.bool,
	textIsRed: PropTypes.bool,
	valueIsMoney: PropTypes.bool,
	hasTopBlackBorder: PropTypes.bool
};

export default PaymentRow;
