import * as React from "react";
import PropTypes from "prop-types";
import { natTimeFromAPIDateAsText } from "../../lib/dateUtils/dateUtils";

export default class NatTime extends React.Component {
	render() {
		return natTimeFromAPIDateAsText({
			startTime: this.props.startTime,
			endTime: this.props.endTime
		});
	}
}

NatTime.defaultProps = {
	endTime: ""
};

NatTime.propTypes = {
	startTime: PropTypes.string.isRequired,
	endTime: PropTypes.string
};
