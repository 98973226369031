import * as React from "react";
import PropTypes from "prop-types";
import { notificationPOST } from "../api/apiEndpoints";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import InputTextArea from "../components/input/InputTextArea";
import FormManager from "../components/form/FormManager";
import FormRow from "../components/form/FormRow";
import LoadingButtonWithConfirm from "../components/button/LoadingButtonWithConfirm";
import axios from "axios";
import SkimpyListAddRemove from "../components/select/SkimpyListAddRemove";
import ProfileStageName from "../components/links/ProfileStageName";
import { Trash } from "../components/icons/Icons";

class SendNotifications extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<FormManager
					initialFormValues={{
						Message: "",
						usersToMessage: this.props.initialUserIdsSelected
					}}
					handleValidation={v => {
						v.required({
							fieldNames: ["Message"]
						});
					}}
					handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
						const bulkNotificationPOST = formValues.usersToMessage.map(userId => {
							return notificationPOST({ UserId: userId, Message: formValues.Message });
						});

						axios
							.all(bulkNotificationPOST)
							.then(() => {
								handleSubmitSuccess();
								this.props.handleOnSendSuccess();
							})
							.catch(() => {
								handleSubmitFail();
							});
					}}
				>
					{/*FormManager children*/}
					{({
						formValues,
						handleOnChange,
						handleSetFormValues,
						handleSubmit,
						formValidation,
						submitEnabled,
						submitting
					}) => {
						return (
							<div>
								<FormRow
									label={"Message"}
									value={
										<InputTextArea
											name={"Message"}
											value={formValues.Message}
											onChange={handleOnChange}
											validationMessages={formValidation.Message}
										/>
									}
								/>
								<FormRow
									label={"Skimpies"}
									value={
										<div>
											<SkimpyListAddRemove
												value={formValues.usersToMessage}
												name={"usersToMessage"}
												handleOnChange={handleOnChange}
											/>
										</div>
									}
								/>

								{formValues.usersToMessage.map((userId, userTMIdx) => {
									return (
										<div className="row" style={{ padding: 3 }} key={userId}>
											<div className="col-sm-12">
												<button
													className="btn btn-danger"
													onClick={() => {
														let selectedUserCopy = formValues.usersToMessage;
														selectedUserCopy.splice(userTMIdx, 1);
														handleSetFormValues({
															...formValues,
															usersToMessage: selectedUserCopy
														});
													}}
												>
													<Trash />
												</button>
												<span style={{ marginLeft: 10 }}>
													<ProfileStageName userId={userId} />
												</span>
											</div>
										</div>
									);
								})}
								<AdminContainerMigrate
									align={"right"}
									buttons={[
										<LoadingButtonWithConfirm
											className="btn btn-primary"
											onClick={handleSubmit}
											loading={submitting}
											disabled={!submitEnabled || formValues.usersToMessage.length === 0}
										>
											<i className="fas fa-envelope" /> Send Notifications
										</LoadingButtonWithConfirm>
									]}
								/>
							</div>
						);
					}}
				</FormManager>
			</div>
		);
	}
}

SendNotifications.propTypes = {
	handleOnSendSuccess: PropTypes.func,
	initialUserIdsSelected: PropTypes.array
};

export default SendNotifications;
