import * as React from "react";
import PropTypes from "prop-types";
import { uploadSections } from "../../api/apiEndpoints";

class RenderRSA extends React.Component {
	static propTypes = {
		stageName: PropTypes.string.isRequired,
		profileId: PropTypes.number.isRequired,
		uploadsByProfileIdByUploadSectionType: PropTypes.object
	};

	render() {
		if (
			!(this.props.profileId in this.props.uploadsByProfileIdByUploadSectionType)
		) {
			return <span>Certificate unavailable</span>;
		}

		if (
			!(
				uploadSections.profile_rsa in
				this.props.uploadsByProfileIdByUploadSectionType[this.props.profileId]
			)
		) {
			return <span>RSA unavailable</span>;
		}

		const src = this.props.uploadsByProfileIdByUploadSectionType[
			this.props.profileId
		][uploadSections.profile_rsa].Path;

		return (
			<a href={src} target="_blank">
				<button className={"btn-clear color-nice-blue"}>Download Certificate</button>
			</a>
		);
	}
}

export default RenderRSA;
