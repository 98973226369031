import * as React from "react";
import PropTypes from "prop-types";
import ResponsiveTableNew from "../../../components/table/ResponsiveTableNew";
import { calendarPOST } from "../../../api/apiEndpoints";
import ResponsiveTableColumn from "../../../components/table/ResponsiveTableColumn";
import ProfileStageName from "../../../components/links/ProfileStageName";
import ProfileWorkPhone from "../../../components/links/ProfileWorkPhone";
import ProfileWorkFacebook from "../../../components/links/ProfileWorkFacebook";
import { findFavouritesByClientId, profileByUserId } from "../../../poll/selectors";
import { connect } from "react-redux";
import { fromArrayToObjectByKey } from "../../../lib/arrayObj/arrayObj";
import { userIsDeleted } from "../../../lib/filter/filterDeleted";

class ListKFWers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			kfwers: [],
			favourites: []
		};
	}

	componentDidMount() {
		calendarPOST({
			TypeId: 1,
			StartTime: this.props.shift.StartTime,
			EndTime: this.props.shift.EndTime
		}).then(d => {
			let calendarEntries = !d.data ? [] : d.data;

			// looking at the calendar over multiple days (ie for multiday shifts) will return multiple rows
			// this removes duplicate user ids from the list
			let seenUserIds = {};
			calendarEntries = calendarEntries
				.filter(calendarEntry => {
					if (calendarEntry.UserId in seenUserIds) {
						return false;
					}

					if (userIsDeleted(calendarEntry.UserId)) {
						return false;
					}

					if (this.props.profilesByUserId[calendarEntry.UserId].KFWHidden) {
						return false;
					}

					seenUserIds[calendarEntry.UserId] = true;
					return true;
				})
				.map(calendarEntry => {
					return {
						StageName: this.props.profilesByUserId[calendarEntry.UserId].StageName,
						...calendarEntry
					};
				});

			const calendarEntriesUserIds = fromArrayToObjectByKey(
				"UserId",
				this.props.calendarEntries
			);

			const favourites = this.props.clientFavouritesArray
				.filter(favourite => {
					if (userIsDeleted(favourite.UserId)) {
						return false;
					}

					if (this.props.profilesByUserId[favourite.UserId].KFWHidden) {
						return false;
					}

					return !(favourite.UserId in calendarEntriesUserIds);
				})
				.map(favourite => {
					return {
						StageName: this.props.profilesByUserId[favourite.UserId].StageName,
						...favourite
					};
				});

			this.setState({
				kfwers: calendarEntries,
				favourites: favourites
			});
		});
	}

	render() {
		return (
			<div className="row">
				<div className="col-sm-12">
					<h4>Please contact these keen for work members</h4>
					<ResponsiveTableNew
						initialSortColumn={"StageName"}
						headings={[
							{
								label: "Skimpy",
								name: "StageName",
								size: 2
							},
							{
								label: "Phone",
								size: 2,
								disableSort: true
							},
							{
								label: "Facebook",
								size: 2,
								disableSort: true
							},
							{
								label: "KFW Notes",
								size: 6,
								disableSort: true
							}
						]}
						values={this.state.kfwers}
					>
						{({ sortedValues }) => {
							return (
								<div>
									{sortedValues.map((cal, calIdx) => {
										return (
											<div key={calIdx} className="row div-table-responsive-row">
												<ResponsiveTableColumn size={2} heading={"Skimpy"}>
													<ProfileStageName userId={cal.UserId} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={2} heading={"Phone"}>
													<ProfileWorkPhone userId={cal.UserId} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={2} heading={"Facebook"}>
													<ProfileWorkFacebook userId={cal.UserId} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={6} heading={"KFW Notes"}>
													{"Notes" in cal && cal.Notes}
												</ResponsiveTableColumn>
											</div>
										);
									})}
								</div>
							);
						}}
					</ResponsiveTableNew>
				</div>
				<div className="col-sm-12">
					<h4>Please contact these venue favourites</h4>
					<ResponsiveTableNew
						initialSortColumn={"StageName"}
						headings={[
							{
								label: "Skimpy",
								name: "StageName",
								size: 4
							},
							{
								label: "Phone",
								size: 4,
								disableSort: true
							},
							{
								label: "Facebook",
								size: 4,
								disableSort: true
							}
						]}
						values={this.state.favourites}
					>
						{({ sortedValues }) => {
							return (
								<div>
									{sortedValues.map((cal, calIdx) => {
										return (
											<div key={calIdx} className="row div-table-responsive-row">
												<div className="col-sm-12">
													<ResponsiveTableColumn size={4} heading={"Skimpy"}>
														<ProfileStageName userId={cal.UserId} />
													</ResponsiveTableColumn>

													<ResponsiveTableColumn size={4} heading={"Phone"}>
														<ProfileWorkPhone userId={cal.UserId} />
													</ResponsiveTableColumn>

													<ResponsiveTableColumn size={4} heading={"Facebook"}>
														<ProfileWorkFacebook userId={cal.UserId} />
													</ResponsiveTableColumn>
												</div>
											</div>
										);
									})}
								</div>
							);
						}}
					</ResponsiveTableNew>
				</div>
			</div>
		);
	}
}

ListKFWers.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		clientFavouritesArray: findFavouritesByClientId(state, props.shift.ClientId),
		profilesByUserId: profileByUserId(state)
	};
};

export default connect(mapStateToProps)(ListKFWers);
