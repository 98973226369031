import * as React from "react";
import { connect } from "react-redux";
import {
	advertsGET,
	advertsUPDATE,
	advertsDELETE
} from "../../api/apiEndpoints";
import Spinner from "../../components/loading/Spinner";
import { clientSelectorById } from "../../poll/selectors";
import MiniContainer from "../../components/container/MiniContainer";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import ResponsiveTableNew from "../../components/table/ResponsiveTableNew";
import FormManager from "../../components/form/FormManager";
import InputTextArea from "../../components/input/InputTextArea";
import InputText from "../../components/input/InputText";
import FormRow from "../../components/form/FormRow";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import { Save, Trash } from "../../components/icons/Icons";
import DateTimePicker from "../../components/datetime/DateTimePicker";
import ClientList from "../../components/select/ClientList";
import { convertDDMMYYYToJSDate } from "../../lib/dateUtils/dateUtils";
import ToggleSlider from "../../components/Toggle/ToggleSlider";
import StyledButton, {
	EButtonBackgroundColors
} from "../../styled/Button/StyledButton";
import InputCheckbox from "../../components/input/InputCheckbox";
import { routes } from "../../router/router";
import * as dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customDateFormat);

import "./AdvertDetails.css";

class AdvertDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			id: null,
			ad: null,
			err: null
		};
	}

	componentDidMount() {
		const id = parseInt(this.props.match.params.id, 10);
		this.setState({ ...this.state, id });
	}

	componentDidUpdate(prevProps, prevState) {
		if (!!this && !prevState.id && !!this.state.id) {
			(async () => {
				try {
					const ad = await advertsGET(this.state.id);
					console.log(ad);
					this.setState({ ...this.state, loading: false, ad: ad.data });
				} catch (err) {
					this.setState({ ...this.state, loading: false, err });
				}
			})();
		}
		console.log(this.state);
	}

	async deleteAdvert() {
		console.log(this.props);
		try {
			this.setState({ ...this.state, loading: true });
			await advertsDELETE(this.state.id);
			this.props.history.push(routes.Adverts);
		} catch (err) {
			console.error(err);
		}
		this.setState({ ...this.state, loading: false });
	}

	render() {
		return (
			<Spinner loading={this.state.loading}>
				{!!this.state.err ? <div className="error">an error occured</div> : []}
				{!!this.state.ad ? (
					<div className="adDetailsGridWrapper">
						<h2 style={{ gridColumn: "1/-1" }} className="heading">
							Edit Advert
						</h2>
						<div style={{ gridColumn: "1/-1" }}>
							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButtonWithConfirm
										onClick={() => this.deleteAdvert()}
										loading={this.state.loading}
										disabled={false}
										className="btn btn-danger"
									>
										<Trash /> Permanently Delete
									</LoadingButtonWithConfirm>
								]}
							/>
						</div>

						<MiniContainer>
							<FormManager
								initialFormValues={{
									Id: this.state.ad.id,
									ClientId: this.state.ad.client_id || null,
									Path: this.state.ad.raw_path,
									Hyperlink: this.state.ad.hyperlink,
									AltText: this.state.ad.alt_text,
									Comments: this.state.ad.comments,
									ExpiresAt: this.state.ad.expires_at,
									IsActive: this.state.ad.is_active
								}}
								handleValidation={v => {
									v.required({
										fieldNames: ["Id", "Path"]
									});
								}}
								handleSubmit={async ({
									formValues,
									handleSubmitFail,
									handleSubmitSuccess
								}) => {
									// api call here
									try {
										const payload = { ...formValues };
										payload.ExpiresAt = !payload.ExpiresAt
											? null
											: `${payload.ExpiresAt}`.indexOf("/") > -1
												? dayjs(payload.ExpiresAt, "DD/MM/YYYY").toDate()
												: dayjs(payload.ExpiresAt).toDate();
										Object.keys(payload).forEach(k => {
											if (payload[k] === undefined) {
												payload[k] = null
											}
										})
										console.log(payload);
										const res = await advertsUPDATE(payload);
										handleSubmitSuccess();
										this.setState({ ...this.state, ad: res.data });
										this.props.history.push(routes.Adverts);
									} catch (err) {
										console.error(err);
										handleSubmitFail(err);
									}
								}}
							>
								{({
									formValues,
									handleOnChange,
									handleSubmit,
									formValidation,
									submitEnabled,
									submitting
								}) => {
									return (
										<div>
											<input type="hidden" value={formValues.Id} name="Id" />
											<FormRow
												label={"Expires At"}
												value={
													<div className="expiresAtWrapper">
														<DateTimePicker
															name="ExpiresAt"
															dayPickerOnly={true}
															onChange={handleOnChange}
															value={formValues.ExpiresAt}
														/>
														{!!formValues.ExpiresAt ? (
															<button
																className="btn btn-default"
																onClick={() =>
																	handleOnChange({ target: { name: "ExpiresAt", value: null } })
																}
															>
																Never
															</button>
														) : (
															[]
														)}
													</div>
												}
											/>
											<FormRow
												label={"Is Active"}
												value={
													<InputCheckbox
														name={"IsActive"}
														value={formValues.IsActive}
														onChange={handleOnChange}
														validationMessages={formValidation.IsActive}
													/>
												}
											/>
											<FormRow
												label={"Venue"}
												value={
													<ClientList
														name={"ClientId"}
														onChange={handleOnChange}
														value={formValues.ClientId}
													/>
												}
											/>
											<FormRow
												label={"Web link"}
												value={
													<InputText
														name={"Hyperlink"}
														value={formValues.Hyperlink}
														onChange={handleOnChange}
														validationMessages={formValidation.Hyperlink}
													/>
												}
											/>
											<FormRow
												label={"Alt Text"}
												value={
													<InputText
														name={"AltText"}
														value={formValues.AltText}
														onChange={handleOnChange}
														validationMessages={formValidation.AltText}
													/>
												}
											/>
											<FormRow
												label={"Path"}
												value={
													<InputText
														name={"Path"}
														value={formValues.Path}
														onChange={handleOnChange}
														validationMessages={formValidation.Path}
														disabled={true}
													/>
												}
											/>
											<FormRow
												label={"Comments"}
												value={
													<InputTextArea
														name={"Comments"}
														value={formValues.Comments}
														onChange={handleOnChange}
														validationMessages={formValidation.Comments}
													/>
												}
											/>

											<AdminContainerMigrate
												align={"right"}
												buttons={[
													<LoadingButtonWithConfirm
														onClick={handleSubmit}
														loading={submitting}
														disabled={!submitEnabled}
														className="btn btn-primary"
													>
														<Save /> Update Advert
													</LoadingButtonWithConfirm>
												]}
											/>
										</div>
									);
								}}
							</FormManager>
						</MiniContainer>
						<MiniContainer>
							<UploadRenderer showThumbnail={false} src={this.state.ad.sas_path} />
						</MiniContainer>
					</div>
				) : (
					[]
				)}
			</Spinner>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		clients: clientSelectorById(state)
	};
};

export default connect(mapStateToProps)(AdvertDetails);
