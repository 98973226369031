import * as React from "react";
import PropTypes from "prop-types";

export default class InputTextArea extends React.Component {
	static defaultProps = {
		inputClassName: "form-control",
		validaitonClassName: "form-validation-box",
		rows: 5,
		style: { resize: "none" }
	};

	static propTypes = {
		name: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		inputClassName: PropTypes.string,
		validaitonClassName: PropTypes.string,
		validationMessages: PropTypes.arrayOf(PropTypes.string),
		rows: PropTypes.number,
		style: PropTypes.object
	};

	render() {
		return (
			<div>
				<textarea
					style={this.props.style}
					rows={this.props.rows}
					name={this.props.name}
					className={this.props.inputClassName}
					onChange={this.props.onChange}
					value={this.props.value}
				/>

				{this.props.validationMessages &&
					this.props.validationMessages.map((message, idx) => {
						return (
							<div className={this.props.validaitonClassName} key={idx}>
								{message}
							</div>
						);
					})}
			</div>
		);
	}
}
