import PropTypes from "prop-types";
import * as React from "react";
import LoadingButton from "./LoadingButton";
import "./LoadingButtonWithConfirm.css";

class LoadingButtonWithConfirm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dialogueOpen: false,
			clickedElement: {
				x: -1,
				y: -1,
				width: -1,
				height: -1
			}
		};
	}

	handleOpenDialogue = e => {
		this.setState({
			dialogueOpen: true,
			clickedElement: {
				x: e.target.offsetLeft,
				y: e.target.offsetTop,
				width: e.target.offsetWidth,
				height: e.target.offsetHeight
			}
		});
	};

	handleCloseDialogue = () => {
		this.setState({
			dialogueOpen: false
		});
	};

	handleConfirmClick = e => {
		this.handleCloseDialogue(e);
		this.props.onClick(e);
	};

	render() {
		const confirmWidth = this.props.confirmWidth;
		const confirmHeight = this.props.confirmHeight;

		let anchor = {};
		switch (this.props.confirmAnchor) {
			case "bottom-left":
				anchor.top = this.state.clickedElement.y + this.state.clickedElement.height;
				anchor.left = this.state.clickedElement.x;
				break;

			case "bottom-right":
				anchor.top = this.state.clickedElement.y + this.state.clickedElement.height;
				anchor.left =
					this.state.clickedElement.x -
					confirmWidth +
					this.state.clickedElement.width;
				break;

			case "top-right":
			default:
				anchor.top = this.state.clickedElement.y - confirmHeight;
				anchor.left =
					this.state.clickedElement.x -
					confirmWidth +
					this.state.clickedElement.width;
		}

		return (
			<span>
				{this.state.dialogueOpen && (
					<span
						className="confirm-dialogue"
						style={{
							top: anchor.top,
							left: anchor.left,
							height: confirmHeight,
							width: confirmWidth
						}}
					>
						<div>
							<div>
								<button
									className="btn-default"
									style={{ float: "right" }}
									onClick={this.handleCloseDialogue}
								>
									<i className="fas fa-times color-fail" />
								</button>
								<h4>Confirmation Required</h4>
							</div>

							{this.props.confirmMessage}
						</div>
						<div className="pull-right">
							<LoadingButton onClick={this.handleConfirmClick}>
								<i className="fas fa-check-circle " /> Confirm
							</LoadingButton>
						</div>
					</span>
				)}

				<LoadingButton
					title={this.props.title}
					className={this.props.className}
					onClick={this.handleOpenDialogue}
					disabled={
						this.props.loading || this.props.disabled || this.state.dialogueOpen
					}
					loading={this.props.loading}
					style={this.props.style}
					id={this.props.id}
				>
					{this.props.children}
				</LoadingButton>
			</span>
		);
	}
}

LoadingButtonWithConfirm.propTypes = {
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	style: PropTypes.object,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	title: PropTypes.string,
	id: PropTypes.string,

	confirmMessage: PropTypes.string,
	confirmHeight: PropTypes.number,
	confirmWidth: PropTypes.number,
	confirmAnchor: PropTypes.string
};

LoadingButtonWithConfirm.defaultProps = {
	confirmMessage:
		"Performing this action requires confirmation - are you sure you wish to proceed?",
	confirmHeight: 150,
	confirmWidth: 300
};

export default LoadingButtonWithConfirm;
