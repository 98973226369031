import React from 'react'
import {BUILD_ID, APP_ENV} from '../../config'

const version = `v1.0.${BUILD_ID} ${APP_ENV}`
console.log('APP VERSION', version)

const AppVersion = () => {

  return (
    <span>
      {version}
    </span>
  )
}

export default AppVersion