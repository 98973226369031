import * as React from "react";
import PropTypes from "prop-types";
import { clientsPATCH } from "../../api/apiEndpoints";
import LoadingButton from "../../components/button/LoadingButton";
import { clientStore } from "../../stores/clientStore";
import { observer } from "mobx-react";
import { IClient } from "../../models/client";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import FormManager from "../../components/form/FormManager";
import InputHtml from "../../components/input/InputHtml";

class EditVenueNotes extends React.Component {
	handleFetchNotes = () => {
		clientStore.fetchById([this.props.clientId], ["Notes"]);
	};

	componentDidMount() {
		this.handleFetchNotes();
	}

	render() {
		if (!clientStore.clientsById.has(this.props.clientId)) {
			return "Loading...";
		}
		const client = clientStore.clientsById.get(this.props.clientId);

		return (
			<FormManager
				initialFormValues={{
					Notes: client.Notes,
					Id: this.props.clientId
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					clientsPATCH({
						Notes: formValues.Notes,
						Id: this.props.clientId
					})
						.then(() => {
							this.handleFetchNotes();
							handleSubmitSuccess();
							this.props.handleSaveFn();
						})
						.catch(error => {
							handleSubmitFail();
							throw error;
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<InputHtml
								name={"Notes"}
								value={formValues.Notes}
								onChange={handleOnChange}
								validationMessages={formValidation.Notes}
								allowColorInput={true}
								allowSizeInput={true}
								allowTextFormattingInput={true}
								allowBulletPointInput={true}
								allowLinkInput={true}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										Save
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}
EditVenueNotes.propTypes = {
	clientId: PropTypes.number.isRequired,
	handleSaveFn: PropTypes.func.isRequired
};

export default observer(EditVenueNotes);
