import * as React from "react";
import PropTypes from "prop-types";
import NatTime from "../../../components/text/NatTime";
import ClientNames from "../../../components/links/ClientNames";
import { shiftActionPOST } from "../../../api/apiEndpoints";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { Save } from "../../../components/icons/Icons";

export default class UndoMakeShiftGenerallyAvailable extends React.Component {
	render() {
		return (
			<div>
				<h4 className="heading">Re-book In My Name</h4>

				<p>Re-book this shift in my name?</p>
				<div>
					<b>
						<ClientNames clientIds={this.props.shift.ChildClientIds} />
					</b>
				</div>
				<div>
					<NatTime
						startTime={this.props.shift.StartTime}
						endTime={this.props.shift.EndTime}
					/>
				</div>

				<AdminContainerMigrate
					align="right"
					buttons={[
						<button
							className="btn btn-success"
							onClick={() => {
								shiftActionPOST({ Action: "book", ShiftId: this.props.shift.Id }).then(
									() => {
										this.props.handleCloseModal();
									}
								);
							}}
						>
							<Save /> Re-book In My Name
						</button>
					]}
				/>
			</div>
		);
	}
}

UndoMakeShiftGenerallyAvailable.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};
