import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hrmsSelectorAsArray, profileByUserId } from "../../poll/selectors";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";

class AllHrmsList extends React.Component {
	render() {
		// label, value
		// Object.keys(this.props.client).forEach((clientId) => {
		//     options.push({ value: Number(clientId), label: this.props.client[clientId].ClientName })
		// })
		let uniqueHrmUserId = {};
		this.props.hrmsAsArray.forEach(hrm => {
			uniqueHrmUserId[hrm.UserId] = undefined;
		});

		const options = Object.keys(uniqueHrmUserId).map(userId => {
			return {
				value: Number(userId),
				label: this.props.profilesByUserId[userId].StageName
			};
		});

		return (
			<Select
				options={options}
				name={this.props.name}
				onChange={this.props.onChange}
				value={this.props.value}
			/>
		);
	}
}

AllHrmsList.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any
};

const mapStateToProps = state => {
	return {
		hrmsAsArray: hrmsSelectorAsArray(state),
		profilesByUserId: profileByUserId(state)
	};
};

export default connect(mapStateToProps)(AllHrmsList);
