import DateFns from "date-fns";
import dayjs from "dayjs";
import { padLeft } from "../formatting/padding";

export const JS_DAY_TO_TEXT = {
	0: "Sunday",
	1: "Monday",
	2: "Tuesday",
	3: "Wednesday",
	4: "Thursday",
	5: "Friday",
	6: "Saturday"
};

export function validAPIDate(apiDate = "") {
	return /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i.test(
		apiDate
	);
}

export function parseAPIDate(apiDate = "") {
	// Expected format "2017-09-08T19:00:00+08:00"
	if (apiDate.indexOf("Z") !== -1) {
		console.log("Trying to convert time without a timezone!", apiDate);
	}

	// console.log("parsing", apiDate.slice(0,19))
	return DateFns.parse(apiDate.slice(0, 19));
}

// dd/mm/yyyy to new Date()
export function convertDDMMYYYToJSDate(ddmmyyyy) {
	const dateParts = ddmmyyyy.split("/");

	// -1 when setting month as dates are 0-11
	return new Date(
		dateParts[2],
		Number(dateParts[1]) - 1,
		dateParts[0],
		0,
		0,
		0,
		0
	);
}

// apiDate => dd/mm/yyyy
export function convertAPIDateToDDMMYYYY(apiDate) {
	if (apiDate === null) {
		return apiDate;
	}
	const jsDate = parseAPIDate(apiDate);

	return (
		padLeft(String(jsDate.getDate()), "00") +
		"/" +
		padLeft(String(jsDate.getMonth() + 1), "00") +
		"/" +
		String(jsDate.getFullYear())
	);
}

export function newDateWithoutSeconds() {
	return DateFns.setSeconds(new Date(), 0);
}

export function convertJSDateToDDMMYYYY(d) {
	return (
		padLeft(String(d.getDate()), "00") +
		"/" +
		padLeft(String(d.getMonth() + 1), "00") +
		"/" +
		String(d.getFullYear())
	);
}

export function convertDateToAPIDate(d) {
	//https://stackoverflow.com/a/643827
	const dateType = Object.prototype.toString.call(d);

	if (dateType === "[object Date]") {
		return DateFns.format(d, "YYYY-MM-DDTHH:mm:ss.SSS+08:00");
	} else if (dateType === "[object String]") {
		return d;
	} else {
		throw "Can't convert a " + dateType + " to API date, must be a Date object";
	}
}

export function offsetApiTime(apiDate = "", shiftLengthMinutes) {
	let d = parseAPIDate(apiDate);
	return convertDateToAPIDate(DateFns.addMinutes(d, shiftLengthMinutes));
}

export function differenceInMinutes(date1, date2) {
	return DateFns.differenceInMinutes(date1, date2);
}

export function differenceInHours(date1, date2) {
	return DateFns.differenceInHours(date1, date2);
}

const currentYear = new Date().getFullYear();

/**
 *  https://date-fns.org/v1.28.5/docs/format
 */
export function natTimeFromAPIDateAsText({ startTime, endTime = "" }) {
	let showStartYearText = " ";
	let showEndYearText = " ";

	const startTimeJSDate = parseAPIDate(startTime);
	let endTimeJSDate = "";
	if (endTime !== "") {
		endTimeJSDate = parseAPIDate(endTime);
	}

	// Show the year when it's not the same as the current year
	if (currentYear !== startTimeJSDate.getFullYear()) {
		showStartYearText = " (" + String(startTimeJSDate.getFullYear()) + ") ";
		if (endTime !== "") {
			showEndYearText = " (" + String(endTimeJSDate.getFullYear()) + ") ";
		}
	}

	// No end time specified, render as "Tue 5 May (2010) 5:50 PM""
	if (endTime === "") {
		return DateFns.format(
			startTimeJSDate,
			"ddd D MMM" + showStartYearText + "h:mm A"
		);
	}

	// End time specified, and difference in start time & end time are greater than a day, render as Wed 13 Dec (2010) 03:00 PM - Tue 19 Dec (2010) 07:00 PM
	if (differenceInHours(endTimeJSDate, startTimeJSDate) > 24) {
		return (
			DateFns.format(startTimeJSDate, "ddd D MMM" + showStartYearText + "h:mm A") +
			" - " +
			DateFns.format(endTimeJSDate, "ddd D MMM" + showEndYearText + "h:mm A")
		);
	}

	// If End time within the day, then display as "Wed 13 Dec (2010) 3:00 PM - 7:00 PM"
	return (
		DateFns.format(startTimeJSDate, "ddd D MMM" + showStartYearText + "h:mm A") +
		" - " +
		DateFns.format(endTimeJSDate, "h:mm A")
	);
}

export function shortDateFormat(date) {
	return !!date ? dayjs(date).format('ddd D MMM YYYY') : ''
}