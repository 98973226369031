import * as React from "react";
import PropTypes from "prop-types";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import InputCheckbox from "../components/input/InputCheckbox";
import ProfileStageName from "../components/links/ProfileStageName";
import ProfileWorkPhone from "../components/links/ProfileWorkPhone";
import ProfileWorkFacebook from "../components/links/ProfileWorkFacebook";
import ClientName from "../components/links/ClientName";
import NatTime from "../components/text/NatTime";

class AdminKFWRow extends React.Component {
	shouldComponentUpdate(nextProps) {
		if (
			nextProps.ticked === this.props.ticked &&
			nextProps.userId === this.props.userId &&
			nextProps.notes === this.props.notes &&
			nextProps.kfw === this.props.kfw &&
			nextProps.kfwHidden === this.props.kfwHidden
		) {
			return false;
		}

		if (
			Array.isArray(nextProps.shiftInfo) &&
			Array.isArray(this.props.shiftInfo)
		) {
			if (nextProps.shiftInfo.length !== this.props.shiftInfo.length) {
				return false;
			}
		}

		if (nextProps.shiftInfo === null && this.props.shiftInfo !== null) {
			return false;
		}

		if (nextProps.shiftInfo !== null && this.props.shiftInfo === null) {
			return false;
		}

		return true;
	}

	render() {
		return (
			<div>
				<ResponsiveTableColumn size={this.props.colSpacing[0]} heading="Select">
					<InputCheckbox
						value={this.props.ticked}
						name={String(this.props.userId)}
						onChange={this.props.handleOnChangeCheckbox}
					/>
				</ResponsiveTableColumn>
				<ResponsiveTableColumn size={this.props.colSpacing[1]} heading="Skimpy">
					<ProfileStageName userId={this.props.userId} />
				</ResponsiveTableColumn>
				<ResponsiveTableColumn size={this.props.colSpacing[2]} heading="Number">
					<ProfileWorkPhone userId={this.props.userId} />
				</ResponsiveTableColumn>
				<ResponsiveTableColumn size={this.props.colSpacing[3]} heading="Facebook">
					<ProfileWorkFacebook userId={this.props.userId} />
				</ResponsiveTableColumn>
				<ResponsiveTableColumn size={this.props.colSpacing[4]} heading="Notes">
					<div>
						<b>{this.props.kfwHidden && <span>KFW Hidden</span>}</b>
					</div>
					<div>{this.props.notes && this.props.notes}</div>
				</ResponsiveTableColumn>
				<ResponsiveTableColumn size={this.props.colSpacing[5]} heading="Shifts">
					{this.props.shiftInfo &&
						this.props.shiftInfo.map((shift, shiftIdx) => {
							return (
								<div key={shiftIdx}>
									<div>{shift.ClientId && <ClientName clientId={shift.ClientId} />}</div>
									<div>
										<NatTime startTime={shift.StartTime} endTime={shift.EndTime} />
									</div>
								</div>
							);
						})}
				</ResponsiveTableColumn>
			</div>
		);
	}
}

AdminKFWRow.propTypes = {
	ticked: PropTypes.bool,
	colSpacing: PropTypes.array.isRequired,
	userId: PropTypes.number.isRequired,
	notes: PropTypes.string,
	shiftInfo: PropTypes.array,
	handleOnChangeCheckbox: PropTypes.func.isRequired,
	kfw: PropTypes.bool,
	kfwHidden: PropTypes.bool
};

export default AdminKFWRow;
