import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";
import PhoneNumberLink from "./PhoneNumberLink";

class ClientTelephone extends React.Component {
	render() {
		return <PhoneNumberLink phoneNumber={this.props.client.PhoneNumber} />;
	}
}

ClientTelephone.propTypes = {
	clientId: PropTypes.number.isRequired,
	client: PropTypes.object
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ClientTelephone);
