import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";

class ClientAddress extends React.Component {
	render() {
		return (
			<span>
				{this.props.client["AddressNumber"]} {this.props.client["AddressRoad"]}{" "}
				{this.props.client["AddressSuburb"]} {this.props.client["AddressPostcode"]}
			</span>
		);
	}
}

ClientAddress.propTypes = {
	clientId: PropTypes.number.isRequired,
	client: PropTypes.object
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ClientAddress);
