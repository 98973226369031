import React from "react";
import "../MyPayments.css";
import { CaretDown, CaretUp } from "../../components/icons/Icons";
const SortButton = ({ direction, onClick }) => {
	return (
		<button onClick={onClick} value={direction} className="btn-clear">
			{direction === "asc" ? <CaretDown /> : <CaretUp />}
		</button>
	);
};

export default SortButton;
