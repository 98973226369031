import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findHrmsByClientId } from "../../poll/selectors";
import ProfileStageName from "../../components/links/ProfileStageName";
import ProfileWorkPhone from "../../components/links/ProfileWorkPhone";

class ShiftHRMList extends React.Component {
	render() {
		return (
			<div>
				{this.props.hrms.map(hrm => {
					return (
						<div key={hrm.UserId}>
							<ProfileStageName userId={hrm.UserId} /> -{" "}
							<ProfileWorkPhone userId={hrm.UserId} />
						</div>
					);
				})}
			</div>
		);
	}
}

ShiftHRMList.propTypes = {
	clientId: PropTypes.number.isRequired,
	hrms: PropTypes.arrayOf(PropTypes.shape({ UserId: PropTypes.number }))
};

const mapStateToProps = (state, props) => {
	return {
		hrms: findHrmsByClientId(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ShiftHRMList);
