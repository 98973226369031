import * as React from "react";

import './Modal.css'

class Modal extends React.Component {
	constructor(props) {
		super(props);
	}

	handleClose() {
		this.props.onClose && this.props.onClose();
	}



	render() {
		return (
			<div className="latModalWrapper">
				<div className="latModal">
					{!!this.props.title ? <h4 className="heading">{this.props.title}</h4> : []}
					<button
						className="btn-default shadowbox"
						className="closeButtonStyles"
						onClick={() => this.handleClose()}
					>
						<i className="fas fa-times color-fail" />
					</button>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Modal
