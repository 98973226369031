import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	clientTeamTalksSelectorAsArray,
	findClientById
} from "../../poll/selectors";
import FormManager from "../../components/form/FormManager";
import {
	clientTeamTalksDELETE,
	clientTeamTalksPATCH,
	clientTeamTalksPOST
} from "../../api/apiEndpoints";
import LoadingButton from "../../components/button/LoadingButton";
import { getAuthUserId } from "../../auth/selectors";
import { poll } from "../../poll/actions";
import NatTime from "../../components/text/NatTime";
import ProfileStageName from "../../components/links/ProfileStageName";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import InputTextArea from "../../components/input/InputTextArea";
import FormRow from "../../components/form/FormRow";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import { Comment, Pencil, Save, Trash } from "../../components/icons/Icons";

class VenueTeamTalk extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			teamTalks: []
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad = props => {
		this.setState({
			teamTalks: props.teamTalks.filter(item => {
				return item.ClientId === this.props.clientId;
			})
		});
	};

	render() {
		return (
			<div>
				<h4 className="heading">Team Talk</h4>
				<div>
					{/*

                        Create a team talk entry

                    */}
					<FormManager
						initialFormValues={{
							UserId: this.props.authId,
							Message: ""
						}}
						handleValidation={v => {
							v.required({
								fieldNames: ["Message"]
							});
						}}
						handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
							clientTeamTalksPOST({
								Message: formValues.Message,
								UserId: this.props.authId,
								ClientId: this.props.clientId
							})
								.then(() => {
									handleSubmitSuccess();
									this.props.dispatch(poll({}));
								})
								.catch(error => {
									handleSubmitFail();
									throw error;
								});
						}}
					>
						{/*FormManager children*/}
						{({
							formValues,
							handleOnChange,
							handleSubmit,
							formValidation,
							submitEnabled,
							submitting
						}) => {
							return (
								<React.Fragment>
									<div>
										<InputTextArea
											rows={5}
											value={formValues.Message}
											onChange={handleOnChange}
											name={"Message"}
										/>
									</div>

									<div style={{ display: "flex", justifyContent: "center" }}>
										<LoadingButton
											className="btn btn-success"
											style={{ padding: "5px 13px", width: "100%" }}
											onClick={handleSubmit}
											loading={submitting}
											disabled={!submitEnabled}
										>
											<Comment /> Comment
										</LoadingButton>
									</div>
								</React.Fragment>
							);
						}}
					</FormManager>
				</div>

				{/*

                    Existing Team Talks

                */}

				<div className="col-sm-12" style={{ paddingTop: 5 }}>
					{this.state.teamTalks.map((tt, idx) => {
						return (
							<div
								className="row"
								key={idx}
								style={{ padding: 5 }}
								className={idx % 2 === 0 ? "row kfw-odd" : "row"}
							>
								<div className="col-sm-5">
									<ProfileStageName userId={tt.UserId} />
								</div>
								<div className="col-sm-7">
									<NatTime startTime={tt.CreatedAt} />:
								</div>
								<div className="col-sm-12">{tt.Message}</div>
								<ProtectedComponent
									allowedGroups={[userGroups.admin, userGroups.hrm]}
									allowedIfLoggedInAsUserId={tt.UserId}
								>
									<div className="col-sm-12 text-right">
										<StandardModalWrapper
											heading="Edit Existing Team Talk Entry"
											modalOpener={({ handleOpenModal }) => {
												return (
													<button
														onClick={handleOpenModal}
														className="btn btn-clear color-primary"
													>
														<Pencil /> Edit
													</button>
												);
											}}
											render={({ handleCloseModal }) => {
												return (
													<div>
														<FormManager
															initialFormValues={{
																Id: tt.Id,
																Message: tt.Message
															}}
															handleValidation={v => {
																v.required({
																	fieldNames: ["Message"]
																});
															}}
															handleSubmit={({
																formValues,
																handleSubmitFail,
																handleSubmitSuccess
															}) => {
																clientTeamTalksPATCH({
																	Id: formValues.Id,
																	Message: formValues.Message
																}).then(() => {
																	handleSubmitSuccess();
																	handleCloseModal();
																});
															}}
														>
															{/*FormManager children*/}
															{({
																formValues,
																handleSubmitSuccess,
																handleOnChange,
																handleSubmit,
																submitEnabled,
																submitting
															}) => {
																return (
																	<div>
																		<FormRow
																			label={"Message"}
																			value={
																				<InputTextArea
																					name={"Message"}
																					value={formValues.Message}
																					onChange={handleOnChange}
																				/>
																			}
																		/>
																		<AdminContainerMigrate
																			align={"right"}
																			buttons={[
																				<LoadingButton
																					className="btn btn-success"
																					onClick={handleSubmit}
																					loading={submitting}
																					disabled={!submitEnabled}
																				>
																					<Save /> Update
																				</LoadingButton>,
																				<LoadingButton
																					className="btn btn-danger"
																					onClick={() => {
																						clientTeamTalksDELETE({
																							Id: formValues.Id
																						}).then(() => {
																							handleSubmitSuccess();
																							handleCloseModal();
																						});
																					}}
																				>
																					<Trash /> Delete
																				</LoadingButton>
																			]}
																		/>
																	</div>
																);
															}}
														</FormManager>
													</div>
												);
											}}
										/>
									</div>
								</ProtectedComponent>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

VenueTeamTalk.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		authId: getAuthUserId(state),
		venue: findClientById(state, props.clientId),
		teamTalks: clientTeamTalksSelectorAsArray(state)
	};
};

export default connect(mapStateToProps)(VenueTeamTalk);
