import * as React from "react";
import PropTypes from "prop-types";
import API from "../../api/client";
import Spinner from "../loading/Spinner";
import PDFViewer from "../PDFViewer/PDFViewer";
import fileDownload from "js-file-download";
import { toastr } from "react-redux-toastr";
import axios from "axios/index";
import "./UploadRenderer.css";

class UploadRenderer extends React.Component {
	static propTypes = {
		src: PropTypes.string,
		style: PropTypes.any,
		onClickFn: PropTypes.func,
		showThumbnail: PropTypes.bool
	};

	static defaultProps = {
		style: {},
		onClickFn: null,
		showThumbnail: true
	};

	state = {
		loading: true,
		showFailedLoad: false
	};

	handleOnLoad = () => {
		this.setState({ loading: false });
	};

	handleOnError = () => {
		this.setState({ showFailedLoad: true });
	};

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return (
			this.state.loading !== nextState.loading ||
			this.state.showFailedLoad !== nextState.showFailedLoad ||
			this.props.src !== nextProps.src
		);
	}

	handleDownloadClick = () => {
		toastr.info("Downloading File");
		axios
			.get(this.props.src, { responseType: "arraybuffer" })
			.then(r => {
				fileDownload(r.data, "certificate.pdf");
			})
			.catch(() => {
				toastr.error("There was an error retrieving this PDF");
			});
	};

	render() {
		if (this.props && this.props.src && this.props.src.indexOf("pdf") > -1) {
			return (
				<React.Fragment>
					<div style={{ textAlign: "center", border: "2px dashed grey" }}>
						<a
							href={this.props.src}
							target="_blank"
							style={{
								fontSize: 18,
								padding: 5,
								width: "100%",
								display: "block"
							}}
						>
							<span>
								<i className="fas fa-download color-fail" /> Download
							</span>
						</a>
					</div>
					<div
						onClick={this.props.onClickFn}
						className="img-hover"
						style={{ padding: 2 }}
					>
						<PDFViewer filePath={this.props.src} />
					</div>
				</React.Fragment>
			);
		}

		if (this.state.showFailedLoad) {
			return "Failed to load image";
		}

		return (
			<React.Fragment>
				<img
					onLoad={this.handleOnLoad}
					onError={this.handleOnError}
					src={this.props && this.props.src ? this.props.src : ''}
					className="img-responsive img-hover"
					style={this.props.style}
					onClick={this.props.onClickFn}
				/>
				<Spinner loading={this.state.loading} />
			</React.Fragment>
		);
	}
}

export default UploadRenderer;
