import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import {
	clientContactsDELETE,
	clientContactsPATCH,
	clientContactsPOST
} from "../../api/apiEndpoints";
import FormManager from "../../components/form/FormManager";
import ResponsiveTableColumn from "../../components/table/ResponsiveTableColumn";
import ResponsiveTableRow from "../../components/table/ResponsiveTableRow";
import ResponsiveTableNew from "../../components/table/ResponsiveTableNew";
import InputText from "../../components/input/InputText";
import { Trash, Undo } from "../../components/icons/Icons";

export default class VenueGeneralInfo extends React.Component {
	render() {
		return (
			<ResponsiveTableNew
				headings={[
					{
						label: "Full Name",
						name: "FullName",
						size: 5
					},
					{
						label: "Phone Number",
						name: "PhoneNumber",
						size: 5
					},
					{
						label: "",
						name: "Save",
						size: 2,
						disableSort: true
					}
				]}
				values={this.props.initialFormValues.ClientContacts}
			>
				{({ sortedValues }) => {
					return (
						<div>
							{/*

                            Create new record row

                        */}
							<ResponsiveTableRow>
								{({ editMode, handleToggleEditMode, submitText }) => {
									return (
										<FormManager
											initialFormValues={{
												FullName: "",
												PhoneNumber: "",
												ClientId: this.props.clientId
											}}
											handleValidation={v => {
												v.required({ fieldNames: ["FullName", "PhoneNumber"] });
											}}
											handleSubmit={({
												formValues,
												handleSubmitFail,
												handleSubmitSuccess
											}) => {
												clientContactsPOST({
													ClientId: formValues.ClientId,
													FullName: formValues.FullName,
													PhoneNumber: formValues.PhoneNumber
												})
													.then(() => {
														handleSubmitSuccess();
														handleToggleEditMode();
													})
													.catch(error => {
														handleSubmitFail();
														throw error;
													});
											}}
										>
											{/*FormManager children*/}
											{({
												formValues,
												handleOnChange,
												handleSubmit,
												formValidation,
												submitEnabled,
												submitting
											}) => {
												if (!editMode) {
													return (
														<div className="row">
															<ResponsiveTableColumn size={12} offsetSize={0}>
																<button
																	className="btn-clear create-new-row"
																	onClick={handleToggleEditMode}
																>
																	Create new record
																</button>
															</ResponsiveTableColumn>
														</div>
													);
												}

												return (
													<div className="row div-table-responsive-row">
														<ResponsiveTableColumn heading="Full Name" size={5}>
															<InputText
																name="FullName"
																onChange={handleOnChange}
																value={formValues.FullName}
																validationMessages={formValidation.FullName}
															/>
														</ResponsiveTableColumn>
														<ResponsiveTableColumn heading="Phone Number" size={5}>
															<InputText
																name="PhoneNumber"
																onChange={handleOnChange}
																value={formValues.PhoneNumber}
																validationMessages={formValidation.PhoneNumber}
															/>
														</ResponsiveTableColumn>
														<ResponsiveTableColumn size={2}>
															<div>
																{/* Save button */}
																<LoadingButton
																	loading={submitting}
																	disabled={!submitEnabled}
																	onClick={handleSubmit}
																>
																	{submitText}
																</LoadingButton>
																{/* Delete button */}
																<button
																	className="btn btn-danger"
																	onClick={handleToggleEditMode}
																>
																	<Trash />
																</button>
															</div>
														</ResponsiveTableColumn>
													</div>
												);
											}}
										</FormManager>
									);
								}}
							</ResponsiveTableRow>
							{/*

                            View / Edit existing records

                        */}
							{sortedValues.map((rowValues, rowIdx) => {
								return (
									<ResponsiveTableRow key={rowIdx}>
										{({ editMode, handleToggleEditMode, submitText }) => {
											return (
												<FormManager
													initialFormValues={rowValues}
													handleValidation={v => {
														v.required({ fieldNames: ["FullName", "PhoneNumber"] });
													}}
													handleSubmit={({
														formValues,
														handleSubmitFail,
														handleSubmitSuccess
													}) => {
														clientContactsPATCH({
															Id: formValues.Id,
															FullName: formValues.FullName,
															PhoneNumber: formValues.PhoneNumber
														})
															.then(() => {
																handleSubmitSuccess();
																handleToggleEditMode();
															})
															.catch(error => {
																handleSubmitFail();
																throw error;
															});
													}}
												>
													{/*FormManager children*/}
													{({
														formValues,
														handleOnChange,
														handleSubmit,
														formValidation,
														submitEnabled,
														submitting
													}) => {
														return (
															<div className="row div-table-responsive-row">
																<ResponsiveTableColumn heading="Full Name" size={5}>
																	{editMode ? (
																		<InputText
																			name="FullName"
																			onChange={handleOnChange}
																			value={formValues.FullName}
																			validationMessages={formValidation.FullName}
																		/>
																	) : (
																		<span className="div-table-responsive-text">
																			{formValues.FullName}
																		</span>
																	)}
																</ResponsiveTableColumn>
																<ResponsiveTableColumn heading="Phone Number" size={5}>
																	{editMode ? (
																		<InputText
																			name="PhoneNumber"
																			onChange={handleOnChange}
																			value={formValues.PhoneNumber}
																			validationMessages={formValidation.PhoneNumber}
																		/>
																	) : (
																		<span className="div-table-responsive-text">
																			{formValues.PhoneNumber}
																		</span>
																	)}
																</ResponsiveTableColumn>
																<ResponsiveTableColumn size={2}>
																	{editMode ? (
																		<div>
																			{/*Save Button*/}
																			<LoadingButton
																				loading={submitting}
																				disabled={!submitEnabled}
																				onClick={handleSubmit}
																			>
																				{submitText}
																			</LoadingButton>

																			{/*View button*/}
																			<button
																				className="btn btn-success"
																				onClick={handleToggleEditMode}
																			>
																				<Undo />
																			</button>
																		</div>
																	) : (
																		<div>
																			{/*Edit button */}
																			<button
																				className="btn btn-info"
																				onClick={handleToggleEditMode}
																			>
																				{submitText}
																			</button>

																			{/*Delete Button*/}
																			<FormManager
																				initialFormValues={rowValues}
																				handleSubmit={({
																					formValues,
																					handleSubmitFail,
																					handleSubmitSuccess
																				}) => {
																					clientContactsDELETE({
																						Id: formValues.Id
																					})
																						.then(() => {
																							handleSubmitSuccess();
																						})
																						.catch(error => {
																							handleSubmitFail();
																							throw error;
																						});
																				}}
																			>
																				{({ handleSubmit }) => {
																					return (
																						<button onClick={handleSubmit} className="btn btn-danger">
																							<Trash />
																						</button>
																					);
																				}}
																			</FormManager>
																		</div>
																	)}
																</ResponsiveTableColumn>
															</div>
														);
													}}
												</FormManager>
											);
										}}
									</ResponsiveTableRow>
								);
							})}
						</div>
					);
				}}
			</ResponsiveTableNew>
		);
	}
}

VenueGeneralInfo.propTypes = {
	clientId: PropTypes.number.isRequired,
	initialFormValues: PropTypes.object,
	handleCloseModal: PropTypes.func.isRequired
};
