import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clientSelectorById } from "../../poll/selectors";
import PollLoading from "../../components/loading/PollLoading";
import { rootSelector } from "../../config/selectors";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import { uploadSections, uploadsGet } from "../../api/apiEndpoints";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import { loggedInUserIsClient } from "../../components/permissions/validateUserPermissions";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import UploadCroppedImg from "../../components/uploads/UploadCroppedImg";

class VenueLogos extends React.PureComponent {
	state = {
		brandPicturePath: "",
		venueBannerPicturePath: "",
		brandId: -1
	};

	static propTypes = {
		clientId: PropTypes.number.isRequired
	};

	static getDerivedStateFromProps(nextProps, currentState) {
		if (
			nextProps.venue !== undefined &&
			currentState.brandId !== nextProps.venue.BrandId
		) {
			return {
				brandId: nextProps.venue.BrandId
			};
		}

		return null;
	}

	componentDidMount() {
		if (this.state.brandId !== -1) {
			this.queryVenueBanner();
			this.queryBrandBanner();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.brandId !== prevState.brandId) {
			this.queryVenueBanner();
			this.queryBrandBanner();
		}
	}

	queryVenueBanner = () => {
		uploadsGet({
			UploadSectionTypes: [uploadSections.client_banner],
			UploadTypeId: [this.props.clientId]
		}).then(r => {
			const uploads = r.data || [];

			if (uploads.length === 1) {
				this.setState({
					venueBannerPicturePath: uploads[0].Path
				});
			}
		});
	};

	queryBrandBanner = () => {
		uploadsGet({
			UploadSectionTypes: [uploadSections.brand_banner],
			UploadTypeId: [this.props.venue.BrandId]
		}).then(r => {
			const uploads = r.data || [];

			if (uploads.length === 1) {
				this.setState({
					brandPicturePath: uploads[0].Path
				});
			}
		});
	};

	render() {
		if (!this.props.venue) {
			return null;
		}

		return (
			<div>
				<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
					<StandardModalWrapper
						modalOpener={({ handleOpenModal }) => {
							return (
								<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
									<span className="heading-edit" onClick={handleOpenModal}>
										Upload
									</span>
								</ProtectedComponent>
							);
						}}
						render={({ handleCloseModal }) => {
							const closeReload = () => {
								this.queryVenueBanner();
								handleCloseModal();
							};

							return (
								<UploadCroppedImg
									uploadSectionType={uploadSections.client_banner}
									uploadSectionId={this.props.venue.Id}
									onSubmitSuccessFn={closeReload}
									aspectRatio={17.4 / 4.14}
								/>
							);
						}}
					/>
				</ProtectedComponent>
				<PollLoading>
					{!loggedInUserIsClient() ? (
						<div className="row">
							<h4 className="heading">&nbsp;</h4>
							<div className="col-sm-6">
								{this.state.brandPicturePath !== "" && (
									<UploadRenderer
										showThumbnail={false}
										src={this.state.brandPicturePath}
									/>
								)}
							</div>
							<div className="col-sm-6">
								{this.state.venueBannerPicturePath !== "" && (
									<UploadRenderer
										showThumbnail={false}
										src={this.state.venueBannerPicturePath}
									/>
								)}
							</div>
						</div>
					) : (
						<div>
							{this.state.brandPicturePath !== "" && (
								<UploadRenderer
									showThumbnail={false}
									src={this.state.brandPicturePath}
								/>
							)}
						</div>
					)}
				</PollLoading>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		venue: clientSelectorById(state)[props.clientId],
		config: rootSelector(state)
	};
};

export default connect(mapStateToProps)(VenueLogos);
