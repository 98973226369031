import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { findClientById } from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import { clientsPATCH } from "../../api/apiEndpoints";
import FormRow from "../../components/form/FormRow";
import LoadingButton from "../../components/button/LoadingButton";
import InputText from "../../components/input/InputText";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";

class VenueHeading extends React.Component {
	render() {
		return (
			<div>
				<StandardModalWrapper
					heading={"Venue Name"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent allowedGroups={[userGroups.hrm, userGroups.admin]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										ClientName: this.props.venue.ClientName,
										Id: this.props.clientId
									}}
									handleValidation={v => {
										v.required({
											fieldNames: ["ClientName"]
										});
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										clientsPATCH({
											ClientName: formValues.ClientName,
											Id: this.props.clientId
										})
											.then(() => {
												handleSubmitSuccess();
												handleCloseModal();
											})
											.catch(error => {
												handleSubmitFail();
												throw error;
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<FormRow
													label={"Venue Name"}
													value={
														<InputText
															value={formValues.ClientName}
															onChange={handleOnChange}
															name={"ClientName"}
															validationMessages={formValidation.ClientName}
														/>
													}
												/>
												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButton
															onClick={handleSubmit}
															loading={submitting}
															disabled={!submitEnabled}
														>
															Save
														</LoadingButton>
													]}
												/>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>

				<h3 className="heading" style={{ textAlign: "center" }}>
					{this.props.venue.ClientName}
				</h3>
			</div>
		);
	}
}

VenueHeading.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(VenueHeading);
