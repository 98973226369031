import * as React from "react";
import { clientLineupGET } from "../api/apiEndpoints";
import { parseAPIDate } from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import { lessThanApiDate } from "../lib/sort/sortByDate";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import NatTime from "../components/text/NatTime";
import ResponsiveTableNew from "../components/table/ResponsiveTableNew";
import ClientName from "../components/links/ClientName";
import FiveStars from "../components/FiveStars/FiveStars";
import ReappearanceText from "../components/text/ReappearanceText";
import MiniContainer from "../components/container/MiniContainer";
import { userInfo } from "../lib/userInfo/userInfo";
import Spinner from "../components/loading/Spinner";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";

class MyFeedback extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingFeedback: false,
			filteredFeedback: []
		};
	}

	componentDidMount() {
		this.refreshFeedback();
	}

	refreshFeedback() {
		this.setState({
			loadingFeedback: true
		});

		let todayMinus1Week = DateFns.addWeeks(new Date(), -1).getTime();

		clientLineupGET().then(r => {
			let filteredData = r.data.filter(feedback => {
				if (feedback.CreatedAt === null) {
					return false;
				}

				if (!feedback.EndTime) {
					return false;
				}
				const createdAtTimeAsEpoch = parseAPIDate(feedback.EndTime).getTime();

				if (todayMinus1Week < createdAtTimeAsEpoch) {
					return false;
				}

				if (userInfo().userId !== feedback.UserId) {
					return false;
				}

				return true;
			});

			this.setState({
				loadingFeedback: false,
				filteredFeedback: filteredData
			});
		});
	}

	render() {
		const colSizing = [4, 4, 2, 2];
		return (
			<Spinner loading={this.state.loadingFeedback}>
				<MiniContainer>
					<ResponsiveTableNew
						initialSortColumn={"StartTime"}
						showPagination={true}
						headings={[
							{
								label: "Venue",
								size: colSizing[0],
								disableSort: true
							},
							{
								label: "Shift",
								name: "StartTime",
								size: colSizing[1],
								sortDescFunc: lessThanApiDate
							},
							{
								label: "Rating",
								name: "FeedbackRating",
								size: colSizing[2]
							},
							{
								label: "Reappearance",
								name: "FeedbackReappearance",
								size: colSizing[3]
							}
						]}
						values={this.state.filteredFeedback}
					>
						{({ sortedValues }) => {
							return (
								<InfiniteScroll
									rowCount={sortedValues.length}
									rowComparison={() => {
										return false;
									}}
									rows={sortedValues}
									overscanRowCount={5}
									rowRender={({ index, row, style, redrawRow }) => {
										return (
											<div style={style} className="div-table-responsive-row">
												<ResponsiveTableColumn size={colSizing[0]} heading={"Venue"}>
													<ClientName clientId={row.ChildClientIds[0]} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={colSizing[1]} heading={"Shift"}>
													<NatTime startTime={row.StartTime} endTime={row.EndTime} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={colSizing[2]} heading={"Rating"}>
													{/*{row.FeedbackRating}*/}
													<FiveStars disabled={true} value={row.FeedbackRating} />
												</ResponsiveTableColumn>

												<ResponsiveTableColumn size={colSizing[3]} heading={"Reappearance"}>
													<ReappearanceText value={row.FeedbackReappearance} />
												</ResponsiveTableColumn>

												{row.FeedbackNotes && (
													<ResponsiveTableColumn size={12} heading={"Venue Comments"}>
														{row.FeedbackNotes}
													</ResponsiveTableColumn>
												)}
											</div>
										);
									}}
								/>
							);
						}}
					</ResponsiveTableNew>
				</MiniContainer>
			</Spinner>
		);
	}
}

export default MyFeedback;
