import PropTypes from "prop-types";
import * as React from "react";

class LoadingButton extends React.Component {
	render() {
		let buttonProps = {};
		if (this.props.id) {
			buttonProps["id"] = this.props.id;
		}

		return (
			<button
				className={this.props.className}
				title={this.props.title}
				onClick={this.props.onClick}
				disabled={this.props.loading || this.props.disabled}
				style={this.props.style}
				{...buttonProps}
			>
				{this.props.children}
				{this.props.loading && <i className={"fas fa-spinner fa-pulse fa-fw"} />}
			</button>
		);
	}
}

LoadingButton.propTypes = {
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	style: PropTypes.object,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	title: PropTypes.string,
	id: PropTypes.string
};

LoadingButton.defaultProps = {
	className: "btn btn-primary",
	title: ""
};

export default LoadingButton;
