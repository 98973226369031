import { profileByUserId } from "../../poll/selectors";
import store from "../../store";

export function getProfileNameFromUserId(userId) {
	const profiles = profileByUserId(store.getState());

	if (!(userId in profiles)) {
		return null;
	}

	return profiles[userId].StageName;
}
