import * as React from "react";
import PropTypes from "prop-types";
import "./AdminContainer.css";

export default class AdminContainerMigrate extends React.Component {
	render() {
		let buttonsOrdered = [];
		if (this.props.align === "left") {
			buttonsOrdered = this.props.buttons;
		} else if (this.props.align === "right") {
			buttonsOrdered = this.props.buttons.reverse();
		} else {
			throw "align must equal 'left' or 'right'";
		}

		// Inverts the order of the buttons so they work with float: right
		return (
			<div
				className={
					this.props.align === "right"
						? "admin-box admin-box-align-right "
						: "admin-box"
				}
			>
				{buttonsOrdered.map((btn, btnIdx) => {
					const existingClassName = btn.props.className ? btn.props.className : "";
					return React.cloneElement(btn, {
						className: existingClassName + " admin-box-button",
						key: btnIdx
					});
				})}
			</div>
		);
	}
}

AdminContainerMigrate.defaultProps = {
	align: "left",
	buttons: []
};

AdminContainerMigrate.propTypes = {
	align: PropTypes.string,
	buttons: PropTypes.array
};
