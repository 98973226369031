import React, {createContext, useState, useEffect, useMemo} from 'react'
import { allPaymentsPOST } from '../api/apiEndpoints'

export const AdminPaymentsContext = createContext({location: {}, history: {}})

const AdminPaymentsProvider = ({initialState, children}) => {
    const [startDate, setStartDate] = useState("01/01/2000");
	const [endTime, setEndTime] = useState("01/01/3000");
	const [currentCredit, setCurrentCredit] = useState();
	const [transactions, setTransactions] = useState([]);

    const userId = useMemo(
		() => {
			const searchParams = new URLSearchParams(initialState.location.search);
			const id = searchParams.get("userId");
			return id ? +id : undefined;
		},
		[initialState.location.search]
	);

    const updateTransactionsAndCredit = (trans, credit) => {
        setTransactions(trans)
        setCurrentCredit(credit)
    }
    const reloadPayments = () => {
        if (!userId) {
            return updateTransactionsAndCredit([], undefined)
        }
        const payload = {
            UserId: userId,
            StartDate: startDate,
            EndTime: endTime
        };
        getAllPayments(payload)
    }

    useEffect(
		() => {
			reloadPayments()
		},
		[userId, startDate, endTime]
	);

	const getAllPayments = async payload => {
		const response = await allPaymentsPOST(payload);
		const currentCredit = response.data.Credit;
		const transactions = response.data.Transactions.map(txn => {
			txn.Amount = txn.Credit === null ? txn.Charge : txn.Credit;
			return txn;
		});
        updateTransactionsAndCredit(transactions, currentCredit)
	};

    return <AdminPaymentsContext.Provider value={{
        userId,
        startDate, setStartDate,
        endTime, setEndTime,
        currentCredit,
        transactions,
        updateTransactionsAndCredit,
        reloadPayments,
        history: initialState.history
    }}>{children}</AdminPaymentsContext.Provider>

}
export default AdminPaymentsProvider