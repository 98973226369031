import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { findClientById } from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import { clientsPATCH } from "../../api/apiEndpoints";
import InputNumber from "../../components/input/InputNumber";
import FormRow from "../../components/form/FormRow";
import LoadingButton from "../../components/button/LoadingButton";
import ClientRegularReappearance from "../../components/text/ClientRegularReappearance";
import ClientFavouriteReappearance from "../../components/text/ClientFavouriteReappearance";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import ClientName from "../../components/links/ClientName";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";

class VenueReappearance extends React.Component {
	render() {
		return (
			<div>
				<StandardModalWrapper
					heading={"Reappearance Rules"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										RegularReappearance: this.props.venue.RegularReappearance,
										FavouriteReappearance: this.props.venue.FavouriteReappearance,
										Id: this.props.clientId
									}}
									handleValidation={v => {
										v.required({
											fieldNames: ["RegularReappearance", "FavouriteReappearance"]
										});
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										clientsPATCH({
											RegularReappearance: formValues.RegularReappearance,
											FavouriteReappearance: formValues.FavouriteReappearance,
											Id: this.props.clientId
										})
											.then(() => {
												handleSubmitSuccess();
												handleCloseModal();
											})
											.catch(error => {
												handleSubmitFail();
												throw error;
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<FormRow
													label={"Favourite Reappearance (days)"}
													value={
														<InputNumber
															value={formValues.FavouriteReappearance}
															onChange={handleOnChange}
															name={"FavouriteReappearance"}
															validationMessages={formValidation.FavouriteReappearance}
														/>
													}
												/>
												<FormRow
													label={"Regular Reappearance (days)"}
													value={
														<InputNumber
															value={formValues.RegularReappearance}
															onChange={handleOnChange}
															name={"RegularReappearance"}
															validationMessages={formValidation.RegularReappearance}
														/>
													}
												/>
												<span style={{ fontSize: 20 }} className="color-fail">
													WARNING - changing this updates all{" "}
													<ClientName clientId={this.props.clientId} /> shifts with this
													reappearance. This may take up to 5 minutes to complete.
												</span>
												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButtonWithConfirm
															className="btn btn-danger"
															onClick={handleSubmit}
															loading={submitting}
															disabled={!submitEnabled}
														>
															Save
														</LoadingButtonWithConfirm>
													]}
												/>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>

				<h4 className="heading">Reappearance Rules</h4>
				<FormRow
					label={"Favourites"}
					value={<ClientFavouriteReappearance clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Regulars"}
					value={<ClientRegularReappearance clientId={this.props.clientId} />}
				/>
			</div>
		);
	}
}

VenueReappearance.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(VenueReappearance);
