export const PAGE_LOGIN_SUCCESS = "PAGE_LOGIN_SUCCESS";

const defaultState = {
	authFail: false,
	loading: false,
	otherError: false
};

export function loginScreenReducer(state = defaultState, action) {
	// console.log("auth reducer", action);
	switch (action.type) {
		case PAGE_LOGIN_SUCCESS: {
			return {
				...state,
				authFail: false,
				otherError: false
			};
		}
		case "AUTH_FAIL_BANNER": {
			return { ...state, authFail: action.payload };
		}
		case "AUTH_LOADING": {
			return { ...state, loading: action.payload, otherError: false, authFail: false };
		}
		case "AUTH_OTHER_ERROR_BANNER": {
			return {...state, otherError: action.payload}
		}
		default: {
			return state;
		}
	}
}
