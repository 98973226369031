import store from "../../store";
import { clientSelectorById, userSelectorById } from "../../poll/selectors";
import { userGroups } from "../userInfo/userInfo";
import { objectIsEmpty } from "../object/objectIsEmpty";

export function userIsDeleted(userId) {
	const usersById = userSelectorById(store.getState());

	if (objectIsEmpty(usersById)) {
		return true;
	}

	if (usersById[userId] === undefined) {
		return true;
	}

	return usersById[userId].UserGroupId === userGroups.inactive;
}

export function clientIsDeleted(clientId) {
	const clientsById = clientSelectorById(store.getState());

	if (objectIsEmpty(clientsById)) {
		return true;
	}

	if (clientsById[clientId] === undefined) {
		return true;
	}

	return clientsById[clientId].DeletedAt !== null;
}
