import * as React from "react";
import PropTypes from "prop-types";
import Select from "../../../components/select/Select";
import InputNumber from "../../../components/input/InputNumber";
import FormRow from "../../../components/form/FormRow";
import FormManager from "../../../components/form/FormManager";
import CreateSeries from "./CreateSeries";
import CreateMultidaySeries from "./CreateMultidaySeries";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { connect } from "react-redux";
import CreateCustomMonthly from "./CreateCustomMonthly";

export const createShiftOptions = {
	customMonthly: "Custom Monthly",
	multidayShift: "Multiday Shift",
	multidaySeries: "Multiday Series",
	series: "Series",
	singleShift: "Single Shift"
};

class CreateNewShifts extends React.Component {
	constructor(props) {
		super(props);

		this.shiftTypeOptions = Object.keys(createShiftOptions).map(key => {
			return {
				value: createShiftOptions[key],
				label: createShiftOptions[key]
			};
		});
	}

	render() {
		return (
			<FormManager
				handleValidation={v => {
					v.greaterThanOrEqualTo({
						field: "numberOfUsersRequired",
						greaterThanValue: 1
					});
				}}
				initialFormValues={{
					currentPage: "menu",
					shiftType: undefined,
					numberOfUsersRequired: 1
				}}
			>
				{({ formValues, formValidation, handleOnChange, handleSetFormValues }) => {
					switch (formValues.currentPage) {
						case "menu": {
							return (
								<div>
									<FormRow
										label="Shift Type"
										value={
											<Select
												name="shiftType"
												value={formValues.shiftType}
												options={this.shiftTypeOptions}
												onChange={handleOnChange}
											/>
										}
									/>

									<FormRow
										label="Number of skimpies required"
										value={
											<InputNumber
												name="numberOfUsersRequired"
												value={formValues.numberOfUsersRequired}
												onChange={handleOnChange}
												validationMessages={formValidation.numberOfUsersRequired}
											/>
										}
									/>

									<AdminContainerMigrate
										align="right"
										buttons={[
											<button
												disabled={formValues.shiftType === undefined}
												className="btn btn-primary pull-right"
												onClick={() => {
													handleSetFormValues({
														...formValues,
														currentPage: formValues.shiftType
													});
												}}
											>
												<i className="fas fa-arrow-circle-right" /> Next
											</button>
										]}
									/>
								</div>
							);
						}

						case createShiftOptions.series: {
							return (
								<CreateSeries
									numberOfUsersRequired={formValues.numberOfUsersRequired}
									handleCloseModal={this.props.pollShiftsFn}
									singleShiftOnly={false}
								/>
							);
						}
						case createShiftOptions.singleShift: {
							return (
								<CreateSeries
									numberOfUsersRequired={formValues.numberOfUsersRequired}
									handleCloseModal={this.props.pollShiftsFn}
									singleShiftOnly={true}
								/>
							);
						}

						case createShiftOptions.multidaySeries: {
							return (
								<CreateMultidaySeries
									numberOfUsersRequired={formValues.numberOfUsersRequired}
									handleCloseModal={this.props.pollShiftsFn}
									singleShiftOnly={false}
								/>
							);
						}

						case createShiftOptions.multidayShift: {
							return (
								<CreateMultidaySeries
									numberOfUsersRequired={formValues.numberOfUsersRequired}
									handleCloseModal={this.props.pollShiftsFn}
									singleShiftOnly={true}
								/>
							);
						}
						case createShiftOptions.customMonthly: {
							return (
								<CreateCustomMonthly
									handleCloseModal={this.props.pollShiftsFn}
									numberOfUsersRequired={formValues.numberOfUsersRequired}
								/>
							);
						}
					}
				}}
			</FormManager>
		);
	}
}

CreateNewShifts.propTypes = {
	pollShiftsFn: PropTypes.func.isRequired
};

export default connect()(CreateNewShifts);
