import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import FormManager from "../../components/form/FormManager";
import FormRow from "../../components/form/FormRow";
import InputText from "../../components/input/InputText";
import { clientsPOST } from "../../api/apiEndpoints";
import SelectBrand from "../../components/select/SelectBrand";

class CreateVenueModal extends React.Component {
	render() {
		return (
			<FormManager
				initialFormValues={{
					ClientName: "",
					BrandId: 6
				}}
				handleValidation={v => {
					v.required({
						fieldNames: ["ClientName", "BrandId"]
					});
					v.mustNotExistInDB({
						fieldName: "ClientName",
						storeKey: "Clients",
						objectKey: "ClientName"
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					clientsPOST({
						ClientName: formValues.ClientName,
						BrandId: formValues.BrandId
					})
						.then(() => {
							handleSubmitSuccess();
							this.props.onSaveFn(formValues.ClientName);
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"Venue Name"}
								value={
									<InputText
										name={"ClientName"}
										value={formValues.ClientName}
										onChange={handleOnChange}
										validationMessages={formValidation.ClientName}
									/>
								}
							/>

							<FormRow
								label={"Brand"}
								value={
									<SelectBrand
										name="BrandId"
										value={formValues.BrandId}
										onChange={handleOnChange}
										validationMessages={formValidation.BrandId}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										<i className="fas fa-address-card" /> Create Venue
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

CreateVenueModal.propTypes = {
	onSaveFn: PropTypes.func
};

export default CreateVenueModal;
