import { REDUX_ROOT_POLL } from "../../store";
import store from "../../store";
import { clientSelectorById } from "../../poll/selectors";

export function sortClientNamesById(a, b) {
	const clientsById = clientSelectorById(store.getState());

	let aClientName = "";
	let bClientName = "";

	if (a in clientsById) {
		aClientName = clientsById[a].ClientName.toLowerCase();
	}
	if (b in clientsById) {
		bClientName = clientsById[b].ClientName.toLowerCase();
	}

	return aClientName.localeCompare(bClientName);
}
