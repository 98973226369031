import * as React from "react";
import { css } from "emotion";
import SkimpyHeavenBankTransfer from "./SkimpyHeavenBankTransfer";
import { CheckSquare, Topless } from "../../components/icons/Icons";

interface IRequirements {
	requirements: [{ Id: number; Description: string }];
	clientId?: number;
	hrmApprovalRequired: boolean;
}

export default class Requirements extends React.Component<IRequirements> {
	public rowClass: any;

	constructor(props: IRequirements) {
		super(props);

		this.rowClass = css`
			height: 24px;
			display: inline-block;
			font-size: 20px;
			svg,
			img,
			span {
				margin: 2px;
			}
			overflow: hidden;
		`;
	}

	render() {
		const requirements = this.props.requirements || [];
		if (requirements && requirements.length <= 0) {
			return null;
		}

		return (
			<div className={this.rowClass}>
				{requirements.map((requirement, index) => {
					switch (requirement.Id) {
						case 3:
							// Topless
							return <Topless title="Topless" key={index} />;
						default:
							return null;
					}
				})}
				{<SkimpyHeavenBankTransfer clientId={this.props.clientId} />}
				{this.props.hrmApprovalRequired && (
					<CheckSquare title="HRM Approval Required" />
				)}
			</div>
		);
	}
}
