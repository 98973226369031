import * as React from "react";
import { connect } from "react-redux";
import FormManager from "../components/form/FormManager";
import MiniContainer from "../components/container/MiniContainer";
import DateTimePicker from "../components/datetime/DateTimePicker";
import { convertJSDateToDDMMYYYY } from "../lib/dateUtils/dateUtils";
import DateFns from "date-fns";
import LoadingButton from "../components/button/LoadingButton";
import { allPaymentsPOST } from "../api/apiEndpoints";
import ResponsiveTableNew, { sortDirectionOptions } from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import NatTime from "../components/text/NatTime";
import ProfileStageName from "../components/links/ProfileStageName";
import PaymentCodeText from "../components/text/PaymentCodeText";
import PaymentSubCodeText from "../components/text/PaymentSubCodeText";
import PaymentCodeList from "../components/select/PaymentCodeList";
import PaymentSubCodeList from "../components/select/PaymentSubCodeList";
import PaymentMethodList from "../components/select/PaymentMethodList";
import SkimpyList from "../components/select/SkimpyList";
import { lessThanApiDate } from "../lib/sort/sortByDate";
import Money from "../components/text/Money";
import InputCheckbox from "../components/input/InputCheckbox";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import { lessThanNumeric } from "../lib/sort/sortByNumber";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import Spinner from "../components/loading/Spinner";
import PaymentLogs from "../PaymentLogs/PaymentLogs";
import { sortStageNameByUserId } from "../lib/sort/sortByUserId";
import EditPaymentLine from "../components/payments/EditPaymentLine";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import { Pencil } from "../components/icons/Icons";
import ClientList from "../components/select/ClientList";

class ReportCodes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filterValues: {
				StartDate: convertJSDateToDDMMYYYY(new Date()),
				EndTime: convertJSDateToDDMMYYYY(DateFns.addDays(new Date(), 1)),
				PaymentCode: undefined,
				PaymentCategorySubCodesId: undefined,
				PaymentMethod: undefined,
				UserId: undefined,
				ClientId: undefined,
				showInactiveUsers: false
			},
			searchResults: {
				Credit: 0,
				Transactions: []
			},
			calcTotal: 0,
			searching: false
		};
	}

	updatePaymentResults = filterValues => {
		this.setState({
			filterValues: filterValues,
			searching: true
		});

		return allPaymentsPOST(filterValues)
			.then(response => {
				const transactions = response.data.Transactions.map(txn => {
					txn["Amount"] = txn.Credit === null ? txn.Charge : txn.Credit;
					return txn;
				});

				const subTotal = transactions.reduce((total, current) => {
					return total + current.Amount;
				}, 0);

				this.setState({
					searchResults: {
						Transactions: transactions,
						Credit: response.data.Credit
					},
					calcTotal: subTotal,
					searching: false
				});
			})
			.catch(() => {
				this.setState({
					searching: false
				});
			});
	};

	clearResults = formValues => {
		this.setState({
			...this.state,
			filterValues: formValues,
			searchResults: {
				Transactions: [],
				Credit: 0
			},
			calcTotal: 0
		});
	};

	render() {
		const colSpacing = [2, 2, 2, 2, 2, 1, 1];

		let transactions = this.state.searchResults.Transactions;

		return (
			<div>
				{this.renderSearch()}

				<div className="row">
					<div className="col-lg-12">
						<div className="mini-container">
							<Spinner loading={this.state.searching}>
								<ResponsiveTableNew
									headings={[
										{
											label: "Date",
											name: "Date",
											size: colSpacing[0],
											sortDescFunc: lessThanApiDate
										},
										{
											label: "Actioned By",
											name: "AuthId",
											size: colSpacing[1],
											sortDescFunc: sortStageNameByUserId
										},
										{
											label: "Skimpy",
											name: "UserId",
											size: colSpacing[2],
											sortDescFunc: sortStageNameByUserId
										},
										{
											label: "Code",
											name: "PaymentCode",
											size: colSpacing[3],
											sortDescFunc: sortStageNameByUserId
										},
										{
											label: "Sub Code",
											name: "PaymentCategorySubCodesId",
											size: colSpacing[4],
											sortDescFunc: sortStageNameByUserId
										},
										{
											label: "Amount",
											name: "Amount", // Credit/Charge - PaymentType
											size: colSpacing[5],
											sortDescFunc: lessThanNumeric
										},
										{
											label: "Edit",
											name: "Edit",
											size: colSpacing[6],
											disableSort: true
										}
									]}
									values={transactions}
									initialSortDirection={sortDirectionOptions.ascending}
									initialSortColumn="Date"
								>
									{({ sortedValues }) => {
										const header = (
											<div className="row div-table-responsive-row">
												<ResponsiveTableColumn
													offsetSize={
														colSpacing[0] +
														colSpacing[1] +
														colSpacing[2] +
														colSpacing[3] +
														colSpacing[4]
													}
													size={colSpacing[5] + colSpacing[6]}
												>
													<b>
														<span style={{ color: "#777" }}>
															<Money value={this.state.calcTotal} /> SubTotal
														</span>
													</b>
												</ResponsiveTableColumn>
											</div>
										);

										const infScroll = (
											<InfiniteScroll
												rowCount={sortedValues.length}
												rowComparison={() => {
													return false;
												}}
												rows={sortedValues}
												overscanRowCount={5}
												rowRender={({ index, row, style, redrawRow }) => {
													let rowStyles = {};
													if (index % 2 === 0) {
														rowStyles = { background: "#EEE" };
													}
													return (
														<div
															style={{ ...style, ...rowStyles }}
															className="div-table-responsive-row"
														>
															<div style={{ minHeight: 40 }}>
																<ResponsiveTableColumn size={colSpacing[0]} heading="Date">
																	<b>
																		<NatTime startTime={row.Date} />
																	</b>
																</ResponsiveTableColumn>

																<ResponsiveTableColumn
																	size={colSpacing[1]}
																	heading="Actioned By"
																>
																	{row.AuthId === null ? (
																		""
																	) : (
																		<ProfileStageName userId={row.AuthId} />
																	)}
																</ResponsiveTableColumn>

																<ResponsiveTableColumn size={colSpacing[2]} heading="Skimpy">
																	{row.UserId === null ? (
																		""
																	) : (
																		<ProfileStageName userId={row.UserId} />
																	)}
																</ResponsiveTableColumn>

																<ResponsiveTableColumn size={colSpacing[3]} heading="Code">
																	{row.PaymentCode === null ? (
																		""
																	) : (
																		<PaymentCodeText paymentCodeId={row.PaymentCode} />
																	)}
																</ResponsiveTableColumn>

																<ResponsiveTableColumn size={colSpacing[4]} heading="Sub Code">
																	{row.PaymentCategorySubCodesId === null ? (
																		""
																	) : (
																		<PaymentSubCodeText
																			paymentSubCodeId={row.PaymentCategorySubCodesId}
																		/>
																	)}
																</ResponsiveTableColumn>

																<ResponsiveTableColumn size={colSpacing[5]} heading="Amount">
																	<Money value={row.Amount} />
																</ResponsiveTableColumn>

																{/*Only show edit for non shift fee entries*/}
																{!row.IsShift && (
																	<ResponsiveTableColumn size={colSpacing[6]} heading="Edit">
																		<StandardModalWrapper
																			modalOpener={({ handleOpenModal }) => {
																				return (
																					<button
																						className="btn btn-success"
																						onClick={handleOpenModal}
																					>
																						<Pencil />
																					</button>
																				);
																			}}
																			heading={"Edit"}
																			render={({ handleCloseModal }) => {
																				return (
																					<EditPaymentLine
																						showInactiveUsers={
																							this.state.filterValues.showInactiveUsers
																						}
																						payment={row}
																						onSuccessUpdateFn={() => {
																							this.updatePaymentResults(this.state.filterValues);
																						}}
																					/>
																				);
																			}}
																		/>

																		<StandardModalWrapper
																			modalOpener={({ handleOpenModal }) => {
																				return (
																					<button
																						className="btn btn-default"
																						onClick={handleOpenModal}
																					>
																						<i className="fas fa-history" />
																					</button>
																				);
																			}}
																			render={({ handleCloseModal }) => {
																				return <PaymentLogs filterByPaymentId={row.PaymentId} />;
																			}}
																		/>
																	</ResponsiveTableColumn>
																)}
															</div>

															<ResponsiveTableColumn size={12} heading="Notes">
																{row.LineItem}
															</ResponsiveTableColumn>
														</div>
													);
												}}
											/>
										);

										return (
											<React.Fragment>
												{header}
												{infScroll}
											</React.Fragment>
										);
									}}
								</ResponsiveTableNew>
							</Spinner>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSearch() {
		return (
			<FormManager
				initialFormValues={this.state.filterValues}
				onChangeCallback={this.clearResults}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					this.updatePaymentResults(formValues).then(() => {
						handleSubmitSuccess();
					});
				}}
			>
				{({ formValues, handleOnChange, handleSubmit }) => {
					return (
						<div>
							<div className="row">
								<div className="col-md-12">
									<MiniContainer>
										<h4 className="heading">Search</h4>
										<div className="row">
											<SearchBarItem
												label="Start Date"
												value={
													<DateTimePicker
														name="StartDate"
														dayPickerOnly={true}
														value={formValues.StartDate}
														format="DD/MM/YYYY"
														onDayChange={handleOnChange}
														onChange={handleOnChange}
													/>
												}
											/>
											<SearchBarItem
												label="End Date"
												value={
													<DateTimePicker
														name="EndTime"
														dayPickerOnly={true}
														value={formValues.EndTime}
														format="DD/MM/YYYY"
														onDayChange={handleOnChange}
														onChange={handleOnChange}
													/>
												}
											/>
											<SearchBarItem
												label={
													<div>
														<span>Skimpy</span>
														<span className="pull-right">
															<InputCheckbox
																name="showInactiveUsers"
																value={formValues.showInactiveUsers}
																onChange={handleOnChange}
															/>
															<span style={{ paddingLeft: 5 }}>Show inactive</span>
														</span>
													</div>
												}
												value={
													<SkimpyList
														inactiveUsersVisible={formValues.showInactiveUsers}
														name="UserId"
														value={formValues.UserId}
														onChange={handleOnChange}
													/>
												}
											/>
											<SearchBarItem
												label={
													<div>Venue</div>
												}
												value={
													<ClientList
														name="ClientId"
														value={formValues.ClientId}
														onChange={handleOnChange}
													/>
												}
											/>
											<SearchBarItem
												label="Code"
												value={
													<PaymentCodeList
														name="PaymentCode"
														value={formValues.PaymentCode}
														onChange={handleOnChange}
													/>
												}
											/>
											<SearchBarItem
												label="Sub Code"
												value={
													<PaymentSubCodeList
														name="PaymentCategorySubCodesId"
														value={formValues.PaymentCategorySubCodesId}
														onChange={handleOnChange}
														paymentCode={formValues.PaymentCode}
													/>
												}
											/>
											<SearchBarItem
												label="Payment Method"
												value={
													<PaymentMethodList
														name="PaymentMethod"
														value={formValues.PaymentMethod}
														onChange={handleOnChange}
													/>
												}
											/>
											<div className="col-sm-12">
												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButton
															loading={this.state.loading}
															disabled={this.state.loading}
															className="btn-primary"
															onClick={handleSubmit}
														>
															<i className="fas fa-search" /> Search
														</LoadingButton>
													]}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12" />
										</div>
									</MiniContainer>
								</div>
							</div>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

ReportCodes.propTypes = {};

const mapStateToProps = (state, props) => {
	return {};
};

export default connect(mapStateToProps)(ReportCodes);
