import * as React from "react";
import PropTypes from "prop-types";
import ClientList from "../select/ClientList";
import ClientName from "../links/ClientName";
import GroupedInputWithButton from "../GroupedInput/GroupedInputWithButton";
import LoadingButtonWithConfirm from "../button/LoadingButtonWithConfirm";
import ProtectedComponent from "../permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import { PlusCircle, Trash } from "../icons/Icons";

class ClientListSelect extends React.Component {
	static propTypes = {
		handleOnCreate: PropTypes.func.isRequired,
		handleOnDelete: PropTypes.func.isRequired,
		value: PropTypes.array,
		handleOnCreateAll: PropTypes.func,
		handleOnDeleteAll: PropTypes.func
	};

	state = {
		selectedId: undefined
	};

	handleAddId = () => {
		this.props.handleOnCreate(this.state.selectedId);
	};

	handleDeleteId = e => {
		this.props.handleOnDelete(Number(e.currentTarget.value));
	};

	handleOnCreateAll = () => {
		this.props.handleOnCreateAll();
	};

	handleOnDeleteAll = () => {
		this.props.handleOnDeleteAll();
	};

	render() {

		return (
			<div>
				<GroupedInputWithButton
					inputProp={
						<ClientList
							hideClientIds={this.props.value}
							value={this.state.selectedId}
							onChange={e => {
								this.setState({
									selectedId: e.target.value
								});
							}}
						/>
					}
					buttonProp={
						<button
							className="btn btn-success"
							disabled={this.state.selectedId === undefined}
							onClick={this.handleAddId}
						>
							<PlusCircle/>
						</button>
					}
				/>
				<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
					<div className="admin-box">
						{this.props.handleOnDeleteAll && (
							<LoadingButtonWithConfirm
								className="admin-box-button pull-right btn-danger"
								onClick={this.handleOnDeleteAll}
							>
								<i className="fas fa-exclamation-triangle color-warning"/> Delete All
							</LoadingButtonWithConfirm>
						)}
						{this.props.handleOnCreateAll && (
							<LoadingButtonWithConfirm
								className="admin-box-button pull-right btn-danger"
								onClick={this.handleOnCreateAll}
							>
								<i className="fas fa-exclamation-triangle color-warning"/> Add All
							</LoadingButtonWithConfirm>
						)}
					</div>
				</ProtectedComponent>
				<div>
					{
						this.props.value.map((clientId) => {
							if (clientId == null) {
								return null
							}
							return <GroupedInputWithButton
								key={clientId}
								parentStyles={{ padding: 5, borderBottom: "1px solid #DDD" }}
								inputProp={<ClientName clientId={clientId}/>}
								buttonProp={
									<button
										className="btn btn-danger"
										value={clientId}
										onClick={this.handleDeleteId}
									>
										<Trash/>
									</button>
								}
							/>
						})
					}
				</div>
			</div>
		);
	}
}

export default ClientListSelect;
