import * as React from "react";
import PropTypes from "prop-types";
import "./FormRow.css";

export default class FormRow extends React.Component {
	render() {
		return (
			<div className="row form-row">
				<label
					className="col-lg-4 col-sm-12 control-label"
					style={{ marginBottom: 0 }}
				>
					{this.props.label}
				</label>
				<div className="col-lg-8 col-sm-12">{this.props.value}</div>
			</div>
		);
	}
}

FormRow.propTypes = {
	label: PropTypes.string,
	value: PropTypes.any
};
