import * as React from "react";
import PropTypes from "prop-types";
import ValidationMessages from "../form/ValidationMessages";
import Loadable from "react-loadable";

const LoadableReactQuill = Loadable({
	loader: () => import("./Quill"),
	loading: () => <span>Loading...</span>,
	timeout: 10000 // 10 seconds
});

export default class InputHtml extends React.Component {
	render() {
		let value = this.props.value;
		if (value === null || value === undefined) {
			value = "";
		}
		return (
			<React.Fragment>
				<LoadableReactQuill
					{...this.props}
					value={value}
					onChange={this.props.onChange}
				/>
				<ValidationMessages messages={this.props.validationMessages} />
			</React.Fragment>
		);
	}
}

InputHtml.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	inputClassName: PropTypes.string,
	validaitonClassName: PropTypes.string,
	validationMessages: PropTypes.arrayOf(PropTypes.string),
	allowColorInput: PropTypes.bool,
	allowSizeInput: PropTypes.bool,
	allowTextFormattingInput: PropTypes.bool,
	allowBulletPointInput: PropTypes.bool,
	allowLinkInput: PropTypes.bool,
	allowImageInput: PropTypes.bool
};
