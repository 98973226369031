import * as React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default class MenuItem extends React.Component {
	render() {
		return (
			<li className="left_menu_item_sub">
				<NavLink activeClassName="left_menu_item_active" to={this.props.route}>
					{this.props.children}
				</NavLink>
			</li>
		);
	}
}

MenuItem.propTypes = {
	route: PropTypes.string.isRequired
};
