import * as React from "react";
import PropTypes from "prop-types";
import ClientName from "../../components/links/ClientName";
import ClientTelephone from "../../components/links/ClientTelephone";
import ClientAddress from "../../components/text/ClientAddress";
import ShiftHRMList from "./ShiftHRMList";
import NatTime from "../../components/text/NatTime";
import { roundTo } from "../../lib/number/roundTo";
import AdminEditSeriesMenu from "../components/AdminEdit/AdminEditSeriesMenu";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import PaymentRow from "./payment/PaymentRow";
import {
	ShiftIconEquals,
	ShiftIconMinus,
	ShiftIconTimes
} from "./icon/ShiftIcons";
import { shiftsState } from "../shiftsState";
import { observer } from "mobx-react";
import { Pencil } from "../../components/icons/Icons";
import { css } from "emotion";
import { miniContainerShadow } from "../../styles/global/mini-container";
import { viewState } from "../../layouts/viewState";
import StyledButton, {
	EButtonBackgroundColors
} from "../../styled/Button/StyledButton";
import { SHIFT_EXPECTED_HOURS_MESSAGE } from "./AdminEdit/shiftFeeUtils";

class MultidayChildShift extends React.Component {
	constructor(props) {
		super(props);
		this.renderPayments = this.renderPayments.bind(this);
	}

	renderPayments() {
		const shift = this.props.Shift;

		const shiftPay = shift.ShiftPay;
		const payRate = shift.PayRate;
		const feePay = shift.FeePay;
		const takeHome = shift.ShiftPay - shift.FeePay;
		const hours = roundTo(shift.ShiftLength / 60, 2);

		return (
			<div>
				<PaymentRow label="Hours" value={hours} icon={null} />

				<PaymentRow
					label="Pay Rate"
					value={payRate}
					valueIsMoney={true}
					icon={<ShiftIconTimes />}
				/>

				<PaymentRow
					label="Total you bill"
					value={shiftPay}
					hasTopBlackBorder={true}
					valueIsMoney={true}
					icon={<ShiftIconEquals />}
				/>

				<PaymentRow
					label="Shift Fee"
					value={feePay}
					textIsRed={true}
					textIsBold={true}
					valueIsMoney={true}
					icon={<ShiftIconMinus />}
				/>
			</div>
		);
	}

	render() {
		const multidayShift = this.props.Shift;
		const { StartTime, EndTime, ClientId } = multidayShift;

		return (
			<div
				className={css`
					display: grid;
					padding: 15px;
					background-color: white;
					margin-bottom: 10px;
					grid-template-columns: ${viewState.showMobileView
						? "1fr"
						: "1fr 1fr 1fr 1fr"};
					grid-column-gap: 7px;

					.col {
						display: flex;
						flex-direction: column;
					}
					.col:nth-child(4) {
						justify-content: flex-end;
					}
					${miniContainerShadow};
				`}
			>
				<div className="col">
					<div>
						<ClientName clientId={ClientId} /> -{" "}
						<ClientTelephone clientId={ClientId} />
					</div>
					<div>
						<ClientAddress clientId={ClientId} />
					</div>
					<ShiftHRMList clientId={ClientId} />
				</div>
				<div className="col">
					<div>
						<NatTime startTime={StartTime} endTime={EndTime} />
					</div>
					<div>{multidayShift.Notes}</div>
				</div>
				<div className="col">
					<div>{this.renderPayments()}</div>
				</div>

				<div className="col">
					{/*Admin edit button*/}
					<ProtectedComponent allowedGroups={[userGroups.hrm, userGroups.admin]}>
						<StandardModalWrapper
							modalOpener={({ handleOpenModal }) => {
								return (
									<StyledButton
										classNameOverrides={css`
											width: 40px;
											height: 40px;
											align-self: flex-end;
										`}
										backgroundColor={EButtonBackgroundColors.blue}
										onClick={handleOpenModal}
									>
										<Pencil />
									</StyledButton>
								);
							}}
							render={({ handleCloseModal }) => {
								const handleCloseModalWithPoll = () => {
									shiftsState.getShifts();
									handleCloseModal();
								};
								return (
									<AdminEditSeriesMenu
										shift={this.props.Shift}
										handleCloseModal={handleCloseModalWithPoll}
									/>
								);
							}}
						/>
					</ProtectedComponent>
				</div>
			</div>
		);
	}
}

MultidayChildShift.propTypes = {
	Shift: PropTypes.shape(
		{ Action: PropTypes.string },
		{ AdminPay: PropTypes.number },
		{ ButtonClass: PropTypes.string },
		{ EndTime: PropTypes.string },
		{ Id: PropTypes.number },
		{ Label: PropTypes.string },
		{ Multiday: PropTypes.array },
		{ Notes: PropTypes.string },
		{ SeriesId: PropTypes.number },
		{ ShiftLength: PropTypes.number },
		{ ShiftStatus: PropTypes.number },
		{ ShiftType: PropTypes.number },
		{ StartTime: PropTypes.string },
		{ TravelPay: PropTypes.number },
		{ UserId: PropTypes.any },
		{ ValidationMessages: PropTypes.array }
	)
};

export default observer(MultidayChildShift);
