import { REDUX_ROOT_POLL } from "../store";

export function rootSelector(state) {
	return state[REDUX_ROOT_POLL];
}

export function pollLoading(state) {
	return rootSelector(state)["loading"];
}

export function termsAndConditionsSelector(state) {
	if (rootSelector(state)["raw"]["SkimpyBible"].length === 0) {
		return null;
	}

	return rootSelector(state)["byId"]["SkimpyBible"][1];
}

export function clientTermsAndConditionsSelector(state) {
	if (rootSelector(state)["raw"]["VenueBible"].length === 0) {
		return null;
	}

	return rootSelector(state)["byId"]["VenueBible"][1];
}

export function brandSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Brands"];
}
export function brandSelectorById(state) {
	return rootSelector(state)["byId"]["Brands"];
}

export function notificationSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Notifications"];
}

export function hrmChecklistSelectorById(state) {
	return rootSelector(state)["byId"]["HrmChecklists"];
}
export function hrmChecklistSelectorAsArray(state) {
	return rootSelector(state)["raw"]["HrmChecklists"];
}

export function clientSelectorById(state) {
	return rootSelector(state)["byId"]["Clients"];
}
export function clientSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Clients"];
}

export function clientTeamTalksSelectorById(state) {
	return rootSelector(state)["byId"]["ClientTeamTalks"];
}
export function clientTeamTalksSelectorAsArray(state) {
	return rootSelector(state)["raw"]["ClientTeamTalks"];
}

export function uploadsSelectorById(state) {
	return rootSelector(state)["byId"]["Uploads"];
}
export function uploadsSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Uploads"];
}

export function brandBannersUploadsById(state) {
	return rootSelector(state)["uploads"]["brands"]["banners"]["byBrandId"];
}

export function profileSelectorById(state) {
	return rootSelector(state)["byId"]["Profiles"];
}
export function profilesAsArray(state) {
	return rootSelector(state)["raw"]["Profiles"];
}

export function profileSelector(state) {
	return rootSelector(state)["raw"]["Profiles"];
}

export function userSelectorById(state) {
	return rootSelector(state)["byId"]["Users"];
}

export function userSelector(state) {
	return rootSelector(state)["raw"]["Users"];
}

export function levelSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Levels"];
}
export function levelSelectorById(state) {
	return rootSelector(state)["byId"]["Levels"];
}

export function skimpyBibleSelectorById(state) {
	return rootSelector(state)["byId"]["SkimpyBible"];
}
export function venueBibleSelectorById(state) {
	return rootSelector(state)["byId"]["VenueBible"];
}

export function hrmsSelectorAsArray(state) {
	return rootSelector(state)["raw"]["Hrms"];
}

export function clientUsersSelectorById(state) {
	return rootSelector(state)["byId"]["ClientUsers"];
}

export function clientUsersSelectorAsArray(state) {
	return rootSelector(state)["raw"]["ClientUsers"];
}

export function findClientById(state, id) {
	if (id in rootSelector(state)["byId"]["Clients"]) {
		return rootSelector(state)["byId"]["Clients"][id];
	}
	return {};
}
export function clientContactsSelectorById(state) {
	return rootSelector(state)["byId"]["ClientContacts"];
}
export function clientContactsSelectorAsArray(state) {
	return rootSelector(state)["raw"]["ClientContacts"];
}

export function seriesSelectorById(state) {
	return rootSelector(state)["byId"]["Series"];
}

export function findSeriesById(state, id) {
	if (id in rootSelector(state)["byId"]["Series"]) {
		return rootSelector(state)["byId"]["Series"][id];
	}
	return {};
}

export function findUserById(state, id) {
	if (id in rootSelector(state)["byId"]["Users"]) {
		return rootSelector(state)["byId"]["Users"][id];
	}

	return {};
}

/*
    Payments
 */
export function paymentCodesAsArray(state) {
	return rootSelector(state)["raw"]["PaymentCategoryCodes"];
}

export function paymentCodesById(state) {
	return rootSelector(state)["byId"]["PaymentCategoryCodes"];
}

export function paymentSubCodesAsArray(state) {
	return rootSelector(state)["raw"]["PaymentCategorySubCodes"];
}

export function paymentSubCodesById(state) {
	return rootSelector(state)["byId"]["PaymentCategorySubCodes"];
}

export function myPayments(state) {
	return rootSelector(state)["raw"]["Payments"];
}
/*
    Profile
 */
export function findProfileById(state, id) {
	if (id in rootSelector(state)["byId"]["Profiles"]) {
		return rootSelector(state)["byId"]["Profiles"][id];
	}
	return {};
}

export function profileByUserId(state) {
	return rootSelector(state)["profiles"]["byUserId"];
}
export function findProfileByUserId(state, id) {
	if (id in rootSelector(state)["profiles"]["byUserId"]) {
		return rootSelector(state)["profiles"]["byUserId"][id];
	}

	return {};
}

/*
    Client lists
 */
export function selectClientUserLists(state) {
	return rootSelector(state)["userClientLists"];
}
export function hrmsByClientId(state) {
	return rootSelector(state)["userClientLists"]["byClientId"]["Hrms"];
}
export function findHrmsByClientId(state, id) {
	if (id in rootSelector(state)["userClientLists"]["byClientId"]["Hrms"]) {
		return rootSelector(state)["userClientLists"]["byClientId"]["Hrms"][id];
	}
	return [];
}
export function findMnrsByClientId(state, id) {
	if (id in rootSelector(state)["userClientLists"]["byClientId"]["Mnrs"]) {
		return rootSelector(state)["userClientLists"]["byClientId"]["Mnrs"][id];
	}
	return [];
}
export function findFavouritesByClientId(state, id) {
	if (id in rootSelector(state)["userClientLists"]["byClientId"]["Favourites"]) {
		return rootSelector(state)["userClientLists"]["byClientId"]["Favourites"][id];
	}
	return [];
}
export function findPreapprovalsByClientId(state, id) {
	if (
		id in rootSelector(state)["userClientLists"]["byClientId"]["Preapprovals"]
	) {
		return rootSelector(state)["userClientLists"]["byClientId"]["Preapprovals"][
			id
		];
	}
	return [];
}
export function findSeriesPreapprovalsByClientId(state, id) {
	if (
		id in
		rootSelector(state)["userClientLists"]["byClientId"]["SeriesPreapprovals"]
	) {
		return rootSelector(state)["userClientLists"]["byClientId"][
			"SeriesPreapprovals"
		][id];
	}
	return [];
}
