import * as React from "react";
import { poll } from "../poll/actions";
import { connect } from "react-redux";
import { hrmChecklistSelectorById } from "../poll/selectors";
import ClientList from "../components/select/ClientList";
import SkimpyList from "../components/select/SkimpyList";
import ReactPaginate from "react-paginate";
import HrmChecklistPendingRow from "./HrmChecklistPendingRow";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import { parseAPIDate } from "../lib/dateUtils/dateUtils";

class HrmChecklistPending extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			UserIdFilter: undefined,
			ClientId: undefined,
			OffsetPagination: 0,
			ItemsPerPage: 5
		};
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
	}

	handleOnFilterChange = e => {
		// reset (ignore) the pagination when changing these filters
		let newState = this.state;
		const { name, value } = e.target;
		switch (name) {
			case "filterClientId":
				newState.ClientId = value;
				break;
			case "filterUserId":
				newState.UserIdFilter = value;
				break;
		}
		this.setState(newState);
	};

	handleOnShiftPageChange = event => {
		this.setState({ OffsetPagination: event.selected });
	};

	renderPagination(pageCount) {
		return (
			<ReactPaginate
				previousLabel={"previous"}
				nextLabel={"next"}
				breakLabel={null}
				pageCount={pageCount}
				marginPagesDisplayed={1}
				pageRangeDisplayed={5}
				onPageChange={this.handleOnShiftPageChange}
				containerClassName={"pagination"}
				subContainerClassName={"pages pagination"}
				activeClassName={"active"}
			/>
		);
	}

	renderSearchBar() {
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="mini-container">
						<h4 className="heading">Search</h4>
						<div className="row">
							<SearchBarItem
								label="Venue"
								value={
									<ClientList
										name="filterClientId"
										value={this.state.ClientId}
										onChange={this.handleOnFilterChange}
									/>
								}
							/>
							<SearchBarItem
								label="Skimpy"
								value={
									<SkimpyList
										name="filterUserId"
										value={this.state.UserIdFilter}
										onChange={this.handleOnFilterChange}
									/>
								}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderChecklistTable(checklistRows) {
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="mini-container" style={{ paddingBottom: 0 }}>
						{/* Header (hidden when xm or sm */}
						<div className="row div-table__thead hidden-xs hidden-sm hidden-md">
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-3 ">Venue</div>
									<div className="col-md-3 ">Shift Time</div>
									<div className="col-md-1 ">Name</div>
									<div className="col-md-2 ">Date Applied</div>
									<div className="col-md-3 ">Approval</div>
								</div>
							</div>
						</div>
						{/*Body*/}

						{checklistRows.map(item => {
							return (
								<div key={item.Id}>
									<HrmChecklistPendingRow
										fullCollumnSizes={[3, 3, 1, 2, 3]}
										checklistItem={item}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	filterChecklistRowsByClientId = checklistRows => {
		return checklistRows.filter(checklist => {
			if (this.state.ClientId === undefined) {
				return true;
			}
			if (checklist.ChildClientIds.indexOf(this.state.ClientId) !== -1) {
				return true;
			}
			return false;
		});
	};

	filterChecklistRowsByUserId = checklistRows => {
		return checklistRows.filter(checklist => {
			if (this.state.UserIdFilter === undefined) {
				return true;
			}
			if (this.state.UserIdFilter === checklist.UserId) {
				return true;
			}
			return false;
		});
	};

	filterPaginatedResults = (itemsPerPage, pageNumber, items = []) => {
		items.sort((a, b) => {
			const aDate = parseAPIDate(a["StartTime"]);
			const bDate = parseAPIDate(b["StartTime"]);

			if (aDate.getTime() === bDate.getTime()) {
				return a.ClientName.localeCompare(b.ClientName);
			}

			return aDate.getTime() - bDate.getTime();
		});
		return items.slice(
			itemsPerPage * pageNumber,
			itemsPerPage * (pageNumber + 1)
		);
	};

	render() {
		// checklist items from object to array
		let checklistRows = Object.keys(this.props.hrmChecklistById).map(id => {
			return this.props.hrmChecklistById[id];
		});

		// remove items if client filter is selected
		checklistRows = this.filterChecklistRowsByClientId(checklistRows);
		// remove items if user filter is selected
		checklistRows = this.filterChecklistRowsByUserId(checklistRows);

		// count the pages now clients have been filtered
		const pageCount = checklistRows.length / this.state.ItemsPerPage;

		// filter results set to show the current page only
		checklistRows = this.filterPaginatedResults(
			this.state.ItemsPerPage,
			this.state.OffsetPagination,
			checklistRows
		);

		return (
			<div className="row">
				<div className="col-md-12">
					{this.renderSearchBar()}
					<div style={{ paddingTop: 15 }} />
					{this.renderChecklistTable(checklistRows)}
					{this.renderPagination(pageCount)}
				</div>
			</div>
		);
	}
}

// HrmChecklistPending.propTypes = {
//     hrmChecklist: hrmChecklistSelectorById(state);
// }

const mapStateToProps = store => {
	return {
		hrmChecklistById: hrmChecklistSelectorById(store)
	};
};

export default connect(mapStateToProps)(HrmChecklistPending);
