import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormManager from "../form/FormManager";
import DateTimePicker from "../datetime/DateTimePicker";
import LoadingButton from "../button/LoadingButton";
import { allPaymentsPATCH } from "../../api/apiEndpoints";
import PaymentCodeList from "../select/PaymentCodeList";
import PaymentSubCodeList from "../select/PaymentSubCodeList";
import PaymentMethodList from "../select/PaymentMethodList";
import SkimpyList from "../select/SkimpyList";
import InputTextArea from "../input/InputTextArea";
import InputNumber from "../input/InputNumber";
import AdminContainerMigrate from "../container/AdminContainerMigrate";
import FormRow from "../form/FormRow";

class EditPaymentLine extends React.Component {
	render() {
		return (
			<FormManager
				initialFormValues={this.props.payment}
				handleValidation={v => {
					v.required({
						fieldNames: ["Amount"]
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					allPaymentsPATCH({
						PaymentId: formValues.PaymentId,
						PaymentCode: formValues.PaymentCode,
						PaymentCategorySubCodesId: formValues.PaymentCategorySubCodesId,
						UserId: formValues.UserId,
						Amount: formValues.Amount,
						Notes: formValues.LineItem,
						// PaymentType: formValues.PaymentType,
						PaymentMethod: formValues.PaymentMethod,
						CreatedAt: formValues.Date
					})
						.then(() => {
							handleSubmitSuccess();
							this.props.onSuccessUpdateFn();
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{({
					formValues,
					formValidation,
					handleOnChange,
					handleSubmit,
					submitting,
					submitEnabled
				}) => {
					return (
						<div>
							<FormRow
								label={"Date"}
								value={
									<DateTimePicker
										name="Date"
										value={formValues.Date}
										onChange={handleOnChange}
									/>
								}
							/>

							<FormRow
								label={"Code"}
								value={
									<PaymentCodeList
										name="PaymentCode"
										value={formValues.PaymentCode}
										onChange={handleOnChange}
									/>
								}
							/>

							<FormRow
								label={"Sub Code"}
								value={
									<PaymentSubCodeList
										name="PaymentCategorySubCodesId"
										value={formValues.PaymentCategorySubCodesId}
										onChange={handleOnChange}
										paymentCode={formValues.PaymentCode}
									/>
								}
							/>

							<FormRow
								label={"Payment Method"}
								value={
									<PaymentMethodList
										name="PaymentMethod"
										value={formValues.PaymentMethod}
										onChange={handleOnChange}
									/>
								}
							/>

							<FormRow
								label={"User"}
								value={
									<SkimpyList
										inactiveUsersVisible={this.props.showInactiveUsers}
										name="UserId"
										value={formValues.UserId}
										onChange={handleOnChange}
									/>
								}
							/>

							<FormRow
								label={"Line Item"}
								value={
									<InputTextArea
										name="LineItem"
										value={formValues.LineItem}
										onChange={handleOnChange}
										rows={3}
									/>
								}
							/>

							<FormRow
								label={"Amount"}
								value={
									<InputNumber
										name="Amount"
										value={formValues.Amount}
										onChange={handleOnChange}
										allowEmptyValue={true}
										validationMessages={formValidation.Amount}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										Save
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

EditPaymentLine.propTypes = {
	payment: PropTypes.object.isRequired,
	onSuccessUpdateFn: PropTypes.func,
	showInactiveUsers: PropTypes.bool
};

const mapStateToProps = (state, props) => {
	return {};
};

EditPaymentLine = connect(mapStateToProps)(EditPaymentLine);
export default EditPaymentLine;
