import * as React from "react";
import { connect } from "react-redux";
import {
	findUserById,
	levelSelectorById,
	profileSelectorById
} from "../../poll/selectors";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormManager from "../../components/form/FormManager";
import {
	profilesPATCH,
	uploadSections,
	uploadsGet,
	usersPATCH
} from "../../api/apiEndpoints";
import FormRow from "../../components/form/FormRow";
import LoadingButton from "../../components/button/LoadingButton";
import ProfileWorkPhone from "../../components/links/ProfileWorkPhone";
import UserPermissionGroup from "../../components/text/UserPermissionGroup";
import ProfileGST from "../../components/profile/display/ProfileGST";
import ProfilePublicProfileEnabled from "../../components/profile/display/ProfilePublicProfileEnabled";
import Level from "../../components/text/Level";
import InputText from "../../components/input/InputText";
import InputCheckbox from "../../components/input/InputCheckbox";
import InputSelectLevel from "../../components/input/InputSelectLevel";
import InputSelectUserGroup from "../../components/input/InputSelectUserGroup";
import { routes } from "../../router/router";
import { NavLink } from "react-router-dom";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import ChangePassword from "../../Password/ChangePassword";
import PropTypes from "prop-types";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import { Save } from "../../components/icons/Icons";
import StyledButton, {
	EButtonBackgroundColors
} from "../../styled/Button/StyledButton";
import { css } from "emotion";

class WorkProfile extends React.PureComponent {
	state = {
		mainProfilePicturePath: ""
	};

	static propTypes = {
		profileId: PropTypes.number.isRequired
	};

	componentDidMount() {
		uploadsGet({
			UploadSectionTypes: [uploadSections.profile_main_profile_picture],
			UploadTypeId: [this.props.profileId]
		}).then(r => {
			const uploads = r.data || [];

			if (uploads.length === 1) {
				this.setState({
					mainProfilePicturePath: uploads[0].Path
				});
			}
		});
	}

	render() {
		if (this.props.profile === undefined) {
			return null;
		}

		const { UserId } = this.props.profile;
		return (
			<div>
				<StandardModalWrapper
					heading={"My Profile for Hotels"}
					modalOpener={({ handleOpenModal }) => {
						return (
							<>
								<ProtectedComponent
									allowedGroups={[userGroups.admin, userGroups.hrm]}
									allowedIfLoggedInAsUserId={UserId}
								>
									<span className="heading-edit" onClick={handleOpenModal}>
										Edit
									</span>
								</ProtectedComponent>
								<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
									<a
										className="heading-edit"
										href={`/admin_accounts?userId=${UserId}`}
										style={{ marginRight: "50px" }}
									>
										View Account
									</a>
								</ProtectedComponent>
							</>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<FormManager
									initialFormValues={{
										StageName: this.props.profile.StageName,
										Email: this.props.user.Email,
										WorkPhone: this.props.profile.WorkPhone,
										WorkFacebook: this.props.profile.WorkFacebook,
										ABN: this.props.profile.ABN,
										BasedIn: this.props.profile.BasedIn,
										GST: this.props.profile.GST,
										PublicProfileEnabled: this.props.profile.PublicProfileEnabled,
										Experience: this.props.profile.Experience,
										LevelId: this.props.profile.LevelId,
										UserGroupId: this.props.user.UserGroupId,
										PersonnelFile: this.props.profile.PersonnelFile,
										PromotionalNotes: this.props.profile.PromotionalNotes
									}}
									handleSubmit={({
										formValues,
										handleSubmitFail,
										handleSubmitSuccess
									}) => {
										Promise.all([
											profilesPATCH({
												Id: this.props.profileId,
												StageName: formValues.StageName,
												WorkPhone: formValues.WorkPhone,
												WorkFacebook: formValues.WorkFacebook,
												ABN: formValues.ABN,
												BasedIn: formValues.BasedIn,
												GST: formValues.GST,
												PublicProfileEnabled: formValues.PublicProfileEnabled,
												Experience: formValues.Experience,
												LevelId: formValues.LevelId,
												PersonnelFile: formValues.PersonnelFile,
												PromotionalNotes: formValues.PromotionalNotes
											}),
											usersPATCH({
												Id: this.props.user.Id,
												Email: formValues.Email,
												UserGroupId: formValues.UserGroupId
											})
										])
											.then(() => {
												handleSubmitSuccess();
												handleCloseModal();
											})
											.catch(error => {
												handleSubmitFail();
												throw error;
											});
									}}
								>
									{/*FormManager children*/}
									{({
										formValues,
										handleOnChange,
										handleSubmit,
										formValidation,
										submitEnabled,
										submitting
									}) => {
										return (
											<div>
												<ProtectedComponent allowedGroups={[userGroups.admin]}>
													<FormRow
														label={"Stage Name"}
														value={
															<InputText
																name={"StageName"}
																value={formValues.StageName}
																onChange={handleOnChange}
																validationMessages={formValidation.StageName}
															/>
														}
													/>
													<FormRow
														label={"Email"}
														value={
															<InputText
																name={"Email"}
																value={formValues.Email}
																onChange={handleOnChange}
																validationMessages={formValidation.Email}
															/>
														}
													/>
													<FormRow
														label={"Work Phone"}
														value={
															<InputText
																name={"WorkPhone"}
																value={formValues.WorkPhone}
																onChange={handleOnChange}
																validationMessages={formValidation.WorkPhone}
															/>
														}
													/>
													<FormRow
														label={"Work Facebook"}
														value={
															<InputText
																name={"WorkFacebook"}
																value={formValues.WorkFacebook}
																onChange={handleOnChange}
																validationMessages={formValidation.WorkFacebook}
															/>
														}
													/>
													<FormRow
														label={"ABN"}
														value={
															<InputText
																name={"ABN"}
																value={formValues.ABN}
																onChange={handleOnChange}
																validationMessages={formValidation.ABN}
															/>
														}
													/>
													<FormRow
														label={"Based In"}
														value={
															<InputText
																name={"BasedIn"}
																value={formValues.BasedIn}
																onChange={handleOnChange}
																validationMessages={formValidation.BasedIn}
															/>
														}
													/>
													<FormRow
														label={"Registered for GST"}
														value={
															<InputCheckbox
																name={"GST"}
																value={formValues.GST}
																onChange={handleOnChange}
																validationMessages={formValidation.GST}
															/>
														}
													/>

													<FormRow
														label={"Personnel File"}
														value={
															<InputText
																name={"PersonnelFile"}
																value={formValues.PersonnelFile}
																onChange={handleOnChange}
																validationMessages={formValidation.PersonnelFile}
															/>
														}
													/>

													<FormRow
														label={"Promotional Notes"}
														value={
															<InputText
																name={"PromotionalNotes"}
																value={formValues.PromotionalNotes}
																onChange={handleOnChange}
																validationMessages={formValidation.PromotionalNotes}
															/>
														}
													/>

													{/*Fold these menus upward so they dont disappear under the modal*/}
													<span className="Select-menu-outer-expand-up">
														<FormRow
															label={"Level"}
															value={
																<InputSelectLevel
																	name={"LevelId"}
																	value={formValues.LevelId}
																	onChange={handleOnChange}
																	validationMessages={formValidation.LevelId}
																/>
															}
														/>
														<FormRow
															label={"User Group"}
															value={
																<InputSelectUserGroup
																	name={"UserGroupId"}
																	value={formValues.UserGroupId}
																	onChange={handleOnChange}
																	validationMessages={formValidation.UserGroupId}
																/>
															}
														/>
													</span>
												</ProtectedComponent>

												<FormRow
													label={"Started skimpy work in"}
													value={
														<InputText
															name={"Experience"}
															disabled={
																userInfo().userGroupId !== userGroups.admin &&
																userInfo().userId !== this.props.user.Id
															}
															value={formValues.Experience}
															onChange={handleOnChange}
															validationMessages={formValidation.Experience}
														/>
													}
												/>

												<FormRow
													label={"Show Schedule on Public Site"}
													value={
														<InputCheckbox
															name={"PublicProfileEnabled"}
															disabled={
																userInfo().userGroupId !== userGroups.admin &&
																userInfo().userId !== this.props.user.Id
															}
															value={formValues.PublicProfileEnabled}
															onChange={handleOnChange}
															validationMessages={formValidation.PublicProfileEnabled}
														/>
													}
												/>

												<AdminContainerMigrate
													align={"right"}
													buttons={[
														<LoadingButton
															onClick={handleSubmit}
															loading={submitting}
															disabled={!submitEnabled}
														>
															<Save /> Save
														</LoadingButton>
													]}
												/>
											</div>
										);
									}}
								</FormManager>
							</div>
						);
					}}
				/>

				<h4 className="heading">My Profile for Hotels</h4>
				<i className="color-fail">Viewable by Admin, HRM and Venues only</i>
				<div className="row">
					<div className="col-lg-4">
						{this.state.mainProfilePicturePath !== "" && (
							<NavLink to={routes.Pictures + "/" + this.props.profile.UserId}>
								<UploadRenderer
									showThumbnail={false}
									src={this.state.mainProfilePicturePath}
								/>
							</NavLink>
						)}
					</div>

					<div className="col-lg-8">
						<FormRow label={"Stage Name"} value={this.props.profile.StageName} />
						<FormRow
							label={"Email"}
							value={
								<a href={"mailto:" + this.props.user.Email}>{this.props.user.Email}</a>
							}
						/>
						<FormRow
							label={"Work Phone"}
							value={<ProfileWorkPhone userId={UserId} />}
						/>
						<FormRow
							label={"Work Facebook"}
							value={
								<span
									className={css`
										overflow-wrap: break-word;
									`}
								>
									<a href={this.props.profile.WorkFacebook} target="_blank">
										{this.props.profile.WorkFacebook}
									</a>
								</span>
							}
						/>
						<FormRow label={"ABN"} value={this.props.profile.ABN} />
						<FormRow label={"Based In"} value={this.props.profile.BasedIn} />
						<FormRow
							label={"Registered for GST"}
							value={<ProfileGST userId={UserId} />}
						/>
						<ProtectedComponent allowedGroups={[userGroups.admin]}>
							<FormRow label={"Personnel File"} value={this.props.profile.PersonnelFile} />
							<FormRow label={"Promotional Notes"} value={this.props.profile.PromotionalNotes} />
						</ProtectedComponent>
						<FormRow
							label={"Show Schedule on Public Site"}
							value={<ProfilePublicProfileEnabled userId={UserId} />}
						/>
						<FormRow
							label={"Started skimpy work in"}
							value={this.props.profile.Experience}
						/>
						<FormRow label={"Level"} value={<Level userId={UserId} />} />
						<FormRow
							label={"Permission Group"}
							value={<UserPermissionGroup userId={UserId} />}
						/>
					</div>
				</div>
				<div>
					<NavLink to={routes.Pictures + "/" + this.props.profile.UserId}>
						<StyledButton backgroundColor={EButtonBackgroundColors.blue}>
							<i className="fas fa-file-image" /> My Photos & Certificates
						</StyledButton>
					</NavLink>
				</div>
				<ProtectedComponent
					allowedGroups={[userGroups.admin]}
					allowedIfLoggedInAsUserId={UserId}
				>
					<div
						className={css`
							.vbutton {
								margin-top: 5px;
							}
						`}
					>
						<StandardModalWrapper
							heading={"Change Password"}
							modalOpener={({ handleOpenModal }) => {
								return (
									<StyledButton
										onClick={handleOpenModal}
										backgroundColor={EButtonBackgroundColors.yellow}
									>
										<i className="fas fa-key" /> Change Password
									</StyledButton>
								);
							}}
							render={({ handleCloseModal }) => {
								return (
									<ChangePassword userId={UserId} handleSaveSuccess={handleCloseModal} />
								);
							}}
						/>
					</div>
				</ProtectedComponent>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile,
		user: findUserById(state, profile.UserId),
		level: levelSelectorById(state)[profile.LevelId]
	};
};

export default connect(mapStateToProps)(WorkProfile);
