import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'; 

export default class SingleMenu extends React.Component {
    render() {
        return (
            <li className="left_menu_item_heading">
                <NavLink activeClassName="left_menu_item_active" to={this.props.route}>
                    <i className={this.props.icon} /> {this.props.heading}
                </NavLink>
            </li>
        );
    }
}

SingleMenu.propTypes = {
    heading: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
};
