import store, { REDUX_ROOT_AUTH } from "../../store";

export const userGroups = {
	inactive: 0,
	trial: 1,
	restricted: 2,
	fullAccess: 3,
	admin: 4,
	client: 5,
	hrm: 6
};

export const userInfo = () => {
	const storeData = store.getState();

	let userInfo = {
		token: storeData[REDUX_ROOT_AUTH].authToken,
		userGroupId: storeData[REDUX_ROOT_AUTH].tokenDecoded.UserGroupId,
		userId: storeData[REDUX_ROOT_AUTH].tokenDecoded.userId,
		clientId: storeData[REDUX_ROOT_AUTH].tokenDecoded.ClientId
	};

	return userInfo;
};
