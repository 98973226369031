import {action, observable} from "mobx";
import {clientsPATCH, getClientsByIds} from "../../api/apiEndpoints";
import {AxiosResponse} from "axios";


export class ManageVenueState {
	constructor(brandId: number) {
		this.brandId = brandId;
		this.getClientsForBrand();
	}

	public brandId: number = 0;

	@observable
	public clientIds: number[] = [];

	@action
	public getClientsForBrand = () => {
		// @ts-ignore
		getClientsByIds({fieldsToQuery: ["BrandId"]})
			.then((resp: AxiosResponse) => {

				let filteredClientIds: number[] = [];

				Object.keys(resp.data).forEach((key) => {
					if (resp.data[key].BrandId == this.brandId) {
						filteredClientIds.push(resp.data[key].Id);
					}
				})

				this.clientIds = filteredClientIds;
			})
	}

	@action
	public handleOnDeleteClientFromBrand = (clientId: number) => {
		// @ts-ignore
		clientsPATCH({
			Id: clientId,
			BrandId: 6,
		}).then(this.getClientsForBrand);
	}

	@action
	public handleOnAddClientToBrand = (clientId: number) => {
		// @ts-ignore
		clientsPATCH({
			Id: clientId,
			BrandId: this.brandId,
		}).then(this.getClientsForBrand);
	};


}
