import { REDUX_ROOT_POLL } from "../../store";
import store from "../../store";

export function sortStageNameByUserId(a, b) {
	const profilesByUserId = store.getState()[REDUX_ROOT_POLL]["profiles"][
		"byUserId"
	];

	let aStageName = "";
	let bStageName = "";

	if (a in profilesByUserId) {
		aStageName = profilesByUserId[a].StageName.toLowerCase();
	}
	if (b in profilesByUserId) {
		bStageName = profilesByUserId[b].StageName.toLowerCase();
	}

	return aStageName.localeCompare(bStageName);
}
