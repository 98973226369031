import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../components/button/LoadingButton";
import ClientName from "../../components/links/ClientName";
import ClientTelephone from "../../components/links/ClientTelephone";
import ClientAddress from "../../components/text/ClientAddress";
import ShiftHRMList from "./ShiftHRMList";
import MultidayChildShift from "./MultidayChildShift";
import NatTime from "../../components/text/NatTime";
import BookingAdminButtons from "./BookingAdminButtons";
import Requirements from "./Requirements";
import {
	ShiftIconEquals,
	ShiftIconMinus,
	ShiftIconPlus,
	ShiftIconTimes
} from "./icon/ShiftIcons";
import PaymentRow from "./payment/PaymentRow";
import ShiftBrand from "./brandImage/ShiftBrand";
import { shiftsState } from "../shiftsState";
import { observer } from "mobx-react";
import { css } from "emotion";
import { SHIFT_EXPECTED_HOURS_MESSAGE } from "./AdminEdit/shiftFeeUtils";

class MultidayParentShift extends React.Component {
	sumMultidayColumn(shift, key) {
		const children = shift.Multiday || [];
		const val = children.reduce(
			(total = 0, shiftVal) => total + shiftVal[key],
			0
		);
		return val;
	}

	handleShowHideChildren = () => {
		shiftsState.handleOnChangeToggleShowMultidayChildShifts(this.props.Shift.Id);
	};

	renderMultidayPayments() {
		const shift = this.props.Shift;

		const shiftPay = this.sumMultidayColumn(shift, "ShiftPay");
		const adminPay = shift.AdminPay;
		const feePay = this.sumMultidayColumn(shift, "FeePay") + adminPay;
		const travelPay = shift.TravelPay;

		const takeHome = shiftPay + travelPay - feePay;
		const children = this.props.Shift.Multiday || [];
		let totalShiftLength = children.reduce((prev, cur) => {
			return prev + cur.ShiftLength;
		}, 0);

		let avgPayRate = children.reduce((prev, cur) => {
			return prev + cur.PayRate;
		}, 0);

		if (children.length !== 0) {
			avgPayRate = avgPayRate / children.length;
		}

		totalShiftLength = totalShiftLength / 60;

		return (
			<div>
				<PaymentRow label="Hours" value={totalShiftLength} icon={null} />

				<PaymentRow
					label="Pay Rate"
					value={avgPayRate}
					icon={<ShiftIconTimes />}
					valueIsMoney={true}
				/>

				<PaymentRow
					label="Shift Total"
					value={shiftPay}
					icon={<ShiftIconPlus />}
					hasTopBlackBorder={true}
					textIsBold={true}
					valueIsMoney={true}
				/>

				<PaymentRow
					label="Travel Rate"
					value={shift.TravelPay}
					icon={<ShiftIconPlus />}
					valueIsMoney={true}
				/>

				<PaymentRow
					label="Total you bill"
					value={shiftPay + shift.TravelPay}
					icon={<ShiftIconEquals />}
					valueIsMoney={true}
					textIsBold={true}
					hasTopBlackBorder={true}
				/>

				<PaymentRow
					label="Shift Fee"
					value={feePay}
					icon={<ShiftIconMinus />}
					valueIsMoney={true}
					textIsBold={true}
					textIsRed={true}
				/>

				<label
					style={{
						color: "red",
						marginTop: "8px",
						fontWeight: "normal",
						fontSize: "11px"
					}}
				>
					{SHIFT_EXPECTED_HOURS_MESSAGE}
				</label>
			</div>
		);
	}

	renderChildShifts() {
		if (shiftsState.getShowMultidayChild(this.props.Shift.Id) !== true) {
			return null;
		}

		const children = this.props.Shift.Multiday || [];

		return (
			<div
				className={css`
					padding: 15px;
					display: flex;
					flex-direction: column;
				`}
			>
				{children.map((childShift, idx) => {
					return (
						<div
							key={idx}
							className={css`
								display: flex;
								flex-direction: column;
							`}
						>
							<MultidayChildShift Shift={childShift} />
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		const multidayShift = this.props.Shift;
		const { StartTime, EndTime } = multidayShift;

		let uniqueClientIdObj = {};
		const children = multidayShift.Multiday || [];

		children.forEach(childShift => {
			uniqueClientIdObj[childShift.ClientId] = null;
		});
		const uniqueClientIds = Object.keys(uniqueClientIdObj).map(clientId => {
			return parseInt(clientId, 10);
		});

		// since multiday are now considered 'one client only' and will have the same requirements, just get the requirements from the first child shift
		let requirements = [];
		if (children.length > 0) {
			requirements = children[0].Requirements;
		}

		return (
			<div className="row">
				<div className="col-md-3 col-lg-3">
					<div>
						<b>Multiday shift</b>
					</div>
					<div>
						{uniqueClientIds.map((clientId, idx) => {
							return (
								<div key={idx}>
									<div>
										<ClientName clientId={clientId} /> -{" "}
										<ClientTelephone clientId={clientId} />
									</div>
									<div>
										<ClientAddress clientId={clientId} />
									</div>
								</div>
							);
						})}
					</div>
					<div>
						<ShiftBrand clientId={multidayShift.ChildClientIds[0]} />
					</div>

					<div>
						<div>
							<span>
								<b>Hotel Relationship Manager</b>
							</span>
						</div>
						{uniqueClientIds.map((clientId, idx) => {
							return (
								<div key={idx}>
									<ShiftHRMList clientId={clientId} />
								</div>
							);
						})}
					</div>
				</div>
				<div className="col-md-2 col-lg-4">
					<div>
						<span style={{ fontWeight: 600 }}>
							<NatTime startTime={StartTime} endTime={EndTime} />
						</span>
					</div>
					<div>
						<Requirements
							requirements={requirements}
							hrmApprovalRequired={multidayShift.HrmApproval === true}
							clientId={multidayShift.ChildClientIds[0]}
						/>
						<div>{multidayShift.Notes}</div>
						<div>
							<span className="color-fail" style={{ fontWeight: 600 }}>
								Each day's expected hours are detailed below
							</span>
						</div>
					</div>
				</div>
				<div className="col-md-4 col-lg-3 text-right">
					<div className="col-xs-12">{this.renderMultidayPayments()}</div>
				</div>

				<div className="col-md-3 col-lg-2">
					<BookingAdminButtons shift={multidayShift} />
				</div>
				<div
					className="col-md-12"
					style={{ paddingTop: 20, paddingLeft: 15, paddingRight: 15 }}
				>
					{" "}
					{shiftsState.getShowMultidayChild(this.props.Shift.Id) ? (
						<LoadingButton
							style={{ width: "100%" }}
							className="btn-default"
							onClick={this.handleShowHideChildren}
						>
							Hide Multiday Shifts
						</LoadingButton>
					) : (
						<LoadingButton
							style={{ width: "100%" }}
							className="btn-default"
							onClick={this.handleShowHideChildren}
						>
							Show Multiday Shifts
						</LoadingButton>
					)}
				</div>

				{this.renderChildShifts()}
			</div>
		);
	}
}

MultidayParentShift.propTypes = {
	Shift: PropTypes.shape(
		{ Action: PropTypes.string },
		{ AdminPay: PropTypes.number },
		{ ButtonClass: PropTypes.string },
		{ EndTime: PropTypes.string },
		{ Id: PropTypes.number },
		{ Label: PropTypes.string },
		{ Multiday: PropTypes.array },
		{ Notes: PropTypes.string },
		{ SeriesId: PropTypes.number },
		{ ShiftLength: PropTypes.number },
		{ ShiftStatus: PropTypes.number },
		{ ShiftType: PropTypes.number },
		{ StartTime: PropTypes.string },
		{ TravelPay: PropTypes.number },
		{ UserId: PropTypes.any },
		{ ValidationMessages: PropTypes.array }
	)
};

export default observer(MultidayParentShift);
