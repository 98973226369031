import { fromArrayToObjectByKey } from "../lib/arrayObj/arrayObj";

const configList = {
	CalendarTypes: {},
	ClientShiftFeedbackTypes: {},
	HrmChecklistStatus: {},
	PaymentCode: {},
	PaymentMethod: {},
	PaymentStatus: {},
	PaymentType: {},
	Permissions: {},
	ProfileMerch: {},
	ProfileServices: {},
	ShiftExtra: {},
	ShiftRequirements: {},
	ShiftStatus: {},
	ShiftTypes: {},
	UserGroups: {}
};

const defaultState = {
	raw: {},
	byId: { ...configList }
};

export function configReducer(state = defaultState, action) {
	switch (action.type) {
		case "CONFIG_SUCCESS_DATA": {
			let configById = {};

			Object.keys(configList).forEach(list => {
				configById[list] = fromArrayToObjectByKey("Id", action.payload[list]);
			});

			return {
				...state,
				raw: action.payload,
				byId: configById
			};
		}
		default: {
			return state;
		}
	}
}
