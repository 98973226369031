import * as React from "react";
import NatTime from "../../components/text/NatTime";

export function formatShiftDiff(diffs) {
	let newDiff = diffs.filter(diff => {
		return diff.Change === true;
	});

	newDiff = newDiff.map(diff => {
		switch (diff.Field) {
			case "StartTime":
				return {
					Field: "Start Time",
					Before: <NatTime startTime={diff.Before} />,
					After: <NatTime startTime={diff.After} />
				};
			case "EndTime":
				return {
					Field: "End Time",
					Before: <NatTime startTime={diff.Before} />,
					After: <NatTime startTime={diff.After} />
				};
			case "Extra":
				return {
					Field: "Dance Show",
					Before: diff.Before === null ? "false" : "true",
					After: diff.After === null ? "false" : "true"
				};
			case "FeePay":
				return {
					Field: "Shift Fee",
					Before: Number(diff.Before).toFixed(2),
					After: Number(diff.After).toFixed(2)
				};
			case "PayRate":
				return {
					Field: "Pay Rate",
					Before: Number(diff.Before).toFixed(2),
					After: Number(diff.After).toFixed(2)
				};
			case "ShiftPay":
				return {
					Field: "Total Shift Pay",
					Before: Number(diff.Before).toFixed(2),
					After: Number(diff.After).toFixed(2)
				};
			case "TravelPay":
				return {
					Field: "Travel Pay",
					Before: Number(diff.Before).toFixed(2),
					After: Number(diff.After).toFixed(2)
				};
			case "AdminPay":
				return {
					Field: "Admin Fee",
					Before: Number(diff.Before).toFixed(2),
					After: Number(diff.After).toFixed(2)
				};
			case "ShiftLength":
				return {
					Field: "Shift Length (Minutes)",
					Before: Number(diff.Before),
					After: Number(diff.After)
				};
			case "Notes":
				return {
					Field: "Notes",
					Before: String(diff.Before),
					After: String(diff.After)
				};
			case "HrmApproval":
				return {
					Field: "HRM Approval required",
					Before: String(diff.Before),
					After: String(diff.After)
				};
			default:
				Object.keys(diff).forEach(key => {
					diff[key] = String(diff[key]);
				});
				return diff;
		}
	});

	return newDiff;
}
