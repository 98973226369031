import * as React from "react";
import { connect } from "react-redux";
import {
	clientSelectorAsArray,
	clientUsersSelectorAsArray,
	userSelectorById
} from "../../poll/selectors";
import { uniqueArray } from "../../lib/arrayObj/uniqueArray";

class VenueEmailAddressesModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			emailAddresses: []
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad(props) {
		/* Email addresses from Clients */
		let emailAddresses = props.venuesAsArray
			.map(venue => {
				return venue.ClientEmail;
			})
			.filter(email => {
				return email !== null;
			});

		/* Users which belong to clients */
		props.clientUsersAsArray
			.map(clientUser => {
				return clientUser.UserId;
			})
			.forEach(userId => {
				emailAddresses.push(props.usersById[userId].Email);
			});

		/* Sort alpha numeric */
		emailAddresses = uniqueArray(emailAddresses).sort((a, b) => {
			return a.localeCompare(b);
		});

		this.setState({ emailAddresses: emailAddresses });
	}

	render() {
		return this.state.emailAddresses.join("; ");
	}
}

const mapStateToProps = state => {
	return {
		usersById: userSelectorById(state),
		venuesAsArray: clientSelectorAsArray(state),
		clientUsersAsArray: clientUsersSelectorAsArray(state)
	};
};

VenueEmailAddressesModal = connect(mapStateToProps)(VenueEmailAddressesModal);
export default VenueEmailAddressesModal;
