const validExtension = (ext, allowedExts) => allowedExts.indexOf(ext) > -1
const validMimeType = (mime, allowedMimes) => {
	const wildCards = allowedMimes.filter(f => f.indexOf('*') > -1).map(m => m.split('/')[0])
	const splitMime = mime.split('/')[0]
	const matchesWildcard = wildCards.indexOf(splitMime) > -1
	const matches = allowedMimes.indexOf(mime) > -1
	return !!matchesWildcard || !!matches
}

export const validate = (file, accepts) => {
  const _promise = new Promise((resolve, reject) => {
    if (!accepts || !accepts.length) {
      reject(new Error('no accept values set on file input'))
    }
    const allowedExtensions = accepts.filter(f => f.indexOf('.') > -1).map(m => m.split('.').pop())
    const allowedMimeTypes = accepts.filter(f => f.indexOf('/') > -1)


    const { name, type } = file;
    const ext = name.split('.').pop()
    const err = (t, a, d) => `Invalid file ${t} detected. Expected ${a.join(', ')} but detected ${d} from file header`

    const isDerivedExtensionValid = allowedExtensions.length ? validExtension(ext, allowedExtensions) : true
    const isDerivedMimetypeValid = allowedMimeTypes.length ? validMimeType(type, allowedMimeTypes) : true
    
    if (!isDerivedMimetypeValid) {
      reject(new Error(err('type', allowedMimeTypes, type)))
    }
    if (!isDerivedExtensionValid) {
      reject(new Error(err("extension", allowedExtensions, ext)))
    }

    resolve(true)
  })
  return _promise
}