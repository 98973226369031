import * as React from "react";
import { connect } from "react-redux";
import { poll } from "../poll/actions";
import WorkProfile from "./components/WorkProfile";
import PersonalInformationView from "./components/PersonalInformationView";
import PersonalInformationEdit from "./components/PersonalInformationEdit";
import Personality from "./components/Personality";
import Services from "./components/Services";
import MiniContainer from "../components/container/MiniContainer";
import { profileByUserId } from "../poll/selectors";
import ClientUserList, {
	ClientUserListTypes
} from "../components/clientUserList/ClientUserList";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import ProtectedComponent from "../components/permissions/ProtectedComponent";
import { userGroups } from "../lib/userInfo/userInfo";
import ProfileAdminTools from "./components/ProfileAdminTools";

class Profile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
	}

	render() {
		const userId = parseInt(this.props.match.params.id, 10);

		if (!(userId in this.props.profileByUserId)) {
			return null;
		}

		const profileId = this.props.profileByUserId[userId].Id;

		return (
			<div className="row">
				<div className="col-sm-6">
					<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm, userGroups.client]} allowedIfLoggedInAsUserId={userId}>
						<MiniContainer>
							<WorkProfile profileId={profileId} />
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer>
						<Personality profileId={profileId} />
					</MiniContainer>

					<ProtectedComponent allowedGroups={[userGroups.admin]}>
						<MiniContainer>
							<ProfileAdminTools profileId={profileId} />
						</MiniContainer>
					</ProtectedComponent>
					{/*<MiniContainer>*/}
					{/*<Services profileId={profileId}/>*/}
					{/*</MiniContainer>*/}
				</div>
				<div className="col-sm-6">
					<ProtectedComponent
						allowedIfLoggedInAsUserId={userId}
						allowedGroups={[userGroups.admin, userGroups.hrm]}
					>
						<MiniContainer>
							<StandardModalWrapper
								heading={"Personal Information"}
								modalOpener={({ handleOpenModal }) => {
									return (
										<span className="heading-edit" onClick={handleOpenModal}>
											Edit
										</span>
									);
								}}
								render={({ handleCloseModal }) => {
									return (
										<PersonalInformationEdit
											handleSaveCallback={handleCloseModal}
											profileId={profileId}
										/>
									);
								}}
							/>
							<h4 className="heading">Personal Information</h4>
							<i className="color-fail">Viewable by Admin & HRM only</i>
							<PersonalInformationView profileId={profileId} />
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer>
						<ClientUserList byUserId={userId} listName={ClientUserListTypes.Hrms} />
					</MiniContainer>
					<MiniContainer>
						<ClientUserList
							byUserId={userId}
							listName={ClientUserListTypes.Favourites}
						/>
					</MiniContainer>
					<ProtectedComponent
						allowedGroups={[userGroups.hrm, userGroups.admin]}
						allowedIfLoggedInAsUserId={userId}
					>
						<MiniContainer>
							<ClientUserList byUserId={userId} listName={ClientUserListTypes.Mnrs} />
						</MiniContainer>
					</ProtectedComponent>
					<MiniContainer>
						<ClientUserList
							byUserId={userId}
							listName={ClientUserListTypes.Preapprovals}
						/>
					</MiniContainer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		profileByUserId: profileByUserId(state)
	};
};

export default connect(mapStateToProps)(Profile);
