import * as React from "react";
import { connect } from "react-redux";
import DateTimePicker from "../../../components/datetime/DateTimePicker";
import {
	convertAPIDateToDDMMYYYY,
	convertDDMMYYYToJSDate,
	convertJSDateToDDMMYYYY,
	parseAPIDate
} from "../../../lib/dateUtils/dateUtils";
import FormRow from "../../../components/form/FormRow";
import { availablePOST } from "../../../api/apiEndpoints";
import DateFns from "date-fns";
import { brandSelectorById, clientSelectorById } from "../../../poll/selectors";
import ProfileStageName from "../../../components/links/ProfileStageName";
import Spinner from "../../../components/loading/Spinner";
import ClientNameAsText from "../../../components/text/ClientNameAsText";

class GenerateDailyLineup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			startTime: convertJSDateToDDMMYYYY(new Date()),
			regularShifts: [],
			multidayShifts: [],
			brandsByClientId: {},
			loading: true
		};
	}

	getShifts = () => {
		this.setState({ loading: true });
		// available's start time wont capture multiday shifts which started earlier, but might still be running
		const mutatedStartTime = convertJSDateToDDMMYYYY(
			DateFns.addDays(convertDDMMYYYToJSDate(this.state.startTime), -30)
		);

		availablePOST({
			Limit: 99999,
			Offset: 0,
			UpdatedAt: 0,
			StartTime: mutatedStartTime
		})
			.then(r => {
				const filteredShifts = r.data.Shifts;

				const regularShiftsFiltered = filteredShifts.filter(shift => {
					if (shift.Extra !== null) {
						return false;
					}
					if ("Multiday" in shift) {
						return false;
					}

					return convertAPIDateToDDMMYYYY(shift.StartTime) === this.state.startTime;
				});
				let multidayShiftsFiltered = filteredShifts.filter(shift => {
					return "Multiday" in shift;
				});

				let multidayShifts = [];
				multidayShiftsFiltered.forEach(shift => {
					// Possible more than one child shift occurs on the same day
					let childrenToShow = shift.Multiday.filter(child => {
						return convertAPIDateToDDMMYYYY(child.StartTime) === this.state.startTime;
					});
					multidayShifts.push(
						...childrenToShow.map(child => {
							return { ...child, UserId: shift.UserId };
						})
					);
				});

				const sortTimes = (a, b) => {
					const aAddress = this.props.clientsById[a.ClientId].AddressPostcode,
						bAddress = this.props.clientsById[b.ClientId].AddressPostcode;

					if (aAddress === bAddress) {
						return (
							parseAPIDate(a.StartTime).getTime() - parseAPIDate(b.StartTime).getTime()
						);
					}

					// return parseAPIDate(a.StartTime).getTime() - parseAPIDate(b.StartTime.getTime());
					return aAddress - bAddress;
				};

				regularShiftsFiltered.sort(sortTimes);
				multidayShifts.sort(sortTimes);

				this.setState({
					regularShifts: regularShiftsFiltered,
					multidayShifts,
					loading: false
				});
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	handleFilterChange = e => {
		this.setState(
			{
				[e.target.name]: e.target.value
			},
			this.getShifts
		);
	};

	componentDidMount() {
		this.getShifts();
	}

	shortTime(apiDate) {
		const d = parseAPIDate(apiDate);
		return DateFns.format(d, "h:mm A");
	}

	renderShifts(shifts) {
		return shifts.map((shift, key) => {
			const clientBrandId = this.props.clientsById[shift.ClientId].BrandId;

			let brandContent = null;
			if (clientBrandId in this.props.brandsById) {
				brandContent = (
					<React.Fragment>
						({this.props.brandsById[clientBrandId].BrandName})
					</React.Fragment>
				);
			} else {
				// Hardcoded to Perths Best Girls
				brandContent = (
					<React.Fragment>({this.props.brandsById[6].BrandName})</React.Fragment>
				);
			}

			return (
				<div key={key}>
					{this.shortTime(shift.StartTime)} - {this.shortTime(shift.EndTime)}{" "}
					<ClientNameAsText clientId={shift.ClientId} /> -{" "}
					<ProfileStageName clickable={false} userId={shift.UserId} /> {brandContent}
				</div>
			);
		});
	}

	render() {
		return (
			<div>
				<FormRow
					label="Generate list for day"
					value={
						<DateTimePicker
							name="startTime"
							value={this.state.startTime}
							onChange={this.handleFilterChange}
							dayPickerOnly={true}
						/>
					}
				/>

				<Spinner loading={this.state.loading}>
					<b>Shifts</b>
					{this.renderShifts(this.state.regularShifts)}

					{this.state.multidayShifts.length !== 0 && (
						<React.Fragment>
							<b>Multiday</b>
							{this.renderShifts(this.state.multidayShifts)}
						</React.Fragment>
					)}
				</Spinner>
			</div>
		);
	}
}

GenerateDailyLineup.propTypes = {};

const mapStateToProps = (state, props) => {
	return {
		brandsById: brandSelectorById(state),
		clientsById: clientSelectorById(state)
	};
};

GenerateDailyLineup = connect(mapStateToProps)(GenerateDailyLineup);
export default GenerateDailyLineup;
