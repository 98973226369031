import * as React from "react";
import AppRouter from "./router/router";
import { Provider } from "react-redux";
import store from "./store";
import ReduxToastr from "react-redux-toastr";
import "./components/toastr/toastr.css";
import "./styles/toaster-overrides.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/bootstrap-overrides.css";
import "jquery";
import "./styles/react-select-overrides.css";
import "./styles/media-query.css";
import "./styles/pagination-overrides.css";
import "./styles/react-modal-overrides.css";
import "./styles/input.css";
import "./styles/global.css";
import "react-virtualized/styles.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import { hidePreloadDiv } from "./preloader/showHidePreloader";

class App extends React.Component {
	componentDidMount() {
		hidePreloadDiv();
	}
	render() {
		return (
			<Provider store={store}>
				<React.Fragment>
					<ReduxToastr
						timeOut={5000}
						preventDuplicates
						transitionIn="fadeIn"
						transitionOut="fadeOut"
						position="top-right"
						
					/>

					<AppRouter />
				</React.Fragment>
			</Provider>
		);
	}
}

export default App;
