import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findUserById } from "../../poll/selectors";
import { findUserGroupById } from "../../config/selectors";

class UserPermissionGroup extends React.Component {
	render() {
		return <span>{this.props.userGroup.Description}</span>;
	}
}

UserPermissionGroup.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	const user = findUserById(state, props.userId);

	return {
		userGroup: findUserGroupById(state, user.UserGroupId)
	};
};

export default connect(mapStateToProps)(UserPermissionGroup);
