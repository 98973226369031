import * as React from "react";
import PropTypes from "prop-types";
import EditParentSeriesWizard from "../AdminEdit/EditParentSeriesWizard";
import { editMultidayParentSeriesPATCH } from "../../../api/apiEndpoints";
import DryRunSeries from "./DryRunSeries";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import Spinner from "../../../components/loading/Spinner";
import { seriesSelectorById } from "../../../poll/selectors";
import { connect } from "react-redux";
import { Save } from "../../../components/icons/Icons";
import { poll } from "../../../poll/actions";

class EditParentSeries extends React.Component {
	constructor(props) {
		super(props);

		const series = props.seriesById[props.shift.SeriesId];

		this.state = {
			formValues: {
				Notes: series.Notes,
				AdminPay: series.AdminPay,
				TravelPay: series.TravelPay,
				HrmApproval: series.HrmApproval
			},
			submittingDryRun: false,
			submittingUpdate: false,
			dryRunResults: [],
			showDryRun: false
		};
	}

	submitSeriesPatch = dryrun => {
		return editMultidayParentSeriesPATCH({
			DryRun: dryrun,
			Id: this.props.shift.SeriesId,
			ContractStart: this.props.shift.StartTime,
			...this.state.formValues
		});
	};

	submitDryRun = () => {
		this.setState({
			submittingDryRun: true,
			showDryRun: true
		});

		this.submitSeriesPatch(true).then(r => {
			this.setState({
				dryRunResults: r.data.Shifts,
				submittingDryRun: false
			});
		});
	};

	handleSubmitUpdate = () => {
		this.setState({
			submittingUpdate: true
		});

		this.submitSeriesPatch(false).then(() => {

			this.props.dispatch(poll({}));
			this.props.handleCloseModal();
		});
	};

	seriesFormValuesCallback = formValues => {
		if (formValues === "done") {
			this.setState({ submitting: true });

			this.submitDryRun();
			return;
		}

		this.setState({
			formValues: formValues
		});
	};

	render() {
		return (
			<div>
				<h4 className="heading">Edit Series</h4>
				<div className="row">
					{!this.state.showDryRun ? (
						<EditParentSeriesWizard
							showNotes={true}
							showTravelPay={true}
							showAdminFee={true}
							showHrmApproval={true}
							parentSeries={this.state.formValues}
							handleCloseModal={this.props.handleCloseModal}
							onChangeCallback={this.seriesFormValuesCallback}
						/>
					) : (
						<div>
							<DryRunSeries dryRunResults={this.state.dryRunResults} />
							<Spinner loading={this.state.submittingDryRun} />
							<AdminContainerMigrate
								align="right"
								buttons={[
									<button
										className="btn btn-primary"
										onClick={() => {
											this.setState({ displayDryRun: false });
										}}
									>
										<i className="fas fa-arrow-circle-left" /> Previous
									</button>,
									<LoadingButtonWithConfirm
										loading={this.state.submittingUpdate}
										onClick={this.handleSubmitUpdate}
										className="btn btn-danger"
									>
										<Save /> Update Series
									</LoadingButtonWithConfirm>
								]}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

EditParentSeries.propTypes = {
	handleCloseModal: PropTypes.func.isRequired,
	shift: PropTypes.object
};

const mapStateToProps = state => {
	return {
		seriesById: seriesSelectorById(state)
	};
};

export default connect(mapStateToProps)(EditParentSeries);
