import * as React from "react";
import PropTypes from "prop-types";
import DateFns from "date-fns";
import {
	convertDateToAPIDate,
	convertDDMMYYYToJSDate,
	parseAPIDate,
	validAPIDate
} from "../../lib/dateUtils/dateUtils";
import Select from "../../components/select/Select";
import { padLeft } from "../../lib/formatting/padding";
// import "./DateTimePicker.css";
import ValidationMessages from "../form/ValidationMessages";
import { Calendar, History, Trash } from "../icons/Icons";
import { css } from "emotion";

const monthNames = {
	0: "Jan",
	1: "Feb",
	2: "Mar",
	3: "Apr",
	4: "May",
	5: "Jun",
	6: "Jul",
	7: "Aug",
	8: "Sep",
	9: "Oct",
	10: "Nov",
	11: "Dec"
};

export default class DateTimePicker extends React.Component {
	leftButtonClass = css`
		font-size: 16px;
		padding: 8px 8px;
		border-top: 1px solid lightgrey !important;
		border-left: 1px solid lightgrey !important;
		border-bottom: 2px solid lightgrey !important;
		background: whitesmoke !important;
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;
		&:hover {
			border-bottom-color: #1890ff !important;
			color: #1890ff; !important;
		}
	`;

	middleCssClass = css`
		border-right: 0px !important;
		border-left: 0px !important;
		border-radius: 0 !important;
		&:hover {
			border-bottom-color: #1890ff !important;
			color: #1890ff; !important;
		}
	`;

	endCssClass = css`
		border-left: 0px !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		&:hover {
			border-bottom-color: #1890ff !important;
			color: #1890ff; !important;
		}
	`;

	constructor(props) {
		super(props);
		this.state = {
			localDateTime: undefined,
			validationMessages: {}
		};
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	onLoad(props) {
		if (props.value === undefined || props.value === null) {
			this.setState({ localDateTime: undefined });
			return;
		}

		if (props.dayPickerOnly) {
			// handle api formatted date
			if (validAPIDate(props.value)) {
				this.setState({ localDateTime: parseAPIDate(props.value) });
				return;
			}

			// handle inputs of DD/MM/YYYY
			this.setState({ localDateTime: convertDDMMYYYToJSDate(props.value) });
			return;
		}

		this.setState({
			localDateTime: parseAPIDate(this.roundTimeToNearestQuarter(props.value))
		});
	}

	roundTimeToNearestQuarter = value => {
		if (this.props.showQuartlyClockOnly) {
			const jsDate = parseAPIDate(value);
			const newMinute = Math.round(jsDate.getMinutes() / 15) * 15;

			let newTS = new Date(
				jsDate.getFullYear(),
				jsDate.getMonth(),
				jsDate.getDate(),
				jsDate.getHours(),
				newMinute,
				0,
				0
			);

			return convertDateToAPIDate(newTS);
		}
		return value;
	};

	handleDateChange = event => {
		this.parseAndUpdateLocalDateTime({ [event.target.name]: event.target.value });
	};

	handleHourChange = event => {
		// If the 'AMPM' is set to 'PM' set the time to 12 hours after the selected value to make the currently selected date in the afternoon.
		// When 'AMPM' is set to 'PM' and hour '12' is selected adding 12 hours would make the date occur on the following day.
		if (this.state.localDateTime.getHours() > 12 && event.target.value !== 12) {
			this.parseAndUpdateLocalDateTime({ Hour: event.target.value + 12 });
			return;
		}
		this.parseAndUpdateLocalDateTime({ Hour: event.target.value });
	};

	handleMinuteChange = event => {
		this.parseAndUpdateLocalDateTime({ Minute: event.target.value });
	};

	handleAMPMClick = event => {
		event.preventDefault();
		const currentHour = this.state.localDateTime.getHours();

		if (currentHour >= 12) {
			this.parseAndUpdateLocalDateTime({ Hour: currentHour - 12 });
		} else if (currentHour < 12) {
			this.parseAndUpdateLocalDateTime({ Hour: currentHour + 12 });
		}
	};

	handleMakeDateNull = () => {
		this.props.onChange({
			target: {
				name: this.props.name,
				value: null
			}
		});
	};

	handleCreateDate = () => {
		this.props.onChange({
			target: {
				name: this.props.name,
				value: convertDateToAPIDate(new Date())
			}
		});
	};

	parseAndUpdateLocalDateTime = ({ Year, Month, Day, Hour, Minute }) => {
		const newDates = {
			Year: Year !== undefined ? Year : this.state.localDateTime.getFullYear(),
			Month: Month !== undefined ? Month : this.state.localDateTime.getMonth(),
			Day: Day !== undefined ? Day : this.state.localDateTime.getDate(),
			Hour:
				Hour !== undefined
					? Hour
					: this.state.localDateTime === undefined
						? undefined
						: this.state.localDateTime.getHours(),
			Minute:
				Minute !== undefined
					? Minute
					: this.state.localDateTime === undefined
						? undefined
						: this.state.localDateTime.getMinutes()
		};

		// let h = Hour !== undefined
		let newTS = new Date(
			newDates.Year,
			newDates.Month,
			newDates.Day,
			newDates.Hour,
			newDates.Minute,
			0,
			0
		);

		let newDate = convertDateToAPIDate(newTS);

		if (this.props.dayPickerOnly) {
			if (!this.props.formatAsApiDate) {
				newDate =
					padLeft(String(newDates.Day), "00") +
					"/" +
					padLeft(String(newDates.Month + 1), "00") +
					"/" +
					String(newDates.Year);
			}
		}

		this.props.onChange({
			target: {
				name: this.props.name,
				value: newDate
			}
		});
	};

	render() {
		if (this.state.localDateTime === undefined) {
			return (
				<button className="btn btn-default" onClick={this.handleCreateDate}>
					<i className="fas fa-calendar-alt" /> Set A Date
				</button>
			);
		}

		// let dayPickerValue = "";
		let hourValue = null;
		let minuteValue = null;
		let yearValue,
			monthValue,
			dayValue = null;
		let ampmValue = "AM";
		if (this.state.localDateTime !== undefined) {
			yearValue = Number(DateFns.format(this.state.localDateTime, "YYYY"));
			monthValue = Number(DateFns.format(this.state.localDateTime, "MM")) - 1;
			dayValue = Number(DateFns.format(this.state.localDateTime, "DD"));
			hourValue = Number(DateFns.format(this.state.localDateTime, "h"));
			minuteValue = Number(DateFns.format(this.state.localDateTime, "m"));
			ampmValue = DateFns.format(this.state.localDateTime, "A");
		}

		// Generate Hours
		let hours = [];
		for (let h = 1; h <= 12; h++) {
			hours.push({
				label: padLeft(String(h), "00"),
				value: h
			});
		}

		// Generate Minutes
		let minutes = [];
		if (this.props.showQuartlyClockOnly) {
			minutes = [
				{
					label: "00",
					value: 0
				},
				{
					label: "15",
					value: 15
				},
				{
					label: "30",
					value: 30
				},
				{
					label: "45",
					value: 45
				}
			];
		} else {
			for (let m = 0; m <= 59; m++) {
				minutes.push({
					label: padLeft(String(m), "00"),
					value: m
				});
			}
		}

		// Generate days
		let days = [];
		if (yearValue !== null) {
			const daysInSelectedMonth = new Date(yearValue, monthValue + 1, 0).getDate();
			for (let h = 1; h <= daysInSelectedMonth; h++) {
				days.push({
					label:
						padLeft(String(h), "00") +
						" &nbsp;- &nbsp;" +
						new Date(yearValue, monthValue, h).toLocaleDateString("en-US", {
							weekday: "short"
						}),
					value: h
				});
			}
		}

		// Generate months
		let months = [];
		if (months !== null) {
			for (let m = 0; m <= 11; m++) {
				const monthName = monthNames[m];
				months.push({
					// label: padLeft(String(m), "00"),
					label: monthName,
					value: m // new Date(...., Month) starts at 0 instead of 1
				});
			}
		}

		// Generate years
		let years = [];
		if (years !== null) {
			for (let m = 1950; m <= 2050; m++) {
				years.push({
					label: String(m),
					value: m
				});
			}
		}

		const resetButton = (
			<button className="btn btn-warning" onClick={this.handleMakeDateNull}>
				<Trash /> Unset
			</button>
		);

		return (
			<React.Fragment>
				<div
					className={css`
						display: flex;
						flex-wrap: wrap;
					`}
				>
					{/*Date*/}
					<div >
						{this.props.disableDayPicker ? (
							<div className="DayPickerDisabled" style={{ float: "left" }}>
								{dayValue}/{monthValue}/{yearValue}
							</div>
						) : (
							<span style={{ display: "flex" }}>
								<button
									title="Today"
									className={this.leftButtonClass}
									onClick={() => {
										const now = new Date();
										this.parseAndUpdateLocalDateTime({
											Year: now.getFullYear(),
											Month: now.getMonth(),
											Day: now.getDate()
										});
									}}
								>
									<Calendar />
								</button>
								<span>
									<Select
										name={"Day"}
										clearable={false}
										className={this.middleCssClass}
										options={days}
										value={dayValue}
										onChange={this.handleDateChange}
									/>
								</span>

								<span>
									<Select
										name={"Month"}
										clearable={false}
										className={this.middleCssClass}
										options={months}
										value={monthValue}
										onChange={this.handleDateChange}
									/>
								</span>

								<span>
									<Select
										name={"Year"}
										clearable={false}
										className={this.endCssClass}
										options={years}
										value={yearValue}
										onChange={this.handleDateChange}
									/>
								</span>
								{/*Remove Date (set to null)*/}
								{this.props.nullable && this.props.dayPickerOnly && resetButton}
							</span>
						)}
					</div>

					{!this.props.dayPickerOnly && (
						<span style={{ display: "flex" }}>
							<button
								title="Set clock to the current time"
								className={this.leftButtonClass}
								onClick={() => {
									const now = new Date();
									this.parseAndUpdateLocalDateTime({
										Hour: now.getHours(),
										Minute: now.getMinutes()
									});
								}}
							>
								<History />
							</button>

							{/*Hours */}
							<span>
								<Select
									clearable={false}
									className={this.middleCssClass}
									options={hours}
									value={hourValue}
									onChange={this.handleHourChange}
								/>
							</span>

							{/*Minutes*/}
							<span>
								<Select
									clearable={false}
									className={this.endCssClass}
									options={minutes}
									value={minuteValue}
									onChange={this.handleMinuteChange}
								/>
							</span>

							{/*AMPM */}
							<button className="btn btn-primary" onClick={this.handleAMPMClick}>
								{ampmValue}
							</button>

							{/*Remove Date (set to null)*/}
							{this.props.nullable && resetButton}
						</span>
					)}

					{/*<br/>*/}
					{/*{this.state.localDateTime && this.state.localDateTime.toString()}*/}
				</div>
				<div style={{ clear: "both" }}>
					<ValidationMessages messages={this.state.validationMessages["date"]} />
				</div>

				<ValidationMessages messages={this.props.validationMessages} />
			</React.Fragment>
		);
	}
}

DateTimePicker.defaultProps = {
	disableDayPicker: false,
	dayPickerOnly: false,
	showQuartlyClockOnly: true,
	nullable: false
};

DateTimePicker.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string, // expecting an API formatted date
	onChange: PropTypes.func,
	disableDayPicker: PropTypes.bool,
	dayPickerOnly: PropTypes.bool,
	validationMessages: PropTypes.arrayOf(PropTypes.string),
	showQuartlyClockOnly: PropTypes.bool,
	nullable: PropTypes.bool,
	formatAsApiDate: PropTypes.bool
};
