import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clientShiftFeedbackTypesAsArray } from "../../config/selectors";
import Select from "./Select";

class ReappearanceList extends React.Component {
	render() {
		const options = [
			{ label: "Favourite (as often as every 15 days)", value: 1 },
			{ label: "Pre Approved (as often as every 50 days)", value: 2 },
			{
				label: "Not Preapproved (ok to be booked when nobody else is available)",
				value: 4
			},
			{ label: "May Not Return (never)", value: 3 }
		];

		return (
			<Select
				disabled={this.props.disabled}
				options={options}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
				defaultOptionValue={0}
			/>
		);
	}
}

ReappearanceList.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool
};

ReappearanceList.defaultProps = {
	disabled: false
};

const mapStateToProps = state => {
	return {
		clientShiftFeedbackTypesAsArray: clientShiftFeedbackTypesAsArray(state)
	};
};

ReappearanceList = connect(mapStateToProps)(ReappearanceList);
export default ReappearanceList;
