import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectClientUserLists } from "../../poll/selectors";
import ProfileStageName from "../../components/links/ProfileStageName";
import ClientName from "../../components/links/ClientName";
import { fromObjectKeysToArray } from "../../lib/arrayObj/arrayObj";
import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import StandardModalWrapper from "../modal/StandardModalWrapper";
import ProtectedComponent from "../permissions/ProtectedComponent";
import EditClientUserList from "./EditClientUserList";
import { sortStageNameByUserId } from "../../lib/sort/sortByUserId";
import { sortClientNamesById } from "../../lib/sort/sortByClientId";
import { clientIsDeleted, userIsDeleted } from "../../lib/filter/filterDeleted";

export const ClientUserListTypes = {
	Hrms: "Hrms",
	Favourites: "Favourites",
	Mnrs: "Mnrs",
	Preapprovals: "Preapprovals",
	SeriesPrepprovals: "SeriesPrepprovals"
};

class ClientUserList extends React.Component {
	friendlyNames = {
		[ClientUserListTypes.Hrms]: "Hotel Relationship Manager",
		[ClientUserListTypes.Favourites]: "Favourites",
		[ClientUserListTypes.Mnrs]: "May Not Return",
		[ClientUserListTypes.Preapprovals]: "Pre Approved",
		[ClientUserListTypes.SeriesPrepprovals]: "Series Preapprovals"
	};

	render() {
		let list = {};
		if (this.props.byClientId !== -1) {
			list = this.props.clientUserLists.byClientId[this.props.listName][
				this.props.byClientId
			];
		} else if (this.props.byUserId !== -1) {
			list = this.props.clientUserLists.byUserId[this.props.listName][
				this.props.byUserId
			];
		} else {
			throw "byClientID & byUserId not valid";
		}

		let displayedItems = fromObjectKeysToArray(list);

		if (this.props.byClientId !== -1) {
			displayedItems.sort((a, b) => {
				return sortStageNameByUserId(a.UserId, b.UserId);
			});
			displayedItems = displayedItems.filter(clUserRel => {
				return !userIsDeleted(clUserRel.UserId);
			});
		}
		if (this.props.byUserId !== -1) {
			displayedItems.sort((a, b) => {
				return sortClientNamesById(a.ClientId, b.ClientId);
			});

			displayedItems = displayedItems.filter(clUserRel => {
				return !clientIsDeleted(clUserRel.ClientId);
			});
		}

		return (
			<div>
				<StandardModalWrapper
					modalOpener={({ handleOpenModal }) => {
						const opener = (
							<span className="heading-edit" onClick={handleOpenModal}>
								Edit
							</span>
						);

						if (this.props.listName === ClientUserListTypes.Hrms) {
							return (
								<ProtectedComponent allowedGroups={[userGroups.admin]}>
									{opener}
								</ProtectedComponent>
							);
						}

						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								{opener}
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<EditClientUserList
								listName={this.props.listName}
								byClientId={this.props.byClientId}
								byUserId={this.props.byUserId}
							/>
						);
					}}
				/>
				<h4 className="heading">{this.friendlyNames[this.props.listName]}</h4>
				<div>
					{this.props.byClientId !== -1 &&
						displayedItems.map((userList, idx) => {
							return (
								<div key={idx}>
									<ProfileStageName
										clickable={userInfo().userGroupId !== userGroups.client}
										userId={userList.UserId}
									/>
								</div>
							);
						})}

					{this.props.byUserId !== -1 &&
						displayedItems.map((userList, idx) => {
							return (
								<div key={idx}>
									<ClientName clientId={userList.ClientId} />
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}

ClientUserList.defaultProps = {
	byUserId: -1,
	byClientId: -1
};

ClientUserList.propTypes = {
	listName: PropTypes.string.isRequired,
	byUserId: PropTypes.number,
	byClientId: PropTypes.number
};

const mapStateToProps = state => {
	return {
		clientUserLists: selectClientUserLists(state)
	};
};

export default connect(mapStateToProps)(ClientUserList);
