import store from "../../store";
import {
	clientTermsAndConditionsSelector,
	termsAndConditionsSelector,
	userSelectorById
} from "../../poll/selectors";
import { userInfo } from "../../lib/userInfo/userInfo";
import { parseAPIDate } from "../../lib/dateUtils/dateUtils";
import { objectIsEmpty } from "../../lib/object/objectIsEmpty";
import { loggedInUserIsClient } from "../permissions/validateUserPermissions";

export function userHasReadTermsAndConditions(userId) {
	const usersById = userSelectorById(store.getState());

	if (objectIsEmpty(usersById)) {
		return true;
	}

	if (usersById[userId] === undefined) {
		return true;
	}

	if (usersById[userInfo().userId].EulaRead === null) {
		return false;
	}

	const userLastReadAt = parseAPIDate(usersById[userInfo().userId].EulaRead);

	let termsAndConditions = undefined;

	if (loggedInUserIsClient()) {
		termsAndConditions = clientTermsAndConditionsSelector(store.getState());
	} else {
		termsAndConditions = termsAndConditionsSelector(store.getState());
	}

	const tcLastUpdated = parseAPIDate(termsAndConditions.ua);

	if (userLastReadAt.getTime() >= tcLastUpdated.getTime()) {
		return true;
	} else {
		return false;
	}
}
