import * as React from "react";
import { userInfo } from "../lib/userInfo/userInfo";
import Venue from "../Venue/Venue";

class ClientLineup extends React.Component {
	render() {
		return <Venue venueId={userInfo().clientId} />;
	}
}

export default ClientLineup;
