import * as React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "normalize.css";
import "./styles/global/fonts";
import "./styles/global/focus";
import "./state/auth/authState";
import "./styles/global/mini-container";

ReactDOM.render(<App />, document.getElementById("root"));
