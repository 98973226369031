import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { profileSelectorById } from "../../poll/selectors";
import FormManager from "../../components/form/FormManager";
import { profilesPATCH } from "../../api/apiEndpoints";
import ToggleSlider from "../../components/Toggle/ToggleSlider";
import StyledButton, { EButtonBackgroundColors } from "../../styled/Button/StyledButton";

class ProfileAdminTools extends React.Component {
	render() {
		if (this.props.profile === undefined) {
			return null;
		}

		return (
			<div>
				<h4 className="heading">Profile Tools</h4>
				<div>
					<FormManager
						initialFormValues={{
							KFWHidden: this.props.profile.KFWHidden
						}}
						handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
							profilesPATCH({
								Id: this.props.profileId,
								KFWHidden: !formValues.KFWHidden
							})
								.then(() => {
									handleSubmitSuccess();
								})
								.catch(() => {
									handleSubmitFail();
								});
						}}
					>
						{/*FormManager children*/}
						{({
							formValues,
							handleOnChange,
							handleSubmit,
							formValidation,
							submitEnabled,
							submitting
						}) => {
							return (
								<StyledButton onClick={handleSubmit} disabled={submitting} backgroundColor={EButtonBackgroundColors.blue}>
									<ToggleSlider value={formValues.KFWHidden} /> Hide profile from KFW
									lists
								</StyledButton>
							)
						}}
					</FormManager>
				</div>
			</div>
		);
	}
}

ProfileAdminTools.defaultProps = {
	profileId: null
};

ProfileAdminTools.propTypes = {
	profileId: PropTypes.number
};

const mapStateToProps = (state, props) => {
	const profile = profileSelectorById(state)[props.profileId];
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile
	};
};

ProfileAdminTools = connect(mapStateToProps)(ProfileAdminTools);
export default ProfileAdminTools;
