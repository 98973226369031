import * as React from "react";
import PropTypes from "prop-types";
import { userGroups, userInfo } from "../../lib/userInfo/userInfo";
import { uploadDELETE, uploadSections } from "../../api/apiEndpoints";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import UploadRenderer from "../../components/uploads/UploadRenderer";
import AdminContainerMigrate from "../../components/container/AdminContainerMigrate";
import FormManager from "../../components/form/FormManager";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import UploadFileModal from "../../components/uploads/UploadFileModal";
import Gallery from "./Gallery";
import UploadCroppedImg from "../../components/uploads/UploadCroppedImg";
import { Trash } from "../../components/icons/Icons";

class PictureEditContainer extends React.PureComponent {
	state = {
		uploads: []
	};

	static propTypes = {
		uploads: PropTypes.array,
		heading: PropTypes.any,
		uploadText: PropTypes.string,
		colWidth: PropTypes.number,
		onSubmitSuccessFn: PropTypes.func.isRequired,
		uploadSectionType: PropTypes.string,
		uploadSectionId: PropTypes.number,
		userId: PropTypes.number
	};

	// https://codesandbox.io/s/vmvjl2q023
	render() {
		let uploads = this.props.uploads.map((upload, uploadIdx) => {
			return (
				<StandardModalWrapper
					key={uploadIdx}
					modalOpener={({ handleOpenModal }) => {
						return (
							<UploadRenderer
								src={upload.Path}
								onClickFn={handleOpenModal}
								showThumbnail={false}
							/>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<React.Fragment>
								<UploadRenderer src={upload.Path} showThumbnail={false} />

								{/*Delete Button*/}
								<ProtectedComponent
									allowedGroups={[userGroups.admin]}
									allowedIfLoggedInAsUserId={this.props.userId}
								>
									<FormManager
										handleSubmit={({
											formValues,
											handleSubmitFail,
											handleSubmitSuccess
										}) => {
											uploadDELETE({
												Id: upload.Id
											})
												.then(() => {
													handleSubmitSuccess();
													this.props.onSubmitSuccessFn();
													handleCloseModal();
												})
												.catch(() => {
													handleSubmitFail();
												});
										}}
									>
										{/*FormManager children*/}
										{({
											formValues,
											handleOnChange,
											handleSubmit,
											formValidation,
											submitEnabled,
											submitting
										}) => {
											// RSA's can't be deleted accidentally
											if (this.props.uploadSectionType === uploadSections.profile_rsa) {
												return null;
											}

											return (
												<AdminContainerMigrate
													align="right"
													buttons={[
														<LoadingButtonWithConfirm
															className="btn btn-danger"
															loading={submitting}
															disabled={submitting}
															onClick={handleSubmit}
														>
															<Trash /> Delete
														</LoadingButtonWithConfirm>
													]}
												/>
											);
										}}
									</FormManager>
								</ProtectedComponent>
							</React.Fragment>
						);
					}}
				/>
			);
		});

		let imageOrGallery = null;
		switch (this.props.uploadSectionType) {
			case uploadSections.profile_main_profile_picture:
			case uploadSections.profile_proof:
			case uploadSections.profile_rsa:
				imageOrGallery = uploads;
				break;
			default:
				imageOrGallery = <Gallery uploads={uploads} />;
		}

		return (
			<div className={"col-sm-" + String(this.props.colWidth)}>
				<h4 className="heading">{this.props.heading}</h4>
				<ProtectedComponent
					allowedGroups={[userGroups.admin]}
					allowedIfLoggedInAsUserId={this.props.userId}
				>
					<StandardModalWrapper
						heading={this.props.uploadText}
						modalOpener={({ handleOpenModal }) => {
							return (
								<button className="btn btn-primary" onClick={handleOpenModal}>
									<i className="fas fa-cloud-upload" /> {this.props.uploadText}
								</button>
							);
						}}
						render={({ handleCloseModal }) => {
							const handleCloseSubmit = () => {
								this.props.onSubmitSuccessFn();
								handleCloseModal();
							};

							if (
								this.props.uploadSectionType ===
								uploadSections.profile_main_profile_picture
							) {
								return (
									<UploadCroppedImg
										uploadSectionType={this.props.uploadSectionType}
										uploadSectionId={this.props.uploadSectionId}
										onSubmitSuccessFn={handleCloseSubmit}
									/>
								);
							} else {
								return (
									<UploadFileModal
										uploadSectionType={this.props.uploadSectionType}
										uploadSectionId={this.props.uploadSectionId}
										onSubmitSuccessFn={handleCloseSubmit}
									/>
								);
							}
						}}
					/>
				</ProtectedComponent>
				<div style={{ paddingTop: 5 }}>
					{uploads.length === 0 && (
						<div style={{ padding: 7, border: "2px dashed #AAA", color: "#666" }}>
							There are no images in this album
						</div>
					)}
					{imageOrGallery}
				</div>
			</div>
		);
	}
}

export default PictureEditContainer;
