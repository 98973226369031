import * as React from "react";
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	List,
	WindowScroller
} from "react-virtualized";

interface IInfiniteScroll {
	rows: any[];
	rowComparison?: (comparison: { oldRow: any; newRow: any }) => boolean;
	rowCount: number;
	rowRender: (
		a: {
			style: any;
			index: number;
			row: { [key: string]: any };
			redrawRow: (id: number) => void;
		}
	) => void;
	listRef?: any;
	overscanRowCount?: number;
}

class InfiniteScroll extends React.Component<IInfiniteScroll> {
	public lastVisibleKey: number = -1;
	public _list: any = null;
	public _cache: any;

	public constructor(props: IInfiniteScroll) {
		super(props);
		this._cache = new CellMeasurerCache({
			fixedWidth: true
		});
	}

	static defaultProps = {
		overscanRowCount: 0
	};

	redrawRow = (id: number) => {
		this.props.rows.some(
			(obj, index, _): boolean => {
				if (obj.Id === id) {
					this._cache.clear(index);
					this._list.recomputeRowHeights(index);
					return true;
				}
				return false;
			}
		);
	};

	resetCache = (
		nextProps: IInfiniteScroll,
		prevProps: IInfiniteScroll | boolean,
		resetAll: boolean = false
	) => {
		// when prevProps doesn't exist it's "true"
		if (resetAll || nextProps.rows.length === 0 || prevProps === true) {
			this._cache.clearAll();
			if (this._list) {
				this._list.recomputeRowHeights();
			}
			return;
		}

		let rowComparisonFn: (
			rowComparison: { oldRow: any; newRow: any }
		) => boolean = ({ oldRow, newRow }) => {
			return JSON.stringify(oldRow) === JSON.stringify(newRow);
		};

		if (this.props.rowComparison) {
			rowComparisonFn = this.props.rowComparison;
		}

		// clear any invalid rows
		for (let idx = 0; idx < nextProps.rows.length; idx++) {
			// clear after the first wrong key or
			// clear after the last visible key
			if (this.lastVisibleKey <= idx) {
				this._cache.clear(idx);
				continue;
			}

			// if the previous set of rows contained more entries than the current one, clear out those records
			if (prevProps && idx >= prevProps.rows.length) {
				this._cache.clear(idx);
				continue;
			}

			// clear out rows which are no longer valid
			if (
				prevProps &&
				!rowComparisonFn({
					oldRow: prevProps.rows[idx],
					newRow: nextProps.rows[idx]
				})
			) {
				this._cache.clear(idx); // clear the current bad
				continue;
			}
		}

		if (this._list) {
			// suggested workaround from: https://github.com/bvaughn/react-virtualized/issues/995
			this._list.scrollToRow(this.lastVisibleKey);
			// https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#forceupdategrid
			// forceably update view
			this._list.forceUpdateGrid();
		}
	};

	handleOnResize = () => {
		if (this._list) {
			this._cache.clearAll();
			this._list.recomputeRowHeights(0);
		}
		window.scrollTo(0, 0);
	};

	componentDidUpdate(prevProps: IInfiniteScroll) {
		this.resetCache(this.props, prevProps);
	}

	rowRender = ({
		index, // Index of row
		// isScrolling, // The List is currently being scrolled
		// isVisible, // This row is visible within the List (eg it is not an overscanned row)
		key, // Unique key within array of rendered rows
		parent, // Reference to the parent List (instance)
		style // Style object to be applied to row (to position it);
	}: // This must be passed through to the rendered row element.
	any) => {
		this.lastVisibleKey = index;

		let keyToUse = key;
		if ("Id" in this.props.rows[index]) {
			keyToUse = this.props.rows[index].Id;
		}
		return (
			<CellMeasurer
				parent={parent}
				key={keyToUse}
				rowIndex={index}
				columnIndex={0}
				cache={this._cache}
			>
				{() => {
					return this.props.rowRender({
						style,
						index,
						row: this.props.rows[index],
						redrawRow: this.redrawRow
					});
				}}
			</CellMeasurer>
		);
	};

	// * deleting rows
	// * adding new rows
	// * resizing
	render() {
		return (
			<AutoSizer onResize={this.handleOnResize} disableHeight>
				{({ width }) => {
					return (
						<WindowScroller>
							{({ height, isScrolling, onChildScroll, scrollTop }) => {
								return (
									<List
										autoHeight={true}
										deferredMeasurementCache={this._cache}
										ref={ref => {
											this._list = ref;
											if (this.props.listRef) {
												this.props.listRef(ref);
											}
										}}
										isScrolling={isScrolling}
										overscanRowCount={this.props.overscanRowCount}
										height={height}
										rowCount={this.props.rowCount}
										rowHeight={this._cache.rowHeight}
										rowRenderer={this.rowRender}
										scrollTop={scrollTop}
										onScroll={onChildScroll}
										width={width}
									/>
								);
							}}
						</WindowScroller>
					);
				}}
			</AutoSizer>
		);
	}
}

export default InfiniteScroll;
