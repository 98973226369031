import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { usersPATCH } from "../api/apiEndpoints";
import FormManager from "../components/form/FormManager";
import FormRow from "../components/form/FormRow";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import InputPassword from "../components/input/InputPassword";
import LoadingButton from "../components/button/LoadingButton";

class ChangePassword extends React.Component {
	render() {
		return (
			<FormManager
				initialFormValues={{
					Password: "",
					ConfirmPassword: ""
				}}
				handleValidation={v => {
					v.password({ fieldName: "Password" });
					v.confirmPassword({
						fieldName: "ConfirmPassword",
						passwordFieldName: "Password"
					});
					v.required({
						fieldNames: ["Password", "ConfirmPassword"]
					});
				}}
				handleSubmit={({ formValues, handleSubmitFail, handleSubmitSuccess }) => {
					usersPATCH({
						Id: this.props.userId,
						Password: formValues.Password
					})
						.then(() => {
							handleSubmitSuccess();
							this.props.handleSaveSuccess();
						})
						.catch(() => {
							handleSubmitFail();
						});
				}}
			>
				{/*FormManager children*/}
				{({
					formValues,
					handleOnChange,
					handleSubmit,
					formValidation,
					submitEnabled,
					submitting
				}) => {
					return (
						<div>
							<FormRow
								label={"Password"}
								value={
									<InputPassword
										name={"Password"}
										value={formValues.Password}
										onChange={handleOnChange}
										validationMessages={formValidation.Password}
									/>
								}
							/>
							<FormRow
								label={"Confirm Password"}
								value={
									<InputPassword
										name={"ConfirmPassword"}
										value={formValues.ConfirmPassword}
										onChange={handleOnChange}
										validationMessages={formValidation.ConfirmPassword}
									/>
								}
							/>

							<AdminContainerMigrate
								align={"right"}
								buttons={[
									<LoadingButton
										onClick={handleSubmit}
										loading={submitting}
										disabled={!submitEnabled}
									>
										<i className="fas fa-key" /> Update Password
									</LoadingButton>
								]}
							/>
						</div>
					);
				}}
			</FormManager>
		);
	}
}

ChangePassword.propTypes = {
	userId: PropTypes.number.isRequired,
	handleSaveSuccess: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
	return {};
};

ChangePassword = connect(mapStateToProps)(ChangePassword);
export default ChangePassword;
