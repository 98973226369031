import React from "react";
// @ts-ignore
import {connect} from "react-redux";
import {adminKFW2state, KFWCategoryState} from "./AdminKFW2state";
import {observer} from "mobx-react";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import SendNotifications from "../SendNotifications";
import LoadingButtonWithConfirm from "../../components/button/LoadingButtonWithConfirm";
import SearchBarItem from "../../components/searchBar/SearchBarItem";
import DateTimePicker from "../../components/datetime/DateTimePicker";
import MiniContainer from "../../components/container/MiniContainer";
import InputCheckbox from "../../components/input/InputCheckbox";
import AdminKFWRow from "../AdminKFWRow";
import {css} from "emotion";
import Spinner from "../../components/loading/Spinner";
import {viewState} from "../../layouts/viewState";


class AdminKFW2 extends React.Component {

	constructor(props: any) {
		super(props);

		adminKFW2state.getKFWList();
	}

	render() {

		const usersToMessage = adminKFW2state.getAllUsersCheckedAsArray();

		return (
			<div>
				<div className="admin-box">
					<StandardModalWrapper
						heading={"Send Notifications"}
						onCloseFn={() => {
							// Clear all checkboxes so the user cant accidently apply other actions once done
							adminKFW2state.clearAllCheckedUsers();
						}}
						//@ts-ignore
						modalOpener={({handleOpenModal}) => {
							return (
								<button
									disabled={adminKFW2state.loadingKFWList}
									className="admin-box-button btn btn-primary"
									onClick={handleOpenModal}
								>
									<i className="fas fa-envelope"/> Send Notifications
								</button>
							);
						}}
						//@ts-ignore
						render={({handleCloseModal}) => {
							const usersCheckedAsArray = adminKFW2state.getAllUsersCheckedAsArray();
							return (
								<SendNotifications
									handleOnSendSuccess={handleCloseModal}
									initialUserIdsSelected={usersCheckedAsArray}
								/>
							);
						}}
					/>
					<LoadingButtonWithConfirm
						disabled={adminKFW2state.loadingKFWList || usersToMessage.length === 0}
						loading={adminKFW2state.loadingKFWList}
						confirmMessage={"Clicking confirm will mark the selected users as KFW"}
						className="admin-box-button btn btn-primary"
						confirmAnchor={"bottom-left"}
						onClick={adminKFW2state.addKFWers}
					>
						<i className="fal fa-calendar-check"/> Add KFW
					</LoadingButtonWithConfirm>

					<LoadingButtonWithConfirm
						disabled={adminKFW2state.loadingKFWList || usersToMessage.length === 0}
						loading={adminKFW2state.loadingKFWList}
						confirmMessage={"Clicking confirm will remove KFW for the selected users"}
						className="admin-box-button btn btn-primary"
						confirmAnchor={"bottom-left"}
						onClick={adminKFW2state.removeKFWers}
					>
						<i className="fal fa-calendar-times"/> Remove KFW
					</LoadingButtonWithConfirm>
				</div>

				{this.renderSearch()}
				{this.renderTable(adminKFW2state.keenForWorkUsers)}
				{this.renderTable(adminKFW2state.usersWithNoShifts)}
				{this.renderTable(adminKFW2state.usersWithShifts)}

				<Spinner loading={adminKFW2state.loadingKFWList}/>
			</div>);
	}

	renderSearch() {
		return (
			<div>
				<div className="mini-container">
					<h4 className="heading">Search</h4>
					<SearchBarItem
						label={"Date"}
						value={
							<DateTimePicker
								dayPickerOnly={true}
								onChange={(e: any) => {
									adminKFW2state.updateKFWQueryDate(e.target.value);
								}}
								value={adminKFW2state.kfwQueryDate}
							/>
						}
					/>
					<SearchBarItem label={"Filters"} value={
						<div>
							<InputCheckbox
								onChange={adminKFW2state.toggleShowInactiveUsers}
								value={adminKFW2state.showInactiveUsers}
								name={""}/>
							<span className={css`padding: 5px `}>Show Inactive Users</span>
						</div>
					}/>
					<SearchBarItem
						label={"Categories"}
						value={
							<React.Fragment>
								<div>
									<InputCheckbox
										onChange={adminKFW2state.keenForWorkUsers.toggleShowCategory}
										value={adminKFW2state.keenForWorkUsers.showCategory}
										name={""}/>
									<span className={css`padding: 5px `}>Show keen for work users</span>
								</div>

								<div>
									<InputCheckbox
										onChange={adminKFW2state.usersWithShifts.toggleShowCategory}
										value={adminKFW2state.usersWithShifts.showCategory}
										name={""}/>
									<span className={css`padding: 5px `}>Show users with shifts</span>
								</div>

								<div>
									<InputCheckbox
										onChange={adminKFW2state.usersWithNoShifts.toggleShowCategory}
										value={adminKFW2state.usersWithNoShifts.showCategory}
										name={""}/>
									<span className={css`padding: 5px `}>Show users with no shifts</span>
								</div>
							</React.Fragment>
						}
					/>
				</div>
			</div>
		);


	}

	renderTable(categoryState: KFWCategoryState) {
		const colSpacing = [1, 2, 2, 2, 2, 3];

		if (!categoryState.showCategory) {
			return null;
		}
		return (
			<React.Fragment>
				<h4 className="kfwHeadingCategory">{categoryState.categoryHeading}</h4>
				<MiniContainer>
					{viewState.showMobileView ?

						<div className="row">
							<div className={"col-sm-" + colSpacing[0]}>
								<b>
									<InputCheckbox
										name={""}
										value={categoryState.categoryChecked}
										onChange={categoryState.toggleSelectAllUsersForCategory}
									/> <span style={{paddingLeft: 5}}>Select All users in category</span>
								</b>
							</div>
						</div> :
						<div className="row">
							<div className={"col-sm-" + colSpacing[0]}>
								<b>
									<InputCheckbox
										name={""}
										value={categoryState.categoryChecked}
										onChange={categoryState.toggleSelectAllUsersForCategory}
									/>
								</b>
							</div>
							<div className={"col-sm-" + colSpacing[1]}>
								<b>Skimpy</b>
							</div>
							<div className={"col-sm-" + colSpacing[2]}>
								<b>Number</b>
							</div>
							<div className={"col-sm-" + colSpacing[3]}>
								<b>Facebook</b>
							</div>
							<div className={"col-sm-" + colSpacing[4]}>
								<b>Notes</b>
							</div>
							<div className={"col-sm-" + colSpacing[5]}>
								<b>Shifts</b>
							</div>
						</div>
					}


					{categoryState.rows.map((row) => {
						return (
							<div key={row.Id} className="row div-table-responsive-row">
								<AdminKFWRow
									colSpacing={colSpacing}
									userId={row.UserId}
									notes={row.Notes}
									handleOnChangeCheckbox={(e: any) => {
										categoryState.checkUserByUserId(Number(e.target.name), e.target.value);
									}}
									kfw={row.KFW}
									kfwHidden={row.KFWHidden}
									shiftInfo={row.ShiftInfo}
									ticked={categoryState.usersChecked.indexOf(row.UserId) !== -1}
								/>
							</div>
						);
					})}
				</MiniContainer>
			</React.Fragment>
		);
	}
}

export default connect()(observer(AdminKFW2));