import * as React from "react";
import { connect } from "react-redux";
import { clientLineupByClientIdGET } from "../api/apiEndpoints";
import { userInfo } from "../lib/userInfo/userInfo";
import { profileByUserId } from "../poll/selectors";
import MiniContainer from "../components/container/MiniContainer";
import { parseAPIDate } from "../lib/dateUtils/dateUtils";
import DisplayHtml from "../components/html/DisplayHtml";
import "./ClientLineup.css";
import ProfileWorkPhone from "../components/links/ProfileWorkPhone";
import DateFns from "date-fns";
import NatTimePop from "./components/NatTimePop";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import { getUploadsBySectionType } from "./lib/commonAPICalls";
import RenderMainProfilePicture from "./components/RenderMainProfilePicture";
import RenderRSA from "./components/RenderRSA";
import Spinner from "../components/loading/Spinner";
import RenderProofPicture from "./components/RenderProofPicture";

class ClientLineup extends React.Component {
	state = {
		lineup: [],
		uploadsByProfileIdByUploadSectionType: {},
		loading: true
	};

	componentDidMount() {
		clientLineupByClientIdGET({ Id: userInfo().clientId }).then(r => {
			const d = (r.data = r.data || []);

			this.setState({ lineup: d, loading: false });
		});

		getUploadsBySectionType().then(uploadsByProfileIdByUploadSectionType => {
			this.setState({
				uploadsByProfileIdByUploadSectionType
			});
		});
	}

	render() {
		if (this.state.loading) {
			return <Spinner loading={true} />;
		}

		if (objectIsEmpty(this.props.profilesByUserId)) {
			return null;
		}

		let lineup = this.state.lineup;
		const startOfDay = DateFns.startOfDay(new Date());
		const startOfDayEpoch = startOfDay.getTime();

		lineup = lineup.filter(row => {
			return parseAPIDate(row.StartTime).getTime() > startOfDayEpoch;
		});

		// lineup.sort
		lineup = lineup.slice(0, 15);

		const heading = name => {
			return (
				<span
					style={{
						display: "block",
						fontSize: 16,
						paddingTop: 6,
						color: "#333",
						fontWeight: "bold"
					}}
				>
					{name}
				</span>
			);
		};

		return (
			<MiniContainer>
				{lineup.map((row, idx) => {
					const profile =
						row.UserId in this.props.profilesByUserId
							? this.props.profilesByUserId[row.UserId]
							: undefined;

					return (
						<div key={idx} className="row lineup-row">
							<div className="col-sm-3">
							{row.UserId && (
								<>
								<RenderMainProfilePicture
									profileId={profile.Id}
									uploadsByProfileIdByUploadSectionType={
									this.state.uploadsByProfileIdByUploadSectionType
									}
								/>
								{"Click profile photo to open photo gallery"}
								</>
							)}
							</div>

							<div className="col-sm-9">
								<div className="row">
									<NatTimePop startTime={row.StartTime} endTime={row.EndTime} />
								</div>
								{!row.UserId && <div className="row">Shift is yet to be booked.</div>}
								{row.UserId && (
									<div className="row">
										<h3>{this.props.profilesByUserId[row.UserId].StageName}</h3>
										<div>
											<div>{heading("Contact Number")}</div>
											<div>
												<ProfileWorkPhone userId={row.UserId} />
											</div>
										</div>
										<div>
											<div>{heading("ABN")}</div>
											<div>{this.props.profilesByUserId[row.UserId].ABN}</div>
										</div>
										<div>
											<div>{heading("RSA")}</div>
											<div>
												<RenderRSA
													stageName={this.props.profilesByUserId[row.UserId].StageName}
													profileId={profile.Id}
													uploadsByProfileIdByUploadSectionType={
														this.state.uploadsByProfileIdByUploadSectionType
													}
												/>
											</div>
										</div>
										<div>
											<div>{heading("Proof Picture")}</div>
											<div>
												<RenderProofPicture
													profileId={profile.Id}
													uploadsByProfileIdByUploadSectionType={
														this.state.uploadsByProfileIdByUploadSectionType
													}
												/>
											</div>
										</div>
										<div>
											<div>{heading("GST Registered")}</div>
											<div>
												{this.props.profilesByUserId[row.UserId].GST ? "Yes" : "No"}
											</div>
										</div>
										<div>
											<div>{heading("Started Skimpy Work In")}</div>
											<div>{this.props.profilesByUserId[row.UserId].Experience}</div>
										</div>
										<div>
											<div>{heading("Personality")}</div>
											<div>
												<DisplayHtml
													value={this.props.profilesByUserId[row.UserId].Personality}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					);
				})}
			</MiniContainer>
		);
	}
}

ClientLineup.propTypes = {};

const mapStateToProps = state => {
	return {
		profilesByUserId: profileByUserId(state)
	};
};

ClientLineup = connect(mapStateToProps)(ClientLineup);
export default ClientLineup;
