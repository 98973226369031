import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentCodesById } from "../../poll/selectors";

class PaymentCodeText extends React.Component {
	render() {
		if (!(this.props.paymentCodeId in this.props.paymentCodesById)) {
			return null;
		}
		const code = this.props.paymentCodesById[this.props.paymentCodeId];

		let description = code.Description;
		if (code.Internal === true) {
			description = code.Description.replace(/([A-Z])/g, " $1");
		}

		if (code.DeletedAt !== null) {
			return <span style={{ textDecoration: "line-through" }}>{description}</span>;
		}

		return <span>{description}</span>;
	}
}

PaymentCodeText.propTypes = {
	paymentCodeId: PropTypes.number
};

const mapStateToProps = state => {
	return {
		paymentCodesById: paymentCodesById(state)
	};
};

export default connect(mapStateToProps)(PaymentCodeText);
