import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentLogsPOST } from "../api/apiEndpoints";
import ProfileStageName from "../components/links/ProfileStageName";
import ResponsiveTableNew from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import NatTime from "../components/text/NatTime";
import PaymentCodeText from "../components/text/PaymentCodeText";
import PaymentSubCodeText from "../components/text/PaymentSubCodeText";
import PaymentTypeText from "../components/text/PaymentTypeText";
import PaymentMethodText from "../components/text/PaymentMethodText";
import { parseAPIDate } from "../lib/dateUtils/dateUtils";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import MiniContainer from "../components/container/MiniContainer";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";

class PaymentLogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			logs: []
		};
	}

	componentDidMount() {
		paymentLogsPOST().then(response => {
			let lastSeenId = -1;
			const rawLogs = response.data.Logs;

			let filteredLogs = rawLogs;
			if (this.props.filterByPaymentId !== -1) {
				filteredLogs = filteredLogs.filter(log => {
					return log.Id === this.props.filterByPaymentId;
				});
			}

			let logs = filteredLogs.map((log, logIdx) => {
				let createdOrUpdated = lastSeenId === log.Id ? "Updated by" : "Created by";

				lastSeenId = log.Id;
				return {
					description: {
						display: (
							<span>
								{createdOrUpdated} <ProfileStageName userId={log.AuthId} /> at{" "}
								<NatTime startTime={log.ua} />
							</span>
						),
						changed: this.hasValueChanged(rawLogs, "AuthId", logIdx)
					},
					user: {
						display: <ProfileStageName userId={log.UserId} />,
						changed: this.hasValueChanged(rawLogs, "UserId", logIdx)
					},
					code: {
						display: <PaymentCodeText paymentCodeId={log.PaymentCode} />,
						changed: this.hasValueChanged(rawLogs, "PaymentCode", logIdx)
					},
					sub_code: {
						display: (
							<PaymentSubCodeText paymentSubCodeId={log.PaymentCategorySubCodesId} />
						),
						changed: this.hasValueChanged(
							rawLogs,
							"PaymentCategorySubCodesId",
							logIdx
						)
					},
					payment_type: {
						display: <PaymentTypeText paymentTypeId={log.PaymentType} />,
						changed: this.hasValueChanged(rawLogs, "PaymentType", logIdx)
					},
					payment_method: {
						display: <PaymentMethodText paymentMethodId={log.PaymentMethod} />,
						changed: this.hasValueChanged(rawLogs, "PaymentMethod", logIdx)
					},
					amount: {
						display: log.Amount,
						changed: this.hasValueChanged(rawLogs, "Amount", logIdx)
					},
					notes: {
						display: log.Notes,
						changed: this.hasValueChanged(rawLogs, "Notes", logIdx)
					},
					updated_at: log.ua,
					created_at: {
						display: <NatTime startTime={log.CreatedAt} />,
						changed: this.hasValueChanged(rawLogs, "CreatedAt", logIdx)
					}
				};
			});

			if (this.props.filterByPaymentId !== -1) {
				this.setState({
					logs: logs.reverse()
				});
			} else {
				logs.sort((a, b) => {
					const aDate = parseAPIDate(a["updated_at"]);
					const bDate = parseAPIDate(b["updated_at"]);

					return bDate - aDate;
				});

				this.setState({
					logs: logs
				});
			}
		});
	}

	hasValueChanged = (logs, key, pos) => {
		if (pos === 0) {
			return false;
		}

		// If the the previous record has a differnet ID then skip as this is the last record for the current Id
		if (logs[pos].Id !== logs[pos - 1].Id) {
			return false;
		}

		return logs[pos][key] !== logs[pos - 1][key];
	};

	renderChangedItem = (displayComponent, changed) => {
		if (changed) {
			return (
				<div style={{ fontWeight: "bold", backgroundColor: "#BBFFBB" }}>
					{displayComponent}
				</div>
			);
		}
		return displayComponent;
	};

	render() {
		const colSpacing = [2, 2, 2, 1, 2, 1, 2];

		return (
			<MiniContainer>
				<ResponsiveTableNew
					values={this.state.logs}
					headings={[
						{
							label: "Skimpy",
							name: "UserId",
							size: colSpacing[0],
							disableSort: true
						},
						{
							label: "Code",
							name: "Code",
							size: colSpacing[1],
							disableSort: true
						},
						{
							label: "Sub Code",
							name: "SubCode",
							size: colSpacing[2],
							disableSort: true
						},
						{
							label: "Payment Type",
							name: "PaymentType",
							size: colSpacing[3],
							disableSort: true
						},
						{
							label: "Payment Method",
							name: "PaymentMethod",
							size: colSpacing[4],
							disableSort: true
						},
						{
							label: "Amount",
							name: "Amount",
							size: colSpacing[5],
							disableSort: true
						},
						{
							label: "Date",
							name: "CreatedAt",
							size: colSpacing[6],
							disableSort: true
						}
					]}
				>
					{({ sortedValues }) => {
						return (
							<InfiniteScroll
								rowCount={sortedValues.length}
								rowComparison={() => {
									return false;
								}}
								rows={sortedValues}
								overscanRowCount={5}
								rowRender={({ index, row, style, redrawRow }) => {
									return (
										<div style={style} className="div-table-responsive-row">
											<div className="col-xs-12">
												{this.renderChangedItem(
													row.description.display,
													row.description.changed
												)}
											</div>
											<ResponsiveTableColumn size={colSpacing[0]} heading="Skimpy">
												{this.renderChangedItem(row.user.display, row.user.changed)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[1]} heading="Code">
												{this.renderChangedItem(row.code.display, row.code.changed)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[2]} heading="Sub Code">
												{this.renderChangedItem(row.sub_code.display, row.sub_code.changed)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[3]} heading="Payment Type">
												{this.renderChangedItem(
													row.payment_type.display,
													row.payment_type.changed
												)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[4]} heading="Payment Method">
												{this.renderChangedItem(
													row.payment_method.display,
													row.payment_method.changed
												)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[5]} heading="Amount">
												{this.renderChangedItem(row.amount.display, row.amount.changed)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[6]} heading="Date">
												{this.renderChangedItem(
													row.created_at.display,
													row.created_at.changed
												)}
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={12} heading="Line Item">
												{this.renderChangedItem(row.notes.display, row.notes.changed)}
											</ResponsiveTableColumn>
										</div>
									);
								}}
							/>
						);
					}}
				</ResponsiveTableNew>
			</MiniContainer>
		);
	}
}

PaymentLogs.defaultProps = {
	filterByPaymentId: -1
};

PaymentLogs.propTypes = {
	filterByPaymentId: PropTypes.number
};

const mapStateToProps = (state, props) => {
	return {};
};

PaymentLogs = connect(mapStateToProps)(PaymentLogs);
export default PaymentLogs;
