import * as React from "react";
import PropTypes from "prop-types";
import { shiftDeleteDELETE } from "../../../api/apiEndpoints";
import NatTime from "../../../components/text/NatTime";
import FormManager from "../../../components/form/FormManager";
import LoadingButtonWithConfirm from "../../../components/button/LoadingButtonWithConfirm";
import ProfileStageName from "../../../components/links/ProfileStageName";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { Trash } from "../../../components/icons/Icons";

export default class CancelThisShift extends React.Component {
	render() {
		return (
			<div>
				<h4 className="heading">Delete an individual shift.</h4>

				<p>This shift will be deleted</p>
				<p>
					<b>
						<NatTime
							startTime={this.props.shift.StartTime}
							endTime={this.props.shift.EndTime}
						/>
					</b>
				</p>

				{this.props.shift.UserId === null || !("UserId" in this.props.shift) ? (
					<p>No one currently owns this shift.</p>
				) : (
					<p>
						<ProfileStageName userId={this.props.shift.UserId} /> owns this shift.
					</p>
				)}

				<div>
					<FormManager
						handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
							shiftDeleteDELETE({
								Id: this.props.shift.Id
							})
								.then(() => {
									handleSubmitSuccess();
									this.props.handleCloseModal();
								})
								.catch(e => {
									handleSubmitFail();
									throw e;
								});
						}}
					>
						{({ handleSubmit, submitting }) => {
							return (
								<AdminContainerMigrate
									buttons={[
										<LoadingButtonWithConfirm
											className="btn btn-danger btn-big-phone pull-right"
											loading={submitting}
											onClick={handleSubmit}
										>
											<Trash /> Delete
										</LoadingButtonWithConfirm>
									]}
									align="right"
								/>
							);
						}}
					</FormManager>
				</div>
			</div>
		);
	}
}

CancelThisShift.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};
