import * as React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-regular-svg-icons/faSave";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUndo } from "@fortawesome/pro-solid-svg-icons/faUndo";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons/faSyncAlt";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faBeer } from "@fortawesome/pro-solid-svg-icons/faBeer";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faBoxUsd } from "@fortawesome/pro-solid-svg-icons/faBoxUsd";
import { faGamepad } from "@fortawesome/pro-solid-svg-icons/faGamepad";
import { faGlassMartiniAlt } from "@fortawesome/pro-solid-svg-icons/faGlassMartiniAlt";
import { faCheckSquare } from "@fortawesome/pro-light-svg-icons/faCheckSquare";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faHistory } from "@fortawesome/pro-solid-svg-icons/faHistory";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import {faPlayCircle} from "@fortawesome/pro-light-svg-icons/faPlayCircle";
import {faImage} from "@fortawesome/free-solid-svg-icons/faImage"

interface IIcon {
	title?: string;
}

library.add(faPlayCircle)
class PlayCircle extends React.Component {
	render() {
		return <FontAwesomeIcon {...this.props} icon={['fal', 'play-circle']} />
	}
}
library.add(faImage)
class Img extends React.Component {
	render() {
		return <FontAwesomeIcon {...this.props} icon={['fas', 'image']} />
	}
}

library.add(faPencil);
class Pencil extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["far", "pencil"]} />;
	}
}
library.add(faSave);
class Save extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["far", "save"]} />;
	}
}
library.add(faEye);
class Eye extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "eye"]} />;
	}
}
library.add(faTrash);
class Trash extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "trash"]} />;
	}
}
library.add(faCheck);
class Check extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "check"]} />;
	}
}
library.add(faCheckSquare);
class CheckSquare extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fal", "check-square"]} />;
	}
}
library.add(faTimes);
class Times extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "times"]} />;
	}
}
library.add(faUndo);
class Undo extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "undo"]} />;
	}
}
library.add(faComment);
class Comment extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "comment"]} />;
	}
}
library.add(faSyncAlt);
class Sync extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "sync-alt"]} />;
	}
}
library.add(faPlusCircle);
class PlusCircle extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "plus-circle"]} />;
	}
}
library.add(faBeer);
class Beer extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "beer"]} />;
	}
}
library.add(faUser);
class User extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "user"]} />;
	}
}
library.add(faBoxUsd);
class Dollar extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "box-usd"]} />;
	}
}
library.add(faGamepad);
class Gamepad extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "gamepad"]} />;
	}
}
library.add(faGlassMartiniAlt);
class GlassMartini extends React.Component<IIcon> {
	render() {
		return (
			<FontAwesomeIcon {...this.props} icon={["fas", "glass-martini-alt"]} />
		);
	}
}
library.add(faCalendar);
class Calendar extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "calendar"]} />;
	}
}
library.add(faHistory);
class History extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "history"]} />;
	}
}
library.add(faCaretDown);
class CaretDown extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "caret-down"]} />;
	}
}
library.add(faCaretUp);
class CaretUp extends React.Component<IIcon> {
	render() {
		return <FontAwesomeIcon {...this.props} icon={["fas", "caret-up"]} />;
	}
}

class Topless extends React.Component<IIcon> {
	render() {
		return (
			<img
				alt="(.)(.)"
				{...this.props}
				src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjVDRUMxNDQ2ODMxMUUyOTkyREQ4RTU4NDU3OUEwQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBMjVDRUMxNTQ2ODMxMUUyOTkyREQ4RTU4NDU3OUEwQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyNUNFQzEyNDY4MzExRTI5OTJERDhFNTg0NTc5QTBDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyNUNFQzEzNDY4MzExRTI5OTJERDhFNTg0NTc5QTBDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+2TroPgAAAiJJREFUeNrsVk0rhFEUNoPyUSZFaYbCDyDNQsnKgiSxYUW+8hFKKH/AgoTJQqHYsLOgRKRGSbKZBQplY2EMRkRKyLyeU8/V9eY1yiTJradz7nuf85x7z71zsBmGEfUTwx71Q+PvJXobbrc7A8iMoF6maKq5jR9jYYKA2BSfz/fwzSTxMFfAM5AKvWdVuhTAASQAoQgcKEQtB7Xf7iif9gTZH7+bhRonurZKVEXrjeC1e3XtGNazgh/nw9Q+F6abUw92vvsJXbQaRVtyxMApBhKBG2A9zC6bgTr690DnJ9x1aiZLDildJRcWsMOXMIl2LPyP7km0FjittGsPYeULdT+08K2G0syXRFmc7H8h8NrCtxpKM0vuKI6TJ164JB8HyoE1oEUrqd7qDe2RRMNMASXAEtCBmJDSlBx2dgQZ2bRFQBvg4qsp1cSdFn4puS7GFpk0g5Jok5MG2kvT8YOaX2/hB00xlybNzWin03nBoBz4hziyF/aAz3cY81WWR17noOqPGHng7QUCgSPAD/8Y3+6AAcRsgF8Nv5/cdtVUJ+Uu2KM8wCjIZ1xLg+kC+thJZhlcS/4QMAb+OflS0h7+sIU/hbVWvXtPsM5q+HnhLu0Uc0AT/WmgRnsYfvJcmsaM3Jl0b5upxZRx54VaH5RdbwMjCFg08aWcvUCBib8lJwV/+d3fow96WhJMOtdPEXAbpgc6yDfIvzNzbP//Bf36RK8CDADHx7QRg+sM+QAAAABJRU5ErkJggg=="
			/>
		);
	}
}

export {
	PlayCircle,
	Img,
	Pencil,
	Save,
	Eye,
	Trash,
	Check,
	CheckSquare,
	Times,
	Undo,
	Comment,
	Sync,
	PlusCircle,
	Beer,
	User,
	Dollar,
	Gamepad,
	GlassMartini,
	Topless,
	Calendar,
	History,
	CaretDown,
	CaretUp
};
