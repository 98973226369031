import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findClientById } from "../../poll/selectors";
import Money from "./Money";

class ClientPaymentMethod extends React.Component {
	render() {
		return <Money value={this.props.client.TravelPay} />;
	}
}

ClientPaymentMethod.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		client: findClientById(state, props.clientId)
	};
};

export default connect(mapStateToProps)(ClientPaymentMethod);
