import { REDUX_ROOT_CONFIG } from "../store";

export function rootSelector(state) {
	return state[REDUX_ROOT_CONFIG];
}

export function userGroupById(state) {
	return rootSelector(state)["byId"]["UserGroups"];
}

export function paymentMethodsById(state) {
	return rootSelector(state)["byId"]["PaymentMethod"];
}
export function paymentMethodsAsArray(state) {
	return rootSelector(state)["raw"]["PaymentMethod"];
}

export function shiftTypesById(state) {
	return rootSelector(state)["byId"]["ShiftTypes"];
}
export function shiftTypesAsArray(state) {
	return rootSelector(state)["raw"]["ShiftTypes"];
}

export function profileServicesById(state) {
	return rootSelector(state)["byId"]["ProfileServices"];
}
export function profileServicesAsArray(state) {
	return rootSelector(state)["raw"]["ProfileServices"];
}

export function profileMerchById(state) {
	return rootSelector(state)["byId"]["ProfileMerch"];
}
export function profileMerchAsArray(state) {
	return rootSelector(state)["raw"]["ProfileMerch"];
}

export function paymentMethodAsArray(state) {
	return rootSelector(state)["raw"]["PaymentMethod"];
}
export function paymentMethodById(state) {
	return rootSelector(state)["byId"]["PaymentMethod"];
}

export function paymentTypeAsArray(state) {
	return rootSelector(state)["raw"]["PaymentType"];
}
export function paymentTypeById(state) {
	return rootSelector(state)["byId"]["PaymentType"];
}

export function clientShiftFeedbackTypesById(state) {
	return rootSelector(state)["byId"]["ClientShiftFeedbackTypes"];
}
export function clientShiftFeedbackTypesAsArray(state) {
	return rootSelector(state)["raw"]["ClientShiftFeedbackTypes"];
}

export function findUserGroupById(state, id) {
	if (id in rootSelector(state)["byId"]["UserGroups"]) {
		return rootSelector(state)["byId"]["UserGroups"][id];
	}

	return {};
}
