import React, {useContext} from 'react'
import StandardModalWrapper from '../../components/modal/StandardModalWrapper';
import { AdminPaymentsContext } from '../AdminPaymentsContext';
import CreateCharge from '../CreateCharge';
import CreatePayment from '../CreatePayment';


const ModalButton = ({ text, onClick }) => {
	return (
		<button className="admin-box-button btn-primary" onClick={onClick}>
			<i className="fas fa-dollar-sign" /> {text}
		</button>
	);
};

const TopButtonBar = () => {
    const { history } = useContext(AdminPaymentsContext);
	const handleSearchOnChange = uid => {
        console.log('handleSearchOnChange', uid)
		history.push("/admin_accounts/?userId=" + uid);
	};
	return (
		<>
			<StandardModalWrapper
				heading={"Create a charge"}
				modalOpener={({ handleOpenModal }) => (
					<ModalButton text="Create a charge" onClick={handleOpenModal} />
				)}
				render={({ handleCloseModal }) => {
					const handleCloseModalAndUpdate = userId => {
						handleSearchOnChange(userId);
						handleCloseModal();
					};
					return <CreateCharge handleSaveReturnUserId={handleCloseModalAndUpdate} />;
				}}
			/>
			<StandardModalWrapper
				heading={"Create a payment"}
				modalOpener={({ handleOpenModal }) => (
					<ModalButton text="Create a payment" onClick={handleOpenModal} />
				)}
				render={({ handleCloseModal }) => {
					const handleCloseModalAndUpdate = userId => {
						handleSearchOnChange(userId);
						handleCloseModal();
					};
					return (
						<CreatePayment handleSaveReturnUserId={handleCloseModalAndUpdate} />
					);
				}}
			/>
		</>
	);
};

export default TopButtonBar