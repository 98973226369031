import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./auth/reducers";
import { loginScreenReducer } from "./loginScreen/reducers";
import { pollReducer } from "./poll/reducers";
import { configReducer } from "./config/reducers";
import { reducer as toastrReducer } from "react-redux-toastr";
// import logger from 'redux-logger';

export const REDUX_ROOT_GUI = "page";
export const REDUX_ROOT_GUI_LOGIN_SCREEN = "login";
export const REDUX_ROOT_POLL = "poll";
export const REDUX_ROOT_AUTH = "auth";
export const REDUX_ROOT_CONFIG = "config";

const appReducer = combineReducers({
	[REDUX_ROOT_GUI]: combineReducers({
		[REDUX_ROOT_GUI_LOGIN_SCREEN]: loginScreenReducer
	}),
	[REDUX_ROOT_AUTH]: authReducer,
	[REDUX_ROOT_POLL]: pollReducer,
	[REDUX_ROOT_CONFIG]: configReducer,
	toastr: toastrReducer
});

const rootReducer = (state, action) => {
	if (action.type === "LOGOUT") {
		state = undefined;
	}
	return appReducer(state, action);
};

// const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);

function configureStore() {
	const reduxStore = createStore(
		rootReducer,
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
		middleware
	);

	// console.log(reduxStore.getState())

	reduxStore.subscribe(() => {
		console.log("reduxStore changed", reduxStore.getState());
	});
 
	return reduxStore;
}

const store = configureStore();

export default store;
