import API from "../api/client";
import { REDUX_ROOT_AUTH } from "../store";
import history from "../history";
import { routes } from "../router/router";
import { loadConfigFromApi } from "../config/actions";
import { userGroups } from "../lib/userInfo/userInfo";

export const PAGE_LOGIN_SUCCESS = "PAGE_LOGIN_SUCCESS";

export function attemptLogin(username, password) {
	return function(dispatch) {
		dispatch(showLoginButtonLoading(true));
		dispatch(showAuthFailBanner(false));

		API.login(username, password)
			.then(response => {
				dispatch(showLoginButtonLoading(false));
				dispatch(showAuthFailBanner(false));
				dispatch(showOtherErrorBanner(false))
				dispatch(updateStoresFromToken(response.data.token));
				dispatch(redirectHomeIfLoggedIn());
			})
			.catch(error => {
				dispatch(showLoginButtonLoading(false));
				if (error.response.status === 401) {
					dispatch(showAuthFailBanner(true));
				} else {
					dispatch(showOtherErrorBanner(true))
				}
			});
	};
}

export function redirectHomeIfLoggedIn() {
	return function(dispatch, getState) {
		console.log("Attempt Login");
		const state = getState()[REDUX_ROOT_AUTH];

		if (
			[
				userGroups.admin,
				userGroups.hrm,
				userGroups.trial,
				userGroups.fullAccess,
				userGroups.restricted
			].includes(state.tokenDecoded.UserGroupId)
		) {
			console.log("User");
			history.push(routes.Shifts);
			dispatch(loadConfigFromApi());
		} else if (state.tokenDecoded.UserGroupId === userGroups.client) {
			console.log("Client");
			history.push(routes.ClientLineup);
			dispatch(loadConfigFromApi());
			// TODO: redirect for client
		} else {
			console.log("Fail");
		}
	};
}

export function badToken() {
	return {
		type: "LOCAL_STORAGE_BAD_TOKEN"
	};
}

export function showAuthFailBanner(bool) {
	return {
		type: "AUTH_FAIL_BANNER",
		payload: bool
	};
}
export function showOtherErrorBanner(bool) {
	return {
		type: "AUTH_OTHER_ERROR_BANNER",
		payload: bool
	};
}

export function showLoginButtonLoading(bool) {
	return {
		type: "AUTH_LOADING",
		payload: bool
	};
}

export function updateStoresFromToken(token) {
	return {
		type: "LOCAL_STORAGE_TOKEN_UPDATED",
		payload: token
	};
}
