import * as React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

export const shiftFilterOptions = [
	{ label: "All Shifts", value: "FILTER_ALL_SHIFTS" },
	{ label: "Available shifts", value: "FILTER_AVAILABLE_SHIFTS" },
	{ label: "My booked shifts", value: "FILTER_MY_BOOKED_SHIFTS" },
	{ label: "My pending shifts", value: "FILTER_MY_PENDING_SHIFTS" }
];

class ShiftTypeFilter extends React.Component {
	render() {
		return (
			<Select
				options={shiftFilterOptions}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
				defaultOptionValue={0}
			/>
		);
	}
}

ShiftTypeFilter.defaultProps = {
	inactiveUsersVisible: false
};

ShiftTypeFilter.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	value: PropTypes.any
};

export default ShiftTypeFilter;
