import * as React from "react";
import { connect } from "react-redux";
import {
	clientContactsSelectorAsArray,
	findClientById
} from "../../poll/selectors";
import PropTypes from "prop-types";
import ClientAddress from "../../components/text/ClientAddress";
import ClientTelephone from "../../components/links/ClientTelephone";
import ClientEmail from "../../components/links/ClientEmail";
import EmbedGoogleMap from "../../components/map/EmbedGoogleMap";
import EditVenueGeneralInfo from "./EditVenueGeneralInfo";
import EditVenueContacts from "./EditVenueContacts";
import StandardModalWrapper from "../../components/modal/StandardModalWrapper";
import FormRow from "../../components/form/FormRow";
import ProtectedComponent from "../../components/permissions/ProtectedComponent";
import { userGroups } from "../../lib/userInfo/userInfo";
import { Check, Times } from "../../components/icons/Icons";

class VenueGeneralInfo extends React.Component {
	constructor(props) {
		super(props);

		this.pageNames = {
			editGeneralInfo: "Edit Address",
			editContacts: "Edit Contacts"
		};

		this.state = {
			initialFormValues: null,
			page: this.pageNames.editGeneralInfo
		};
	}

	componentDidMount() {
		this.onLoad(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.onLoad(nextProps);
	}

	onLoad(nextProps) {
		this.setState({
			initialFormValues: {
				AddressNumber: nextProps.venue.AddressNumber,
				AddressRoad: nextProps.venue.AddressRoad,
				AddressState: nextProps.venue.AddressState,
				AddressSuburb: nextProps.venue.AddressSuburb,
				AddressPostcode: nextProps.venue.AddressPostcode,
				PhoneNumber: nextProps.venue.PhoneNumber,
				ClientEmail: nextProps.venue.ClientEmail,
				DistanceFromCBD: nextProps.venue.DistanceFromCBD,
				Lat: nextProps.venue.Lat,
				Lng: nextProps.venue.Lng,
				DeletedAt: nextProps.venue.DeletedAt,
				ClientContacts: nextProps.venueContactsAsArray
					// Filter for the selected ClientId
					.filter(contact => {
						return contact.ClientId === nextProps.clientId;
					})
					// Only keep keys we're interested in
					.map(contact => {
						return {
							Id: contact.Id,
							ClientId: contact.ClientId,
							FullName: contact.FullName,
							PhoneNumber: contact.PhoneNumber
						};
					})
			}
		});
	}

	handlePageChange = e => {
		this.setState({ page: e.target.name });
	};

	render() {
		if (this.state.initialFormValues === null) {
			return null;
		}

		return (
			<div>
				<StandardModalWrapper
					modalOpener={({ handleOpenModal }) => {
						return (
							<ProtectedComponent allowedGroups={[userGroups.admin, userGroups.hrm]}>
								<span className="heading-edit" onClick={handleOpenModal}>
									Edit
								</span>
							</ProtectedComponent>
						);
					}}
					render={({ handleCloseModal }) => {
						return (
							<div>
								<ul className="nav nav-tabs" style={{ marginBottom: 15 }}>
									<li>
										<button
											className={
												this.state.page === this.pageNames.editGeneralInfo ? "active" : ""
											}
											name={this.pageNames.editGeneralInfo}
											onClick={this.handlePageChange}
										>
											{this.pageNames.editGeneralInfo}
										</button>
									</li>
									<li>
										<button
											className={
												this.state.page === this.pageNames.editContacts ? "active" : ""
											}
											name={this.pageNames.editContacts}
											onClick={this.handlePageChange}
										>
											{this.pageNames.editContacts}
										</button>
									</li>
								</ul>

								{this.state.page === this.pageNames.editGeneralInfo && (
									<EditVenueGeneralInfo
										clientId={this.props.clientId}
										handleCloseModal={handleCloseModal}
										initialFormValues={this.state.initialFormValues}
									/>
								)}

								{this.state.page === this.pageNames.editContacts && (
									<EditVenueContacts
										clientId={this.props.clientId}
										handleCloseModal={handleCloseModal}
										initialFormValues={this.state.initialFormValues}
									/>
								)}
							</div>
						);
					}}
				/>

				<h4 className="heading">Address and Contacts</h4>

				<FormRow
					label={"Address"}
					value={<ClientAddress clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Client Email"}
					value={<ClientEmail clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Phone number"}
					value={<ClientTelephone clientId={this.props.clientId} />}
				/>
				<FormRow
					label={"Distance from CBD"}
					value={<span>{this.state.initialFormValues.DistanceFromCBD}</span>}
				/>
				<FormRow
					label={"Latitude"}
					value={<span>{this.state.initialFormValues.Lat}</span>}
				/>
				<FormRow
					label={"Longitude"}
					value={<span>{this.state.initialFormValues.Lng}</span>}
				/>
				<FormRow
					label={"Client Active"}
					value={
						this.state.initialFormValues.DeletedAt === null ? (
							<Check />
						) : (
							<Times />
						)
					}
				/>
				<FormRow
					label={"Venue Contacts"}
					value={this.state.initialFormValues.ClientContacts.map((contact, idx) => {
						return (
							<div key={idx}>
								{[contact.FullName, contact.PhoneNumber].join(" - ")}
							</div>
						);
					})}
				/>

				<div className="row">
					<div className="col-sm-12">
						<EmbedGoogleMap
							addressAsArray={[
								this.state.initialFormValues.AddressNumber,
								this.state.initialFormValues.AddressRoad,
								this.state.initialFormValues.AddressSuburb,
								this.state.initialFormValues.AddressState,
								this.state.initialFormValues.AddressPostcode
							]}
						/>
					</div>
				</div>
			</div>
		);
	}
}

VenueGeneralInfo.propTypes = {
	clientId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		venue: findClientById(state, props.clientId),
		venueContactsAsArray: clientContactsSelectorAsArray(state)
	};
};

export default connect(mapStateToProps)(VenueGeneralInfo);
