import React from "react";
import {css} from "emotion";

class VirtualButton extends React.Component<{
	onClick: any;
	value: any;
	className?: any;
}> {
	handleOnClick = () => {
		if (this.props.onClick) {
			this.props.onClick(this.props.value);
		}
	};

	render() {
		return (
			<span
				className={css`
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					user-select: none;
					${this.props.className}
				` + " vbutton"}
				onClick={this.handleOnClick}
			>
				{this.props.children}
			</span>
		);
	}
}

export default VirtualButton;
