import * as React from "react";
import { connect } from "react-redux";
import { getPaymentsByUserId } from "../api/apiEndpoints";
import ResponsiveTableNew, {
	sortDirectionOptions
} from "../components/table/ResponsiveTableNew";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import { profileByUserId, userSelectorById } from "../poll/selectors";
import { objectIsEmpty } from "../lib/object/objectIsEmpty";
import ProfileStageName from "../components/links/ProfileStageName";
import Money from "../components/text/Money";
import AdminContainerMigrate from "../components/container/AdminContainerMigrate";
import { sortStageNameByUserId } from "../lib/sort/sortByUserId";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import MiniContainer from "../components/container/MiniContainer";

class AdminEndBalance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			creditEntries: [],
			showInactiveUsers: false
		};
	}

	componentDidMount() {
		getPaymentsByUserId().then(r => {
			this.setState({
				creditEntries: r.data
			});
		});
	}

	componentWillReceiveProps(nextProps) {
		if (objectIsEmpty(nextProps.profilesByUserId)) {
			return;
		}

		this.setState({
			creditEntries: this.state.creditEntries.map(row => {
				return {
					...row,
					stageName: nextProps.profilesByUserId[row.UserId].StageName
				};
			})
		});
	}

	render() {
		if (objectIsEmpty(this.props.usersById)) {
			return null;
		}

		let creditEntriesFiltered = [...this.state.creditEntries];

		if (!this.state.showInactiveUsers) {
			creditEntriesFiltered = creditEntriesFiltered.filter(entry => {
				return this.props.usersById[entry.UserId].UserGroupId !== 0;
			});
		}

		const colSpacing = [6, 6];
		return (
			<MiniContainer>
				<h4 className="heading">End Balances</h4>
				<AdminContainerMigrate
					buttons={[
						<button
							className="btn btn-primary"
							onClick={() => {
								this.setState({
									showInactiveUsers: !this.state.showInactiveUsers
								});
							}}
						>
							<i
								className={
									this.state.showInactiveUsers ? "fas fa-toggle-on" : "fas fa-toggle-off"
								}
							/>{" "}
							Show inactive skimpies
						</button>
					]}
				/>
				<ResponsiveTableNew
					initialSortColumn={"stageName"}
					initialSortDirection={sortDirectionOptions.decending}
					values={creditEntriesFiltered}
					headings={[
						{
							label: "Skimpy",
							name: "UserId",
							size: colSpacing[0],
							sortDescFunc: sortStageNameByUserId
						},
						{
							label: "End Balance",
							name: "Credit",
							size: colSpacing[1],
							sortDescFunc: (a, b) => {
								return a - b;
							}
						}
					]}
				>
					{({ sortedValues }) => {
						return (
							<InfiniteScroll
								rowCount={sortedValues.length}
								rowComparison={() => {
									return false;
								}}
								rows={sortedValues}
								overscanRowCount={5}
								rowRender={({ index, row, style, redrawRow }) => {
									return (
										<div style={style} className="div-table-responsive-row">
											<ResponsiveTableColumn size={colSpacing[0]} heading="Skimpy">
												<ProfileStageName userId={row.UserId} />
											</ResponsiveTableColumn>
											<ResponsiveTableColumn size={colSpacing[1]} heading="End Balance">
												<Money value={row.Credit} negativeValuesAreRed={true} />
											</ResponsiveTableColumn>
										</div>
									);
								}}
							/>
						);
					}}
				</ResponsiveTableNew>
			</MiniContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		profilesByUserId: profileByUserId(state),
		usersById: userSelectorById(state)
	};
};

AdminEndBalance = connect(mapStateToProps)(AdminEndBalance);
export default AdminEndBalance;
