import * as React from "react";
import PropTypes from "prop-types";
import FormManager from "../form/FormManager";
import LoadingButton from "../button/LoadingButton";
import ValidationMessages from "../form/ValidationMessages";
import { uploadPOST } from "../../api/apiEndpoints";
import AdminContainerMigrate from "../container/AdminContainerMigrate";
import { Save } from "../icons/Icons";

class UploadFileModal extends React.Component {
	static propTypes = {
		uploadSectionType: PropTypes.string.isRequired, //
		uploadSectionId: PropTypes.number.isRequired, // profile ID, client ID or brand ID
		onSubmitSuccessFn: PropTypes.func.isRequired
	};

	render() {
		return (
			<React.Fragment>
				<FormManager
					handleSubmit={({ handleSubmitFail, handleSubmitSuccess }) => {
						if (this.filesInput.files.length !== 1) {
							return;
						}

						let file = this.filesInput.files.item(0);
						let mimeType = file.type;

						uploadPOST({
							sectionType: this.props.uploadSectionType,
							sectionId: this.props.uploadSectionId,
							file: file,
							mimeType: mimeType
						})
							.then(() => {
								handleSubmitSuccess();
								this.props.onSubmitSuccessFn();
							})
							.catch(() => {
								handleSubmitFail();
							});
					}}
					handleValidation={v => {
						v.validFileExtension({
							fieldName: "file",
							validFileExtensions: [".jpg", ".jpeg", ".png", ".pdf"]
						});
					}}
				>
					{({
						handleSubmit,
						submitting,
						handleOnChange,
						formValidation,
						submitEnabled
					}) => {
						return (
							<div>
								<input
									style={{ paddingBottom: "5px" }}
									type="file"
									ref={input => {
										this.filesInput = input;
									}}
									onChange={handleOnChange}
									name="file"
								/>

								<ValidationMessages messages={formValidation["file"]} />

								<AdminContainerMigrate
									align="right"
									buttons={[
										<LoadingButton
											className="btn btn-danger"
											loading={submitting}
											disabled={!submitEnabled}
											onClick={handleSubmit}
										>
											<Save /> Upload Image
										</LoadingButton>
									]}
								/>
							</div>
						);
					}}
				</FormManager>
			</React.Fragment>
		);
	}
}

export default UploadFileModal;
