import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findProfileByUserId, levelSelectorById } from "../../poll/selectors";
import { css } from "emotion";

class ProfileWorkFacebook extends React.Component {

	cls = css`
     .fb {
       display: grid;
       grid-template-columns: 16px 1fr;
       font-size: 14px;
       align-items: baseline;

       .fb-text {
         word-wrap: break-word;
         word-break: break-all;
       }
     }
	`

	render() {
		if (!this.props.profile || this.props.profile == undefined) {
			return null;
		}

		if (this.props.profile.WorkFacebook === null || this.props.profile.WorkFacebook == undefined) {
			return null;
		}


		const text = this.props.profile.WorkFacebook.toLowerCase()
			.replace("https://", "")
			.replace("www.facebook.com", "")
			.replace("facebook.com", "")
			.replace("/", "")
			.slice(0, 55);


		return (
			<a href={this.props.profile.WorkFacebook} className={this.cls} target="_blank">
				<div className="fb">
					<i className="fab fa-facebook fb-icon"/>
					<span className="fb-text">
							{text}
						</span>
				</div>

			</a>
		);
	}
}

ProfileWorkFacebook.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => {
	const profile = findProfileByUserId(state, props.userId);
	if (profile === undefined) {
		return {};
	}

	return {
		profile: profile,
		level: levelSelectorById(state)[profile.LevelId]
	};
};

export default connect(mapStateToProps)(ProfileWorkFacebook);
