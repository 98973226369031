import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { myPayments } from "../poll/selectors";
import Money from "../components/text/Money";
import { NavLink } from "react-router-dom";
import { routes } from "../router/router";

class UserCredit extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	render() {
		if (this.props.myPayments === undefined) {
			return null;
		}
		return (
			// Overwrite default <a> colouring
			<NavLink to={routes.MyPayments} style={{ color: "#fff" }}>
				<span className="top_menu_item">
					<Money negativeValuesAreRed={true} value={this.props.myPayments.Credit} />
				</span>
			</NavLink>
		);
	}
}

UserCredit.propTypes = {
	userId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
	return {
		myPayments: myPayments(state)
	};
};

export default connect(mapStateToProps)(UserCredit);
