import {action, observable} from "mobx";

class ViewState {
	@observable
	public showMobileView: boolean = false;

	@action
	public setDesktopView = () => {
		this.showMobileView = false;
	};

	@action
	public setMobileView = () => {
		this.showMobileView = true;
	};
}

export const viewState = new ViewState();
