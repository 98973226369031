import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { uploadSections, uploadsGet } from "../../../api/apiEndpoints";
import UploadRenderer from '../../../components/uploads/UploadRenderer';

const ShiftBrand = ({ clientId }) => {
	const clientsById = useSelector(
		state =>
			state && state.poll && state.poll.byId && state.poll.byId.Clients
				? state.poll.byId.Clients || {}
				: {}
	);
	const brandsById = useSelector(
		state =>
			state && state.poll && state.poll.byId && state.poll.byId.Brands
				? state.poll.byId.Brands || {}
				: {}
	);

	const client = useMemo(
		() => {
			return clientId && clientsById && clientsById[clientId]
				? clientsById[clientId] || null
				: null;
		},
		[clientId, clientsById]
	);

	const brand = useMemo(() => {
		if (!Object.keys(brandsById).length) {
			return null
		}
		if (!client) {
			return null
		}
		const tmp = brandsById[client.BrandId]
		return tmp || null
	}, [client, brandsById])

	if (!brand) {
		return null;
	}

	if (!brand.brandImage) {
		return (
			<React.Fragment>
				<div>
					<b>Representing Brand</b>
				</div>
				<div>{brand.BrandName}</div>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<UploadRenderer showThumbnail={false} src={brand.brandImage} style={{maxWidth: '120px'}} />
		</React.Fragment>
	);
};

export default ShiftBrand;
