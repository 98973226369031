import {action, observable} from "mobx";
import {convertDDMMYYYToJSDate, convertJSDateToDDMMYYYY} from "../../lib/dateUtils/dateUtils";
import {adminKFWPOST, createKFWPOST, removeKFWDELETE} from "../../api/apiEndpoints";
import DateFns from "date-fns";
import axios from "axios";
import {toastr} from "react-redux-toastr";

export interface IADMINKFW2 {
	Id: number;
	UserId: number;
	UserGroupId: number;
	StageName: string;
	KFWHidden: boolean;
	KFW: boolean;
	Notes: string | null;
	KFWId: number | null;
	ShiftInfo: {
		Id: number;
		StartTime: string; // 3390 +8
		EndTime: string; // 3390 +8
		ShiftType: number;
		ShiftStatus: number;
		ClientId: number;
	}[];
}

class AdminKFW2state {
	public keenForWorkUsers = new KFWCategoryState("Keen for work users", true);
	public usersWithNoShifts = new KFWCategoryState("Users with no shifts", false);
	public usersWithShifts = new KFWCategoryState("Users with shifts", true);

	// Search params
	@observable
	public kfwQueryDate: string = convertJSDateToDDMMYYYY(new Date());

	// Filter params
	@observable
	public showInactiveUsers: boolean = false;

	@observable
	public loadingKFWList: boolean = false;

	// search params
	@action
	public updateKFWQueryDate = (newDate: string) => {
		this.kfwQueryDate = newDate;
		this.getCollections().forEach(collection => {
			collection.reset();
		})
		this.getKFWList();
	}

	// show/hide inactive users
	@observable
	public toggleShowInactiveUsers = () => {
		this.showInactiveUsers = !this.showInactiveUsers;
		this.getKFWList();
	}

	// clear all checked boxes
	@action
	public clearAllCheckedUsers = () => {
		this.getCollections().forEach(collection => {
			collection.clearAllChecks();
		})
	}

	public getCollections = (): KFWCategoryState[] => {
		return [
			this.keenForWorkUsers,
			this.usersWithNoShifts,
			this.usersWithShifts];
	}

	// return checked users formatted as an array
	public getAllUsersCheckedAsArray = (): number[] => {
		let usersCheckedAsArray: number[] = [];

		this.getCollections().forEach((collection) => {
			usersCheckedAsArray.push(...collection.getUsersCheckedAsArray())
		});

		return usersCheckedAsArray;
	}

	@action
	public getKFWList = () => {
		this.loadingKFWList = true;

		const searchDate = convertDDMMYYYToJSDate(this.kfwQueryDate);

		adminKFWPOST({
			StartTime: DateFns.startOfDay(searchDate),
			EndTime: DateFns.endOfDay(searchDate)
		}).then(r => {
			if (r.data === null) {
				return;
			}

			const kfwResults: IADMINKFW2[] = r.data;

			let keenForWorkUsers: IADMINKFW2[] = [],
				usersWithNoShifts: IADMINKFW2[] = [],
				usersWithShifts: IADMINKFW2[] = [];

			kfwResults.forEach(row => {
				if (!this.showInactiveUsers && row.UserGroupId === 0) {
					return;
				}

				if (row.KFW) {
					keenForWorkUsers.push(row);
					return;
				}
				if (row.ShiftInfo === null) {
					usersWithNoShifts.push(row);
					return;
				}

				usersWithShifts.push(row);
			});

			this.loadingKFWList = false;

			this.keenForWorkUsers.rows = keenForWorkUsers;
			this.usersWithNoShifts.rows = usersWithNoShifts;
			this.usersWithShifts.rows = usersWithShifts;

		}).catch(() => {

			this.getCollections().forEach(collection => {
				collection.reset();
			})
			toastr.warning("Could not load KFW list");
			this.getKFWList();
			this.loadingKFWList = false;
		});
	}

	// Add KFWers, performs a bulk calendar post
	@action
	public addKFWers = () => {
		const usersToChange = this.getAllUsersCheckedAsArray();
		const bulkKFWPOST = usersToChange.map(userId => {
			return createKFWPOST({
				UserId: userId,
				StartTime: DateFns.startOfDay(convertDDMMYYYToJSDate(this.kfwQueryDate))
			});
		});

		axios
			.all(bulkKFWPOST)
			.then(() => {
				toastr.info(
					"Successfully added " +
					Object.keys(usersToChange).length +
					" users to KFW"
				);
				this.getCollections().forEach(collection => {
					collection.clearAllChecks();
				});
				this.getKFWList();
			})
			.catch(() => {
				this.getCollections().forEach(collection => {
					collection.reset();
				})
				toastr.warning("Action failed");
				this.getKFWList();
			});
	};

	// remove KFWers, performs a bulk calendar post
	@action
	public removeKFWers = () => {

		const usersToChange = this.getAllUsersCheckedAsArray();
		let KFWIdsToDelete: number[] = [];

		// lookup KFW ids for the selected users
		this.getCollections().forEach(collection => {
			collection.rows.forEach(row => {

				if (usersToChange.indexOf(row.UserId) === -1) {
					return
				}
				KFWIdsToDelete.push(Number(row.KFWId));
			})
		})

		const bulkKFWPOST = KFWIdsToDelete.map(kfwId => {
			return removeKFWDELETE({
				Id: kfwId,
			});
		});

		axios
			.all(bulkKFWPOST)
			.then(() => {
				toastr.info(
					"Successfully removed " +
					Object.keys(usersToChange).length +
					" users from KFW"
				);
				this.getCollections().forEach(collection => {
					collection.clearAllChecks();
				});
				this.getKFWList();

			})
			.catch(() => {
				this.getCollections().forEach(collection => {
					collection.reset();
				})
				toastr.warning("Action failed");
				this.getKFWList();

			});
	};
}


export class KFWCategoryState {

	constructor(categoryHeading: string, showCategoryByDefault: boolean) {
		this.categoryHeading = categoryHeading;
		this.showCategory = showCategoryByDefault;
	}

	@observable
	public showCategory: boolean = false;

	@observable
	public categoryChecked: boolean = false;

	@observable
	public categoryHeading: string = "";

	@observable
	public usersChecked: number[] = [];

	@observable
	public rows: IADMINKFW2[] = [];

	@action
	public checkUserByUserId = (userId: number, checked: boolean) => {
		const found = this.usersChecked.indexOf(userId);

		if (checked && found === -1) {
			this.usersChecked.push(userId);
		} else {
			this.usersChecked.splice(found, 1);
		}
	}

	// return checked users formatted as an array
	public getUsersCheckedAsArray = (): number[] => {
		return this.usersChecked;
	}

	@action
	public toggleShowCategory = () => {
		this.showCategory = !this.showCategory;
	}

	@action
	public toggleSelectAllUsersForCategory = () => {
		this.categoryChecked = !this.categoryChecked;

		if (this.categoryChecked) {
			this.usersChecked = this.rows.map(row => {
				return row.UserId;
			})
		} else {
			this.usersChecked = [];
		}
	}

	@action
	public reset = () => {
		this.rows = [];
		this.clearAllChecks();
	}

	@action
	public clearAllChecks = () => {
		// set(this.usersChecked, {});
		this.usersChecked = [];
		this.categoryChecked = false;
	}
}


export const adminKFW2state = new AdminKFW2state();
// @ts-ignore
window.adminKFW2state = adminKFW2state;
