import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clientSelectorById } from "../../poll/selectors";

class SkimpyHeavenBankTransfer extends React.Component {
	render() {
		if (
			!this.props.clientsById ||
			!(this.props.clientId in this.props.clientsById) ||
			this.props.clientsById[this.props.clientId].PaymentMethod !==
				"Invoice SKH - Skimpy Account"
		) {
			return '';
		}

		return (
			<span
				title="Invoice SKH - Skimpy Account"
				style={{ fontSize: 18, fontWeight: "bold", fontStyle: "italic" }}
				className="color-skh-gold"
			>
				SH
			</span>
		);
	}
}

SkimpyHeavenBankTransfer.propTypes = {
	clientId: PropTypes.number
};

const mapStateToProps = (state, props) => {
	return {
		clientsById: clientSelectorById(state)
	};
};

SkimpyHeavenBankTransfer = connect(mapStateToProps)(SkimpyHeavenBankTransfer);
export default SkimpyHeavenBankTransfer;
