import * as React from "react";
import PropTypes from "prop-types";
import {
	clientReadNotesGET,
	clientReadNotesPATCH,
	shiftActionPOST
} from "../../../api/apiEndpoints";
import { connect } from "react-redux";
import {
	clientContactsSelectorAsArray,
	clientSelectorById,
	seriesSelectorById
} from "../../../poll/selectors";
import VenueNotes from "../../../Venue/components/VenueNotes";
import { Link } from "react-router-dom";
import AdminContainerMigrate from "../../../components/container/AdminContainerMigrate";
import { routes } from "../../../router/router";
import ClientNotes from "../../../components/text/ClientNotes";
import PhoneNumberLink from "../../../components/links/PhoneNumberLink";
import ClientNames from "../../../components/links/ClientNames";
import FormManager from "../../../components/form/FormManager";
import LoadingButton from "../../../components/button/LoadingButton";
import { Save } from "../../../components/icons/Icons";

const bookShiftPageOptions = {
	LoadVenueNotesRead: "LoadVenueNotesRead",
	VenueNotes: "VenueNotes",
	BookShift: "BookShift"
};

class BookShift extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Page: bookShiftPageOptions.BookShift,
			NotesRead: false
		};
	}

	componentDidMount() {
		clientReadNotesGET({ ClientId: this.props.shift.ChildClientIds[0] })
			.then(() => {
				this.setState({ NotesRead: true, Page: bookShiftPageOptions.BookShift });
			})
			.catch(e => {
				if (e.response.status === 400) {
					this.setState({ NotesRead: false, Page: bookShiftPageOptions.VenueNotes });
				}
			});
	}

	handleBookAction = action => {
		return () => {
			switch (action) {
				case "Book":
					shiftActionPOST({ Action: "book", ShiftId: this.props.shift.Id }).then(
						() => {
							this.props.handleCloseModal();
						}
					);
					break;
				case "Apply": {
					shiftActionPOST({ Action: "apply", ShiftId: this.props.shift.Id }).then(
						() => {
							this.props.handleCloseModal();
						}
					);
				}
			}
		};
	};

	render() {
		if (!this.props.clientSelectorById) {
			return null;
		}

		let content = null;

		switch (this.state.Page) {
			case bookShiftPageOptions.LoadVenueNotesRead:
				content = "Checking notes...";
				break;

			case bookShiftPageOptions.VenueNotes:
				content = (
					<div>
						<div>
							<h4>
								<ClientNames clientIds={this.props.shift.ChildClientIds} />
							</h4>
						</div>
						<div style={{ paddingBottom: 5 }}>
							<div>
								Venue -{" "}
								<PhoneNumberLink
									phoneNumber={
										this.props.clientSelectorById[this.props.shift.ChildClientIds[0]]
											.PhoneNumber
									}
								/>
							</div>
							{this.props.venueContactsAsArray
								.filter(contact => {
									return contact.ClientId === this.props.shift.ChildClientIds[0];
								})
								.map((contact, idx) => {
									return (
										<div key={idx}>
											{contact.FullName} -{" "}
											<PhoneNumberLink phoneNumber={contact.PhoneNumber} />
										</div>
									);
								})}
						</div>

						<ClientNotes clientId={this.props.shift.ChildClientIds[0]} />
						<div>
							<AdminContainerMigrate
								align="right"
								buttons={[
									<button
										className="btn btn-primary"
										style={{ float: "right" }}
										onClick={() => {
											clientReadNotesPATCH({
												ClientId: this.props.shift.ChildClientIds[0]
											}).then(() => {
												this.setState({ Page: bookShiftPageOptions.BookShift });
											});
										}}
									>
										<i className="fas fa-arrow-circle-right" /> Next
									</button>
								]}
							/>
						</div>
					</div>
				);
				break;

			case bookShiftPageOptions.BookShift:
				let apiAction = "";
				switch (this.props.shift.Action) {
					case "Book":
						apiAction = "book";
						break;
					case "Apply": {
						apiAction = "apply";
						break;
					}
				}

				content = (
					<div>
						<p className="text-center" style={{ fontSize: "2.0rem" }}>
							Before you book this shift, ensure you have read the entire venue
							information, including the invoicing instructions and payment method.
						</p>
						<p className="text-center" style={{ fontSize: "2.0rem" }}>
							Click{" "}
							<Link to={routes.Venue + "/" + this.props.shift.ChildClientIds[0]}>
								{" "}
								here
							</Link>{" "}
							to view information about this venue.
						</p>

						<FormManager
							handleSubmit={() => {
								shiftActionPOST({
									Action: apiAction,
									ShiftId: this.props.shift.Id
								}).then(() => {
									this.props.handleCloseModal();
								});
							}}
						>
							{/*FormManager children*/}
							{({
								formValues,
								handleOnChange,
								handleSubmit,
								formValidation,
								submitEnabled,
								submitting
							}) => {
								return (
									<AdminContainerMigrate
										align="right"
										buttons={[
											// Save Button
											<LoadingButton
												className={"btn btn-" + this.props.shift.ButtonClass}
												loading={submitting}
												disabled={submitting}
												onClick={handleSubmit}
											>
												<Save /> {this.props.shift.Action}
											</LoadingButton>
										]}
									/>
								);
							}}
						</FormManager>
					</div>
				);
				break;
		}

		return <div>{content}</div>;
	}
}

BookShift.propTypes = {
	shift: PropTypes.object.isRequired,
	handleCloseModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
	return {
		seriesById: seriesSelectorById(state),
		venueContactsAsArray: clientContactsSelectorAsArray(state),
		clientSelectorById: clientSelectorById(state)
	};
};

export default connect(mapStateToProps)(BookShift);
