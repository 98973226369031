import React, { useMemo, useState, useEffect, useContext } from "react";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import AdminPaymentsProvider, {
	AdminPaymentsContext
} from "./AdminPaymentsContext";
import PaymentResults from './components/PaymentResults';
import SearchBar from "./components/SearchBar";
import TopButtonBar from "./components/TopButtonbar";
import CreateCharge from "./CreateCharge";
import CreatePayment from "./CreatePayment";

const Inner = () => {
	const { userId } = useContext(AdminPaymentsContext);

	return (
		<>
			<div className="admin-box">
				<TopButtonBar />
			</div>
			<SearchBar />
            <PaymentResults key={userId} />
		</>
	);
};

const AdminPayments = ({ location, history }) => {
	return (
		<AdminPaymentsProvider initialState={{ location, history }}>
			<Inner />
		</AdminPaymentsProvider>
	);
};

export default AdminPayments;
