import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { paymentMethodsAsArray } from "../../config/selectors";
import Select from "./Select";
import { sortAlphaNumeric } from "./sort";

class PaymentMethodList extends React.Component {
	render() {
		if (!this.props.paymentMethodsAsArray) {
			return null;
		}

		const options = this.props.paymentMethodsAsArray.map(code => {
			return {
				value: code.Id,
				label: code.Description
			};
		});

		sortAlphaNumeric(options);

		return (
			<Select
				options={options}
				name={this.props.name}
				value={this.props.value}
				onChange={this.props.onChange}
				defaultOptionValue={0}
			/>
		);
	}
}

PaymentMethodList.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	value: PropTypes.any
};

const mapStateToProps = state => {
	return {
		paymentMethodsAsArray: paymentMethodsAsArray(state)
	};
};

export default connect(mapStateToProps)(PaymentMethodList);
