import * as React from "react";
import "./Select.css";
import { CaretDown, Times } from "../icons/Icons";
import { css } from "emotion";

interface ISelectOption {
	label: string;
	value: any;
	selected?: boolean;
}

interface ISelect {
	options: ISelectOption[];
	onChange: (e: any) => void;
	value?: any;
	searchable?: boolean;
	clearable?: boolean;
	name?: string;
	disabled?: boolean;
	className?: string;
}

class Select extends React.Component<ISelect> {
	public undefinedOptionText: string = "select an option";

	constructor(props: ISelect) {
		super(props);
	}

	static defaultProps = {
		searchable: true,
		clearable: true,
		disabled: false
	};

	public handleReset = () => {
		let normalizedOutput = {
			target: {
				name: this.props.name,
				value: undefined
			}
		};
		this.props.onChange(normalizedOutput);
	};

	handleOnChange = (e: any) => {
		// quit if no options are available
		if (this.props.options.length === 0) {
			return;
		}

		// select converts numbers to strings, this converts them back to numbers if they were originally numbers
		let value = e.target.value;

		if (value === this.undefinedOptionText) {
			value = undefined;
		} else if (typeof this.props.options[0].value === "number") {
			value = Number(value);
		}

		let normalizedOutput = {
			target: {
				name: this.props.name,
				value: value
			}
		};

		this.props.onChange(normalizedOutput);
	};

	render() {
		const paddingForCaret = 16;

		let caretClass = css`
			position: absolute;
			right: ${paddingForCaret / 2}px;
			line-height: 40px;
			pointer-events: none;
		`;

		const buttonWidth = 55;
		const buttonClass = css`
			position: absolute;
			right: 0px;
			height: 100%;
			width: ${buttonWidth}px;
			padding: 0px;
			background: none;
			color: rgba(255, 0, 0, 0.6);
			border-left: 1px solid lightgrey !important;
			& :hover {
				color: rgba(255, 0, 0, 1);
			}
		`;

		if (this.props.clearable && !this.props.disabled) {
			caretClass = css`
				${caretClass};
				right: ${buttonWidth + paddingForCaret / 2}px;
			`;
		}

		return (
			<div
				className={css`
					display: flex;
					position: relative;
					& select {
						padding-right: ${paddingForCaret}px !important;
					}
				`}
			>
				<select
					disabled={this.props.disabled}
					value={
						this.props.value === undefined || this.props.value === null
							? ""
							: this.props.value
					}
					onChange={this.handleOnChange}
					className={this.props.className}
					name={this.props.name}
				>
					{this.props.clearable && (
						<option value={this.undefinedOptionText}>
							{this.undefinedOptionText}
						</option>
					)}

					{this.props.options.map((option, optionIdx) => {
						return (
							<option
								key={optionIdx}
								value={option.value}
								dangerouslySetInnerHTML={{ __html: option.label }}
							/>
						);
					})}
				</select>

				<span className={caretClass}>
					<CaretDown />
				</span>

				{this.props.clearable &&
					!this.props.disabled && (
						<button onClick={this.handleReset} className={buttonClass}>
							CLEAR
						</button>
					)}
			</div>
		);
	}
}

export default Select;
