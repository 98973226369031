import * as React from "react";
import { connect } from "react-redux";
import {
	pollLoading,
	profileSelector,
	userSelectorById
} from "../poll/selectors";
import ProfileStageName from "../components/links/ProfileStageName";
import MiniContainer from "../components/container/MiniContainer";
import { poll } from "../poll/actions";
import { uploadSections, uploadsGet } from "../api/apiEndpoints";
import SearchBarItem from "../components/searchBar/SearchBarItem";
import Select from "../components/select/Select";
import SkimpyList from "../components/select/SkimpyList";
import { userGroups } from "../lib/userInfo/userInfo";

class SkimpyAlerts extends React.Component {
	state = {
		uploadsByProfileId: {},
		filters: {
			userId: undefined,
			category: undefined
		}
	};

	componentDidMount() {
		this.props.dispatch(poll({}));
		this.getUploads();
	}

	getUploads = () => {
		uploadsGet({
			UploadSectionTypes: [
				uploadSections.profile_rsa,
				uploadSections.profile_main_profile_picture,
				uploadSections.profile_proof
			]
		}).then(r => {
			const uploads = r.data || [];

			const uploadsByProfileId = uploads.reduce((newVals, currentVal) => {
				if (newVals[currentVal.UploadTypeId] === undefined) {
					newVals[currentVal.UploadTypeId] = [];
				}

				newVals[currentVal.UploadTypeId].push(currentVal);

				return newVals;
			}, {});

			this.setState({
				uploadsByProfileId: uploadsByProfileId
			});
		});
	};

	handleOnFilterChange = event => {
		let newFilterState = {
			...this.state.filters,
			[event.target.name]: event.target.value
		};
		this.setState({
			filters: newFilterState
		});
	};

	render() {
		const friendlyNames = {
			abn: "ABN",
			facebook: "Facebook Link",
			rsa: "RSA",
			mainProfilePicture: "Main Profile Picture",
			proofPicture: "Proof Picture"
		};

		// TODO: memoize this if its too slow
		const profilesToValidate = this.props.profilesAsArray.filter(profile => {
			return [
				userGroups.trial,
				userGroups.restricted,
				userGroups.fullAccess
			].includes(this.props.usersById[profile.UserId].UserGroupId);
		});

		// undefined alert is an OK status
		let alerts = profilesToValidate.map(profile => {
			let alert = {
				userId: profile.UserId
			};

			// ABN
			if (profile.ABN === "60151746682") {
				alert.abn = "Using SKH ABN";
			} else if (profile.ABN === null) {
				alert.abn = true;
			}

			// Facebook
			if (
				[
					"http://www.facebook.com",
					"http://www.facebook.com/skimpy.heaven",
					"http://facebook.com",
					"https://www.facebook.com/"
				].includes(profile.WorkFacebook)
			) {
				alert.facebook = `Not a valid Facebook profile: ${profile.WorkFacebook}`;
			} else if (profile.WorkFacebook === null) {
				alert.facebook = "Missing Facebook link";
			}

			// Uploads
			alert.rsa = "Missing";
			alert.mainProfilePicture = "Missing";
			alert.proofPicture = "Missing";
			if (profile.Id in this.state.uploadsByProfileId) {
				this.state.uploadsByProfileId[profile.Id].forEach(profileUpload => {
					switch (profileUpload.UploadSectionType) {
						case uploadSections.profile_rsa:
							delete alert["rsa"];
							break;
						case uploadSections.profile_proof:
							delete alert["proofPicture"];
							break;
						case uploadSections.profile_main_profile_picture:
							delete alert["mainProfilePicture"];
							break;
					}
				});
			}

			return alert;
		});

		if (this.state.filters.userId) {
			alerts = alerts.filter(alert => {
				return alert.userId === this.state.filters.userId;
			});
		}
		if (this.state.filters.category) {
			alerts = alerts.filter(alert => {
				return this.state.filters.category in alert;
			});
		}

		// sort results by most fail
		alerts.sort((a, b) => {
			const aFails = Object.keys(a).length;
			const bFails = Object.keys(b).length;
			// If equally as bad, then sort by RSA
			if (aFails === bFails) {
				if ("rsa" in a !== "rsa" in b) {
					return a.rsa === undefined ? -1 : 1;
				} else {
					return a.userId - b.userId;
				}
			}

			return bFails - aFails;
		});

		return (
			<React.Fragment>
				<div className="mini-container" style={{ marginBottom: 15 }}>
					<h4 className="heading">Search</h4>
					<div className="row">
						<SearchBarItem
							label={"Skimpy"}
							value={
								<SkimpyList
									name="userId"
									inactiveUsersVisible={false}
									onChange={this.handleOnFilterChange}
									value={this.state.filters.userId}
								/>
							}
						/>

						<SearchBarItem
							label={"Warning"}
							value={
								<Select
									options={Object.keys(friendlyNames).map(key => {
										return {
											label: friendlyNames[key],
											value: key
										};
									})}
									name="category"
									dayPickerOnly={true}
									onChange={this.handleOnFilterChange}
									value={this.state.filters.category}
								/>
							}
						/>
					</div>
				</div>

				{alerts.length === 0 && "No results found for query"}

				{alerts.map((alert, alertIdx) => {
					return (
						<MiniContainer key={alertIdx}>
							<ProfileStageName userId={alert.userId} />
							{Object.keys(friendlyNames).map(key => {
								if (this.state.filters.category) {
									if (key !== this.state.filters.category) {
										return null;
									}
								}

								return (
									<div key={alertIdx + key} style={{ display: "flex" }}>
										{key in alert ? (
											<div style={{ flexBasis: 20 }}>
												<i className="fas fa-exclamation-triangle fa-1x color-fail" />
											</div>
										) : (
											<div style={{ flexBasis: 20 }}>
												<i className="fas fa-check-square fa-1x color-success" />
											</div>
										)}
										<div>
											{friendlyNames[key]}
											{key in alert && (
												<div className="color-fail" style={{ fontWeight: "bold" }}>
													{alert[key]}
												</div>
											)}
										</div>
									</div>
								);
							})}
						</MiniContainer>
					);
				})}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profilesAsArray: profileSelector(state),
		usersById: userSelectorById(state),
		pollLoading: pollLoading(state)
	};
};

export default connect(mapStateToProps)(SkimpyAlerts);
