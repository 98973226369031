import * as React from "react";
import { connect } from "react-redux";
import { paymentSubCodesAsArray } from "../poll/selectors";
import { poll } from "../poll/actions";
import { paymentCodesAsArray } from "../poll/selectors";
import ResponsiveTableRow from "../components/table/ResponsiveTableRow";
import ResponsiveTableColumn from "../components/table/ResponsiveTableColumn";
import FormManager from "../components/form/FormManager";
import InputText from "../components/input/InputText";
import LoadingButton from "../components/button/LoadingButton";
import {
	paymentCategoryCodesDELETE,
	paymentCategoryCodesPATCH,
	paymentCategoryCodesPOST,
	paymentCategorySubCodesDELETE,
	paymentCategorySubCodesPATCH,
	paymentCategorySubCodesPOST
} from "../api/apiEndpoints";
import AdminContainer from "../components/container/AdminContainer";
import StandardModalWrapper from "../components/modal/StandardModalWrapper";
import PaymentCodeText from "../components/text/PaymentCodeText";
import PaymentSubCodeText from "../components/text/PaymentSubCodeText";
import { Save, Trash, Undo } from "../components/icons/Icons";

class ManageCodes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			codes: [], // { Id, Description, SubCodes }
			showDeleted: false
		};
	}

	componentDidMount() {
		this.props.dispatch(poll({}));
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.paymentCodeAsArray === undefined) {
			return;
		}

		this.setState({
			codes: this.sortAndFilterCodes(nextProps, this.state.showDeleted)
		});
	}

	sortAndFilterCodes = (props, showDeleted) => {
		return props.paymentCodeAsArray
			.filter(code => {
				if (showDeleted) {
					return true;
				}
				return code.DeletedAt === null;
			})
			.sort((a, b) => {
				return a["Description"].localeCompare(b["Description"]);
			})
			.map(code => {
				return {
					Id: code.Id,
					Description: code.Description,
					Internal: code.Internal,
					DeletedAt: code.DeletedAt,
					SubCodes: props.paymentSubCodesAsArray
						.filter(subCode => {
							if (subCode.ParentId !== code.Id) {
								return false;
							}

							if (showDeleted) {
								return true;
							}

							return subCode.DeletedAt === null;
						})
						.sort((a, b) => {
							return a["Description"].localeCompare(b["Description"]);
						})
				};
			});
	};

	handleShowHideDeletedCodes = () => {
		if (this.state.showDeleted) {
			this.setState({
				showDeleted: false,
				codes: this.sortAndFilterCodes(this.props, false)
			});
		} else {
			this.setState({
				showDeleted: true,
				codes: this.sortAndFilterCodes(this.props, true)
			});
		}
	};

	render() {
		return (
			<div>
				<AdminContainer>
					<StandardModalWrapper
						heading="Create Payment Code"
						modalOpener={({ handleOpenModal }) => {
							return (
								<button
									className="btn btn-primary admin-box-btn col-xs-3 col-sm-3"
									onClick={handleOpenModal}
								>
									<i className="fas fa-book" /> Create Payment Code
								</button>
							);
						}}
						render={({ handleCloseModal }) => {
							return (
								<div>
									<FormManager
										initialFormValues={{
											Description: ""
										}}
										handleValidation={v => {
											v.required({ fieldNames: ["Description"] });
										}}
										handleSubmit={({
											formValues,
											handleSubmitFail,
											handleSubmitSuccess
										}) => {
											paymentCategoryCodesPOST({
												Description: formValues.Description
											})
												.then(() => {
													handleSubmitSuccess();
													handleCloseModal();
												})
												.catch(() => {
													handleSubmitFail();
												});
										}}
									>
										{/*FormManager children*/}
										{({
											formValues,
											handleOnChange,
											handleSubmit,
											formValidation,
											submitEnabled,
											submitting
										}) => {
											return (
												<div className="row div-table-responsive-row">
													<ResponsiveTableColumn heading="Description" size={8}>
														<InputText
															name="Description"
															onChange={handleOnChange}
															value={formValues.Description}
															validationMessages={formValidation.Description}
														/>
													</ResponsiveTableColumn>

													<ResponsiveTableColumn size={4}>
														<div>
															{/*Save Button*/}
															<LoadingButton
																loading={submitting}
																disabled={!submitEnabled}
																onClick={handleSubmit}
															>
																<Save /> Save
															</LoadingButton>
														</div>
													</ResponsiveTableColumn>
												</div>
											);
										}}
									</FormManager>
								</div>
							);
						}}
					/>
					<button
						className="btn btn-primary admin-box-btn col-xs-3 col-sm-3"
						onClick={this.handleShowHideDeletedCodes}
					>
						<i
							className={
								this.state.showDeleted ? "fas fa-toggle-on" : "fas fa-toggle-off"
							}
						/>{" "}
						Show Deleted Codes
					</button>
				</AdminContainer>
				{this.state.codes.map((code, codeIdx) => {
					// formats internal codes with spaces
					let description = code.Description;
					if (code.Internal) {
						description = code.Description.replace(/([A-Z])/g, " $1").trim();
					}

					return (
						<div className="mini-container" key={codeIdx}>
							<ResponsiveTableRow>
								{({ editMode, handleToggleEditMode, submitText }) => {
									return (
										<div>
											<FormManager
												initialFormValues={{
													Description: description
												}}
												handleValidation={v => {
													v.required({ fieldNames: ["Description"] });
												}}
												handleSubmit={({
													formValues,
													handleSubmitFail,
													handleSubmitSuccess
												}) => {
													paymentCategoryCodesPATCH({
														Id: code.Id,
														Description: formValues.Description
													})
														.then(() => {
															handleToggleEditMode();
															handleSubmitSuccess();
														})
														.catch(() => {
															handleSubmitFail();
														});
												}}
											>
												{/*FormManager children*/}
												{({
													formValues,
													handleOnChange,
													handleSubmit,
													formValidation,
													submitEnabled,
													submitting
												}) => {
													let headingDisplay = null;

													if (!editMode || code.Internal) {
														headingDisplay = (
															<h4 className="color-charcoal">{formValues.Description}</h4>
														);
													} else {
														headingDisplay = (
															<InputText
																name="Description"
																onChange={handleOnChange}
																value={formValues.Description}
																validationMessages={formValidation.Description}
															/>
														);
													}

													return (
														<div className="row div-table-responsive-row">
															<div className="col-lg-5 col-sm-12">
																{code.DeletedAt === null ? (
																	headingDisplay
																) : (
																	<PaymentCodeText paymentCodeId={code.Id} />
																)}
															</div>

															{!code.Internal && (
																<ResponsiveTableColumn size={2}>
																	{editMode ? (
																		<div>
																			{/*Save Button*/}
																			<LoadingButton
																				loading={submitting}
																				disabled={!submitEnabled}
																				onClick={handleSubmit}
																			>
																				{submitText}
																			</LoadingButton>

																			{/*View button*/}
																			<button
																				className="btn btn-success"
																				onClick={handleToggleEditMode}
																			>
																				<Undo />
																			</button>
																		</div>
																	) : (
																		<div>
																			{/*Edit button */}
																			<button
																				className="btn btn-skh-gold"
																				onClick={handleToggleEditMode}
																			>
																				{submitText}
																			</button>

																			{/*Delete Button*/}
																			<FormManager
																				handleSubmit={({
																					handleSubmitFail,
																					handleSubmitSuccess
																				}) => {
																					paymentCategoryCodesDELETE({ Id: code.Id })
																						.then(() => {
																							handleSubmitSuccess();
																						})
																						.catch(() => {
																							handleSubmitFail();
																						});
																				}}
																			>
																				{({ handleSubmit }) => {
																					return (
																						<button onClick={handleSubmit} className="btn btn-danger">
																							<Trash />
																						</button>
																					);
																				}}
																			</FormManager>
																		</div>
																	)}
																</ResponsiveTableColumn>
															)}
														</div>
													);
												}}
											</FormManager>
										</div>
									);
								}}
							</ResponsiveTableRow>

							<ResponsiveTableRow>
								{({ editMode, handleToggleEditMode, submitText }) => {
									return (
										<FormManager
											initialFormValues={{
												Description: ""
											}}
											handleValidation={v => {
												v.required({ fieldNames: ["Description"] });
											}}
											handleSubmit={({
												formValues,
												handleSubmitFail,
												handleSubmitSuccess
											}) => {
												paymentCategorySubCodesPOST({
													ParentId: code.Id,
													Description: formValues.Description
												})
													.then(() => {
														handleToggleEditMode();
														handleSubmitSuccess();
													})
													.catch(() => {
														handleSubmitFail();
													});
											}}
										>
											{/*FormManager children*/}
											{({
												formValues,
												handleOnChange,
												handleSubmit,
												formValidation,
												submitEnabled,
												submitting
											}) => {
												if (!editMode) {
													return (
														<div className="row">
															<ResponsiveTableColumn size={2} offsetSize={0}>
																<button
																	className="btn-clear create-new-row"
																	onClick={handleToggleEditMode}
																>
																	Create Subcode
																</button>
															</ResponsiveTableColumn>
														</div>
													);
												}

												return (
													<div className="row div-table-responsive-row">
														<ResponsiveTableColumn heading="Description" size={5}>
															<InputText
																name="Description"
																onChange={handleOnChange}
																value={formValues.Description}
																validationMessages={formValidation.Description}
															/>
														</ResponsiveTableColumn>

														<ResponsiveTableColumn size={2}>
															<div>
																{/* Save button */}
																<LoadingButton
																	loading={submitting}
																	disabled={!submitEnabled}
																	onClick={handleSubmit}
																>
																	{submitText}
																</LoadingButton>
																{/* Delete button */}
																<button
																	className="btn btn-danger"
																	onClick={handleToggleEditMode}
																>
																	<Trash />
																</button>
															</div>
														</ResponsiveTableColumn>
													</div>
												);
											}}
										</FormManager>
									);
								}}
							</ResponsiveTableRow>
							{/*
                            View / Edit existing records
                        */}
							{code.SubCodes.map((rowValues, rowIdx) => {
								return (
									<ResponsiveTableRow key={rowIdx}>
										{({ editMode, handleToggleEditMode, submitText }) => {
											return (
												<FormManager
													initialFormValues={rowValues}
													handleValidation={v => {
														v.required({ fieldNames: ["Description"] });
													}}
													handleSubmit={({
														formValues,
														handleSubmitFail,
														handleSubmitSuccess
													}) => {
														paymentCategorySubCodesPATCH({
															Id: formValues.Id,
															Description: formValues.Description
														})
															.then(() => {
																handleToggleEditMode();
																handleSubmitSuccess();
															})
															.catch(() => {
																handleSubmitFail();
															});
													}}
												>
													{/*FormManager children*/}
													{({
														formValues,
														handleOnChange,
														handleSubmit,
														formValidation,
														submitEnabled,
														submitting
													}) => {
														return (
															<div className="row div-table-responsive-row">
																<ResponsiveTableColumn heading="Description" size={5}>
																	{editMode ? (
																		<InputText
																			name="Description"
																			onChange={handleOnChange}
																			value={formValues.Description}
																			validationMessages={formValidation.Description}
																		/>
																	) : (
																		<span className="div-table-responsive-text">
																			{formValues.DeletedAt === null ? (
																				formValues.Description
																			) : (
																				<PaymentSubCodeText paymentSubCodeId={rowValues.Id} />
																			)}
																		</span>
																	)}
																</ResponsiveTableColumn>
																<ResponsiveTableColumn size={2}>
																	{editMode ? (
																		<div>
																			{/*Save Button*/}
																			<LoadingButton
																				loading={submitting}
																				disabled={!submitEnabled}
																				onClick={handleSubmit}
																			>
																				{submitText}
																			</LoadingButton>

																			{/*View button*/}
																			<button
																				className="btn btn-success"
																				onClick={handleToggleEditMode}
																			>
																				<Undo />
																			</button>
																		</div>
																	) : (
																		<div>
																			{/*Edit button */}
																			<button
																				className="btn btn-info"
																				onClick={handleToggleEditMode}
																			>
																				{submitText}
																			</button>

																			{/*Delete Button*/}
																			<FormManager
																				initialFormValues={rowValues}
																				handleSubmit={({
																					formValues,
																					handleSubmitFail,
																					handleSubmitSuccess
																				}) => {
																					paymentCategorySubCodesDELETE({ Id: formValues.Id })
																						.then(() => {
																							handleSubmitSuccess();
																						})
																						.catch(() => {
																							handleSubmitFail();
																						});
																				}}
																			>
																				{({ handleSubmit }) => {
																					return (
																						<button onClick={handleSubmit} className="btn btn-danger">
																							<Trash />
																						</button>
																					);
																				}}
																			</FormManager>
																		</div>
																	)}
																</ResponsiveTableColumn>
															</div>
														);
													}}
												</FormManager>
											);
										}}
									</ResponsiveTableRow>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}
}

ManageCodes.propTypes = {};

const mapStateToProps = state => {
	return {
		paymentCodeAsArray: paymentCodesAsArray(state),
		paymentSubCodesAsArray: paymentSubCodesAsArray(state)
	};
};

export default connect(mapStateToProps)(ManageCodes);
